// @ts-nocheck
import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LProps, withLocalization } from '../utils/wrappers';
import { State } from '../redux/state';
import actions from '../redux/actions';
import TopUserScores from '../shared/TopUserScores';
import { Routes, getCompetitionUserScoresForEntityParams } from '../utils/routerHelper';
import { WaitingPageContent } from '../shared/WaitingPage';
import { RouterProps, withRouter } from 'react-router';
import { asPage } from '../utils/wrappers/asPage';

interface Props {
  entityID: number;
  initializing: boolean;
  initializeTopUserScoresPage: (entityID: number) => void;
}

const didEntityIDChange = (nextProps: Props, props: Props) =>
  !isNaN(nextProps.entityID) && props.entityID !== nextProps.entityID;

class EntityTopUserScores extends React.Component<LProps<Props>> {
  public componentWillMount() {
    const { initializeTopUserScoresPage, entityID } = this.props;
    initializeTopUserScoresPage(entityID);
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (didEntityIDChange(nextProps, this.props)) {
      const { initializeTopUserScoresPage, entityID } = nextProps;
      initializeTopUserScoresPage(entityID);
    }
  }

  public render() {
    const { entityID, initializing } = this.props;
    if (isNaN(entityID) || initializing) {
      return <WaitingPageContent />;
    }
    return (
      <TopUserScores
        entityID={entityID}
      />
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => ({
  entityID: getCompetitionUserScoresForEntityParams(ownProps.history.location, ownProps.i18n).entityID,
  initializing: state.pendingRoute === Routes.CompetitionUserScoresForEntity
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  initializeTopUserScoresPage: actions.initializeTopUserScoresPage
}, dispatch);

export default asPage({})(compose(
  withLocalization('topUserScores'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EntityTopUserScores) as React.SFC);
