import { Dispatch } from 'redux';
import up from 'updeep';
import { throttle, isEqual } from 'lodash';
import * as selectors from '../selectors';
import { State, defaultState, BlogEntryQuery, Modals } from '../state';
import { ImageResponse, BlogEntry } from '../../userApi';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';
import { ActionType } from './types';
import { postImage } from './image';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { ExtraArguments } from '../extraArguments';
import { makeBlogEntryPath, makeEntityBlogPath, getEntityBlogParams, getBlogEntryParams, Routes } from '../../utils/routerHelper';
import { BlogEntryForm } from '../../utils/forms';
import { trackEvent } from '../../utils/googleAnalytics';

export const saveBlogEntry = (blogEntryForm: BlogEntryForm, fileInputID: string) =>
async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { i18n, getImage } = extra;
  const t = i18n.getFixedT(extra.i18n.language, 'blogEntry');
  dispatch(patch({ spinner: true }));

  const blogEntry = {
    ...blogEntryForm
  } as BlogEntry;
  let imageResponse: ImageResponse;
  if (getImage(fileInputID)) {
    imageResponse = await postImage(fileInputID)(dispatch, getState, extra);
    if (!imageResponse) return;
    blogEntry.photo = imageResponse.url;
  }

  if (!blogEntry.photo) delete blogEntry.photo;
  const { converter } = await import('../../utils/markdown');
  blogEntry.contentHTML = converter.makeHtml(blogEntry.contentMD);

  const entity = getState().entities[blogEntry.entityID];
  const entityName = entity && entity.name;
  let savedBlogEntry: BlogEntry;
  try {
    if (blogEntry.id === 0) {
      savedBlogEntry = await postEntityBlogEntry(blogEntry)(dispatch, getState, extra);
      trackEvent('blogEntry', 'create', entityName);
    } else {
      savedBlogEntry = await putBlogEntry(blogEntry)(dispatch, getState, extra);
      trackEvent('blogEntry', 'update', entityName);
    }
    toast({
      style: StyleState.Success,
      message: t('Your blog entry was successully saved')
    })(dispatch, getState);
    extra.history.push(makeBlogEntryPath(savedBlogEntry.id, t));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('blogEntry', 'unhandledError', entityName);
  }
};

const postEntityBlogEntry = (blogEntry: BlogEntry) => async (dispatch: Dispatch, getState: () => State, { history, i18n}: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'blogEntry');
  const state = getState();
  const api = selectors.userApi(state);
  const res = await api.postEntityBlogEntries(blogEntry.entityID, { blogEntry });
  const update: Partial<State> = {
    spinner: false,
    blogEntries: [
      res.blogEntry,
      ...state.blogEntries
    ]
  };
  dispatch(patch(update));
  return res.blogEntry;
};

const putBlogEntry = (blogEntry: BlogEntry) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { blogEntries } = getState();
  const api = selectors.userApi(getState());
  const res = await api.putEntityBlogEntry(blogEntry.entityID, blogEntry.id, { blogEntry });
  const index = blogEntries.findIndex((be) => be.id === blogEntry.id);
  dispatch(patch({
    spinner: false,
    blogEntries: up.updateIn(`${index}`, res.blogEntry, blogEntries)
  }));
  return res.blogEntry;
};

export const deleteBlogEntry = (blogEntryToDelete: BlogEntry) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { blogEntries } = getState();
  dispatch(patch({ spinner: true, modal: Modals.None }));
  const t = extra.i18n.getFixedT(extra.i18n.language, 'blogEntry');
  const api = selectors.userApi(getState());
  try {
    await api.deleteEntityBlogEntry(blogEntryToDelete.entityID, blogEntryToDelete.id);
    extra.history.push(makeEntityBlogPath(blogEntryToDelete.entityID, t));
    toast({
      style: StyleState.Success,
      message: t('Your blog entry was successully deleted.')
    })(dispatch, getState);
    dispatch(patch({
      spinner: false,
      blogEntries: up.reject((be) => be.id === blogEntryToDelete.id)
    }));
  } catch (err) {
    dispatch(patch({ blogEntries }));
    handleUnexpectedError(err)(dispatch, getState);
  }
};

export const initializeBlogEntryForm = (blogEntryID: number) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  if (blogEntryID === 0) {
    dispatch(patch({
      pendingRoute: null
    }));
    return;
  }
  const { blogEntries } = getState();
  let blogEntry = blogEntries.find((be) => be.id === blogEntryID);
  if (!blogEntry) {
    const newQuery: Partial<BlogEntryQuery> = { entityID: 0, blogEntryID };
    await initializeBlogEntries(newQuery)(dispatch, getState, extra);
    blogEntry = blogEntries.find((be) => be.id === blogEntryID);
  }
  const { pendingRoute } = getState();
  dispatch(patch({
    pendingRoute: pendingRoute === Routes.BlogEntryEdit ? null : pendingRoute
  }));
};

export const initializeBlogPage = () => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const newQuery: Partial<BlogEntryQuery> = { entityID: 0, blogEntryID: 0 };
  await initializeBlogEntries(newQuery)(dispatch, getState, extra);
  const { pendingRoute } = getState();
  dispatch(patch({ pendingRoute: pendingRoute === Routes.Blog ? null : pendingRoute }));
};

export const initializeBlogEntryPage = () => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const params = getBlogEntryParams(extra.history.location, extra.i18n);
  const { blogEntries } = getState();
  // if blog entry already loaded, no need to re-retrieve from the API.
  if (blogEntries.some((be) => be.id === params.blogEntryID)) {
    dispatch(patch({ pendingRoute: null }));
    return;
  }

  const newQuery: Partial<BlogEntryQuery> = { entityID: 0, blogEntryID: (params.blogEntryID || 0) };
  await initializeBlogEntries(newQuery)(dispatch, getState, extra);
  const { pendingRoute } = getState();
  dispatch(patch({ pendingRoute: pendingRoute === Routes.BlogEntry ? null : pendingRoute }));
};

export const initializeEntityBlogPage = () => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const params = getEntityBlogParams(extra.history.location, extra.i18n);
  const newQuery: Partial<BlogEntryQuery> = { entityID: params.entityID, blogEntryID: 0 };
  await initializeBlogEntries(newQuery)(dispatch, getState, extra);
  const { pendingRoute } = getState();
  dispatch(patch({ pendingRoute: pendingRoute === Routes.EntityBlog ? null : pendingRoute }));
};

const initializeBlogEntries = (newQuery: Partial<BlogEntryQuery>) => async (dispatch: Dispatch, getState: () => State, { history, i18n }: ExtraArguments) => {
  const state = getState();
  const { blogEntryQuery, competition, token } = state;
  newQuery.competitionID = competition.id;
  if (isEqual(blogEntryQuery, newQuery)) return;

  setBlogEntryQuery(newQuery as BlogEntryQuery)(dispatch, getState);
  dispatch(patch({ spinner: true }));
  const api = selectors.userApi(state);
  try {
    const { blogEntryID, entityID, competitionID } = newQuery;
    const res = await api.getBlogEntries(entityID, competitionID, blogEntryID, 1, token);
    dispatch(patch({
      ...res,
      spinner: false
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
  }
};

const setBlogEntryQuery = (blogEntryQuery: BlogEntryQuery) => async (dispatch: Dispatch, _getState: () => State) => dispatch(patch({
  blogEntryQuery,
  blogEntries: up.constant(defaultState.blogEntries),
  blogEntryPagination: up.constant({ nextPage: -1 }),
  blogEntryComments: up.constant({}),
  blogEntryCommentPagination: up.constant({}),
  blogEntryCommentsFlags: up.constant({}),
  blogEntryCommentsLikes: up.constant({}),
  blogEntryLikes: up.constant({})
}, ActionType.SetBlogEntryQuery));

const pageBlogEntriesBase = (_page: number) => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const { blogEntryPagination, blogEntries, blogEntryQuery } = state;
  const api = selectors.userApi(state);
  try {
    const { blogEntryID, entityID, competitionID } = blogEntryQuery;
    const res = await api.getBlogEntries(entityID, competitionID, blogEntryID, blogEntryPagination.nextPage);
    const update: Partial<State> = {
      ...res,
      blogEntries: [
        ...blogEntries,
        ...res.blogEntries
      ]
    };
    dispatch(patch(update, ActionType.GetBlogEntries));
  } catch (err) {
    dispatch(patch({
      blogEntryPagination: { nextPage: -1 }
    }, ActionType.GetBlogEntries));
    handleUnexpectedError(err)(dispatch, getState);
  }
};

export const pageBlogEntries = throttle(pageBlogEntriesBase, 500);
