import { toast } from './toast';
import { patch } from './patch';
import logger from '../../utils/logger';
import { Dispatch } from 'redux';
import { State, Toast } from '../state';
import { StyleState } from '../../utils/styles';
import { ValidationErrors } from '../../userApi';
import { isResponse, getValidationErrors } from '../../utils/userApiHelper';

const genericErrorToast = {
  style: StyleState.Danger,
  message: 'Sorry, we\'ve experienced an unexpected error. We\'re looking into it. If the error persists, please let our staff know at contact@coolcampuschallenge.org.'
};

export const handleUnexpectedError = (err: any, toastData: Partial<Toast> = genericErrorToast) => async (dispatch: Dispatch, getState: () => State) => {
  if (isResponse(err)) {
    // Token expired. Logout.
    if (err.status === 401) {
      localStorage.clear();      
      window.location.pathname = '/';
      return;
    }
    logUnexpectedResponse(err);
  }

  dispatch(patch({ spinner: false }));
  logger.error(err);
  toast(toastData)(dispatch, getState);
};

export const handleUserApiError = (err: any, fnValidationErrors?: (ve: ValidationErrors) => void, toastData: Partial<Toast> = genericErrorToast) =>
async (dispatch: Dispatch, getState: () => State) => {
    const validationErrors = await getValidationErrors(err);
    if (typeof validationErrors === 'function') {
      fnValidationErrors(validationErrors);
      return;
    }

    handleUnexpectedError(err, toastData)(dispatch, getState);
    return;
};

const logUnexpectedResponse = async (res: Response) => {
  const ve = await getValidationErrors(res);
  if (ve) {
    logger.error(new Error(`Unhandled API response: ${JSON.stringify({ validationErrors: ve, url: res.url, status: res.status })}`));
  } else {
    const text = await res.text();
    logger.error(new Error(`Unhandled API response: ${JSON.stringify({ text, url: res.url, status: res.status })}`));
  }
};
