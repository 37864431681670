import * as React from 'react';
import { Alert } from 'reactstrap';
import { LProps, withLocalization } from '../utils/wrappers';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { CompetitionPledge } from '../userApi';
import { isUserTypeEligibleForPledge, getScoreLevelPhoto, isUserScoreEligibleForPledge } from '../utils/userApiHelper';
import { orJoin } from '../utils/strings';
import logger from '../utils/logger';
import { compose } from 'redux';
import { connect } from 'react-redux';

interface ManualProps {
  pledge: CompetitionPledge;
}

interface Props extends ManualProps {
  message: string;
}

interface UserIneligibleForPledgeState {
  hasError: boolean;
}

// IMPORTANT: This does not handle release date eligibility.
class UserIneligibleForPledge extends React.Component<LProps<Props>, UserIneligibleForPledgeState> {
  public state = { hasError: false };

  public static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    logger.error(error, info);
  }

  public render() {
    const { hasError } = this.state;
    if (hasError) return <div />;
    const { message } = this.props;
    return (
      <Alert
        className="cc__user_ineligible_for_pledge"
        color="secondary"
      >
        <h6
          dangerouslySetInnerHTML={{
            __html: message
          }}
        />
      </Alert>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<Props>): Partial<Props> => {
  const { user } = state;
  const { pledge, t, i18n, formatNumber } = ownProps;

  const typeIneligible = !isUserTypeEligibleForPledge(user, ownProps.pledge);
  if (typeIneligible) {
    return {
      message: t('You must be a {{userType}} user to take this action.', { userType: orJoin(pledge.userTypes.map((ut) => ut.toString()), i18n)})
    };
  }
  const { competitionScoreLevels, competitionUserScores } = state;
  const score = competitionUserScores[user.id];
  const isScoreIneligible = !isUserScoreEligibleForPledge(score, competitionScoreLevels, pledge);
  if (isScoreIneligible) {
    const nextScoreLevel = selectors.nextUserScoreLevel(state);
    const pointsToNextLevel = nextScoreLevel.threshold - score.points;
    const icon = getScoreLevelPhoto(nextScoreLevel) ? `<img src="${getScoreLevelPhoto(nextScoreLevel)}" alt="${nextScoreLevel.name}" />`: undefined;
    const message = t('Earn {{pointsToNextLevel}} more points to achieve {{icon}} {{scoreLevel}} and take this action!', {
      pointsToNextLevel: formatNumber(pointsToNextLevel, 0),
      scoreLevel: nextScoreLevel.name,
      icon
    });
    return {
      message
    };
  }
  return {
    message: t('You are ineligible for this pledge')
  };
};

export default compose(
  withLocalization('userIneligibleForPledge'),
  connect(mapStateToProps)
)(UserIneligibleForPledge) as React.SFC<ManualProps>;
