import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import { RouterProps, withRouter } from 'react-router';
import actions from '../../redux/actions';
import { getPledgeCategoryParams } from '../../utils/routerHelper';
import { asPage } from '../../utils/wrappers/asPage';
import Pledges from '../../shared/PledgesTable';

interface Props {
  pledgeCategoryID: number;
  initializePledgeCategoryPage: () => void;
}

class PledgeCategoryPage extends React.Component<LProps<Props> & RouterProps> {
  public componentWillMount() {
    const { initializePledgeCategoryPage } = this.props;
    initializePledgeCategoryPage();
  }

  public render() {
    const {
      pledgeCategoryID
    } = this.props;
    return (
      <div className="cc__page__column_content">
        <Pledges competitionPledgeCategoryID={pledgeCategoryID} />
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => ({
  pledgeCategoryID: getPledgeCategoryParams(ownProps.history.location, ownProps.i18n).competitionPledgeCategoryID
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializePledgeCategoryPage: actions.initializePledgeCategoryPage
}, dispatch);

export default asPage({ requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withLocalization('blog'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PledgeCategoryPage) as React.SFC);
