import * as React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { CompetitionScoreLevel } from '../userApi';
import { getScoreLevelPhoto } from '../utils/userApiHelper';
import { LProps, withLocalization } from '../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { State, Modals } from '../redux/state';
import actions from '../redux/actions';
import { connect } from 'react-redux';

interface Props {
  scoreLevels: CompetitionScoreLevel[];
  isOpen: boolean;
  toggle: () => void;
}

const ScoreLevelModal = ({
  isOpen, toggle, scoreLevels, formatNumber, t
}: LProps<Props>) => (
  <Modal id="cc__score_level_modal" isOpen={isOpen} toggle={toggle}>
    <ModalBody>
      <Button onClick={toggle} close={true} />
      {scoreLevels.concat().reverse().map((scoreLevel) => (
        <div className="cc__score_level" key={scoreLevel.id}>
          <div className="cc__score_level__img">
            {getScoreLevelPhoto(scoreLevel) ?
              <img src={getScoreLevelPhoto(scoreLevel)} alt={scoreLevel.name} />
            : (<span className="placeholder"/>)}
            </div>
          <div>
            <div className="cc__score_level__name">{scoreLevel.name}</div>
            <div className="cc__score_level__points">{t('{{ threshold }} Points', { threshold: formatNumber(scoreLevel.threshold)} )}</div>
          </div>
        </div>
      ))}
    </ModalBody>
  </Modal>
);

const mapStateToProps = (state: State): Partial<Props> => ({
  isOpen: state.modal === Modals.CompetitionScoreLevels,
  scoreLevels: state.competitionScoreLevels
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  toggle: actions.closeModal
}, dispatch);
export default compose(
  withLocalization('scoreLevel'),
  connect(mapStateToProps, mapDispatchToProps)
)(ScoreLevelModal) as React.SFC;
