// @ts-nocheck
import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { LProps, withLocalization } from '../../utils/wrappers';
import { Competition, CompetitionUserScore } from '../../userApi';
import { State } from '../../redux/state';
import actions from '../../redux/actions';
import CompetitionUserScoreRow from './CompetitionUserScoreRow';
import Paginator from '../Paginator';
import "./topUserScores.css"

interface ManualProps {
  entityID: number;
  onSort: (key:string)=>void;
}

interface Props extends ManualProps {
  competition: Competition;
  topUserScores: CompetitionUserScore[];
  page: number;
  pageSize: number;
  totalPages: number;
  pageTopUserScores: (page: number, sort:string) => void;  
}
interface TopUserScoresState{
  term: string
  sort: string,
  desc: boolean
}

class TopUserScores extends React.Component<LProps<Props>,TopUserScoresState> {
  constructor(props:LProps<Props>){
    super(props)
    this.state ={
      term: "",
      sort: 'points',
      desc: true
    }
  }  
  changeSort(newKey){    
    const newState = {desc: true,sort: newKey}
    if(newKey == this.state.sort){
      newState.desc = !this.state.desc
    }    
    this.setState(newState)
    this.props.pageTopUserScores(this.props.page, `${newState.desc?"-":""}${newKey}`)
  }
  paginate(page:number){
    
    this.props.pageTopUserScores(page, `${this.state.desc?"-":""}${this.state.sort}`)
  }

  public render() {
    const {
      t, topUserScores, page, pageSize, totalPages,
      pageTopUserScores
    } = this.props;
    return (
      <div>

        <Table>
          <thead>
            <tr>
              <th >#</th>
              <th className="clickable" onClick={ ()=> this.changeSort("name")}>{t('Name')}</th>
              <th>{t('Campus')}</th>
              <th className="clickable" onClick={ ()=> this.changeSort("points")}>{t('Points')}</th>
              <th className="clickable" onClick={ ()=> this.changeSort("co2")}>Lbs CO<sub>2</sub> saved</th>
            </tr>
          </thead>
          <tbody>
            {topUserScores.map((cus, i) => (
              <CompetitionUserScoreRow
                competitionID={this.props.competition.id}
                key={cus.userID}
                score={cus}
                rank={(page - 1) * pageSize + i + 1}
              />
            ))}
          </tbody>
        </Table>
        <Paginator
          totalPages={totalPages}
          page={page}
          showPage={(page)=>this.paginate(page)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: ManualProps): Partial<Props> => ({
  competition: state.competition,
  topUserScores: state.topCompetitionUserScores,
  page: state.topCompetitionUserScoreQuery.pagination.page,
  pageSize: state.topCompetitionUserScoreQuery.pagination.pageSize,
  totalPages: state.topCompetitionUserScoreQuery.pagination.totalPages
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: ManualProps): Partial<Props> => bindActionCreators({
  initializeTopUserScoresPage: () => actions.initializeTopUserScoresPage(ownProps.entityID),
  pageTopUserScores: actions.pageTopUserScores
}, dispatch);

export default compose(
  withLocalization('topUserScores'),
  connect(mapStateToProps, mapDispatchToProps)
)(TopUserScores) as React.SFC<ManualProps>;
