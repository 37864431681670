import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { i18nextContext, I18next } from '../i18nextShim';
import { Routes, getCurrentRoute, goToRouteByName } from '../routerHelper';

interface RouterHelpers {
  currentRoute: string;
  pushRoute: (route: Routes) => void;
  goToRouteByName: (route: Routes) => void;
}

export type RouterProps<P> = RouteComponentProps<P> & RouterHelpers;

type RouterComponent<P> = React.FunctionComponent<RouterProps<P>> |
  React.ComponentClass<RouterProps<P>>;

export const withRouterAndHelpers = <P>(Component: RouterComponent<P>) => {
  const wrapped = ({ history, location, match, ...props }: RouteComponentProps<P>) =>
    React.createElement(
      i18nextContext.Consumer,
      {
        children: (i18n: I18next) => {
          const pushRoute = (route: Routes) => history.push(`/${localStorage.competitionName}/${route}`);
          const currentRoute = getCurrentRoute(location, i18n);
          return React.createElement(
            Component,
            {
              history, location, match, pushRoute,
              goToRouteByName: (route: Routes) => goToRouteByName(route, history, i18n),
              currentRoute, ...props
            }
          );
        }
      }
  );
  return withRouter(wrapped);
};
