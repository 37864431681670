import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { Button } from 'reactstrap';
import RouteLink from './RouteLink';
import { Routes, makeEntityPath, makeFaqPath, FaqSection } from '../utils/routerHelper';
import { LProps, withLocalization, withRouterAndHelpers, RouterProps } from '../utils/wrappers';
import actions from '../redux/actions';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { UserRead, Entity, Competition } from '../userApi';
import NavBarDropdown from './NavBarDropdown';
import { isProduction } from '../utils/environment';

interface Props {
  isLoggedIn: boolean;
  hasJoined: boolean;
  user: UserRead;
  university: Entity;
  competition: Competition;
  canViewCompetitionContent: boolean;
  logout: () => void;
}

const buttonStyle = {
  width: '135px',
  height: '39px',
  marginRight: '14px'
};

const NavBar = ({
  t, history, logout, user, goToRouteByName, isLoggedIn,hasJoined,
  university, competition, canViewCompetitionContent
}: LProps<RouterProps<{}> & Props>) => { return (
  <div id="nav_bar">
    <div className="cc__nav_bar__content">
      <div className="cc_nav_bar__logo">
        {competition && <Link to={`/${competition.name}`}><img className="cc__logo" src="/assets/logo/blue.png" alt={t('Cool Campus Challenge logo')} /></Link>}
        
      </div>
      <div className="cc_nav_bar__tools">
        {!isLoggedIn &&
        <div className="nav_bar__web__buttons">
          <Button id="nav_bar__signup" className={'cc__rounded'} onClick={() => goToRouteByName(Routes.Signup)} style={buttonStyle}>
            {t('Get Started')}
          </Button>
          <Button id="nav_bar__login" className={'cc__rounded'} onClick={() => goToRouteByName(Routes.Login)} style={buttonStyle}>
            {t('Login')}
          </Button>
        </div>
        }
        {(!isLoggedIn || !competition ) &&
          <div id="nav_bar__web__links">
            {competition && <RouteLink activable={true} route={Routes.About}>{t('About')}</RouteLink>}
            {!competition && <RouteLink activable={true} route={Routes.GlobalAbout} >{t('About')}</RouteLink>}
            {!isProduction && competition && <RouteLink activable={true} route={Routes.Blog}>{t('Blog')}</RouteLink>}
            <Link className={cx({ inactive: history.location.pathname.search('faq') < 0 })} to={makeFaqPath(FaqSection.General, t)}>{t('FAQ')}</Link>
          </div>
        }
        <div className="cc__nav_bar__web__avatar">
          {competition && <NavBarDropdown
            t={t}
            user={user}
            logout={logout}
            history={history}
            university={university}
            competition={competition}
            loggedIn={!!(isLoggedIn && user)}
            isProduction={isProduction}
          />}
        </div>
        {(isLoggedIn && canViewCompetitionContent) &&
          <div id="nav_bar__web__links">
            <RouteLink activable={true} route={Routes.Pledges}>{t('Actions')}</RouteLink>
            <RouteLink activable={true} route={Routes.Account}>{t('Account')}</RouteLink>
            {university &&
              <Link
                to={makeEntityPath(competition.id, university, t)}
                className={cx({ inactive: history.location.pathname !==  makeEntityPath(competition.id, university, t)})}
              >{t('Campus')}</Link>
            }
            <RouteLink activable={true} route={Routes.Teams}>{t('Teams')}</RouteLink>
          </div>
        }
      </div>
    </div>
  </div>
)};

const mapStateToProps = (state: State): Partial<Props> => ({
  isLoggedIn: selectors.isLoggedIn(state),
  hasJoined: selectors.hasJoined(state),
  user: state.user,
  university: selectors.userUniversity(state),
  competition: state.competition,
  canViewCompetitionContent: selectors.canViewCompetitionContent(state)
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  logout: actions.logout
}, dispatch);

export default compose(
  withLocalization('navigation'),
  withRouterAndHelpers,
  connect(mapStateToProps, mapDispatchToProps)
)(NavBar);
