import { compact, pick, last } from 'lodash';
import {
  ValidationErrors, ValidationError, CompetitionScoreLevel, UserRead, Comment,
  Story, UserWrite, CompetitionPledge, MembershipLevel, UserType, PledgeCompletions, CompetitionUserScore, CompetitionScoreLevels, CompetitionPledgeUserType, Competition, RGBA,
  ImageMutation as ServerImageMutation,
  Entity
} from '../userApi';
import { TranslationFunction } from 'i18next';
import { joinAnd } from './joinAnd';
import { I18next } from './i18nextShim';
import moment = require('moment');
import { ImageMutation } from './image';
import logger from './logger';

export enum CompetitionPledges {
  HighlightAHero = 'd38c64a1-e216-4f91-b66f-e17c50c9e25a',
  InviteAFriend = '34b4e1c4-5217-426e-a966-4c1ff0ea2d71',
  AddAnAction = '71b4fb8d-f5e2-4bc8-8aef-dfac92d8baae',
  AttendAnEvent = '41dcbd5c-90fd-49a6-8f46-fa9ab0039156',
  TakeCalculator = 'cdcb0d93-53af-4c7c-8750-27f389fb8e92',
  TakeSurvey = 'e13cd5b6-351a-4c48-83d9-051428e4a626'
}

//export const competitionName = 'coolcampuschallenge2019';

export const isValidationErrors = (err: any): err is ValidationErrors => {
  return err instanceof Array &&
    (err as ValidationError[]).some((validationError) => validationError.code !== undefined);
};

export const isResponse = (err: any): err is Response =>
  err.json instanceof Function && err.text instanceof Function;

export const getValidationErrors = async (err: any) => {
  if (!isResponse(err)) {
    return;
  }
  const res = err.clone();
  try {
    const payload = await res.json();
    if (isValidationErrors(payload)) {      
      return payload;
    }
  } catch (readErr) {
    // Not validation error response. Pass.
  }
  return;
};

export const getScoreLevelColor = (scoreLevel: CompetitionScoreLevel) => {
  switch (scoreLevel.name) {
    case 'Champion':
      return '#e44c9a';
    case 'Ambassador':
      return '#ff8f28';
    case 'Apprentice':
      return '#ffd200';
    case 'Novice':
      return '#47A4D6';
    default:
      return '#808080';
  }
};

export const getScoreLevelPhoto = (scoreLevel: CompetitionScoreLevel, size: '' | '@2x' | '@3x' = '') => {
  switch (scoreLevel.name) {
    case 'Champion':
      return `/assets/score_levels/champion-icon-large${size}.png`;
    case 'Ambassador':
      return `/assets/score_levels/ambassador-icon-large${size}.png`;
    case 'Apprentice':
    return `/assets/score_levels/apprentice-icon-large${size}.png`;
    case 'Novice':
      return `/assets/score_levels/novice-icon-large${size}.png`;
    default:
      return null;
  }
};

export const displayFullName = (user: UserRead) =>
  compact([user.firstName, user.lastName]).join(' ');

export const commentableTypeToString = (commentableType: Comment.CommentableTypeEnum) => {
  switch (commentableType) {
    case Comment.CommentableTypeEnum.Story:
      return 'story';
    case Comment.CommentableTypeEnum.BlogEntry:
      return 'blog_entry';
    default:
      throw new Error(`Unsupported commentableType: ${commentableType}.`);
  }
};

const { LocationQualifierEnum } = Story;

export const toLocationQualifierEnum = (value: string): Story.LocationQualifierEnum => {
  switch (value) {
    case 'home':
      return LocationQualifierEnum.Home;
    case 'work':
      return LocationQualifierEnum.Work;
    case 'both':
      return LocationQualifierEnum.Both;
    default:
      if (value !== '') logger.error(new Error(`Unsupported Story.LocationQualifierEnum ${value}.`));
      return LocationQualifierEnum.Empty;
  }
};
const { StatusEnum } = Story;

export const toStoryStatusEnum = (value: string): Story.StatusEnum => {
  switch (value) {
    case 'already_do':
      return StatusEnum.AlreadyDo;
    case 'new':
      return StatusEnum.New;
    default:
      if (value !== '') logger.error(new Error(`Unsupported Story.StatusEnum ${value}.`));
      return StatusEnum.Empty;
  }
};

// Autogenerated code does not support multi-part form data.
// It is fixed in a future release: https://github.com/OpenAPITools/openapi-generator/pull/569
export const postFormData = async <T>(url: string, body: FormData, token?: string): Promise<T> => {
  const options = {
    body,
    headers: { Authorization: token },
    method: 'POST'
  };
  const response = await fetch(url, options);
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  } else {
    throw response;
  }
};

export const userReadToWrite = (user: UserRead): UserWrite =>
  pick(user, [
      'type', 'email', 'firstName', 'lastName', 'profilePhoto', 'descriptionMD',
      'descriptionHTML', 'public', 'socialLinks', 'options'
  ]) as UserWrite;

export const makeCampusHeroTitle = (taggedUsers: UserRead[], t: TranslationFunction) => {
  let title = '';
  if (taggedUsers.length > 1) {
    title = t('Cool Campus Heroes', { ns: 'story' });
  } else {
    title = t('Cool Campus Hero', { ns: 'story' });
  }
  const userNames = taggedUsers.map((user) => displayFullName(user));
  return `${title}: ${joinAnd(userNames, t)}`;
};

export const makeStoryTitle = (story: Story, pledge: CompetitionPledge, taggedUsers: UserRead[], t: TranslationFunction) => {
  if (!pledge) return '';
  if (pledge.uuid === CompetitionPledges.HighlightAHero) {
    return makeCampusHeroTitle(taggedUsers, t);
  }
  return pledge.shortTitle;
};

export const atLeastAdmin = (level: MembershipLevel) =>
  (level === MembershipLevel.Admin || level === MembershipLevel.Superadmin);

export const atLeastSuperadmin = (level: MembershipLevel) =>
  (level === MembershipLevel.Superadmin);

export const displayUserType = (userType: UserType, i18n: I18next) => {
  const t = i18n.getFixedT(i18n.language, 'userType');
  switch (userType) {
    case UserType.Student:
      return t('Student');
    case UserType.Faculty:
      return t('Faculty');
    case UserType.Staff:
      return t('Staff');
    default:
      return '';
  }
};

export const displayCompetitionPledgeUserType = (userType: CompetitionPledgeUserType, i18n: I18next) => {
  const t = i18n.getFixedT(i18n.language, 'userType');
  switch (userType) {
    case CompetitionPledgeUserType.Staff:
      return t('staff');
    case CompetitionPledgeUserType.Labs:
      return t('labs');
    default:
      logger.error(new Error(`Unexpect CompetitionPledgeUserType ${userType}.`));
      return '';
  }
};

export const userTypeFromString = (userTypeS: string): UserType =>
  UserType[Object.keys(UserType).find((userType) => UserType[userType as any].toString() === userTypeS) as any] as any;

export const pointsPerPhoto = 50;
export const pointsPerStoryRepeat = 50;

export const calculatePointsForNextStory = (pledgeCompletions: PledgeCompletions, pledge: CompetitionPledge) => {
  if (!pledge) return 0;
  if (!pledgeCompletions) return pledge.points;
  const completions = pledgeCompletions[pledge.id] || 0;
  if (completions === 0) return pledge.points;
  if (completions >= pledge.repeats) return 0;
  return Math.min(pledge.points, pointsPerStoryRepeat);
};

export const estimatePointsAvailable = (pledgeCompletions: PledgeCompletions, pledge: CompetitionPledge) => {
  if (!pledge || !pledgeCompletions) return 0;
  const repeatPoints = Math.min(pledge.points, pointsPerStoryRepeat);
  const totalPointsAvailable = pledge.points + repeatPoints * Math.max(0, pledge.repeats - 1);
  const completions = pledgeCompletions[pledge.id];
  if (completions >= pledge.repeats) return 0;
  if (completions === undefined || completions === 0) return totalPointsAvailable;
  if (completions === 1) return totalPointsAvailable - pledge.points;
  return totalPointsAvailable - repeatPoints * (completions - 1);
};

export const someMatchingEmailDomain = (email: string, permittedDomains: string[]) =>
  permittedDomains.some((domain) => last(email.split('@')).search(domain) >= 0);

// IMPORTANT: These must coincide with validations in app/operations/validate_pledge_eligibility.go.
export const isUserScoreEligibleForPledge = (score: CompetitionUserScore, scoreLevels: CompetitionScoreLevels, pledge: CompetitionPledge) => {
  if (!pledge || !pledge.scoreLevelUUID || !scoreLevels) return true;
  const thresholdLevel = scoreLevels.find((scoreLevel) => scoreLevel.uuid === pledge.scoreLevelUUID);
  if (!thresholdLevel) return true;
  return score.points >= thresholdLevel.threshold;
};

export const isUserTypeEligibleForPledge = (user: UserRead, pledge: CompetitionPledge) => {
  if (!pledge.userTypes || pledge.userTypes.length === 0) return true;
  if (user.options.isLabUser && pledge.userTypes.some((userType) => userType === CompetitionPledgeUserType.Labs)) {
    return true;
  } else if (user.options.isHealthFacilityEmployee && pledge.userTypes.some((userType) => userType === CompetitionPledgeUserType.Staff)) {
    return true;
  }
  return false;
};

export const isDateEligibleForPledge = (pledge: CompetitionPledge, competition: Competition) => {
  if (!pledge.releaseWeek || pledge.releaseWeek === 1) return true;
  return moment(competition.startsAt).add(pledge.releaseWeek - 1, 'weeks').isBefore(Date.now());
};

export const savingsCO2LbsToCars = (savingsCO2Lbs: number): number => savingsCO2Lbs * 96.3 / Math.pow(10, 6);

export const maxImageSizeBytes = 1 * Math.pow(10, 6);

const hexToRgba = (hex: string): RGBA => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1
  } : { r: 0, g: 0, b: 0, a: 0 };
};

export const roundTo = (value:number, decimals:number)=>{

  let round = Math.round(value)  
  return round
}

export const imageMutationToServerIM = (mutation: Partial<ImageMutation>, maxMB: number, contentType: ServerImageMutation.ContentTypeEnum): ServerImageMutation => {
  const crop = { x: roundTo(mutation.pixelCrop?.x||0,3), y: roundTo(mutation.pixelCrop?.y||0,3), width: mutation.pixelCrop?.width||0, height: mutation.pixelCrop?.height||0 }
  if(mutation.image){    
    const imageWidth = mutation.image.width
    const imageHeight = mutation.image.height
    const imageFullWidth = mutation.image.naturalWidth
    const imageFullHeight = mutation.image.naturalHeight    
    crop.x = Math.round(crop.x * imageFullWidth / imageWidth)
    crop.y = Math.round(crop.y * imageFullHeight / imageHeight)
    crop.width  = Math.round(crop.width * imageFullWidth / imageWidth)
    crop.height = Math.round(crop.height * imageFullHeight / imageHeight)
  }

  return {
    rotation: -mutation.rotation || 0,
    bgColor: hexToRgba(mutation.backgroundColor),
    crop,
    contentType,
    maxMB 
  }
};

export const entityCanBlog = (entity: Entity) => entity.type === Entity.TypeEnum.University;
