import up from 'updeep';
import * as selectors from '../selectors';
import { CompetitionEntityPledge, CompetitionEntityPledges } from '../../userApi';
import { Dispatch } from 'redux';
import { State } from '../state';
import { ExtraArguments } from '../extraArguments';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';
import { trackEvent } from '../../utils/googleAnalytics';

export const saveCompetitionEntityPledge = (competitionEntityPledge: CompetitionEntityPledge) => async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  dispatch(patch({ spinner: true }));
  const api = selectors.userApi(getState());

  try {
    const { converter } = await import('../../utils/markdown');
    competitionEntityPledge.contentHTML = converter.makeHtml(competitionEntityPledge.contentMD);
    let competitionEntityPledges: CompetitionEntityPledges;
    if (competitionEntityPledge.id === 0) {
      competitionEntityPledges = await api.postCompetitionEntityPledges({ competitionEntityPledge });
      trackEvent('entityPledge', 'create');
    } else {
      competitionEntityPledges = await api.putCompetitionEntityPledge(competitionEntityPledge.id, { competitionEntityPledge });
      trackEvent('entityPledge', 'update');
    }
    dispatch(patch({
      competitionEntityPledges,
      editingCompetitionEntityPledgeEntityID: 0,
      spinner: false
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('entityPledge', 'unhandledError');
  }
};

export const destroyCompetitionEntityPledge = (competitionEntityPledge: CompetitionEntityPledge) => async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  dispatch(patch({ spinner: true }));
  const api = selectors.userApi(getState());

  try {
    await api.deleteCompetitionEntityPledge(competitionEntityPledge.id);
    trackEvent('entityPledge', 'delete');
    dispatch(patch({
      competitionEntityPledges: {
        [competitionEntityPledge.competitionPledgeID]:
          up.omitBy((_cep: CompetitionEntityPledge, entityID: number) => entityID === competitionEntityPledge.entityID)
      },
      editingCompetitionEntityPledgeEntityID: 0,
      spinner: false
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('entityPledge', 'unhandledError', 'delete');
  }
};
