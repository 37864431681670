import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import InviteAFriendPledge from './InviteAFriend';
import { compose } from 'redux';

const EarnMorePoints = ({
  t
}: LProps<{}>) => (
  <div className="cc__white_box">
    <div>{t('Earn more points')}</div>
    <hr />
    <InviteAFriendPledge />
  </div>
);

export default compose(
  withLocalization('earnMorePoints')
)(EarnMorePoints);
