import * as React from 'react';
import { withRouter, RouterProps } from 'react-router';
import { History } from 'history';
import { Alert } from 'reactstrap';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { withLocalization, LProps } from '../../utils/wrappers';
import { CompetitionPledge, CompetitionPledgeCategory, CompetitionUserScore, Entities, CompetitionEntityPledge } from '../../userApi';
import { StyleState } from '../../utils/styles';
import Stories from '../../shared/Stories';
import { makePledgeCategoryPath, Routes, getPledgeParams } from '../../utils/routerHelper';
import ScoreboardParticipants from '../../shared/ScoreboardParticipants';
import Footer from '../../shared/Footer';
import { asPage } from '../../utils/wrappers/asPage';
import { WaitingWhiteBox, WaitingPageContent } from '../../shared/WaitingPage';
import CompetitionEntityPledges from './CompetitionEntityPledges';
import PledgeStats from './PledgeStats';
import { estimatePointsAvailable, CompetitionPledges } from '../../utils/userApiHelper';

interface Props {
  initializePledgePage: (history: History) => void;
  pointsAvailable: number;
  pledgeID: number;
  completions: number;
  pointsEarned: number;
  initializingPledgePage: boolean;
  pledge: CompetitionPledge;
  pledgeCategory: CompetitionPledgeCategory;
  lastUsersToVerify: CompetitionUserScore[];
  competitionEntityPledges: CompetitionEntityPledge[];
  entities: Entities;
}
enum MobileSection {
  INFO = 1,
  ACTIVITY = 2
}

interface PledgeState {
  mobileSection: number;
}

const initialState: PledgeState = {
  mobileSection: MobileSection.INFO
};

const didPledgeIDUpdate = (nextProps: LProps<Props>, props: LProps<Props>) => {
  if (!props.pledge) return false;
  return props.pledgeID !== nextProps.pledgeID && !isNaN(nextProps.pledgeID);
};

const someDescriptionContent = (pledge: CompetitionPledge) => {
  return Boolean(pledge.description) || Boolean(pledge.assumptions) || !isEmpty(pledge.learnMoreLinks);
};

class PledgePage extends React.Component<LProps<Props> & RouterProps, PledgeState> {
  public state = initialState;

  public componentWillMount() {
    const { initializePledgePage, history } = this.props;
    initializePledgePage(history);
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (didPledgeIDUpdate(nextProps, this.props)) {
      const { initializePledgePage, history } = this.props;
      initializePledgePage(history);
    }
  }

  public render() {
    const {
      t, pledge, pledgeCategory, lastUsersToVerify, initializingPledgePage,
      pointsAvailable, completions, pointsEarned
    } = this.props;
    if (initializingPledgePage) {
      return <WaitingPageContent />;
    }
    return (
      <div id="pledge_page">
        <div>
          {!pledge &&
            <Alert
              color={StyleState.Danger}
            >{t('We could not find the requested competition pledge.')}</Alert>
          }
          <div className="cc__page__column_content">
            <div className={'cc__page__column_content__left ' + this.getActiveClass(MobileSection.INFO)}>
              <div>
                <h3>{pledge.shortTitle}</h3>
                {pointsAvailable === 0 &&
                  <h4 className="cc__lighttext">
                    <img style={{ width: '36px' }} src="/assets/story/checkmark@2x.png" alt={t('action taken')} />&nbsp;
                    {t('Complete')}
                  </h4>
                }
              </div>
              {pledgeCategory &&
                <div>
                  <Link to={makePledgeCategoryPath(pledgeCategory.id, t)}>{pledgeCategory.name}</Link>
                </div>
              }
              <PledgeStats
                pointsEarned={pointsEarned}
                pledge={pledge}
                completions={completions}
              />
              {someDescriptionContent(pledge) &&
                <div className="cc__white_box">
                  {pledge.description && <h6>{t('Description')}</h6>}
                  {pledge.description && <div className="cc__lighttext">{pledge.description}</div>}
                  {pledge.assumptions &&
                    <div>
                      <br/>
                      <h6>{t('Assumptions')}</h6>
                      <div className="cc__lighttext">{pledge.assumptions}</div>
                    </div>
                  }
                  {(pledge.learnMoreLinks && pledge.learnMoreLinks.length > 0) &&
                    <div id="cc__pledge_page__learn_more">
                      <h6>{t('Learn More')}</h6>
                      <ul>
                        {pledge.learnMoreLinks.map((learnMoreLink, i) => (
                          <li key={i}>
                            <a href={learnMoreLink[1]} target="_blank" rel="noopener noreferrer">{learnMoreLink[0]}</a>
                          </li>
                        ))}
                        </ul>

                    </div>
                  }
                </div>
              }
              {(!initializingPledgePage && lastUsersToVerify && lastUsersToVerify.length > 0) &&
                <div className="cc__white_box">
                  <h6>{t('Last users to verify this action')}</h6>
                  <div className="cc__scoreboard">
                    <ScoreboardParticipants showRank={false} userScores={lastUsersToVerify} />
                  </div>
                </div>
              }
              {!initializingPledgePage && <CompetitionEntityPledges pledge={pledge} />}
              {initializingPledgePage && <WaitingWhiteBox />}
              <Footer />
            </div>
            <div className={'cc__page__column_content__right ' + this.getActiveClass(MobileSection.ACTIVITY)}>
              {!initializingPledgePage &&
                <Stories showPrompt={pledge.uuid !== CompetitionPledges.TakeSurvey} />
              }
              {initializingPledgePage &&
                <WaitingWhiteBox />
              }
            </div>
          </div>
        </div>
        <div className="cc__pledge__bottom_menu">
          <div role="button" className={'cc__pledge__bottom_menu__button ' + this.getActiveTextClass(MobileSection.INFO)} onClick={() => this.selectSection(MobileSection.INFO)}>
            <span>Info</span>
          </div>
          <div role="button" className={'cc__pledge__bottom_menu__button ' + this.getActiveTextClass(MobileSection.ACTIVITY)} onClick={() => this.selectSection(MobileSection.ACTIVITY)}>
            <span>Activity</span>
          </div>
        </div>
      </div>
    );
  }
  private getActiveClass(index: number) {
    return (index & this.state.mobileSection) === this.state.mobileSection ? 'cc__mobile_show' : 'cc__mobile_hide';
  }
  private getActiveTextClass(index: number) {
    return (index & this.state.mobileSection) === this.state.mobileSection ? 'active' : '';
  }
  private selectSection(index: number) {
    this.setState({
      mobileSection: index
    });
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => {
  const initializingPledgePage = state.pendingRoute === Routes.Pledge;
  if (initializingPledgePage) {
    return {
      initializingPledgePage
    };
  }
  const pledgeID = getPledgeParams(ownProps.history.location, ownProps.i18n).competitionPledgeID;
  const pledge = selectors.currentPledge(state, ownProps);
  if (!pledge) return { initializingPledgePage: true, pledgeID };
  return {
    pledgeID,
    pledge,
    completions: state.userPledgeCompletions[pledge.id] || 0,
    pointsEarned: state.userPledgePoints[pledge.id] || 0,
    pointsAvailable: estimatePointsAvailable(state.userPledgeCompletions, pledge),
    pledgeCategory: selectors.currentPledgeCategory(state, ownProps),
    lastUsersToVerify: selectors.lastUsersToVerify(state, ownProps),
    entities: selectors.getEntitiesWithCompetitionEntityPledges(state, ownProps)
  };
};
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  initializePledgePage: actions.initializePledgePage
}, dispatch);

export default asPage({ isGrey: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withRouter,
  withLocalization('pledge'),
  connect(mapStateToProps, mapDispatchToProps)
)(PledgePage));
