import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { LProps, withLocalization } from '../utils/wrappers';
import { CompetitionUserPoint } from '../userApi';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { State, Modals } from '../redux/state';
import actions from '../redux/actions';
import { connect } from 'react-redux';

interface Props {
  isOpen: boolean;
  dismissStoryPointsModal: () => void;
  points: CompetitionUserPoint;
  lastPointsCanEarnMore: boolean;
}

const StoryPointsModal = ({
  t, isOpen, dismissStoryPointsModal, points, lastPointsCanEarnMore
}: LProps<Props>) => (
  <Modal
    isOpen={isOpen}
    toggle={dismissStoryPointsModal}
    id={'cc__story_points_modal'}
  >
    {points && <ModalHeader>
      {t('Congratulations, you\'ve earned {{points}} points!', { points: points.points })}
    </ModalHeader>}
    {points && <ModalBody>
      <div id="cc__story_points_modal__icon">
        <div>
        <img
          style={{ width: '55px' }}
          alt={'action completed'}
          src={'/assets/landing/actions_completed.png'}
        />
        </div>
      </div>
      <div id="cc__story_points_modal__tally">
        {(Boolean(points.pledgePoints) && lastPointsCanEarnMore) &&
          <div><i className="fa fa-check-circle-o" /> {points.pledgePoints} {t('points for taking this action for the first time')}</div>
        }
        {(Boolean(points.pledgePoints) && !lastPointsCanEarnMore) &&
          <div><i className="fa fa-check-circle-o" /> {points.pledgePoints} {t('points for taking this action')}</div>
        }
        {Boolean(points.repeatPoints) && <div><i className="fa fa-check-circle-o" /> {points.repeatPoints} {t('points for repeating this action')}</div>}
        {Boolean(points.photoPoints) && <div><i className="fa fa-check-circle-o" /> {points.photoPoints} {t('points for including a photo')}</div>}
      </div>
    </ModalBody>}
    <ModalFooter>
      <Button outline onClick={dismissStoryPointsModal} color="success">{t('Got it!')}</Button>
    </ModalFooter>
  </Modal>
);

const mapStateToProps = (state: State) => ({
  isOpen: state.modal === Modals.StoryPoints && Boolean(state.lastPointsEarned),
  points: state.lastPointsEarned,
  lastPointsCanEarnMore: state.lastPointsCanEarnMore
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  dismissStoryPointsModal: actions.dismissStoryPointsModal
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('stories')
)(StoryPointsModal) as React.ComponentClass;
