import up from 'updeep';
import { patch } from './patch';
import { Comment } from '../../userApi';
import { State } from '../state';
import { Dispatch } from 'redux';
import { handleUnexpectedError } from './handleUnexpectedError';
import * as selectors from '../selectors';
import { commentableTypeToString } from '../../utils/userApiHelper';
import { ExtraArguments } from '../extraArguments';
import { trackEvent } from '../../utils/googleAnalytics';

export const postComments = (commentForm: Partial<Comment>, commentPromptID: string) =>
async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const state = getState();
  const api = selectors.userApi(state);

  dispatch(patch({ spinner: true }));
  try {
    const { commentableID, commentableType } = commentForm;
    const body = { comment: commentForm as Comment };
    const { comment } = await api.postComments(commentableID, commentableTypeToString(commentableType), body);
    trackEvent('comment', 'create');
    const { storyComments } = getState();
    const existingComments = storyComments[commentableID] || [];
    if (commentableType === Comment.CommentableTypeEnum.Story) {
      dispatch(patch({
        storyComments: { [commentableID]: [ comment, ...existingComments ] },
        savedCommentPromptID: commentPromptID,
        spinner: false
      }));
    } else if (commentableType === Comment.CommentableTypeEnum.BlogEntry) {
      dispatch(patch({
        blogEntryComments: { [commentableID]: [ comment, ...existingComments ] },
        savedCommentPromptID: commentPromptID,
        spinner: false
      }));
    }
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('comment', 'unhandledError', 'create');
  }
};

export const deleteStoryComment = (comment: Comment) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const { storyComments } = getState();
  const storyID = comment.commentableID;
  dispatch(patch({
    storyComments: { [storyID]: up.reject((c) => c.id === comment.id) }
  }));
  const api = selectors.userApi(getState());
  try {
    await api.deleteComment(comment.id, 'story');
    trackEvent('comment', 'delete');
  } catch (err) {
    dispatch(patch({
      storyComments: { [storyID]: storyComments[storyID] }
    }));
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('comment', 'unhandledError', 'delete');
  }
};
