import { SocialLinks } from '../../userApi';
import { isValidURL } from '../validations';
import { TranslationFunction } from 'i18next';
import { isEmpty } from 'lodash';

export interface SocialLinkErrors {
  website?: string;
  facebook?: string;
  linkedin?: string;
}

const invalidURLError = 'Must be a valid URL';
const hasHttp = (s: string) => /^https?:\/\//i.test(s);

export const processSocialLinks = (socialLinks: SocialLinks) => {
  const processed = { ...socialLinks };
  if (processed.facebook && !hasHttp(processed.facebook)) {
    processed.facebook = `https://${processed.facebook}`;
  }
  if (processed.website && !hasHttp(processed.website)) {
    processed.website = `https://${processed.website}`;
  }
  if (processed.linkedin && !hasHttp(processed.linkedin)) {
    processed.linkedin = `https://${processed.linkedin}`;
  }
  return processed;
};

export const validateSocialLinks = (socialLinks: SocialLinks, t: TranslationFunction) => {
  const errors: SocialLinkErrors = {};
  if (socialLinks.facebook && !isValidURL(socialLinks.facebook)) {
    errors.facebook = t(invalidURLError);
  }
  if (socialLinks.linkedin && !isValidURL(socialLinks.linkedin)) {
    errors.linkedin = t(invalidURLError);
  }
  if (socialLinks.website && !isValidURL(socialLinks.website)) {
    errors.website = t(invalidURLError);
  }
  return isEmpty(errors) ? undefined : errors;
};
