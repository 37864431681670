import * as Ajv from 'ajv';
import { last } from 'lodash';

const validator = new Ajv({ allErrors: true });

validator.addKeyword('password', {
  type: 'string',
  compile: () => (datum) => /^[\d\w!@#$%^&*(),.?"{}|<> ]+$/.test(datum) &&
    /\d+/.test(datum) && /\w+/.test(datum)
});

validator.addKeyword('date', {
  type: 'string',
  compile: () => (datum: string) => !isNaN(new Date(datum).getTime())
});

const passwordSchema = { type: 'string', password: true , minLength: 8, maxLength: 64 };

export const validatePassword = (password: string) => validator.validate(passwordSchema, password);

export const makeErrorMessage = (errors: Ajv.ErrorObject[], dataPath: string, message: string, isValidationReady: boolean): string => (
  (isValidationReady &&
  (errors.some((error) => dataPath === error.dataPath) ||
  errors.some((error) => new RegExp(`required property '${last(dataPath.split('.'))}'`).test(error.message)))) ? message : ''
);

export const isValidURL = (url: string) => {
  try {
    const _parseURL = new URL(url);
    return true;
  } catch (_err) {
    return false;
  }
};

export { validator };
