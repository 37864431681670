import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose } from 'redux';
import ExternalLink from '../../shared/ExternalLink';

const CompetitionAboutText = ({ t }: LProps<{}>) => (
    <p>
        <span>
            {t('The Cool Campus Challenge is a partnership between the University of California, the CoolClimate Network and the Center for Sustainable Energy. For questions or comments, please contact us at')}&nbsp;
        </span>
        <ExternalLink href={'mailto:contact@coolcampuschallenge.org'}>contact@coolcampuschallenge.org</ExternalLink>.
    </p>
);

export default compose(
   withLocalization('competitionAboutText')
)(CompetitionAboutText) as React.SFC;
