import {
  applyMiddleware, compose,
  createStore, Store
} from 'redux';
import thunk from 'redux-thunk';
import u from 'updeep';
import { ApplicationAction } from './actions/types';
import * as m from './middleware';
import initialState, { State } from './state';
import { ExtraArguments } from './extraArguments';

const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const reducer = (state: State = initialState, action: ApplicationAction): State => {
  if (action.payload === undefined) return state; 
  
  return u({ ...action.payload, 
            "profilePledgeCompletions": u.constant(action.payload.profilePledgeCompletions), 
            "profileMemberships": u.constant(action.payload.profileMemberships), 
            "profileEntityRanks": u.constant(action.payload.profileEntityRanks)
          }, 
          state)

};
export const createCompetitionStore = (extra: ExtraArguments): Store<State> => {
  const middleware = [
    m.storage,
    m.crashReporter,
    thunk.withExtraArgument(extra)
  ];

  return createStore(
    reducer,
    initialState,
    devToolsExtension ?
      compose(applyMiddleware(...middleware), devToolsExtension()) :
      compose(applyMiddleware(...middleware))
  );
};
