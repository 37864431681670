import * as React from 'react';
import up from 'updeep';
import { isEmpty, trim } from 'lodash';
import { Button, Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import { compose, Dispatch } from 'redux';
import { withLocalization, LProps } from '../../utils/wrappers';
import { State } from '../../redux/state';
import actions, { bindActionCreators } from '../../redux/actions';
import { connect } from 'react-redux';
import { JoinErrors, makeJoinErrors, joinVEMessages } from '../../utils/forms';
import { Patch } from '../../redux/actions/all';
import RouteLink from '../../shared/RouteLink';
import { Routes } from '../../utils/routerHelper';
import { ValidationErrors, CreateSessionParams, removeVEForField, Competition, UserRead } from '../../userApi';

interface Props {
  join: () => void;
  validationErrors: ValidationErrors;
  competition?: Competition
  user?: UserRead  
  logout: () => void;
}

interface JoinState {  
  errors: Partial<JoinErrors>;
  submitted: boolean;
}

const initialState: JoinState = {  
  errors: {},  
  submitted: false
};

class Join extends React.Component<LProps<Props>, JoinState> {
  public state: JoinState = initialState;

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { validationErrors, t } = this.props;
    if (nextProps.validationErrors !== validationErrors) {
      const { submitted } = this.state;
      const errors = makeJoinErrors(nextProps.validationErrors, t);
      this.setState({ errors });
    }
  }

  private join() {    
    this.props.join();
  }

  onLogout=(event:React.MouseEvent<HTMLAnchorElement>)=>{
    event.preventDefault()
    this.props.logout()

  }

  public render() {
    const { errors } = this.state;
    const { competition, user } = this.props
    const {
      t
    } = this.props;
    return (
      <div id="join_form">        
        <p>
          {t("You haven't join")}&nbsp;
          <b>{competition?.name}</b>&nbsp;
          {t("competition yet")}          
        </p>        
        <p>
        {t("Do you want to join?")}
        </p>
        <p>
          {errors.join && <FormFeedback>{errors.join}</FormFeedback>}
        </p>
        <Button
          id="join_form__submit"
          onClick={() => this.join()}
          style={{ width: '250px' }}
        >{t('Join')}</Button>        
        <div style={{ margin: '5px 0px' }}>
          <span>{t("Isn't you")}&nbsp;{user?.firstName}?</span>&nbsp;
          <a href="" onClick={this.onLogout}>{t('Log out')}</a>          
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _props: Props) => ({
  validationErrors: state.joinVE,
  competition: state.competition,
  user: state.user
});

const mapDispatchToProps = (dispatch: Dispatch, _props: Props) =>
  bindActionCreators({
    join: actions.join,
    logout: actions.logout
  }, dispatch);

export default compose(
  withLocalization('auth'),
  connect(mapStateToProps, mapDispatchToProps)
)(Join);
