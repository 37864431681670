import { values } from 'lodash';
import { State } from '../state';
import { Entity, EntityMembership, CompetitionEntityScore } from '../../userApi';
import logger from '../../utils/logger';
import { getEntityParams } from '../../utils/routerHelper';
import { I18next } from '../../utils/i18nextShim';
import { History } from 'history';
import { atLeastAdmin, atLeastSuperadmin } from '../../utils/userApiHelper';

const userEntityOfType = (state: State, entityType: Entity.TypeEnum) => {
  const { user } = state;
  if (entityType === Entity.TypeEnum.HealthFacility && !user.options.isHealthFacilityEmployee) return;

  const m = values(state.userMemberships)
    .find((membership) =>
      state.entities[membership.entityID] && state.entities[membership.entityID].type === entityType &&
      membership.status === EntityMembership.StatusEnum.Accepted
    );
  if (!m) return;
  return state.entities[m.entityID];
};

export const userUniversity = (state: State) => userEntityOfType(state, Entity.TypeEnum.University);
export const userHealthFacility = (state: State) => userEntityOfType(state, Entity.TypeEnum.HealthFacility);
export const userTeam = (state: State) => userEntityOfType(state, Entity.TypeEnum.Team);

const acceptedEntityOfType = (state: State, userID: number, entityType: Entity.TypeEnum) => {
  let user = state.users[userID];
  if (userID === state.user.id) {
    user = state.user;
  } else if (!user) {
    logger.warn(`acceptedEntityOfType: failed to find user ${userID}.`);
    return;
  }
  if (entityType === Entity.TypeEnum.HealthFacility && !user.options.isHealthFacilityEmployee) return;

  const acceptedEntityIDs = Object.keys(user.acceptedCompetitionEntityMemberships[state.competition.id] || {});
  const entityID = acceptedEntityIDs.find((id) => (state.entities[id]) && state.entities[id].type === entityType);
  if (!entityID) return;

  return state.entities[entityID];
};

export const acceptedUniversity = (state: State, userID: number) => acceptedEntityOfType(state, userID, Entity.TypeEnum.University);
export const acceptedTeam = (state: State, userID: number) => acceptedEntityOfType(state, userID, Entity.TypeEnum.Team);
export const acceptedHealthFacility = (state: State, userID: number) => acceptedEntityOfType(state, userID, Entity.TypeEnum.HealthFacility);

const entitiesOfType = (state: State, type: Entity.TypeEnum) =>
  values(state.entities).filter((entity) => entity.type === type);

export const healthFacilityEntities = (state: State) => entitiesOfType(state, Entity.TypeEnum.HealthFacility);

const userRankForEntityType = (state: State, entityType: Entity.TypeEnum) => {
  const entity = userEntityOfType(state, entityType);
  if (!entity) return;
  return state.userEntityRanks[entity.id];
};

export const userUniversityRank = (state: State) => userRankForEntityType(state, Entity.TypeEnum.University);
export const userHealthFacilityRank = (state: State) => userRankForEntityType(state, Entity.TypeEnum.HealthFacility);

interface RoutableAndLocalizable {
  history: History;
  i18n: I18next;
}

export const currentEntity = (state: State, props: RoutableAndLocalizable): Entity =>
  state.entities[getEntityParams(props.history.location, props.i18n).entityID];

export const canJoinCurrentEntity = (state: State, props: RoutableAndLocalizable) => {
  const entity = currentEntity(state, props);
  if (entity.type !== Entity.TypeEnum.Team) return false;

  if (Boolean(userTeam(state))) return false;

  const university = userUniversity(state);
  if (!university) return false;

  return university.id === entity.parentID;
};

interface EntityProps {
  entity: Entity;
}

export const entityRank = (state: State, props: EntityProps) => {
  const entity = state.entities[props.entity.id];
  let entityScores: CompetitionEntityScore[];
  if (entity.type === Entity.TypeEnum.University) {
    entityScores = state.competitionEntityScores.university;
  } else if (entity.type === Entity.TypeEnum.Team) {
    entityScores = state.competitionEntityScores.team;
  } else if (entity.type === Entity.TypeEnum.HealthFacility) {
    entityScores = state.competitionEntityScores.healthFacility;
  } else {
    logger.error(`Could not select entityRank for ${props.entity.id}, type ${entity.type}.`);
    return 0;
  }

  return entityScores.findIndex((score) => score.entityID === props.entity.id) + 1;
};

export const entityScore = (state: State, props: EntityProps): CompetitionEntityScore => {
  if (!state.competitionEntityScores) return { points: 0, savingsCO2Lbs: 0 };
  switch (props.entity.type) {
    case Entity.TypeEnum.Team:
      return state.competitionEntityScores.team.find((score) => score.entityID === props.entity.id);
    case Entity.TypeEnum.University:
      return state.competitionEntityScores.university.find((score) => score.entityID === props.entity.id);
    case Entity.TypeEnum.HealthFacility:
      return state.competitionEntityScores.healthFacility.find((score) => score.entityID === props.entity.id);
    default:
      throw new Error(`Unsupported EntityType ${props.entity.type}.`);
  }
};

export const teamScoresForUserUniversity = (state: State) => {
  const university = userUniversity(state);
  if (!university) return [];
  const teamScores = state.competitionEntityScores.team || [];
  return teamScores.filter((score) => state.entities[score.entityID].parentID === university.id);
};

export const isAtLeastAcceptedAdmin = (state: State, props: { entity: Entity }) => {
  const { userMemberships } = state;
  const membership = userMemberships[props.entity.id];
  if (!membership) return false;
  if (membership.status !== EntityMembership.StatusEnum.Accepted) return false;
  return atLeastAdmin(membership.level);
};

export const isAtLeastAcceptedSuperadmin = (state: State, props: { entity: Entity }) => {
  const { userMemberships } = state;
  const membership = userMemberships[props.entity.id];
  if (!membership) return false;
  if (membership.status !== EntityMembership.StatusEnum.Accepted) return false;
  return atLeastSuperadmin(membership.level);
};

export const userIsSomeUniversityAdmin = (state: State) => {
  const { userMemberships, entities } = state;
  return values(userMemberships).some((membership) =>  {
    const entity = entities[membership.entityID];
    if (!entity) return false;
    return entity.type === Entity.TypeEnum.University &&  atLeastAdmin(membership.level);
  });
};

export const universityRank = (state: State) => {
  const university = userUniversity(state);
  const { competitionEntityScores } = state;
  const scoreIndex = competitionEntityScores.university ? competitionEntityScores.university.findIndex((score) => 
    score.entityID == university.id
  ) : -1;
  if (scoreIndex === -1) {
    logger.error(new Error(`Could not find entity score for university with entityID ${university?.id||""}.`));
    return competitionEntityScores.university?.length || 0;
  }
  return scoreIndex + 1;
};

export const universityParticipationRank = (state: State) => {
  const university = userUniversity(state);
  const { entities, competitionEntities } = state;
  const campuses = values(entities)
    .filter((entity) => entity.type === Entity.TypeEnum.University);
  const participationIndex = campuses.sort((e1, e2) => {
      const population1 = e1.population;
      const memberCt1 = competitionEntities[e1.id].memberCount;
      const pctParticipation1 = Boolean(population1) ? memberCt1 / population1 : 0;
      const population2 = e2.population;
      const memberCt2 = competitionEntities[e2.id].memberCount;
      const pctParticipation2 = Boolean(population2) ? memberCt2 / population2 : 0;
      return pctParticipation1 > pctParticipation2 ? -1 : 1;
    })
    .findIndex((entity) => entity.id === university.id);
  if (participationIndex === -1) {
    logger.error(new Error(`Could not get participation rank for university with entityID ${university.id}.`));
    return campuses.length;
  }
  return participationIndex + 1;
};

export const healthFacilityRank = (state: State) => {
  const healthFacility = userHealthFacility(state);
  if (!healthFacility) return;
  const { competitionEntityScores } = state;
  const scoreIndex = competitionEntityScores.healthFacility.findIndex((score) => score.entityID === healthFacility.id);
  if (scoreIndex === -1) {
    logger.error(new Error(`Could not find entity score for healthFacility with entityID ${healthFacility.id}.`));
    return competitionEntityScores.healthFacility.length;
  }
  return scoreIndex + 1;
};

export const canSetHealthSystemsEmployee = (state: State) => {
  const university = userUniversity(state);
  if (!university) return false;
  return values(state.entities).some((entity) => entity.type === Entity.TypeEnum.HealthFacility && entity.abbreviation === university.abbreviation);
};
