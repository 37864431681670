import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CompetitionUserScore, CompetitionScoreLevel } from '../userApi';
import { State, Modals } from '../redux/state';
import * as selectors from '../redux/selectors';
import { withLocalization, LProps } from '../utils/wrappers';
import ProgressBar from './ProgressBar';
import { getScoreLevelColor, getScoreLevelPhoto } from '../utils/userApiHelper';
import actions from '../redux/actions';
import ScoreLevelModal from './ScoreLevelModal';
import { withPreventDefault } from '../utils/withPreventDefault';
import LinkButton from './LinkButton';

interface ManualProps {
  scoreLevel: CompetitionScoreLevel;
  nextScoreLevel: CompetitionScoreLevel;
  score: CompetitionUserScore;
}

interface Props extends ManualProps {
  percentToNextLevel: number;
  openScoreLevelModal: () => void;
}

const UserScoreLevel = ({
  t, formatNumber, score, scoreLevel,
  nextScoreLevel, openScoreLevelModal, percentToNextLevel
}: LProps<Props>) => (
  <div className="cc__white_box">
    <div className="cc__row-space_between">
      <span style={{ fontSize: '18px' }}>{scoreLevel.name}</span>
      {getScoreLevelPhoto(scoreLevel) && <img style={{ width: '21px' }} src={getScoreLevelPhoto(scoreLevel)} alt={scoreLevel.name} />}
    </div>
    {(nextScoreLevel === scoreLevel) &&
      <div className="cc__lighttext">{t('You\'ve done it!')}</div>
    }
    {(nextScoreLevel !== scoreLevel) &&
      <div>
        <div className="cc__lighttext cc__score_level__points_to_next">
          {(nextScoreLevel.threshold - score.points > 0) &&
            t('{{points}} points to {{nextLevel}}',
            { points: formatNumber(nextScoreLevel.threshold - score.points), nextLevel: nextScoreLevel.name })
          }
        </div>
        <ProgressBar
          labels={[scoreLevel.name, nextScoreLevel.name]}
          percent={percentToNextLevel}
          style={{ backgroundColor: getScoreLevelColor(scoreLevel), color: '#333333'}}
        />
      </div>
    }
    <LinkButton onClick={openScoreLevelModal}>
      {t('Learn more')} &gt;
    </LinkButton>
    <ScoreLevelModal />
  </div>
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  percentToNextLevel: selectors.percentToNextLevel(state, ownProps)
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  openScoreLevelModal: withPreventDefault(() => actions.setModal(Modals.CompetitionScoreLevels))
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('scoreLevels')
)(UserScoreLevel) as React.ComponentClass<ManualProps>;
