import { State, StoryQuery } from '../state';
import { Dispatch } from 'redux';
import { getPledgeParams, Routes } from '../../utils/routerHelper';
import { History } from 'history';
import { setStoryQuery, patch, handleUnexpectedError } from '../actions/all';
import * as selectors from '../selectors';
import { ExtraArguments } from '../extraArguments';

export const initializePledgePage = (history: History) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  dispatch(patch({ spinner: true }));

  const t = i18n.getFixedT(i18n.language, 'pledge');

  const params = getPledgeParams(history.location, i18n);

  const entityStoryQuery: StoryQuery = {
    entityID: 0,
    userID: 0,
    competitionPledgeID: params.competitionPledgeID,
    storyID: 0
  };
  setStoryQuery(entityStoryQuery)(dispatch, getState);

  try {
    const state = getState();
    const api = selectors.userApi(state);
    const { token } = state;
    const res = await api.getCompetitionPledge(params.competitionPledgeID, token);
    const pr = getState().pendingRoute;
    dispatch(patch({
      ...res,
      spinner: false,
      pendingRoute: pr === Routes.Pledge ? null : pr
    }));
  } catch (err) {
    handleUnexpectedError(err, {
      title: t('Oops!'),
      message: t('We experienced an error retrieving stories.')
    })(dispatch, getState);
    const pr = getState().pendingRoute;
    dispatch(patch({
      storyPagination: { nextPage: -1 },
      pendingRoute: pr === Routes.Pledge ? null : pr
    }));
  }
};

export const initializePledgeCategoryPage = () => patch({ pendingRoute: null });
export const initializePledgesPage = () => patch({ pendingRoute: null });
