import * as React from 'react';
import up from 'updeep';
import cx from 'classnames';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { Alert, Button, FormGroup, FormFeedback } from 'reactstrap';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { CompetitionPledge, Story, UserRead, TaggedUserIDs, OpenGraphLink, OpenGraphResponse } from '../userApi';
import { LProps, withLocalization } from '../utils/wrappers';
import Dropdown from './Dropdown';
import { toLocationQualifierEnum, toStoryStatusEnum, calculatePointsForNextStory } from '../utils/userApiHelper';
import ImageUploader from './ImageUploader/ImageUploader';
import { StyleState } from '../utils/styles';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import actions from '../redux/actions';
import Search from './Search';
import StoryTaggedUsers from './StoryTaggedUsers';
import MarkdownEditor from './MarkdownEditor';
import { Form } from '../utils/forms/form';
import { clearPostedImage } from '../redux/actions/all';
import { uuid } from '../utils/uuid';
import { makePledgePath } from '../utils/routerHelper';
import { Link } from 'react-router-dom';
import OpenGraphCarousel from './OpenGraphCarousel';
import { getURLs } from '../utils/strings';
import Checkbox from './Checkbox';

interface ManualProps {
  story: Story;
  pledge?: CompetitionPledge;
  onSave: (story: Story, taggedUserIDs: TaggedUserIDs, fileInputID: string, savedStoryFormID: string) => void;
}

export interface Props extends ManualProps {
  form: Partial<Story>;
  isVerifiedCompetitionUser: boolean;
  fileInputID: string;
  taggedUserIDs: number[];
  postedPhoto: string;
  savedStoryFormID: string;
  pointsForNextStory: number;
  hasCompetitionEnded: boolean;
  clearPostedImage: (inputID: string) => void;
  cancelStoryEdit: () => void;
  parseOpenGraph: (urls: string[]) => Promise<OpenGraphResponse>;
  clearNewStoryTaggedUserIDs: () => void;
}

interface StoryFormState extends Form {
  taggedUserIDs: number[];
  form: Partial<Story>;
  submitted: boolean;
  hasBeenParsed: { [key: string]: boolean };
  parsedLinks: { [key: string]: OpenGraphLink };
}

const fileInputIDBase = 'story_image_uploader';

const initialState: StoryFormState = {
  form: {
    id: 0, contentMD: '', status: Story.StatusEnum.Empty,
    locationQualifier: Story.LocationQualifierEnum.Empty, links: []
  },
  taggedUserIDs: [],
  dirty: {},
  submitted: false,
  parsedLinks: {},
  hasBeenParsed: {}
};

const storyFormSaved = (nextProps: Props, storyFormID: string) =>
  nextProps.savedStoryFormID === storyFormID;

class StoryForm extends React.Component<LProps<Props>, StoryFormState> {
  public state = initialState;
  private id = uuid();
  private parseURLs: () => void;

  public componentWillMount() {
    const { taggedUserIDs, story } = this.props;
    this.setState({
      ...initialState,
      taggedUserIDs,
      form: { ...initialState.form, ...story }
    });
    this.parseURLs = debounce(() => this._parseURLs(), 1500);
  }

  public componentWillUnmount() {
    const { clearNewStoryTaggedUserIDs } = this.props;
    clearNewStoryTaggedUserIDs();
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { form } = this.state;
    const { fileInputID } = this.props;
    if (nextProps.postedPhoto !== this.props.postedPhoto && nextProps.postedPhoto) {
      this.update({ form: { ...form, photo: nextProps.postedPhoto } });
      clearPostedImage(fileInputID);
    }

    if (storyFormSaved(nextProps, this.id)) {
      this.id = uuid();
      this.clear();
    }
  }

  private tagUser(user: UserRead) {
    const { taggedUserIDs } = this.state;
    if (taggedUserIDs.some((userID) => userID === user.id)) return;
    this.setState({
      taggedUserIDs: [ ...taggedUserIDs, user.id ]
    });
  }

  private untagUser(user: UserRead) {
    const { taggedUserIDs } = this.state;
    if (!taggedUserIDs.some((userID) => userID === user.id)) return;
    this.setState({
      taggedUserIDs: taggedUserIDs.filter((userID) => userID !== user.id)
    });
  }

  private async updateContent(contentMD: string) {
    const { form } = this.state;
    this.setState({ form: { ...form, contentMD } });
    this.parseURLs();
  }

  private async _parseURLs() {
    const { parseOpenGraph } = this.props;
    const { contentMD } = this.state.form;
    const urls = getURLs(contentMD);
    const newURLs = urls.filter((url) => !this.state.hasBeenParsed[url]);
    const hasBeenParsed = urls.reduce((previous: { [key: string]: boolean }, url: string) => ({
      ...previous,
      [url]: true
    }), this.state.hasBeenParsed);
    this.setState({ hasBeenParsed });
    const res = await parseOpenGraph(newURLs);
    const { form } = this.state;
    const parsedLinks = {
      ...this.state.parsedLinks,
      ...res.openGraphLinks
    };
    const links = [
      ...Object.keys(res.openGraphLinks),
      ...Object.keys(this.state.parsedLinks)
    ].reduce((previous: OpenGraphLink[], url: string) => {
      const ogl = parsedLinks[url];
      if (!ogl) return previous;
      return previous.concat([ogl]);
    }, []);
    this.setState({
      parsedLinks,
      form: { ...form, links }
    });
  }

  private update(update: Partial<StoryFormState>, _validate: boolean = false) {
    this.setState(update as StoryFormState);
  }

  private clear() {
    const { taggedUserIDs, story, cancelStoryEdit } = this.props;
    if (story.id !== 0) {
      cancelStoryEdit();
    } else {
      this.setState({
        ...initialState,
        taggedUserIDs,
        form: { ...initialState.form, ...story }
      });
    }
  }

  private deletePhoto() {
    const { form } = this.state;
    const { story } = this.props;
    if (form.photo === story.photo) {
      this.update({ form: up({ photo: '' }, form) });
    } else {
      this.update({ form: up({ photo: story.photo }, form) });
    }
  }

  private submit() {
    const { form, taggedUserIDs } = this.state;
    const submitted = true;
    const { fileInputID, onSave } = this.props;
    if (form.contentMD.length < 50) {
      this.setState({ submitted });
      return;
    }
    onSave(form as Story, taggedUserIDs, fileInputID, this.id);
  }

  public render() {
    const { form, submitted } = this.state;
    const {
      t, story,
      isVerifiedCompetitionUser, hasCompetitionEnded,
      fileInputID, pledge, pointsForNextStory
    } = this.props;
    const placeholder = (pledge && pledge.storyPrompt) || t('Describe your action');
    return (
      <div className={cx('cc__story_form cc__white_box', { 'cc__story_form--markdown': pledge && pledge.canUseMarkdown })}>
        {story.id === 0 && <h6>{t('Take this action')}</h6>}
        {pledge && <div><Link to={makePledgePath(pledge, t)}>{pledge.shortTitle}</Link></div>}
        {(Boolean(pointsForNextStory) && !hasCompetitionEnded) && <div className="cc__lighttext">{pointsForNextStory} {t('points for next story submitted')}</div>}
        <div>
          <ImageUploader
            id={fileInputID}
            photoClassName={'cc__story__photo'}
            aspectRatio={t('1:1 to 2:1')}
            currentImage={form.photo}
            onDelete={() => this.deletePhoto()}
            disabled={!isVerifiedCompetitionUser}
            prompt={t('Upload a photo or video and receive 50 bonus points.')}
          />
        </div>
        {(pledge && pledge.canTagUsers) &&
          <div>
            <StoryTaggedUsers
              taggedUserIDs={this.state.taggedUserIDs}
              removeUserTag={(user) => this.untagUser(user)}
            />
            <Search
              onSelect={(user) => this.tagUser(user)}
            />
          </div>
        }
        <div>
          {(pledge && pledge.canUseMarkdown) &&
            <MarkdownEditor
              value={form.contentMD}
              onChange={(markdown) => this.update({ form: up({ contentMD: markdown }, form) })}
            />
          }
          {(pledge && !pledge.canUseMarkdown) &&
            <div>
              <FormGroup className="cc__story_form__input cc__lighttext">
                <Input
                  aria-label={t('Markdown content')}
                  type="textarea"
                  disabled={!isVerifiedCompetitionUser}
                  placeholder={placeholder}
                  value={form.contentMD}
                  onChange={(e) => this.updateContent(e.target.value)}
                />
              </FormGroup>
              {(submitted && form.contentMD.length < 50) &&
                <FormFeedback>
                {t('Stories require 50 minimum characters. You\'ve entered')}&nbsp;
                  <span
                    style={{ width: '16px', display: 'inline-block', textAlign: 'right' }}
                  >{form.contentMD.length}</span>&nbsp;
                  {form.contentMD.length === 1 ? t('character') : t('characters')}.
                </FormFeedback>
              }
            </div>
          }
        </div>
        {(pledge && pledge.qualifyLocation) &&
          <div className="cc__story_form__details cc__lighttext">
            <span>{t('I\'m doing this action at')}&nbsp;</span>
            <Dropdown
              currentValue={form.locationQualifier || Story.LocationQualifierEnum.Empty}
              values={[Story.LocationQualifierEnum.Empty, Story.LocationQualifierEnum.Home, Story.LocationQualifierEnum.Work, Story.LocationQualifierEnum.Both]}
              displayValues={[t('----'), t('home'), t('work'), t('both')]}
              onChange={(value) => this.update({ form: up({ locationQualifier: toLocationQualifierEnum(value) }, form) })}
            />
          </div>
        }
        {(pledge && !pledge.canTagUsers) &&
          <div className="cc__story_form__details cc__lighttext">
            <span>{t('This is something')}&nbsp;</span>
            <Dropdown
              currentValue={form.status || Story.StatusEnum.Empty}
              values={[Story.StatusEnum.Empty, Story.StatusEnum.AlreadyDo, Story.StatusEnum.New]}
              displayValues={[t('----'), t('I already do'), t('new to me')]}
              onChange={(value) => this.update({ form: up({ status: toStoryStatusEnum(value) }, form) })}
            />
          </div>
        }
        {(form.links && form.links.length > 0) &&
          <OpenGraphCarousel links={form.links} />
        }
        {isVerifiedCompetitionUser &&
          <div className="cc__story_form__buttons">
            <Button
              color="primary"
              style={{marginRight: 20}}
              onClick={() => this.submit()}
            >{t('Take action')}</Button>
            <Button
              outline={true}
              color="danger"
              disabled={!isVerifiedCompetitionUser}
              onClick={() => this.clear()}
            >{t('Cancel')}</Button>
          </div>
        }
        {!isVerifiedCompetitionUser &&
          <Alert
            color={StyleState.Warning}
          >{t('Please verify your email so you can upload stories!')}</Alert>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<ManualProps>): Partial<Props> => ({
  isVerifiedCompetitionUser: selectors.isVerifiedCompetitionUser(state),
  fileInputID: `${fileInputIDBase}${ownProps.story.id}`,
  postedPhoto: state.postedImages[`${fileInputIDBase}${ownProps.story.id}`],
  taggedUserIDs: state.storyTaggedUserIDs[ownProps.story.id] || [],
  savedStoryFormID: state.savedStoryFormID,
  hasCompetitionEnded: selectors.hasCompetitionEnded(state),
  pointsForNextStory: calculatePointsForNextStory(state.userPledgeCompletions, ownProps.pledge)
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  clearPostedImage: actions.clearPostedImage,
  cancelStoryEdit: actions.cancelStoryEdit,
  clearNewStoryTaggedUserIDs: actions.clearNewStoryTaggedUserIDs,
  parseOpenGraph: actions.parseOpenGraph
}, dispatch);

export default compose(
  withLocalization('story'),
  connect(mapStateToProps, mapDispatchToProps)
)(StoryForm) as React.SFC<ManualProps>;
