import * as React from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { Input, Button, FormGroup, FormText } from 'reactstrap';
import { connect } from 'react-redux';
import { LProps, withLocalization } from '../utils/wrappers';
import { State } from '../redux/state';
import { Comment } from '../userApi';
import actions from '../redux/actions';
import { uuid } from '../utils/uuid';
import { StatType } from '../routes/Home/StatIcon';

interface ManualProps {
  comment: Partial<Comment>;
}

interface Props extends ManualProps {
  savedCommentPromptID: string;
  postComments: (comment: Partial<Comment>, commentPromptID: string) => void;
}

interface CommentPromptState {
  contentMD: string;
}

const initialState: CommentPromptState = { contentMD: '' };

const commentSaved = (nextProps: Props, id: string) =>
  nextProps.savedCommentPromptID === id;

class CommentPrompt extends React.Component<LProps<Props>, CommentPromptState> {
  public state = initialState;
  private id = uuid();

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (commentSaved(nextProps, this.id)) {
      this.setState(initialState);
      this.id = uuid();
    }
  }

  public render() {
    const {
      t, postComments, comment
    } = this.props;
    const { contentMD } = this.state;
    return (
      <div className={'cc__comment_prompt'}>
        <FormGroup
          id={`cc__comment_prompt__${comment.commentableType}_${comment.commentableID}`}
        >
          <Input
            aria-label={t('Write a comment')}
            placeholder={t('Write a comment...')}
            value={contentMD}
            onChange={(e) => this.setState({ contentMD: e.target.value })}
          />
          <FormText>{contentMD.length > 500 ? `${contentMD.length}/2000` : ''}</FormText>
        </FormGroup>

        {contentMD.length > 0 &&
          <Button
            color="primary"
            onClick={() => postComments({ ...comment, ...this.state }, this.id)}
          >
            {t('Save')}
          </Button>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: ManualProps): Partial<Props> => ({
  savedCommentPromptID: state.savedCommentPromptID
});
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: ManualProps): Partial<Props> => bindActionCreators({
  postComments: actions.postComments
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('story')
)(CommentPrompt) as React.ComponentClass<ManualProps>;
