import { Action } from 'redux';
import { State } from '../state';

export enum ActionType {
  Patch = 'patch',
  SetError = 'setError',
  SetLandingPagePosition = 'setLandingPagePosition',
  UpdateLoginForm = 'updateLoginForm',
  UpdateSignupForm = 'updateSignupForm',
  UpdateCompetitionVerificationForm = 'updateCompetitionVerificationForm',
  UpdateForgotPasswordForm = 'updateForgotPasswordForm',
  UpdatePasswordResetForm = 'updatePasswordResetForm',
  SubmitLogin = 'submitLogin',
  SubmitSignup = 'submitSignup',
  MarkFieldDirty = 'markFieldDirty',
  Initialize = 'initialize',
  SetStoryQuery = 'setStoryQuery',
  SetBlogEntryQuery = 'setBlogEntryQuery',
  UpdateStoryForm = 'updateStoryForm',
  GetCompetitionStories = 'getCompetitionStories',
  RouteChange = 'routeChange',
  InitializeProfilePage = 'initializeProfilePage',
  UpdateUserUpdateForm = 'updateUserUpdateForm',
  UpdateUserEmailForm = 'updateUserEmailForm',
  putUser = 'putUser',
  GetBlogEntries = 'getBlogEntries'
}

export interface ApplicationAction extends Action {
  type: ActionType;
  payload: Partial<State>;
}

export type MarkFieldDirty = (field: string) => void;
export type UpdateForm = <T>(update: Partial<T>) => void;
export type SubmitForm = () => void;
