import * as React from 'react';
import { FormGroup, Input, FormFeedback, Button, FormText } from 'reactstrap';
import { isEmpty } from 'lodash';
import { CompetitionPledge, ValidationErrors, ValidationError } from '../../userApi';
import { TranslationFunction } from 'i18next';
import { InviteAFriendForm, InviteAFriendErrors, makeInviteAFriendErrors } from '../../utils/forms';
import { State, Modals } from '../../redux/state';
import * as selectors from '../../redux/selectors';
import { CompetitionPledges, estimatePointsAvailable } from '../../utils/userApiHelper';
import actions from '../../redux/actions';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { withLocalization } from '../../utils/wrappers';
import { connect } from 'react-redux';
import { uuid } from '../../utils/uuid';
import EntityEmailDomains from '../../shared/EntityEmailDomains';
import LinkButton from '../../shared/LinkButton';

interface Props {
  pledge: CompetitionPledge;
  pointsAvailable: number;
  t: TranslationFunction;
  validationErrors: ValidationErrors;
  savedInviteAFriendFormID: string;
  hasCompetitionEnded: boolean;
  save: (form: InviteAFriendForm, formID: string) => void;
  removeInviteAFriendVE: (code: ValidationError.CodeEnum) => void;
  openEntityEmailDomainModal: () => void;
}

interface InviteAFriendPledgeState {
  form: { name: string, email: string };
  errors: Partial<InviteAFriendErrors>;
  submitted: boolean;
  dirty: { [key: string]: boolean };
}

const initialState: InviteAFriendPledgeState = {
  errors: {},
  form: { name: '', email: '' },
  dirty: {},
  submitted: false
};

const didFormSave = (nextProps: Props, id: string) =>
  nextProps.savedInviteAFriendFormID === id;

class InviteAFriendPledge extends React.Component<Props, InviteAFriendPledgeState> {
  public state = initialState;
  private id = uuid();

  public componentWillReceiveProps(nextProps: Props) {
    const { t } = this.props;
    if (didFormSave(nextProps, this.id)) {
      this.setState(initialState);
      this.id = uuid();
    } else if (nextProps.validationErrors !== this.props.validationErrors) {
      const { form, dirty, submitted } = this.state;
      const errors = makeInviteAFriendErrors({ ...form, dirty, submitted }, nextProps.validationErrors, t);
      this.setState({ errors });
    }
  }

  private update(update: Partial<InviteAFriendPledgeState>, validate: boolean = false) {
    const { validationErrors, t } = this.props;
    if (validate) {
      const { dirty, submitted } = this.state;
      let { form } = this.state;
      if (update.form) {
        form = { ...form, ...update.form };
      }
      const errors = makeInviteAFriendErrors({ ...form, dirty, submitted }, validationErrors, t);
      update.errors = errors;
    }
    this.setState(update as InviteAFriendPledgeState);
  }

  private markDirty(field: string) {
    const { validationErrors, t, removeInviteAFriendVE } = this.props;
    const dirty = { ...this.state.dirty, [field]: true };
    const { submitted } = this.state;
    const update: Partial<InviteAFriendPledgeState> = { dirty };
    const errors = makeInviteAFriendErrors({ ...this.state.form, dirty, submitted }, validationErrors, t);
    update.errors = errors;
    this.setState(update as InviteAFriendPledgeState);
    if (field === 'email') {
      removeInviteAFriendVE(ValidationError.CodeEnum.ErrUserEmailExists);
    }
  }

  private submit() {
    const { dirty, form } = this.state;
    const submitted = true;
    const { save, validationErrors, t } = this.props;
    const errors = makeInviteAFriendErrors({ ...form, dirty, submitted }, validationErrors, t);
    if (isEmpty(errors)) {
      save({ ...form, dirty, submitted }, this.id);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors } = this.state;
    const { pledge, t, pointsAvailable, openEntityEmailDomainModal, hasCompetitionEnded } = this.props;
    return (
      <div>
        <div>
          {pledge.shortTitle}
        </div>
        {(pointsAvailable && !hasCompetitionEnded) && <div>{pledge.points}&nbsp;{t('points')}</div>}
        {!pointsAvailable && <div className="cc__textlight">{t('You\'ve earned all points for this pledge')}</div>}
        <FormGroup>
          <Input
            invalid={Boolean(errors.name)}
            id="invite_friend__name__input"
            aria-label={t('Name')}
            type="text"
            name="name"
            placeholder={t('Name')}
            onChange={(e) => this.update({ form: { ...form, name: e.target.value } })}
            onBlur={(_e) => this.markDirty('name')}
            value={form.name || ''}
          />
          <FormText><LinkButton onClick={openEntityEmailDomainModal} text={t('View permitted university domains')} /></FormText>
          {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Input
            invalid={Boolean(errors.email)}
            id="invite_friend__input"
            aria-label={t('Email')}
            type="email"
            name="email"
            placeholder={t('Email')}
            onChange={(e) => this.update({ form: { ...form, email: e.target.value } })}
            onBlur={(_e) => this.markDirty('email')}
            value={form.email || ''}
          />
          {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </FormGroup>
        <Button
          color="primary"
          onClick={() => this.submit()}
        >{t('Invite')}</Button>
        <EntityEmailDomains />
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: {}): Partial<Props> => {
  const pledge = selectors.findPledgeByUUID(state, { uuid: CompetitionPledges.InviteAFriend });
  return {
    pledge,
    hasCompetitionEnded: selectors.hasCompetitionEnded(state),
    pointsAvailable: estimatePointsAvailable(state.userPledgeCompletions, pledge),
    validationErrors: state.inviteAFriendVE,
    savedInviteAFriendFormID: state.savedInviteAFriendFormID
  };
};

const mapDispatchToProps = (dispatch: Dispatch, _ownProps: {}): Partial<Props> => bindActionCreators({
  save: actions.postInvite,
  removeInviteAFriendVE: actions.removeInviteAFriendVE,
  openEntityEmailDomainModal: () => actions.setModal(Modals.EntityEmailDomains)
}, dispatch);

export default compose(
  withLocalization('inviteAFriend'),
  connect(mapStateToProps, mapDispatchToProps)
)(InviteAFriendPledge) as React.SFC;
