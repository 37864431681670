import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { CompetitionPledge, Story, TaggedUserIDs } from '../userApi';
import { LProps } from '../utils/wrappers';
import actions from '../redux/actions';
import { State } from '../redux/state';
import { connect } from 'react-redux';
import StoryForm from './StoryForm';

interface ManualProps {
  competitionPledgeID: number;
}

interface Props extends ManualProps {
  pledge: CompetitionPledge;
  postCompetitionStories: (story: Story, taggedUserIDs: TaggedUserIDs, fileInputID: string, storyFormID: string) => void;
}

const StoryCreator = ({
  postCompetitionStories, pledge
}: LProps<Props>) => (
  <StoryForm
    pledge={pledge}
    story={{ id: 0, competitionPledgeID: pledge.id } as Story}
    onSave={postCompetitionStories}
  />
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  pledge: state.competitionPledges[ownProps.competitionPledgeID]
});
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: ManualProps): Partial<Props> => bindActionCreators({
  postCompetitionStories: actions.postCompetitionStories
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(StoryCreator) as React.ComponentClass<ManualProps>;
