import * as React from 'react';
import { CompetitionEntityPledge } from '../../userApi';
import MarkdownEditor from '../../shared/MarkdownEditor';
import { Button } from 'reactstrap';
import { LProps, withLocalization } from '../../utils/wrappers';
import { patch } from '../../redux/actions/all';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { State } from '../../redux/state';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

interface ManualProps {
  competitionEntityPledge: Partial<CompetitionEntityPledge>;
}

interface Props extends ManualProps {
  save: (cep: CompetitionEntityPledge) => void;
  destroy: () => void;
  cancel: () => void;
}

interface CompetitionEntityPledgeFormState {
  form: Partial<CompetitionEntityPledge>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: CompetitionEntityPledgeFormState = {
  form: { contentMD: '' },
  dirty: {},
  submitted: false
};

class CompetitionEntityPledgeForm extends React.Component<LProps<Props>, CompetitionEntityPledgeFormState> {
  public state = initialState;

  public componentWillMount() {
    const { competitionEntityPledge } = this.props;
    this.setState({
      form: { ...initialState.form, ...competitionEntityPledge }
    });
  }

  private update(update: Partial<CompetitionEntityPledgeFormState>) {
    this.setState(update as CompetitionEntityPledgeFormState);
  }

  public render() {
    const { form } = this.state;
    const { t, save, destroy, cancel } = this.props;
    return (
      <div>
        <MarkdownEditor
          value={form.contentMD}
          onChange={(markdown) => this.update({ form: { ...form, contentMD: markdown.slice(0, 1000) } })}
        />
        <div className="cc__space_around">
          <Button
            color="primary"
            size="sm"
            onClick={() => save(form)}
          >{t('Save')}</Button>
          <Button
            color="warning"
            size="sm"
            onClick={cancel}
          >{t('Cancel')}</Button>
          {form.id !== 0 &&
            <Button
              color="danger"
              size="sm"
              onClick={destroy}
            ><i className="fa fa-trash" /></Button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (_state: State, _ownProps: LProps<ManualProps>): Partial<Props> => ({});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: LProps<ManualProps>): Partial<Props> => bindActionCreators({
  cancel: () => patch({ editingCompetitionEntityPledgeEntityID: 0 }),
  save: (cep: CompetitionEntityPledge) => actions.saveCompetitionEntityPledge(cep),
  destroy: () => actions.destroyCompetitionEntityPledge(ownProps.competitionEntityPledge)
}, dispatch);

export default compose(
  withLocalization('pledge'),
  connect(mapStateToProps, mapDispatchToProps)
)(CompetitionEntityPledgeForm) as React.SFC<ManualProps>;
