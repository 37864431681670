import { Middleware, Store } from 'redux';
import logger from '../../utils/logger';
import { setError } from '../actions/errors';
import { State, UiErrorType } from '../state';

const crashReporter: Middleware = (_store: Store<State>) => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    logger.error(error);

    return next(setError({
      code: UiErrorType.FRONTEND
    }));
  }
};

export default crashReporter;
