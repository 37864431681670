import * as React from 'react';

interface ManualProps {
  contentHTML: string;
  textOnly?: boolean;
  limit?: number;
}

interface SafeHTMLState {
  safeContentHTML: string;
}

const stripTags = (html: string): string => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

class SafeHTML extends React.Component<ManualProps, SafeHTMLState> {
  public state: SafeHTMLState = { safeContentHTML: '' };

  public async componentWillMount() {
    const { filterHTML } = await import('../utils/markdown');
    this.setState({
      safeContentHTML: filterHTML(this.props.contentHTML)
    });
  }

  public render() {
    const { safeContentHTML } = this.state;
    const { textOnly, limit } = this.props;
    let content = safeContentHTML;
    if (textOnly) content = stripTags(safeContentHTML);
    if (limit && content.length > limit) content = `${content.slice(0, limit)}...`;
    return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    );
  }
}

export default SafeHTML;
