import {
  UserRead, ValidationError, Stories, UsersByID, EntityMemberships, Likes, Comments, Flags,
  BlogEntries, Pagination, CommentPagination, LatestToPledge, CompetitionUserScores,
  Entities, CompetitionPledges, CompetitionScoreLevels, CompetitionPledgeCategories,
  CompetitionEntityScores, CompetitionScore, UserEmails, CompetitionUser, PledgeCompletions, EntityRanks,
  EntityTopUserScoresPagination, EntityTopSubentityScores, CompetitionEntities, Competition, TopUserScores, Story, StoryTaggedUserIDs, ValidationErrors, CompetitionEntityPledges, PledgePoints, CompetitionUserPoint, CompetitionUserScore, CompetitionUserScoreQuery
} from '../userApi';
import { StyleState } from '../utils/styles';
import { parseLocalStorageState } from './middleware/storage';
import { Routes } from '../utils/routerHelper';
import { lodashQueryString } from '../utils/lodashQueryString';

export enum UiErrorType {
  FRONTEND = 'frontend',
  BACKEND = 'backend'
}

export interface UiError {
  uiMessage?: string;
  code?: UiErrorType;
}

export interface Toast {
  error?: Error | string;
  message: string;
  title?: string;
  on: boolean;
  style: StyleState;  
  persistent?: boolean;
}

export interface Notification {
  level: StyleState;
  header: string;
  body: React.ReactChild;
}

export enum Modals {
  CompetitionScoreLevels = 'competitionScoreLevels',
  UserUpdatePassword = 'userUpdatePassword',
  UserDeletePassword = 'userDeletePassword',
  EntityEmailDomains = 'entityEmailDomains',
  ConfirmStoryDelete = 'confirmStoryDelete',
  StoryPoints = 'storyPoints',
  None = ''
}

export interface InitialQuery {
  [key: string]: string;
  userApiBase?: string;
  storeCompleteState?: string;
  qualtricsID?: string;
  competitionName?: string;
  passwordResetToken?: string;
  verificationToken?: string;
  invitationToken?: string;
}

export interface StoryQuery {
  entityID: number;
  userID: number;
  storyID: number;
  competitionPledgeID: number;
}

export interface BlogEntryQuery {
  entityID: number;
  blogEntryID: number;
  competitionID: number;
}

export interface State {
  token?: string;
  storyTerm?: string,
  initialQuery: InitialQuery;

  initialized: boolean;
  spinner?: boolean;
  toast?: Partial<Toast>;

  pathname: string;
  pendingRoute?: Routes;

  modal: Modals;
  storyToDelete?: Story;

  landingPagePosition?: number;
  user?: UserRead;
  error?: UiError;

  // Any component that uploads a photo should use ImageUploader.
  // ImageUploader will preview the photo using a data URL. When
  // the form is submitted, first call userApi.postImage. This will
  // save the image in GCS and return its URL, which will be stored
  // in postImages by the input[type="file"].id.
  // IMPORTANT: form components are responsible for detecting when their
  // the URL has been updated in postedImages. At this point they should
  // update their form value and clear the URL from postedImage. In
  // actions/image.ts, postImage action will clear the file input of its
  // contents.
  postedImages?: { [key: string]: string };

  editingStoryID?: number;
  editingCompetitionEntityPledgeEntityID?: number;

  savedCommentPromptID?: string;
  savedUserUpdateID?: string;
  savedStoryFormID?: string;
  savedInviteAFriendFormID?: string;

  competitionEmailVerificationSent?: string;

  loginVE?: ValidationError[];
  joinVE?: ValidationError[];
  signupVE?: ValidationError[];
  userEmailVE?: ValidationError[];
  forgotPasswordVE?: ValidationError[];
  userUpdateVE?: ValidationError[];
  userDeleteVE?: ValidationError[];
  inviteAFriendVE: ValidationErrors;

  searchResults: { [key: string]: number[] };
  isSearching: { [key: string]: boolean };

  storyQuery: StoryQuery;
  blogEntryQuery: BlogEntryQuery;

  competitionPledgeIDsByUUID: { [key: string]: number };

  competition?: Competition;
  entities?: Entities;
  competitionEntities?: CompetitionEntities;
  competitionPledges?: CompetitionPledges;
  competitionEntityPledges?: CompetitionEntityPledges;
  competitionScoreLevels?: CompetitionScoreLevels;
  competitionPledgeCategories?: CompetitionPledgeCategories;
  competitionTopUserScores?: TopUserScores;
  competitionEntityScores?: CompetitionEntityScores;
  competitionScore?: CompetitionScore;

  lastPointsEarned?: CompetitionUserPoint;
  lastPointsCanEarnMore?: boolean;

  userEmails?: UserEmails;
  userMemberships?: EntityMemberships;
  competitionUser?: CompetitionUser;
  userPledgeCompletions?: PledgeCompletions;
  userPledgePoints?: PledgePoints;
  userEntityRanks?: EntityRanks;

  profileMemberships?: EntityMemberships;
  profilePledgeCompletions?: PledgeCompletions;
  profileEntityRanks?: EntityRanks;

  stories?: Stories;
  storyTaggedUserIDs?: StoryTaggedUserIDs;
  storyLikes?: Likes;
  storyFlags?: Flags;
  storyPagination?: Pagination;
  storyComments?: Comments;
  storyCommentsLikes?: Likes;
  storyCommentsFlags?: Flags;
  storyCommentPagination?: CommentPagination;
  blogEntries?: BlogEntries;
  blogEntryLikes?: Likes;
  blogEntryPagination?: Pagination;
  blogEntryCommentPagination?: CommentPagination;
  blogEntryComments?: Comments;
  blogEntryCommentsLikes?: Likes;
  blogEntryCommentsFlags?: Flags;

  entityTopUserScores?: { [key: string]: TopUserScores };
  entityTopUserScoresPagination?: EntityTopUserScoresPagination;
  entityTopSubentityScores?: EntityTopSubentityScores;

  latestToPledge?: LatestToPledge;
  competitionUserScores?: CompetitionUserScores;

  topCompetitionUserScores?: CompetitionUserScore[];
  topCompetitionUserScoreQuery?: CompetitionUserScoreQuery;

  users?: UsersByID;
}

const initialQuery: InitialQuery = {};
if (window.URLSearchParams) {
  const initialParams = new URLSearchParams(window.location.search.replace(/^\?/, ''));
  initialParams.forEach((value, key) => initialQuery[key] = value);
} else {
  // shim for IE.
  const initialParams = lodashQueryString(window.location.search);
  Object.keys(initialParams).forEach((key) => initialQuery[key] = initialParams[key][0]);
}

export const defaultState: State = {
  initialized: false,
  pendingRoute: null,
  pathname: '',

  initialQuery,
  modal: Modals.None,
  spinner: false,
  savedCommentPromptID: '',
  savedUserUpdateID: '',
  savedStoryFormID: '',
  savedInviteAFriendFormID: '',
  competitionEmailVerificationSent: '',
  toast: { on: false, title: '', message: '', style: StyleState.Info },
  landingPagePosition: 0,
  error: null,
  token: '',
  storyQuery: {
    storyID: 0,
    entityID: 0,
    userID: 0,
    competitionPledgeID: 0
  },
  blogEntries: [],
  blogEntryQuery: {
    entityID: 0,
    blogEntryID: 0,
    competitionID: 0
  },

  postedImages: {},
  editingCompetitionEntityPledgeEntityID: 0,

  userEmailVE: [],
  loginVE: [],
  signupVE: [],
  joinVE: [],
  forgotPasswordVE: [],
  userUpdateVE: [],
  inviteAFriendVE: [],

  searchResults: {},
  isSearching: {},

  competitionPledgeIDsByUUID: {},

  stories: [],
  storyTaggedUserIDs: {},

  topCompetitionUserScores: [],
  topCompetitionUserScoreQuery: {
    entityID: 0,
    competitionID: 0,
    pagination: {
      page: 1,
      pageSize: 50,
      nextPage: -1,
      totalPages: 0
    }
  }
};

const initialState: State = Object.assign(defaultState, parseLocalStorageState());

export default initialState;
