import { Dispatch } from 'redux';
import up from 'updeep';
import * as selectors from '../selectors';
import { State } from '../state';
import { ImageResponse, ImageMutation } from '../../userApi';
import { ExtraArguments } from '../extraArguments';
import { postFormData } from '../../utils/userApiHelper';
import { patch } from './patch';
import {
  dataURLToBlob, DataURLFromFile, setDataURLDimensions, getExtensionForMimeType, blobToDataURL
} from '../../utils/image';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { handleUnexpectedError } from './handleUnexpectedError';
import { trackEvent } from '../../utils/googleAnalytics';

export const clearPostedImage = (inputID: string) => {
  return patch({ postedImages: up.omitBy((_value: string, k: string) => k === inputID) });
};

// If postImage does not return an ImageResponse, but getImage(imageID) exists,
// you can assume that saving the image failed.
export const postImage = (imageID: string) =>
  async (dispatch: Dispatch, getState: () => State, { i18n, getImage, delImage }: ExtraArguments): Promise<ImageResponse> => {
    const t = i18n.getFixedT(i18n.language, 'image');
    const dataURLFromFile = getImage(imageID);
    if (!dataURLFromFile) return;

    const blob = await dataURLToBlob(dataURLFromFile);
    const formData = new FormData();
    formData.append('image', blob, dataURLFromFile.filename);
    const state = getState();
    const basePath = selectors.userApiBasePath(state);
    try {
      const imgResponse = await postFormData<ImageResponse>(`${basePath}/image`, formData, state.token);
      trackEvent('image', 'create');
      delImage(imageID);
      dispatch(patch({
        postedImages: { [imageID]: imgResponse.url }
      }));
      return imgResponse;
    } catch (err) {
      handleUnexpectedError(err, {
        message: t('We failed to save your photo. Please contact support.')
      })(dispatch, getState);
      trackEvent('image', 'unhandledError', 'create');
    }
  };

export const postImageMutation = (dataURLFromFile: DataURLFromFile, mutation: ImageMutation) =>
async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { i18n } = extra;
  const t = i18n.getFixedT(i18n.language, 'image');
  dispatch(patch({ spinner: true }));
  try {
    const state = getState();
    const { token } = state;
    const blob = await dataURLToBlob(dataURLFromFile);
    const formData = new FormData();
    formData.append('image', blob, dataURLFromFile.filename);
    formData.append('mutation', JSON.stringify(mutation));

    const options = {
      body: formData,
      headers: { Authorization: token, Accept: mutation.contentType.toString() || dataURLFromFile.mimeType },
      method: 'POST'
    };
    const basePath = selectors.userApiBasePath(state);

    const response = await fetch(`${basePath}/image_mutation`, options);
    trackEvent('image', 'mutate');
    const blob2 = await response.blob();
    const mimeType = response.headers.get('Content-type');
    const dataURL = await blobToDataURL(blob2);
    const newDataURL = await setDataURLDimensions({
      filename: dataURLFromFile.filename.replace(/\.\w+$/, getExtensionForMimeType(mimeType)),
      width: 0, height: 0,
      dataURL,
      mimeType
    });
    dispatch(patch({ spinner: false }));
    return newDataURL;
  } catch (err) {
    dispatch(patch({ spinner: false }));
    toast({
      style: StyleState.Warning,
      message: t('We had trouble editing your photo. Try cropping this photo or using another.')
    })(dispatch, getState);
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('image', 'unhandledError', 'mutate');
  }
};
