import up from 'updeep';
import { patch } from './patch';
import { Toast, State } from '../state';
import { Dispatch } from 'redux';
import { StyleState } from '../../utils/styles';

const closedToast: Toast = up.constant({ on: false, title: '', message: '', style: StyleState.Info });

export const closeToast = () => patch({
  toast: closedToast
});

export const toast = (toastData: Partial<Toast>, duration: number = 10000) => (dispatch: Dispatch, _getState: () => State) => {
  dispatch(patch({ toast: { on: true, ...toastData } }));
  if(!toastData.persistent){
    setTimeout(() => {
      dispatch(patch({ toast: closedToast }));
    }, duration);
  }
};
