import { pickBy } from 'lodash';
import { Form, validationReady } from './form';
import { ValidationErrors, validationErrorExists, ValidationError } from '../../userApi';
import { TranslationFunction } from 'i18next';
import { validator, makeErrorMessage } from '../validations';

export interface InviteAFriendForm extends Form {
  email: string;
  name: string;
}

export interface InviteAFriendErrors {
  email: string;
  name: string;
}

const inviteAFriendValidation = {
  type: 'object',
  required: ['name', 'email'],
  properties: {
    email: { type: 'string', format: 'email', minLength: 5 },
    name: { type: 'string', minLength: 2 }
  }
};

export const makeInviteAFriendErrors = (form: Partial<InviteAFriendForm>, validationErrors: ValidationErrors, t: TranslationFunction): Partial<InviteAFriendErrors> => {
  let errorMessages: Partial<InviteAFriendErrors> = {};
  if (!validator.validate(inviteAFriendValidation, form)) {
    const { errors } = validator;
    errorMessages = {
      email: makeErrorMessage(errors, '.email', t('Must be valid email.'), validationReady(form as Form, 'email')),
      name: makeErrorMessage(errors, '.name', t('Must be at least two characters.'), validationReady(form as Form, 'name'))
    };
  }
  if (!errorMessages.email && validationErrorExists(ValidationError.CodeEnum.ErrUserEmailExists, validationErrors)) {
    errorMessages.email = t('A user has already registered that email.');
  }
  return pickBy(errorMessages);
};
