import * as React from 'react';
import * as InfiniteScroll from 'react-infinite-scroller';
import { Alert } from 'reactstrap';
import { LProps, withLocalization } from '../utils/wrappers';
import { BlogEntries, Pagination } from '../userApi';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { State } from '../redux/state';
import actions from '../redux/actions';
import { connect } from 'react-redux';
import { StyleState } from '../utils/styles';
import BlogEntryPreview from './BlogEntryPreview';

interface ManualProps {
  showEntity?: boolean;
}

interface Props extends ManualProps {
  blogEntries: BlogEntries;
  pagination: Pagination;
  pageBlogEntries: (page: number) => void;
}

const BlogEntries = ({
  t, blogEntries, pagination, pageBlogEntries, showEntity = true
}: LProps<Props>) => (
  <div className="cc__blog_entries">
    {(blogEntries.length === 0 && pagination.nextPage === -1) &&
      <div className="cc__white_box">
        <Alert
          color={StyleState.Info}
        >{t('No blog entries found!')}</Alert>
      </div>
    }
    {(blogEntries.length > 0 || pagination.nextPage > -1) &&
      <InfiniteScroll
        pageStart={1}
        element="section"
        loadMore={pageBlogEntries}
        hasMore={pagination.nextPage !== -1}
        loader={<div className="loader" key={0}>{t('Loading')} ...</div>}
        threshold={250}
      >
        {blogEntries.map((blogEntry, i) =>
          <BlogEntryPreview
            key={blogEntry.id}
            showEntity={showEntity}
            blogEntry={blogEntry}
          />
        )}
      </InfiniteScroll>
    }
  </div>
);

const mapStateToProps = (state: State): Partial<Props> => ({
  blogEntries: state.blogEntries || [],
  pagination: state.blogEntryPagination
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  pageBlogEntries: actions.pageBlogEntries
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('blogEntry')
)(BlogEntries) as React.ComponentClass<ManualProps>;
