import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import i18n from './utils/i18nextShim';
import { createCompetitionStore } from './redux';
import a, { ActionType } from './redux/actions';
import Routes from './routes';
import { getCurrentRoute } from './utils/routerHelper';
import { createBrowserHistory } from 'history';
import { patch } from './redux/actions/all';
import { delImage, setImage, getImage } from './redux/imageStore';
import { intercom } from './utils/intercom';

/**
 * Here we (1) initialize Redux store and (2) History. If
 * add real internationalization support, we will also want
 * to initialize the i18n language files here.
 */
const initializeApp = async () => {
  const history = createBrowserHistory({});

  const store = createCompetitionStore({ history, i18n, delImage, setImage, getImage });

  // After initializing story, query parameters will be stored in state.
  // Push the pathname without query string to remove them.
  history.push(history.location.pathname);

  a.initialize()(store.dispatch, store.getState, { history, i18n });

  store.dispatch(patch({
    pendingRoute: getCurrentRoute(history.location, i18n),
    pathname: history.location.pathname
  }, ActionType.RouteChange));
  history.listen((location, _action) => {
    intercom('update');
    if (location.pathname !== store.getState().pathname) {
      store.dispatch(patch({
        pendingRoute: getCurrentRoute(location, i18n),
        pathname: location.pathname
      }, ActionType.RouteChange));
    }
  });

  render(
    (
      <Provider store={store}>
        <Routes history={history}/>
      </Provider>
    ),
    document.getElementById('app')
  );
};

initializeApp();
