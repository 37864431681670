import * as React from 'react';
import up from 'updeep';
import { isEmpty, trim } from 'lodash';
import { Button, Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import { compose, Dispatch } from 'redux';
import { withLocalization, LProps } from '../../utils/wrappers';
import { State } from '../../redux/state';
import actions, { bindActionCreators } from '../../redux/actions';
import { connect } from 'react-redux';
import { LoginErrors, makeLoginErrors, loginVEMessages } from '../../utils/forms';
import { Patch } from '../../redux/actions/all';
import RouteLink from '../../shared/RouteLink';
import { Routes } from '../../utils/routerHelper';
import { ValidationErrors, CreateSessionParams, removeVEForField } from '../../userApi';

interface Props {
  login: (params: CreateSessionParams) => void;
  validationErrors: ValidationErrors;
  patch: Patch;
}

interface LoginState {
  form: { email: string, password: string };
  errors: Partial<LoginErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: LoginState = {
  form: { email: '', password: '' },
  errors: {},
  dirty: {},
  submitted: false
};

class Login extends React.Component<LProps<Props>, LoginState> {
  public state: LoginState = initialState;

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { validationErrors, t } = this.props;
    if (nextProps.validationErrors !== validationErrors) {
      const { dirty, submitted, form } = this.state;
      const errors = makeLoginErrors({ dirty, submitted, ...form }, nextProps.validationErrors, t);
      this.setState({ errors });
    }
  }

  private update(update: Partial<LoginState>, validate: boolean = false) {
    const { validationErrors, t } = this.props;
    const { dirty, submitted, form } = this.state;
    if (validate) {
      const errors = makeLoginErrors({ dirty, submitted, ...form }, validationErrors, t);
      update.errors = errors;
    }
    this.setState(update as LoginState);
  }

  private markDirty(field: string) {
    const { submitted, form } = this.state;
    const dirty = { ...this.state.dirty, [field]: true };
    const { validationErrors, t, patch } = this.props;
    let ve = validationErrors;
    if (loginVEMessages[field]) {
      ve = removeVEForField(field, validationErrors, loginVEMessages);
      patch({ loginVE: up.constant(ve) });
    }

    const update: Partial<LoginState> = { dirty: up({ [field]: true }, this.state.dirty) };
    const errors = makeLoginErrors({ dirty, submitted, ...form }, ve, t);
    update.errors = errors;
    this.setState({ errors, dirty });
  }

  private login() {
    const { form, dirty } = this.state;
    const submitted = true;
    const { login, t, validationErrors } = this.props;
    const errors = makeLoginErrors({ dirty, submitted, ...form }, validationErrors, t);
    if (isEmpty(errors)) {
      login(form);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors } = this.state;
    const {
      t
    } = this.props;
    return (
      <div id="login_form">
        <FormGroup id="login_form__email">
          <Label>{t('Email')}</Label>
          <Input
            aria-label={t('Email')}
            invalid={Boolean(errors.email)}
            value={form.email || ''}
            type={'email'}
            onBlur={() => this.markDirty('email')}
            onChange={(e) => this.update({ form: { ...form, email: trim(e.target.value || '').toLowerCase() } })}
          />
          {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </FormGroup>
        <FormGroup id="login_form__password">
          <Label>{t('Password')}</Label>
          <Input
            aria-label={t('Password')}
            invalid={Boolean(errors.password)}
            value={form.password as string || ''}
            type={'password'}
            onBlur={() => this.markDirty('password')}
            onChange={(e) => this.update({ form: { ...form, password: e.target.value } })}
          />
          {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
        </FormGroup>
        <Button
          id="login_form__submit"
          onClick={() => this.login()}
          style={{ width: '250px' }}
        >{t('Login')}</Button>
        <div style={{ margin: '10px 0px 5px 0px' }}>
          <span>{t('Forget something?')}</span>&nbsp;
          <RouteLink route={Routes.ForgotPassword}>{t('Reset Password')}</RouteLink>
        </div>
        <div style={{ margin: '5px 0px' }}>
          <span>{t('Not registered?')}</span>&nbsp;
          <RouteLink route={Routes.Signup}>{t('Sign up!')}</RouteLink>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _props: Props) => ({
  validationErrors: state.loginVE
});

const mapDispatchToProps = (dispatch: Dispatch, _props: Props) =>
  bindActionCreators({
    login: actions.login,
    patch: actions.patch
  }, dispatch);

export default compose(
  withLocalization('auth'),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
