import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Competition } from '../../userApi';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import { RouterProps } from 'react-router';
import actions from '../../redux/actions';
import { Routes } from '../../utils/routerHelper';
import BlogEntries from '../../shared/BlogEntries';
import { asPage } from '../../utils/wrappers/asPage';
import { WaitingPageContent } from '../../shared/WaitingPage';
import CompetitionAboutText from '../About/CompetitionAboutText';

interface Props {
  competition: Competition;
  initializingBlogPage: boolean;
  initializeBlogPage: () => void;
}

class BlogPage extends React.Component<LProps<Props> & RouterProps> {
  public componentWillMount() {
    const { initializeBlogPage } = this.props;
    initializeBlogPage();
  }

  public render() {
    const {
      initializingBlogPage, t
    } = this.props;
    if (initializingBlogPage) {
      return (<WaitingPageContent />);
    }
    return (
      <div className="cc__page__column_content">
        <div className="cc__page__column_content__left cc__lighttext">
          <CompetitionAboutText />
        </div>
        <div className="cc__page__column_content__right">
          <BlogEntries showEntity={false} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: {}): Partial<Props> => ({
  initializingBlogPage: state.pendingRoute === Routes.Blog
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializeBlogPage: actions.initializeBlogPage
}, dispatch);

export default asPage({})(compose(
  withLocalization('blog'),
  connect(mapStateToProps, mapDispatchToProps)
)(BlogPage) as React.SFC);
