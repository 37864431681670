import { Dispatch } from 'redux';
import up from 'updeep';
import { State } from '../state';
import * as selectors from '../selectors';
import { ExtraArguments } from '../extraArguments';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';

export const initializeSearchResults = (key: string) => patch({ searchResults: { [key]: up.constant([]) } });

export const clearSearchResults = (key: string) => patch({ searchResults: up.omitBy((_v, k) => k === key) });

export const search = (query: string, key: string) =>
async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  dispatch(patch({ isSearching: { [key]: true } }));
  const state = getState();
  const api = selectors.userApi(state);
  const { competition } = state;
  try {
    const res = await api.getCompetitionUsers(competition.id, query);
    dispatch(patch({
      searchResults: { [key]: res.searchResults },
      isSearching: up.omitBy((_value, id) => id === key),
      users: res.users
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
  }
};
