import * as React from 'react';
import { ModalHeader, Modal, ModalBody, Carousel, CarouselItem, FormGroup, Label, Button } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { has } from 'lodash';
import Radio from '../../shared/Radio';
import Checkbox from '../../shared/Checkbox';
import AgreeToTermsAndConditions from '../../shared/AgreeToTermsAndConditions';
import { SignupState } from './Signup';
import { UserType } from '../../userApi';
import { userTypeFromString } from '../../utils/userApiHelper';

interface Props {
  t: TranslationFunction;
  canSetHealthSystemsEmployee: boolean;
  update: (update: Partial<SignupState>) => void;
  submit: () => void;
  signupState: SignupState;
}

export default class SignupWizard extends React.Component<Props> {

  private previous() {
    const { update, signupState: { signupWizardStep } } = this.props;
    if (signupWizardStep > 0) {
      update({ signupWizardStep: signupWizardStep - 1 });
    }
  }

  private next() {
    const { update, signupState: { signupWizardStep } } = this.props;
    if (signupWizardStep < 2) {
      update({ signupWizardStep: signupWizardStep + 1 });
    }
  }

  public render() {
    const { t, signupState, update, submit, canSetHealthSystemsEmployee } = this.props;
    const { form, agreedToTermsAndConditions, signupWizardStep } = signupState;

    const items = [
      (<CarouselItem key={0}>
        <FormGroup
          id="signup_form__user_type"
          role={'radiogroup'}
          aria-labelledby={'signup_form__uc_affiliation__label'}
        >
          <Label id={'signup_form__uc_affiliation__label'}>{t('UC Affiliation')}</Label><br />
          <Radio
            name={'user_type'}
            value={UserType.Student.toString()}
            checked={form.type === UserType.Student}
            onChange={(userType) => update({ form: { ...form, type: userTypeFromString(userType) } })}
          >{t('Student')}</Radio>&nbsp;&nbsp;
          <Radio
            name={'user_type'}
            value={UserType.Faculty.toString()}
            checked={form.type === UserType.Faculty}
            onChange={(userType) => update({ form: { ...form, type: userTypeFromString(userType) } })}
          >{t('Faculty')}</Radio>&nbsp;&nbsp;
          <Radio
            name={'user_type'}
            value={UserType.Staff.toString()}
            checked={form.type === UserType.Staff}
            onChange={(userType) => update({ form: { ...form, type: userTypeFromString(userType) } })}
          >{t('Staff')}</Radio>&nbsp;&nbsp;
        </FormGroup>
        <div className="cc__signup_wizard__controls">
          <Button
            outline={true}
            size="sm"
            color="primary"
            onClick={() => update({ signupWizardStep: signupWizardStep + 1 })}
            className="cc__signup_wizard__controls__next"
          >{t('Next')}</Button>
        </div>
      </CarouselItem>),
      (<CarouselItem key={1}>
        <FormGroup
          id="signup_form__labUser"
          role={'radiogroup'}
          aria-labelledby={'signup_form__labe_user__label'}
        >
          <Label id={'signup_form__labe_user__label'}>{t('Are you a lab user?')}</Label><br />
          <Radio
            name={'signup_form__lab_user'}
            value={'1'}
            checked={form.options.isLabUser === true}
            onChange={(value) => update({ form: { ...form, options: { ...form.options, isLabUser: value === '1' } } })}
          >{t('Yes')}</Radio>&nbsp;&nbsp;
          <Radio
            name={'signup_form__lab_user'}
            value={'0'}
            checked={form.options.isLabUser === false}
            onChange={(value) => update({ form: { ...form, options: { ...form.options, isLabUser: value === '1' } } })}
          >{t('No')}</Radio>&nbsp;&nbsp;
        </FormGroup>
        <div className="cc__signup_wizard__controls">
          <Button
            outline={true}
            size="sm"
            color="primary"
            onClick={() => update({ signupWizardStep: signupWizardStep - 1 })}
            className="cc__signup_wizard__controls__prev"
          >{t('Back')}</Button>
          <Button
            outline={true}
            size="sm"
            color="primary"
            disabled={!has(form.options, 'isLabUser')}
            onClick={() => update({ signupWizardStep: signupWizardStep + 1 })}
            className="cc__signup_wizard__controls__next"
          >{t('Next')}</Button>
        </div>
      </CarouselItem>)
    ];
    if (canSetHealthSystemsEmployee) {
      items.push((<CarouselItem key={2}>
        <FormGroup
          id="signup_form__healthFacilityEmployee"
          role={'radiogroup'}
          aria-labelledby={'signup_form__health_facility_employee__label'}
        >
          <Label id="signup_form__health_facility_employee__label">{t('Are you a UC Health System employee?')}</Label><br />
          <Radio
            name={'signup_form__health_facility_employee'}
            value={'1'}
            checked={form.options.isHealthFacilityEmployee === true}
            onChange={(value) => update({ form: { ...form, options: { ...form.options, isHealthFacilityEmployee: value === '1' } } })}
          >{t('Yes')}</Radio>&nbsp;&nbsp;
          <Radio
            name={'signup_form__health_facility_employee'}
            value={'0'}
            checked={form.options.isHealthFacilityEmployee === false}
            onChange={(value) => update({ form: { ...form, options: { ...form.options, isHealthFacilityEmployee: value === '1' } } })}
          >{t('No')}</Radio>&nbsp;&nbsp;
        </FormGroup>
        <div className="cc__signup_wizard__controls">
          <Button
            outline={true}
            size="sm"
            color="primary"
            onClick={() => update({ signupWizardStep: signupWizardStep - 1 })}
            className="cc__signup_wizard__controls__prev"
          >{t('Back')}</Button>
          <Button
            outline={true}
            size="sm"
            color="primary"
            disabled={!has(form.options, 'isHealthFacilityEmployee')}
            onClick={() => update({ signupWizardStep: signupWizardStep + 1 })}
            className="cc__signup_wizard__controls__next"
          >{t('Next')}</Button>
        </div>
      </CarouselItem>));
    }

    items.push(
      <CarouselItem key={3}>
        <FormGroup
          id="signup_form__agreedToTermsAndConditions"
        >
          <Checkbox
            checked={Boolean(agreedToTermsAndConditions)}
            onChange={(checked) => checked ?
              update({ agreedToTermsAndConditions: new Date().toISOString() }) :
              update({ agreedToTermsAndConditions: '' })
            }
          >
            <AgreeToTermsAndConditions />
          </Checkbox>
        </FormGroup>
        <div className="cc__signup_wizard__controls">
          <Button
            outline={true}
            size="sm"
            color="primary"
            onClick={() => update({ signupWizardStep: signupWizardStep - 1 })}
            className="cc__signup_wizard__controls__prev"
          >{t('Back')}</Button>
          <Button
            color="primary"
            size="sm"
            disabled={!Boolean(agreedToTermsAndConditions)}
            onClick={() => submit()}
            id="cc__signup_wizard__controls__submit"
          >{t('Submit')}</Button>
        </div>
      </CarouselItem>
    );

    return (
      <Modal
        isOpen={signupState.isWizardOpen}
        id="signup_form_wizard"
        onClosed={() => update({ isWizardOpen: false })}
        toggle={() => update({ isWizardOpen: !signupState.isWizardOpen })}
      >
        <ModalHeader
        toggle={() => update({ isWizardOpen: !signupState.isWizardOpen })}
        >{t('Complete your registration')}</ModalHeader>
        <ModalBody>
          <Carousel
            interval={false}
            next={() => this.next()}
            previous={() => this.previous()}
            activeIndex={signupState.signupWizardStep}
          >
            {items}
          </Carousel>
        </ModalBody>
      </Modal>
    );
  }
}
