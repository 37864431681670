// @ts-nocheck
import * as React from 'react';
import { compose } from 'redux';
import { CompetitionUserScore } from '../userApi';
import { withLocalization, LProps } from '../utils/wrappers';

interface Props {
  score: CompetitionUserScore;
  userUniversityRank?: number;
  pledgeCompletions: number;
  footprint: number;
}

const UserStats = ({
  t, formatNumber, score, userUniversityRank, pledgeCompletions, footprint
}: LProps<Props>) => (
  <div className="cc__white_box">
    <div className="cc__stats__item">
      <div className="cc__stats__label">{t('Points')}</div>
      <div className="cc__lighttext">{formatNumber(score.points, 0)}</div>
    </div>    
    <div className="cc__stats__item">
      <div className="cc__stats__label">{t('Actions')}</div>
      <div className="cc__lighttext">{formatNumber(pledgeCompletions, 0)}</div>
    </div>
    <div className="cc__stats__item">
      <div
        className="cc__stats__label"
        dangerouslySetInnerHTML={{
          __html: t('Current Carbon Footprint (mt CO2e)')
        }}
      />
      <div className="cc__lighttext">{formatNumber(footprint)}</div>
    </div>
    <div className="cc__stats__item">
      <div
        title="Lbs CO2 equivalent saved "
        className="cc__stats__label"
        dangerouslySetInnerHTML={{
          __html: t('Lbs CO<sub>2</sub>e saved')
        }}
      />
      
    </div>
    <div className="cc__stats__item">
      <div
        className="cc__stats__label"
        dangerouslySetInnerHTML={{
          __html: t('&nbsp; - New actions')
        }}
      />
      <div className="cc__lighttext">{formatNumber(score.savingsCO2Lbs, 0)}</div>
    </div>
    <div className="cc__stats__item">
      <div
        className="cc__stats__label"
        dangerouslySetInnerHTML={{
          __html: t('&nbsp; - Actions already taken')
        }}
      />
      <div className="cc__lighttext">{formatNumber(score.takenCO2Lbs, 0)}</div>
    </div>
  </div>
);

export default compose(
  withLocalization('userStats')
)(UserStats) as React.FunctionComponent<Props>;
