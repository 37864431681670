import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { State, Toast } from '../redux/state';
import actions, { bindActionCreators } from '../redux/actions';

interface Props {
  toast: Toast;
  closeToast: () => void;
}

const Toast = ({
  toast,
  closeToast
}: Props) => (
  <div id="cc__toast">
    <Alert color={toast.style} id="cc__toast" isOpen={toast.on} toggle={closeToast}>
      {toast.title && <h6>{toast.title}</h6>}
      <div dangerouslySetInnerHTML={{__html:toast.message}}/>
    </Alert>
  </div>
);

const mapStateToProps = (state: State) => ({
  toast: state.toast
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  closeToast: actions.closeToast
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Toast);
