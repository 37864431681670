import * as React from 'react';
import { ButtonGroup, Button, Tooltip } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { makeStoryPath } from '../utils/routerHelper';

interface Props {
  isCreator: boolean;
  t: TranslationFunction;
  storyID: number;
  editStory: () => void;
  openConfirmStoryDelete: () => void;
}

const copyPermalink = (storyID: number) => {
  const input = document.getElementById(`cc__story_link__${storyID}`) as HTMLInputElement;
  input.select();
  document.execCommand('copy');
};

interface StoryControlsState {
  tooltipOpen: boolean;
}

const initialState: StoryControlsState = {
  tooltipOpen: false
};

class StoryControls extends React.Component<Props, StoryControlsState> {
  public state = initialState;

  private copyLink() {
    const { storyID } = this.props;
    copyPermalink(storyID);
    this.setState({ tooltipOpen: true });
    setTimeout(() => {
      this.setState({ tooltipOpen: false });
    }, 5000);
  }

  public render() {
    const {
      isCreator, t, storyID, editStory, openConfirmStoryDelete
    } = this.props;
    return (
      <div className="cc__story__controls">
        <ButtonGroup>
          <Button
            id={`cc__story_controls__link__${storyID}`}
            size="sm"
            outline
            onClick={() => this.copyLink()}
            aria-label={t('Copy link')}
          ><i className="fa fa-link" /></Button>
          {isCreator && <Button size="sm" outline onClick={editStory} aria-label={t('Edit')}><i className="fa fa-pencil" /></Button>}
          {isCreator && <Button size="sm" outline={true} aria-label={t('Delete')} color="danger" onClick={openConfirmStoryDelete}><i className="fa fa-trash" /></Button>}
        </ButtonGroup>
        <input
          readOnly={true}
          id={`cc__story_link__${storyID}`}
          type="text"
          value={`${window.location.protocol}//${window.location.host}${makeStoryPath(storyID, t)}`} style={{ position: 'absolute', left: '-99999px' }}
        />
        <Tooltip
          placement="left"
          isOpen={this.state.tooltipOpen}
          trigger={'hover'}
          toggle={() => this.state.tooltipOpen && this.setState({ tooltipOpen: false })}
          target={`cc__story_controls__link__${storyID}`}
        >
          {t('Story link copied!')}
        </Tooltip>
      </div>
    );
  }
}

export default StoryControls;
