import * as React from 'react';
import cx from 'classnames';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { withLocalization, LProps } from '../utils/wrappers';
import ScoreboardCampuses from '../shared/ScoreboardCampuses';
import ScoreboardTeams from '../shared/ScoreboardTeams';
import ScoreboardParticipants from '../shared/ScoreboardParticipants';
import { CompetitionEntityScore, CompetitionUserScore } from '../userApi';
import ScoreboardHealthSystems from './ScoreboardHealthSystems';
import { makeCompetitionUserScoresPath } from '../utils/routerHelper';

interface Props {
  topTeamScores: CompetitionEntityScore[];
  topUserScores: CompetitionUserScore[];
  competitionID: number;
}

enum ScoreboardView {
  Campuses = 'campuses',
  Participants = 'participants',
  Teams = 'teams',
  HealthSystems = 'healthSystems'
}

interface ScoreboardState {
  scoreboardView: ScoreboardView;
}

class Scoreboard extends React.Component<LProps<Props>, ScoreboardState> {
  public state = { scoreboardView: ScoreboardView.Participants };

  public render() {
    const { scoreboardView } = this.state;
    const { t, topTeamScores, topUserScores, competitionID } = this.props;
    return (
      <div className="cc__scoreboard">
        <div className="cc__scoreboard__header">
          <div className={'cc__darktext'}>{t('Scoreboard')}</div>
          <div className={cx('cc__scoreboard__header_tabs')}>
            <div
              role="button"
              className={cx('cc__scoreboard__header_tab', { active: scoreboardView === ScoreboardView.Participants })}
              onClick={() => this.setState({ scoreboardView: ScoreboardView.Participants })}
            >{t('Participants')}</div>
            
            <div
              role="button"
              className={cx('cc__scoreboard__header_tab', { active: scoreboardView === ScoreboardView.Teams })}
              onClick={() => this.setState({ scoreboardView: ScoreboardView.Teams })}
            >{t('Teams')}</div>
            <div
              role="button"
              className={cx('cc__scoreboard__header_tab', { active: scoreboardView === ScoreboardView.Campuses })}
              onClick={() => this.setState({ scoreboardView: ScoreboardView.Campuses })}
            >{t('UC Location')}</div>            
          </div>
        </div>
        {scoreboardView === ScoreboardView.Campuses && <ScoreboardCampuses />}        
        {scoreboardView === ScoreboardView.Teams &&
          <ScoreboardTeams
            limit={25}
            teamScores={topTeamScores}
          />
        }
        {scoreboardView === ScoreboardView.Participants &&
          <ScoreboardParticipants
            viewAll={makeCompetitionUserScoresPath(competitionID, t)}
            userScores={topUserScores}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: Props): Props => ({
  topTeamScores: state.competitionEntityScores.team,
  topUserScores: state.competitionTopUserScores,
  competitionID: state.competition.id
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('scoreboard')
)(Scoreboard) as React.ComponentClass;
