import { State } from '../state';

interface Searchable {
  id: string;
}

export const searchResults = (state: State, props: Searchable) => {
  if (!state.searchResults[props.id]) return [];
  return state.searchResults[props.id].map((userID) => state.users[userID]);
};
