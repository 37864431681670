import * as React from 'react';
import BlogEntryForm from '../../shared/BlogEntryForm';
import { getBlogEntryEditParams, Routes } from '../../utils/routerHelper';
import { LProps, withLocalization } from '../../utils/wrappers';
import { State } from '../../redux/state';
import { RouterProps, withRouter } from 'react-router';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { asPage } from '../../utils/wrappers/asPage';
import actions from '../../redux/actions';
import { BlogEntry } from '../../userApi';
import { WaitingPageContent } from '../../shared/WaitingPage';

interface Props {
  blogEntry: BlogEntry;
  initializing: boolean;
  blogEntryID: number;
  initializeBlogEntryForm: (blogEntryID: number) => void;
}

class BlogEntryEditPage extends React.Component<Props> {

  public componentDidMount() {
    const { initializeBlogEntryForm, blogEntryID } = this.props;
    initializeBlogEntryForm(blogEntryID);
  }

  public render() {
    const { blogEntry, initializing } = this.props;
    if (initializing) {
      return <WaitingPageContent />;
    }
    return (<BlogEntryForm blogEntry={blogEntry} />);
  }
}

const mapStateToProps = (state: State, props: LProps<RouterProps>): Partial<Props> => {
  const { pendingRoute, blogEntries } = state;
  const { blogEntryID } = getBlogEntryEditParams(props.history.location, props.i18n);
  if (pendingRoute === Routes.BlogEntryEdit) return { blogEntryID, initializing: true };
  const blogEntry = blogEntries.find((be) => be.id === blogEntryID);
  return ({
    blogEntry,
    initializing: false
  });
};
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: LProps<RouterProps>) => bindActionCreators({
  initializeBlogEntryForm: actions.initializeBlogEntryForm
}, dispatch);

export default asPage({})(compose(
  withLocalization('editBlogEntry'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BlogEntryEditPage) as React.SFC);
