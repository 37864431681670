import * as selectors from '../selectors';
import up from 'updeep';
import { Dispatch } from 'redux';
import { State, StoryQuery, Modals } from '../state';
import { ExtraArguments } from '../extraArguments';
import { getUserParams, Routes } from '../../utils/routerHelper';
import { patch } from './patch';
import { setStoryQuery } from './story';
import { handleUnexpectedError } from './handleUnexpectedError';
import { ActionType } from './types';
import { StyleState } from '../../utils/styles';
import { toast } from './toast';
import { getValidationErrors } from '../../utils/userApiHelper';
import { matchesErrorCode, ValidationError } from '../../userApi';
import { trackEvent } from '../../utils/googleAnalytics';

export const initializeUserPage = () => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  dispatch(patch({ spinner: true }));

  const t = i18n.getFixedT(i18n.language, 'userProfile');
  const params = getUserParams(history.location, i18n);
  const entityStoryQuery: StoryQuery = {
    entityID: 0,
    userID: params.userID,
    competitionPledgeID: 0,
    storyID: 0
  };
  setStoryQuery(entityStoryQuery)(dispatch, getState);
  try {
    const state = getState();
    const { competition, token } = state;
    const api = selectors.userApi(state);
    const res = await api.getCompetitionUser(competition.id, params.userID, token);
    const { pendingRoute: pr } = getState();
    dispatch(patch({
      ...res,
      spinner: false,
      pendingRoute: pr === Routes.User ? null : pr
    }, ActionType.InitializeProfilePage));
  } catch (err) {
    const { pendingRoute: pr } = getState();
    dispatch(patch({
      storyPagination: { nextPage: -1 },
      pendingRoute: pr === Routes.User ? null : pr
    }));
    handleUnexpectedError(err, {
      title: t('Oops!'),
      message: t('We experienced an error retrieving stories.')
    })(dispatch, getState);
  }
};

export const deleteUser = (password: string) => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'account');
  try {
    const state = getState();
    dispatch(patch({ spinner: true }));
    const api = selectors.userApi(state);
    const { user } = state;
    await api.postUserDelete(user.id, password, { headers: { 'Content-Type': 'text/plain'  }});
    trackEvent('account', 'delete');
    dispatch(patch({ modal: null }));
    toast({
      style: StyleState.Info,
      message: t('Your account was successfully deleted')
    })(dispatch, getState);
    setTimeout(() => {
      localStorage.clear();
      window.location.href = '/';
    }, 5000);
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors && validationErrors.some(matchesErrorCode(ValidationError.CodeEnum.ErrPasswordIncorrect))) {
      const update: Partial<State> = {
        userDeleteVE: validationErrors,
        modal: Modals.UserDeletePassword,
        spinner: false
      };
      dispatch(patch(update));
      return;
    }
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('account', 'unhandledError', 'delete');
    return;
  }
};

export const removeUserDeleteVE = (code: ValidationError.CodeEnum) => async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  const { userDeleteVE } = getState();
  if (!userDeleteVE) return;
  dispatch(patch({
    userDeleteVE: up(up.reject<ValidationError>(matchesErrorCode(code)), userDeleteVE)
  }));
};
