import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State, Modals } from '../redux/state';
import StoryComponent from '../shared/StoryComponent';
import { RouterProps, withRouter } from 'react-router';
import { getStoryParams } from '../utils/routerHelper';
import { LProps, withLocalization } from '../utils/wrappers';
import { Story, CompetitionPledge, UserRead } from '../userApi';
import { makeStoryTitle } from '../utils/userApiHelper';
import actions from '../redux/actions';
import * as selectors from '../redux/selectors';
import SafeHTMLPageContent from '../shared/SafeHTMLPageContent';
import { asPage } from '../utils/wrappers/asPage';
import ConfirmDeleteModal from '../shared/ConfirmDeleteModal';

interface Props {
  story: Story;
  pledge: CompetitionPledge;
  title: string;
  creator: UserRead;
  modal: Modals;
  initializeStoryPage: () => void;
  deleteStory: () => void;
  closeConfirmStoryDelete: () => void;
}

class StoryPage extends React.Component<Props> {

  public componentWillMount() {
    const { initializeStoryPage } = this.props;
    initializeStoryPage();
  }

  public render() {
    const {
      story, title, pledge, creator, modal, deleteStory, closeConfirmStoryDelete
    } = this.props;
    if (!story) return '';
    return (
      <div>
        {(!story.contentHTML || !pledge.canUseMarkdown) &&
          <StoryComponent story={story} />
        }
        {(story.contentHTML && pledge.canUseMarkdown) &&
          <SafeHTMLPageContent
            creator={creator}
            title={title}
            contentHTML={story.contentHTML}
            date={story.createdAt}
            photo={story.photo}
          />
        }
        <ConfirmDeleteModal
          isOpen={modal === Modals.ConfirmStoryDelete}
          confirm={deleteStory}
          cancel={closeConfirmStoryDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => {
  const { storyID } = getStoryParams(ownProps.history.location, ownProps.i18n);
  if (isNaN(storyID)) return {};
  const story = state.stories.find((s) => s.id === storyID);
  if (!story) return {};
  const taggedUsers = selectors.taggedUsers(state, { story });
  const pledge = state.competitionPledges[story.competitionPledgeID];
  const creator = state.users[story.createdBy];
  return {
    modal: state.modal,
    creator,
    story,
    pledge,
    title: makeStoryTitle(story, pledge, taggedUsers, ownProps.t)
  };
};
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: LProps<RouterProps>) => bindActionCreators({
  initializeStoryPage: actions.initializeStoryPage,
  deleteStory: actions.deleteStory,
  closeConfirmStoryDelete: actions.closeConfirmStoryDelete
}, dispatch);

export default asPage({ requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withRouter,
  withLocalization('story'),
  connect(mapStateToProps, mapDispatchToProps)
)(StoryPage));
