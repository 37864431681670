import * as React from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import { compose } from 'redux';
import { OpenGraphLink } from '../userApi';
import { LProps, RouterProps, withRouterAndHelpers, withLocalization } from '../utils/wrappers';
import ExternalLink from './ExternalLink';

interface Props {
  links: OpenGraphLink[];
}

interface OpenGraphCarouselState {
  activeIndex: number;
}

class OpenGraphCarousel extends React.Component<LProps<Props> & RouterProps<{}>, OpenGraphCarouselState> {
  public state = { activeIndex: 0 };

  private next() {
    const { activeIndex } = this.state;
    const { links } = this.props;
    if (activeIndex === links.length - 1) {
      this.setState({ activeIndex: 0 });
    } else {
      this.setState({ activeIndex: activeIndex + 1 });
    }
  }

  private previous() {
    const { activeIndex } = this.state;
    const { links } = this.props;
    if (activeIndex === 0) {
      this.setState({ activeIndex: links.length - 1 });
    } else {
      this.setState({ activeIndex: activeIndex - 1 });
    }
  }

  public render() {
    const {
      links, t
    } = this.props;
    const { activeIndex } = this.state;
    return (
      <div
        className="cc__open_graph__carousel"
        style={{ display: 'block' }}
      >
      <div>
        <Carousel
          activeIndex={activeIndex}
          next={() => this.next()}
          previous={() => this.previous()}
          interval={false}
        >
          {links.map((link, i) => (
            <CarouselItem autoPlay={false} key={i}>
              <div className="link_slide">
                <div className="link_slide__data">
                  <div><ExternalLink href={link.url}>{link.title || link.url}</ExternalLink></div>
                  <div className="cc__lighttext">
                    <div>{link.description}</div>
                  </div>
                </div>
                <div className="cc__open_graph__img_container">
                  {/** OPTIMIZATION: don't load photos unless they are viewable. */}
                  {(link.image && activeIndex === i) && <img src={link.image} alt={link.title} />}
                </div>
              </div>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" directionText={t('Previous')} onClickHandler={() => this.previous()}>
            <img src="/assets/prevNextControl.svg" alt={t('previous')} />
          </CarouselControl>
          <CarouselControl direction="next" directionText={t('Next')} onClickHandler={() => this.next()} />
        </Carousel>
      </div>
    </div>
    );
  }
}
export default compose(
  withRouterAndHelpers,
  withLocalization('links')
)(OpenGraphCarousel) as React.ComponentClass<Props>;
