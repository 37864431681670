import * as React from 'react';
import { values, has } from 'lodash';
import { State } from '../../redux/state';
import CompetitionEntityPledgeContent from './CompetitionEntityPledge';
import { isAtLeastAcceptedAdmin } from '../../redux/selectors';
import { CompetitionPledge, Entity } from '../../userApi';
import { compose } from 'redux';
import { connect } from 'react-redux';

interface ManualProps {
  pledge: CompetitionPledge;
}

interface Props extends ManualProps {
  entities: Entity[];
}

const CompetitionEntityPledges = ({
  entities, pledge
}: Props) => (
  <div>
    {entities.map((entity) => (
      <CompetitionEntityPledgeContent
        key={entity.id}
        pledge={pledge}
        entity={entity}
      />
    ))}
  </div>
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => {
  const { competitionEntityPledges } = state;
  // get all entities where user is admin or there is CompetitionEntityPledge content.
  const entities = values(state.entities)
    .filter((entity) =>
      isAtLeastAcceptedAdmin(state, { entity }) || has(competitionEntityPledges[ownProps.pledge.id], entity.id)
    );
  return {
    entities
  };
};

export default compose(
  connect(mapStateToProps)
)(CompetitionEntityPledges) as React.ComponentClass<ManualProps>;
