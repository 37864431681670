import { UserRead, CompetitionUser, Entity } from '../userApi';
import { displayFullName } from './userApiHelper';
import { isDevelopment } from './environment';
import logger from './logger';

const INTERCOM_APP_ID = 'khhafx5u';

type Operation = 'boot' | 'update' | 'shutdown';

interface IntercomOptions {
  user?: UserRead;
  competitionUser?: CompetitionUser;
  university?: Entity;
  force?: boolean;
}

export const openIntercom = () => {
  if (!(window as any).Intercom) return;
  (window as any).Intercom('showMessages');
};

interface IntercomData {
  app_id: string;
  name?: string;
  email?: string;
  created_at?: string;
  verified?: boolean;
  user_id?: number | string;
  campus_id?: number;
  campus_name?: string;
}

export const intercom = (op: Operation, { user, competitionUser, university }: IntercomOptions = {}) => {
  if (isDevelopment) return;
  if (!(window as any).Intercom) {
    logger.warn('Intercom unavailable');
    return;
  }

  const data: IntercomData = {
    app_id: INTERCOM_APP_ID
  };
  if (user) {
    data.user_id = user.id;
    data.name = displayFullName(user);
    data.email = user.email;
    data.created_at = user.createdAt;
  }
  if (competitionUser) {
    data.verified = competitionUser.verified;
  }
  if (university) {
    data.campus_id = university.id;
    data.campus_name = university.name;
  }
  // We had an issue where all users were initialized with user_id=0.
  // We need to delete the Intercom cookie associated with that user
  // and reinitialize Intercom fresh.
  if (!data.user_id && !localStorage.getItem('intercomCookieResetv01')) {
    deleteIntercomCookie();
    localStorage.setItem('intercomCookieResetv01', '1');
  }

  (window as any).Intercom(op, data);
};

const deleteIntercomCookie = () => {
  document.cookie = 'intercom-session-khhafx5u=;max-age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.coolcampuschallenge.org; path=/;';
};
