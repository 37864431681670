import * as React from 'react';
import cx from 'classnames';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose } from 'redux';

interface Props {
  fileInputID: string;
  className?: string;
  disabled?: boolean;
  banner?: boolean;
  prompt?: string;
}

const bannerClassname = 'cc__image_uploader__add_banner';

const ImageUploadTrigger = ({
  t, fileInputID, prompt = '', className = '',
  disabled = false, banner = false
}: LProps<Props>) => (
  <div
    role="button"
    className={cx('cc__image_uploader__add_photo', className, { disabled, [bannerClassname]: banner })}
    onClick={() => document.getElementById(fileInputID).click()}
  >
    <div><img className="" src="/assets/add-photo.svg" alt={t('Click to add photo or video')} />{t('Add photo or video')}</div>
    {prompt && <div>{prompt}</div>}
  </div>
);

export default compose(
  withLocalization('imageUploader')
)(ImageUploadTrigger) as React.SFC<Props>;
