/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Cool Climate Competition API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface BlogEntries
 */
export interface BlogEntries extends Array<BlogEntry> {
}

/**
 * 
 * @export
 * @interface BlogEntry
 */
export interface BlogEntry {
    /**
     * 
     * @type {number}
     * @memberof BlogEntry
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BlogEntry
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof BlogEntry
     */
    entityID?: number;
    /**
     * 
     * @type {number}
     * @memberof BlogEntry
     */
    activeVersionID?: number;
    /**
     * 
     * @type {string}
     * @memberof BlogEntry
     */
    photo?: string;
    /**
     * 
     * @type {number}
     * @memberof BlogEntry
     */
    likes?: number;
    /**
     * All entries returned through API should be active (ie latest revision).
     * @type {boolean}
     * @memberof BlogEntry
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BlogEntry
     */
    revision?: number;
    /**
     * 
     * @type {string}
     * @memberof BlogEntry
     */
    contentMD: string;
    /**
     * 
     * @type {string}
     * @memberof BlogEntry
     */
    contentHTML: string;
    /**
     * 
     * @type {string}
     * @memberof BlogEntry
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogEntry
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BlogEntry
     */
    createdBy?: number;
    /**
     * 
     * @type {NullInt}
     * @memberof BlogEntry
     */
    updatedBy?: NullInt;
}

/**
 * 
 * @export
 * @interface BlogEntryCommentResults
 */
export interface BlogEntryCommentResults {
    /**
     * 
     * @type {Comments}
     * @memberof BlogEntryCommentResults
     */
    blogEntryComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof BlogEntryCommentResults
     */
    blogEntryCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof BlogEntryCommentResults
     */
    blogEntryCommentFlags: Flags;
    /**
     * 
     * @type {UsersByID}
     * @memberof BlogEntryCommentResults
     */
    users: UsersByID;
    /**
     * 
     * @type {CommentPagination}
     * @memberof BlogEntryCommentResults
     */
    blogEntryCommentPagination: CommentPagination;
}

/**
 * Keys are BlogEntryIDs. Indicates whether authenticated user has liked the blog entry.
 * @export
 * @interface BlogEntryLikes
 */
export interface BlogEntryLikes {
    [key: string]: boolean;

}

/**
 * 
 * @export
 * @interface BlogEntryResults
 */
export interface BlogEntryResults {
    /**
     * 
     * @type {BlogEntries}
     * @memberof BlogEntryResults
     */
    blogEntries: BlogEntries;
    /**
     * 
     * @type {Likes}
     * @memberof BlogEntryResults
     */
    blogEntryLikes: Likes;
    /**
     * 
     * @type {Pagination}
     * @memberof BlogEntryResults
     */
    blogEntryPagination: Pagination;
    /**
     * 
     * @type {Comments}
     * @memberof BlogEntryResults
     */
    blogEntryComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof BlogEntryResults
     */
    blogEntryCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof BlogEntryResults
     */
    blogEntryCommentFlags: Flags;
    /**
     * 
     * @type {UsersByID}
     * @memberof BlogEntryResults
     */
    users: UsersByID;
    /**
     * 
     * @type {CommentPagination}
     * @memberof BlogEntryResults
     */
    blogEntryCommentPagination: CommentPagination;
}

/**
 * 
 * @export
 * @interface Body
 */
export interface Body {
    /**
     * 
     * @type {Comment}
     * @memberof Body
     */
    comment: Comment;
}

/**
 * Only `contentMD` will be updated.
 * @export
 * @interface Body1
 */
export interface Body1 {
    /**
     * 
     * @type {Comment}
     * @memberof Body1
     */
    comment: Comment;
}

/**
 * 
 * @export
 * @interface Body10
 */
export interface Body10 {
    /**
     * 
     * @type {BlogEntry}
     * @memberof Body10
     */
    blogEntry: BlogEntry;
}

/**
 * 
 * @export
 * @interface Body11
 */
export interface Body11 {
    /**
     * 
     * @type {number}
     * @memberof Body11
     */
    newOwnerID: number;
}

/**
 * 
 * @export
 * @interface Body12
 */
export interface Body12 {
    /**
     * 
     * @type {CompetitionEntityPledge}
     * @memberof Body12
     */
    competitionEntityPledge: CompetitionEntityPledge;
}

/**
 * 
 * @export
 * @interface Body13
 */
export interface Body13 {
    /**
     * 
     * @type {CompetitionEntityPledge}
     * @memberof Body13
     */
    competitionEntityPledge: CompetitionEntityPledge;
}

/**
 * 
 * @export
 * @interface Body14
 */
export interface Body14 {
    /**
     * 
     * @type {string}
     * @memberof Body14
     */
    level: Body14.LevelEnum;
}

/**
 * @export
 * @namespace Body14
 */
export namespace Body14 {
    /**
     * @export
     * @enum {string}
     */
    export enum LevelEnum {
        Member = <any> 'member',
        Admin = <any> 'admin',
        Superadmin = <any> 'superadmin'
    }
}

/**
 * 
 * @export
 * @interface Body15
 */
export interface Body15 {
    /**
     * 
     * @type {string}
     * @memberof Body15
     */
    status: Body15.StatusEnum;
}

/**
 * @export
 * @namespace Body15
 */
export namespace Body15 {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Accepted = <any> 'accepted'
    }
}

/**
 * 
 * @export
 * @interface Body16
 */
export interface Body16 {
    /**
     * 
     * @type {string}
     * @memberof Body16
     */
    email: string;
}

/**
 * 
 * @export
 * @interface Body17
 */
export interface Body17 {
    /**
     * 
     * @type {Password}
     * @memberof Body17
     */
    password: Password;
    /**
     * 
     * @type {string}
     * @memberof Body17
     */
    token: string;
}

/**
 * 
 * @export
 * @interface Body18
 */
export interface Body18 {
    /**
     * 
     * @type {number}
     * @memberof Body18
     */
    footprint?: number;
}

/**
 * 
 * @export
 * @interface Body19
 */
export interface Body19 {
    /**
     * 
     * @type {boolean}
     * @memberof Body19
     */
    primary: boolean;
}

/**
 * 
 * @export
 * @interface Body2
 */
export interface Body2 {
    /**
     * 
     * @type {Entity}
     * @memberof Body2
     */
    entity: Entity;
}

/**
 * 
 * @export
 * @interface Body3
 */
export interface Body3 {
    /**
     * 
     * @type {Entity}
     * @memberof Body3
     */
    entity: Entity;
}

/**
 * 
 * @export
 * @interface Body4
 */
export interface Body4 {
    /**
     * 
     * @type {EntityMembership}
     * @memberof Body4
     */
    entityMembership: EntityMembership;
}

/**
 * 
 * @export
 * @interface Body5
 */
export interface Body5 {
    /**
     * 
     * @type {Story}
     * @memberof Body5
     */
    story: Story;
    /**
     * 
     * @type {TaggedUserIDs}
     * @memberof Body5
     */
    taggedUserIDs?: TaggedUserIDs;
}

/**
 * 
 * @export
 * @interface Body6
 */
export interface Body6 {
    /**
     * 
     * @type {FlaggableTypeflaggableIDflagsFlag}
     * @memberof Body6
     */
    flag: FlaggableTypeflaggableIDflagsFlag;
}

/**
 * 
 * @export
 * @interface Body7
 */
export interface Body7 {
    /**
     * 
     * @type {string}
     * @memberof Body7
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Body7
     */
    email: string;
}

/**
 * 
 * @export
 * @interface Body8
 */
export interface Body8 {
    /**
     * 
     * @type {Story}
     * @memberof Body8
     */
    story: Story;
    /**
     * 
     * @type {TaggedUserIDs}
     * @memberof Body8
     */
    taggedUserIDs?: TaggedUserIDs;
}

/**
 * 
 * @export
 * @interface Body9
 */
export interface Body9 {
    /**
     * 
     * @type {BlogEntry}
     * @memberof Body9
     */
    blogEntry: BlogEntry;
}

/**
 * 
 * @export
 * @interface CalculatorAnswers
 */
export interface CalculatorAnswers {
    /**
     * 
     * @type {CalculatorAnswersUi}
     * @memberof CalculatorAnswers
     */
    ui: CalculatorAnswersUi;
    /**
     * 
     * @type {any}
     * @memberof CalculatorAnswers
     */
    averageFootprint: any;
    /**
     * 
     * @type {CalculatorAnswersUserFootprint}
     * @memberof CalculatorAnswers
     */
    userFootprint: CalculatorAnswersUserFootprint;
    /**
     * 
     * @type {UserPledges}
     * @memberof CalculatorAnswers
     */
    pledges: UserPledges;
}

/**
 * 
 * @export
 * @interface CalculatorAnswersUi
 */
export interface CalculatorAnswersUi {
    /**
     * 
     * @type {string}
     * @memberof CalculatorAnswersUi
     */
    averageComparisonLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorAnswersUi
     */
    unitSystem?: string;
    /**
     * 
     * @type {GoogleMapsGeocode}
     * @memberof CalculatorAnswersUi
     */
    selectedLocation?: GoogleMapsGeocode;
}

/**
 * 
 * @export
 * @interface CalculatorAnswersUserFootprint
 */
export interface CalculatorAnswersUserFootprint {
    /**
     * 
     * @type {CalculatorAnswersUserFootprintInputs}
     * @memberof CalculatorAnswersUserFootprint
     */
    inputs?: CalculatorAnswersUserFootprintInputs;
    /**
     * 
     * @type {CalculatorResultsFootprintTotals}
     * @memberof CalculatorAnswersUserFootprint
     */
    computeFootprint?: CalculatorResultsFootprintTotals;
}

/**
 * 
 * @export
 * @interface CalculatorAnswersUserFootprintInputs
 */
export interface CalculatorAnswersUserFootprintInputs {
    /**
     * 
     * @type {string}
     * @memberof CalculatorAnswersUserFootprintInputs
     */
    inputLocationMode?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorAnswersUserFootprintInputs
     */
    inputLocation?: string;
    /**
     * 
     * @type {Country}
     * @memberof CalculatorAnswersUserFootprintInputs
     */
    inputLocationCountry?: Country;
    /**
     * 
     * @type {string}
     * @memberof CalculatorAnswersUserFootprintInputs
     */
    inputSize?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorAnswersUserFootprintInputs
     */
    inputIncome?: string;
}

/**
 * 
 * @export
 * @interface CalculatorResultLeader
 */
export interface CalculatorResultLeader extends UserRead {
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    resultTransportTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    resultHousingTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    resultFoodTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    resultGoodsTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    resultServicesTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    resultGrandTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    pledgeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultLeader
     */
    completedPledgeCount?: number;
}

/**
 * 
 * @export
 * @interface CalculatorResultsFootprintTotals
 */
export interface CalculatorResultsFootprintTotals {
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultsFootprintTotals
     */
    resultTransportTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultsFootprintTotals
     */
    resultHousingTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultsFootprintTotals
     */
    resultFoodTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultsFootprintTotals
     */
    resultGoodsTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultsFootprintTotals
     */
    resultServicesTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatorResultsFootprintTotals
     */
    resultGrandTotal: number;
}

/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    commentableType?: Comment.CommentableTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    commentableID?: number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    contentMD: string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    flags?: number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    likes?: number;
    /**
     * ID of user that created the comment.
     * @type {number}
     * @memberof Comment
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    updatedAt?: string;
}

/**
 * @export
 * @namespace Comment
 */
export namespace Comment {
    /**
     * @export
     * @enum {string}
     */
    export enum CommentableTypeEnum {
        BlogEntry = <any> 'blog_entry',
        Story = <any> 'story'
    }
}

/**
 * Keys are commentableIDs.
 * @export
 * @interface CommentPagination
 */
export interface CommentPagination {
    [key: string]: Pagination;

}

/**
 * Keys are commentableIDs - uniqueness only guaranteed at commentableType scope (ie blog_entry or story).
 * @export
 * @interface Comments
 */
export interface Comments {
    [key: string]: Array<Comment>;

}

/**
 * 
 * @export
 * @interface Competition
 */
export interface Competition {
    /**
     * 
     * @type {number}
     * @memberof Competition
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Competition
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Competition
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Competition
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Competition
     */
    permittedDomains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Competition
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof Competition
     */
    endsAt: string;
}

/**
 * 
 * @export
 * @interface CompetitionDashboard
 */
export interface CompetitionDashboard {
    /**
     * 
     * @type {Competition}
     * @memberof CompetitionDashboard
     */
    competition: Competition;
    /**
     * 
     * @type {Entities}
     * @memberof CompetitionDashboard
     */
    entities: Entities;
    /**
     * 
     * @type {CompetitionEntities}
     * @memberof CompetitionDashboard
     */
    competitionEntities: CompetitionEntities;
    /**
     * 
     * @type {CompetitionPledges}
     * @memberof CompetitionDashboard
     */
    competitionPledges: CompetitionPledges;
    /**
     * 
     * @type {CompetitionScoreLevels}
     * @memberof CompetitionDashboard
     */
    competitionScoreLevels: CompetitionScoreLevels;
    /**
     * 
     * @type {CompetitionPledgeCategories}
     * @memberof CompetitionDashboard
     */
    competitionPledgeCategories: CompetitionPledgeCategories;
    /**
     * 
     * @type {TopUserScores}
     * @memberof CompetitionDashboard
     */
    competitionTopUserScores: TopUserScores;
    /**
     * 
     * @type {CompetitionEntityScores}
     * @memberof CompetitionDashboard
     */
    competitionEntityScores: CompetitionEntityScores;
    /**
     * 
     * @type {CompetitionScore}
     * @memberof CompetitionDashboard
     */
    competitionScore: CompetitionScore;
    /**
     * 
     * @type {Stories}
     * @memberof CompetitionDashboard
     */
    stories: Stories;
    /**
     * 
     * @type {Comments}
     * @memberof CompetitionDashboard
     */
    storyComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionDashboard
     */
    storyLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionDashboard
     */
    storyFlags: Flags;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionDashboard
     */
    storyCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionDashboard
     */
    storyCommentFlags: Flags;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof CompetitionDashboard
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {Pagination}
     * @memberof CompetitionDashboard
     */
    storyPagination: Pagination;
}

/**
 * Keys are EntityIDs.
 * @export
 * @interface CompetitionEntities
 */
export interface CompetitionEntities {
    [key: string]: CompetitionEntity;

}

/**
 * 
 * @export
 * @interface CompetitionEntity
 */
export interface CompetitionEntity {
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntity
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntity
     */
    entityID: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntity
     */
    competitionID: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntity
     */
    pledgeCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntity
     */
    memberCount: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionEntity
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionEntity
     */
    updatedAt?: string;
}

/**
 * 
 * @export
 * @interface CompetitionEntityPage
 */
export interface CompetitionEntityPage {
    /**
     * 
     * @type {EntityMemberships}
     * @memberof CompetitionEntityPage
     */
    entityMemberships: EntityMemberships;
    /**
     * Keys are EntityIDs.
     * @type {{ [key: string]: Pagination; }}
     * @memberof CompetitionEntityPage
     */
    entityMembershipPagination?: { [key: string]: Pagination; };
    /**
     * 
     * @type {{ [key: string]: TopUserScores; }}
     * @memberof CompetitionEntityPage
     */
    entityTopUserScores?: { [key: string]: TopUserScores; };
    /**
     * 
     * @type {EntityTopUserScoresPagination}
     * @memberof CompetitionEntityPage
     */
    entityTopUserScoresPagination?: EntityTopUserScoresPagination;
    /**
     * 
     * @type {EntityTopSubentityScores}
     * @memberof CompetitionEntityPage
     */
    entityTopSubentityScores?: EntityTopSubentityScores;
    /**
     * 
     * @type {Stories}
     * @memberof CompetitionEntityPage
     */
    stories: Stories;
    /**
     * 
     * @type {Comments}
     * @memberof CompetitionEntityPage
     */
    storyComments?: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionEntityPage
     */
    storyLikes?: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionEntityPage
     */
    storyFlags?: Flags;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionEntityPage
     */
    storyCommentLikes?: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionEntityPage
     */
    storyCommentFlags?: Flags;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof CompetitionEntityPage
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {Pagination}
     * @memberof CompetitionEntityPage
     */
    storyPagination: Pagination;
}

/**
 * 
 * @export
 * @interface CompetitionEntityPledge
 */
export interface CompetitionEntityPledge {
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityPledge
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityPledge
     */
    entityID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityPledge
     */
    competitionPledgeID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionEntityPledge
     */
    contentMD?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionEntityPledge
     */
    contentHTML?: string;
}

/**
 * Keys are CometitionPledgeIDs
 * @export
 * @interface CompetitionEntityPledges
 */
export interface CompetitionEntityPledges {
    [key: string]: { [key: string]: CompetitionEntityPledge; };

}

/**
 * 
 * @export
 * @interface CompetitionEntityScore
 */
export interface CompetitionEntityScore {
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    competitionID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    entityID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    savingsCO2Lbs?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    savingsWaterOz?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    savingsGasGallons?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionEntityScore
     */
    savingsElectricityKwh?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionEntityScore
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionEntityScore
     */
    updatedAt?: string;
}

/**
 * 
 * @export
 * @interface CompetitionEntityScores
 */
export interface CompetitionEntityScores {
    /**
     * 
     * @type {Array<CompetitionEntityScore>}
     * @memberof CompetitionEntityScores
     */
    team?: Array<CompetitionEntityScore>;
    /**
     * 
     * @type {Array<CompetitionEntityScore>}
     * @memberof CompetitionEntityScores
     */
    university?: Array<CompetitionEntityScore>;
    /**
     * 
     * @type {Array<CompetitionEntityScore>}
     * @memberof CompetitionEntityScores
     */
    healthFacility?: Array<CompetitionEntityScore>;
}

/**
 * 
 * @export
 * @interface CompetitionJoinParams
 */
export interface CompetitionJoinParams {
    /**
     * 
     * @type {string}
     * @memberof CompetitionJoinParams
     */
    competitionName: string;
}

/**
 * 
 * @export
 * @interface CompetitionName
 */
export interface CompetitionName {
}

/**
 * 
 * @export
 * @interface CompetitionPledge
 */
export interface CompetitionPledge {
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    uuid: string;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    competitionID: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    competitionPledgeCategoryID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    shortTitle: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    description: string;
    /**
     * 
     * @type {LearnMoreLinks}
     * @memberof CompetitionPledge
     */
    learnMoreLinks: LearnMoreLinks;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    assumptions: string;
    /**
     * 
     * @type {Array<CompetitionPledgeUserType>}
     * @memberof CompetitionPledge
     */
    userTypes: Array<CompetitionPledgeUserType>;
    /**
     * References CompetitionScoreLevel#uuid.
     * @type {string}
     * @memberof CompetitionPledge
     */
    scoreLevelUUID: string;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    savingsCO2Lbs: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    savingsWaterOz?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    savingsGasGallons?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    savingsElectricityKwh?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    storyPrompt: string;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    repeats: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    calculatorAPIKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitionPledge
     */
    qualifyLocation: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    photo: string;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledge
     */
    releaseWeek: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitionPledge
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitionPledge
     */
    canTagUsers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitionPledge
     */
    canUseMarkdown: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledge
     */
    uiLocation: CompetitionPledge.UiLocationEnum;
}

/**
 * @export
 * @namespace CompetitionPledge
 */
export namespace CompetitionPledge {
    /**
     * @export
     * @enum {string}
     */
    export enum UiLocationEnum {
        Primary = <any> 'primary',
        Secondary = <any> 'secondary',
        Empty = <any> ''
    }
}

/**
 * Keys are CompetitionPledgeCategoryIDs.
 * @export
 * @interface CompetitionPledgeCategories
 */
export interface CompetitionPledgeCategories {
    [key: string]: CompetitionPledgeCategory;

}

/**
 * 
 * @export
 * @interface CompetitionPledgeCategory
 */
export interface CompetitionPledgeCategory {
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledgeCategory
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionPledgeCategory
     */
    competitionID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionPledgeCategory
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface CompetitionPledgePage
 */
export interface CompetitionPledgePage {
    /**
     * 
     * @type {LatestToPledge}
     * @memberof CompetitionPledgePage
     */
    latestToPledge: LatestToPledge;
    /**
     * 
     * @type {CompetitionUserScores}
     * @memberof CompetitionPledgePage
     */
    competitionUserScores: CompetitionUserScores;
    /**
     * 
     * @type {CompetitionEntityPledges}
     * @memberof CompetitionPledgePage
     */
    competitionEntityPledges: CompetitionEntityPledges;
    /**
     * 
     * @type {UsersByID}
     * @memberof CompetitionPledgePage
     */
    users: UsersByID;
    /**
     * 
     * @type {Stories}
     * @memberof CompetitionPledgePage
     */
    stories: Stories;
    /**
     * 
     * @type {Comments}
     * @memberof CompetitionPledgePage
     */
    storyComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionPledgePage
     */
    storyLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionPledgePage
     */
    storyFlags: Flags;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionPledgePage
     */
    storyCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionPledgePage
     */
    storyCommentFlags: Flags;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof CompetitionPledgePage
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {Pagination}
     * @memberof CompetitionPledgePage
     */
    storyPagination: Pagination;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CompetitionPledgeUserType {
    Labs = <any> 'labs',
    Staff = <any> 'staff'
}

/**
 * Keys are CompetitionPledgeIDs.
 * @export
 * @interface CompetitionPledges
 */
export interface CompetitionPledges {
    [key: string]: CompetitionPledge;

}

/**
 * 
 * @export
 * @interface CompetitionScore
 */
export interface CompetitionScore {
    /**
     * 
     * @type {number}
     * @memberof CompetitionScore
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScore
     */
    competitionID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScore
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScore
     */
    savingsCO2Lbs?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScore
     */
    participants?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScore
     */
    pledgesCompleted?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionScore
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionScore
     */
    updatedAt?: string;
}

/**
 * 
 * @export
 * @interface CompetitionScoreLevel
 */
export interface CompetitionScoreLevel {
    /**
     * 
     * @type {number}
     * @memberof CompetitionScoreLevel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScoreLevel
     */
    competitionID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionScoreLevel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionScoreLevel
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionScoreLevel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CompetitionScoreLevel
     */
    threshold?: number;
}

/**
 * 
 * @export
 * @interface CompetitionScoreLevels
 */
export interface CompetitionScoreLevels extends Array<CompetitionScoreLevel> {
}

/**
 * 
 * @export
 * @interface CompetitionUser
 */
export interface CompetitionUser {
    /**
     * 
     * @type {number}
     * @memberof CompetitionUser
     */
    competitionID: number;
    /**
     * 
     * @type {MembershipLevel}
     * @memberof CompetitionUser
     */
    level: MembershipLevel;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitionUser
     */
    verified: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUser
     */
    footprint?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitionUser
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUser
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUser
     */
    qualtricsID?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetitionUser
     */
    permittedDomains: Array<string>;
}

/**
 * 
 * @export
 * @interface CompetitionUserDashboard
 */
export interface CompetitionUserDashboard {
    /**
     * 
     * @type {Competition}
     * @memberof CompetitionUserDashboard
     */
    competition: Competition;
    /**
     * 
     * @type {Entities}
     * @memberof CompetitionUserDashboard
     */
    entities: Entities;
    /**
     * 
     * @type {CompetitionEntities}
     * @memberof CompetitionUserDashboard
     */
    competitionEntities: CompetitionEntities;
    /**
     * 
     * @type {CompetitionPledges}
     * @memberof CompetitionUserDashboard
     */
    competitionPledges: CompetitionPledges;
    /**
     * 
     * @type {CompetitionScoreLevels}
     * @memberof CompetitionUserDashboard
     */
    competitionScoreLevels: CompetitionScoreLevels;
    /**
     * 
     * @type {CompetitionPledgeCategories}
     * @memberof CompetitionUserDashboard
     */
    competitionPledgeCategories: CompetitionPledgeCategories;
    /**
     * 
     * @type {TopUserScores}
     * @memberof CompetitionUserDashboard
     */
    topUserScores: TopUserScores;
    /**
     * 
     * @type {CompetitionEntityScores}
     * @memberof CompetitionUserDashboard
     */
    competitionEntityScores: CompetitionEntityScores;
    /**
     * 
     * @type {CompetitionScore}
     * @memberof CompetitionUserDashboard
     */
    competitionScore: CompetitionScore;
    /**
     * 
     * @type {Stories}
     * @memberof CompetitionUserDashboard
     */
    stories: Stories;
    /**
     * 
     * @type {Comments}
     * @memberof CompetitionUserDashboard
     */
    storyComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionUserDashboard
     */
    storyLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionUserDashboard
     */
    storyFlags: Flags;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionUserDashboard
     */
    storyCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionUserDashboard
     */
    storyCommentFlags: Flags;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof CompetitionUserDashboard
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {Pagination}
     * @memberof CompetitionUserDashboard
     */
    storyPagination: Pagination;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUserDashboard
     */
    token?: string;
    /**
     * 
     * @type {UserRead}
     * @memberof CompetitionUserDashboard
     */
    user: UserRead;
    /**
     * 
     * @type {EntityMemberships}
     * @memberof CompetitionUserDashboard
     */
    userMemberships: EntityMemberships;
    /**
     * 
     * @type {UserEmails}
     * @memberof CompetitionUserDashboard
     */
    userEmails: UserEmails;
    /**
     * 
     * @type {CompetitionUser}
     * @memberof CompetitionUserDashboard
     */
    competitionUser: CompetitionUser;
    /**
     * 
     * @type {PledgeCompletions}
     * @memberof CompetitionUserDashboard
     */
    userPledgeCompletions: PledgeCompletions;
    /**
     * 
     * @type {PledgePoints}
     * @memberof CompetitionUserDashboard
     */
    userPledgePoints?: PledgePoints;
    /**
     * 
     * @type {CompetitionUserScores}
     * @memberof CompetitionUserDashboard
     */
    competitionUserScores: CompetitionUserScores;
    /**
     * 
     * @type {EntityRanks}
     * @memberof CompetitionUserDashboard
     */
    userEntityRanks: EntityRanks;
}

/**
 * 
 * @export
 * @interface CompetitionUserPoint
 */
export interface CompetitionUserPoint {
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    userID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    competitionPledgeID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    pledgePoints?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    repeatPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    photoPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    savingsCO2Lbs?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    savingsWaterOz?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    savingsGasGallons?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    savingsElectricityKwh?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUserPoint
     */
    type?: CompetitionUserPoint.TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserPoint
     */
    originalID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUserPoint
     */
    createdAt?: string;
}

/**
 * @export
 * @namespace CompetitionUserPoint
 */
export namespace CompetitionUserPoint {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Pledge = <any> 'pledge',
        Negation = <any> 'negation',
        Empty = <any> ''
    }
}

/**
 * 
 * @export
 * @interface CompetitionUserProfile
 */
export interface CompetitionUserProfile {
    /**
     * 
     * @type {EntityMemberships}
     * @memberof CompetitionUserProfile
     */
    profileMemberships: EntityMemberships;
    /**
     * 
     * @type {PledgeCompletions}
     * @memberof CompetitionUserProfile
     */
    profilePledgeCompletions: PledgeCompletions;
    /**
     * 
     * @type {CompetitionUserScores}
     * @memberof CompetitionUserProfile
     */
    competitionUserScores: CompetitionUserScores;
    /**
     * 
     * @type {EntityRanks}
     * @memberof CompetitionUserProfile
     */
    profileEntityRanks: EntityRanks;
    /**
     * 
     * @type {UsersByID}
     * @memberof CompetitionUserProfile
     */
    users?: UsersByID;
    /**
     * 
     * @type {Stories}
     * @memberof CompetitionUserProfile
     */
    stories: Stories;
    /**
     * 
     * @type {Comments}
     * @memberof CompetitionUserProfile
     */
    storyComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionUserProfile
     */
    storyLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionUserProfile
     */
    storyFlags: Flags;
    /**
     * 
     * @type {Likes}
     * @memberof CompetitionUserProfile
     */
    storyCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof CompetitionUserProfile
     */
    storyCommentFlags: Flags;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof CompetitionUserProfile
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {Pagination}
     * @memberof CompetitionUserProfile
     */
    storyPagination: Pagination;
}

/**
 * 
 * @export
 * @interface CompetitionUserScore
 */
export interface CompetitionUserScore {
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    competitionID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    userID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    savingsCO2Lbs?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    takenCO2Lbs?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    savingsWaterOz?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    savingsGasGallons?: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScore
     */
    savingsElectricityKwh?: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUserScore
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitionUserScore
     */
    updatedAt?: string;
}

/**
 * 
 * @export
 * @interface CompetitionUserScoreQuery
 */
export interface CompetitionUserScoreQuery {
    /**
     * 
     * @type {Pagination}
     * @memberof CompetitionUserScoreQuery
     */
    pagination: Pagination;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScoreQuery
     */
    entityID: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitionUserScoreQuery
     */
    competitionID: number;
}

/**
 * 
 * @export
 * @interface CompetitionUserScoreResults
 */
export interface CompetitionUserScoreResults {
    /**
     * 
     * @type {UsersByID}
     * @memberof CompetitionUserScoreResults
     */
    users: UsersByID;
    /**
     * 
     * @type {CompetitionUserScoreQuery}
     * @memberof CompetitionUserScoreResults
     */
    topUserScoresQuery: CompetitionUserScoreQuery;
    /**
     * 
     * @type {Array<CompetitionUserScore>}
     * @memberof CompetitionUserScoreResults
     */
    topUserScores: Array<CompetitionUserScore>;
}

/**
 * Keys are userIDs.
 * @export
 * @interface CompetitionUserScores
 */
export interface CompetitionUserScores {
    [key: string]: CompetitionUserScore;

}

/**
 * 
 * @export
 * @interface CompetitionUsers
 */
export interface CompetitionUsers {
    /**
     * 
     * @type {UsersByID}
     * @memberof CompetitionUsers
     */
    users: UsersByID;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompetitionUsers
     */
    searchResults: Array<number>;
}

/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
}

/**
 * 
 * @export
 * @interface CreateSessionParams
 */
export interface CreateSessionParams {
    /**
     * 
     * @type {string}
     * @memberof CreateSessionParams
     */
    email: string;
    /**
     * 
     * @type {Password}
     * @memberof CreateSessionParams
     */
    password: Password;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionParams
     */
    competitionName?: string;
    /**
     * Token provided in signup UI URL of invitation emails.
     * @type {string}
     * @memberof CreateSessionParams
     */
    invitationToken?: string;
}

/**
 * 
 * @export
 * @interface CreateUserOrSessionResponse
 */
export interface CreateUserOrSessionResponse {
    /**
     * 
     * @type {UserRead}
     * @memberof CreateUserOrSessionResponse
     */
    user: UserRead;
    /**
     * Signed JSON web token.
     * @type {string}
     * @memberof CreateUserOrSessionResponse
     */
    token?: string;
}

/**
 * 
 * @export
 * @interface CreateUserParams
 */
export interface CreateUserParams {
    /**
     * 
     * @type {UserWrite}
     * @memberof CreateUserParams
     */
    user: UserWrite;
    /**
     * 
     * @type {Password}
     * @memberof CreateUserParams
     */
    password: Password;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParams
     */
    agreedToTermsAndConditions: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParams
     */
    competitionName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParams
     */
    invitationToken?: string;
}

/**
 * 
 * @export
 * @interface Entities
 */
export interface Entities {
    [key: string]: Entity;

}

/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {number}
     * @memberof Entity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Entity
     */
    parentID?: number;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    abbreviation?: string;
    /**
     * 
     * @type {number}
     * @memberof Entity
     */
    population?: number;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    type?: Entity.TypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    publicJoin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    joinByRequest?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    joinByInvite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    acceptByEmailDomain?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Entity
     */
    permittedDomains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    bannerPhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    descriptionMD?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    descriptionHTML?: string;
    /**
     * 
     * @type {SocialLinks}
     * @memberof Entity
     */
    socialLinks?: SocialLinks;
}

/**
 * @export
 * @namespace Entity
 */
export namespace Entity {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Team = <any> 'team',
        University = <any> 'university',
        HealthFacility = <any> 'healthFacility'
    }
}

/**
 * 
 * @export
 * @interface EntityMembership
 */
export interface EntityMembership {
    /**
     * 
     * @type {number}
     * @memberof EntityMembership
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityMembership
     */
    userID: number;
    /**
     * 
     * @type {number}
     * @memberof EntityMembership
     */
    entityID: number;
    /**
     * 
     * @type {MembershipLevel}
     * @memberof EntityMembership
     */
    level: MembershipLevel;
    /**
     * 
     * @type {string}
     * @memberof EntityMembership
     */
    status: EntityMembership.StatusEnum;
    /**
     * 
     * @type {NullInt}
     * @memberof EntityMembership
     */
    approvedBy?: NullInt;
    /**
     * 
     * @type {NullInt}
     * @memberof EntityMembership
     */
    invitedBy?: NullInt;
    /**
     * 
     * @type {string}
     * @memberof EntityMembership
     */
    acceptedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityMembership
     */
    createdAt?: string;
}

/**
 * @export
 * @namespace EntityMembership
 */
export namespace EntityMembership {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Invited = <any> 'invited',
        Requested = <any> 'requested',
        Accepted = <any> 'accepted'
    }
}

/**
 * Keys are entityIDs.
 * @export
 * @interface EntityMemberships
 */
export interface EntityMemberships {
    [key: string]: EntityMembership;

}

/**
 * Keys are EntityIDs. Values are the number of times the user has completed the pledge.
 * @export
 * @interface EntityRanks
 */
export interface EntityRanks {
    [key: string]: number;

}

/**
 * 
 * @export
 * @interface EntityTopSubentityScores
 */
export interface EntityTopSubentityScores {
    [key: string]: Array<CompetitionEntityScore>;

}

/**
 * Keys are EntityIDs.
 * @export
 * @interface EntityTopUserScoresPagination
 */
export interface EntityTopUserScoresPagination {
    [key: string]: Pagination;

}

/**
 * 
 * @export
 * @interface FlaggableTypeflaggableIDflagsFlag
 */
export interface FlaggableTypeflaggableIDflagsFlag {
    /**
     * 
     * @type {string}
     * @memberof FlaggableTypeflaggableIDflagsFlag
     */
    contentMD?: string;
}

/**
 * Keys are flaggableIDs. Uniqueness is guaranteed by flaggableType (ie comment, story). Indicates whether current user has flagged the relevant comment or story.
 * @export
 * @interface Flags
 */
export interface Flags {
    [key: string]: boolean;

}

/**
 * 
 * @export
 * @interface GoogleMapsGeocode
 */
export interface GoogleMapsGeocode {
    /**
     * 
     * @type {Array<GoogleMapsGeocodeAddressComponents>}
     * @memberof GoogleMapsGeocode
     */
    addressComponents: Array<GoogleMapsGeocodeAddressComponents>;
}

/**
 * 
 * @export
 * @interface GoogleMapsGeocodeAddressComponents
 */
export interface GoogleMapsGeocodeAddressComponents {
    /**
     * 
     * @type {string}
     * @memberof GoogleMapsGeocodeAddressComponents
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMapsGeocodeAddressComponents
     */
    longName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleMapsGeocodeAddressComponents
     */
    types: Array<string>;
}

/**
 * 
 * @export
 * @interface ImageMutation
 */
export interface ImageMutation {
    /**
     * 
     * @type {string}
     * @memberof ImageMutation
     */
    contentType?: ImageMutation.ContentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ImageMutation
     */
    rotation?: number;
    /**
     * Will compress image below this size.
     * @type {number}
     * @memberof ImageMutation
     */
    maxMB?: number;
    /**
     * 
     * @type {RGBA}
     * @memberof ImageMutation
     */
    bgColor?: RGBA;
    /**
     * 
     * @type {Rect}
     * @memberof ImageMutation
     */
    crop?: Rect;
}

/**
 * @export
 * @namespace ImageMutation
 */
export namespace ImageMutation {
    /**
     * @export
     * @enum {string}
     */
    export enum ContentTypeEnum {
        Png = <any> 'image/png',
        Jpeg = <any> 'image/jpeg',
        Gif = <any> 'image/gif'
    }
}

/**
 * 
 * @export
 * @interface ImageResponse
 */
export interface ImageResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageResponse
     */
    url: string;
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {CalculatorAnswers}
     * @memberof InlineResponse200
     */
    calculatorAnswers: CalculatorAnswers;
}

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Comments}
     * @memberof InlineResponse2001
     */
    comments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof InlineResponse2001
     */
    commentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof InlineResponse2001
     */
    commentFlags: Flags;
    /**
     * 
     * @type {UsersByID}
     * @memberof InlineResponse2001
     */
    users: UsersByID;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2001
     */
    pagination: Pagination;
}

/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20010
     */
    db?: boolean;
    /**
     * True if server is under maintenance.
     * @type {boolean}
     * @memberof InlineResponse20010
     */
    maintenance?: boolean;
}

/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Entities}
     * @memberof InlineResponse2002
     */
    entities: Entities;
}

/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {Story}
     * @memberof InlineResponse2003
     */
    story: Story;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof InlineResponse2003
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {UsersByID}
     * @memberof InlineResponse2003
     */
    users?: UsersByID;
}

/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {CompetitionUserScores}
     * @memberof InlineResponse2004
     */
    competitionUserScores?: CompetitionUserScores;
}

/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {BlogEntries}
     * @memberof InlineResponse2005
     */
    blogEntries: BlogEntries;
    /**
     * 
     * @type {Likes}
     * @memberof InlineResponse2005
     */
    blogEntryLikes?: Likes;
    /**
     * 
     * @type {Comments}
     * @memberof InlineResponse2005
     */
    comments?: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof InlineResponse2005
     */
    commentLikes?: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof InlineResponse2005
     */
    commentFlags?: Flags;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2005
     */
    pagination?: Pagination;
    /**
     * 
     * @type {UsersByID}
     * @memberof InlineResponse2005
     */
    users?: UsersByID;
}

/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {Array<CalculatorResultLeader>}
     * @memberof InlineResponse2006
     */
    data?: Array<CalculatorResultLeader>;
    /**
     * Total number of pages the query can return.
     * @type {number}
     * @memberof InlineResponse2006
     */
    totalPages: number;
    /**
     * 1-based index of next page to request. If no pages are left, will return -1.
     * @type {number}
     * @memberof InlineResponse2006
     */
    nextPage: number;
}

/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    verificationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    invitationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    passwordResetToken?: string;
}

/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {UserRead}
     * @memberof InlineResponse2008
     */
    user: UserRead;
}

/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {UserRead}
     * @memberof InlineResponse2009
     */
    user: UserRead;
    /**
     * 
     * @type {UserEmail}
     * @memberof InlineResponse2009
     */
    userEmail?: UserEmail;
}

/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {EntityMembership}
     * @memberof InlineResponse201
     */
    entityMembership?: EntityMembership;
}

/**
 * 
 * @export
 * @interface InlineResponse2011
 */
export interface InlineResponse2011 {
    /**
     * 
     * @type {Story}
     * @memberof InlineResponse2011
     */
    story: Story;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof InlineResponse2011
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {UsersByID}
     * @memberof InlineResponse2011
     */
    users?: UsersByID;
    /**
     * 
     * @type {CompetitionUserPoint}
     * @memberof InlineResponse2011
     */
    points?: CompetitionUserPoint;
}

/**
 * Will return points only if they were created (ie user has not used all pledge repeats).
 * @export
 * @interface InlineResponse2012
 */
export interface InlineResponse2012 {
    /**
     * 
     * @type {CompetitionUserPoint}
     * @memberof InlineResponse2012
     */
    points?: CompetitionUserPoint;
}

/**
 * 
 * @export
 * @interface InlineResponse204
 */
export interface InlineResponse204 {
    /**
     * 
     * @type {EntityMemberships}
     * @memberof InlineResponse204
     */
    entityMemberships?: EntityMemberships;
}

/**
 * Keys are CompetitionPledgeIDs. Array values are UserIDs.
 * @export
 * @interface LatestToPledge
 */
export interface LatestToPledge {
    [key: string]: Array<number>;

}

/**
 * Array of tuples. First item in tuples is URL text; second item is URL.
 * @export
 * @interface LearnMoreLinks
 */
export interface LearnMoreLinks extends Array<string> {
}

/**
 * Keys are likableIDs. Uniqueness is guaranteed by likableType (ie blogEntry, comment, story). Indicates whether current user has flagged the relevant blogEntry, comment or story.
 * @export
 * @interface Likes
 */
export interface Likes {
    [key: string]: boolean;

}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MembershipLevel {
    Member = <any> 'member',
    Admin = <any> 'admin',
    Superadmin = <any> 'superadmin'
}

/**
 * 
 * @export
 * @interface NullInt
 */
export interface NullInt {
    /**
     * 
     * @type {boolean}
     * @memberof NullInt
     */
    valid: boolean;
    /**
     * 
     * @type {number}
     * @memberof NullInt
     */
    int64: number;
}

/**
 * 
 * @export
 * @interface OpenGraphLink
 */
export interface OpenGraphLink {
    /**
     * 
     * @type {string}
     * @memberof OpenGraphLink
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenGraphLink
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof OpenGraphLink
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenGraphLink
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenGraphLink
     */
    image?: string;
}

/**
 * 
 * @export
 * @interface OpenGraphResponse
 */
export interface OpenGraphResponse {
    /**
     * Keys are URLs submitted in body.
     * @type {{ [key: string]: string; }}
     * @memberof OpenGraphResponse
     */
    errors: { [key: string]: string; };
    /**
     * Keys are URLs submitted in body.
     * @type {{ [key: string]: OpenGraphLink; }}
     * @memberof OpenGraphResponse
     */
    openGraphLinks: { [key: string]: OpenGraphLink; };
}

/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * SQL order string
     * @type {string}
     * @memberof Pagination
     */
    order?: string;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    page?: number;
    /**
     * default value depends on route.
     * @type {number}
     * @memberof Pagination
     */
    pageSize?: number;
    /**
     * -1 indicates no more pages left.
     * @type {number}
     * @memberof Pagination
     */
    nextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface Password
 */
export interface Password {
}

/**
 * Key are CompetitionPledgeIDs. Values the number of times the user has verified the pledge.
 * @export
 * @interface PledgeCompletions
 */
export interface PledgeCompletions {
    [key: string]: number;

}

/**
 * Key are CompetitionPledgeIDs. Values the total number of points earned on each pledge.
 * @export
 * @interface PledgePoints
 */
export interface PledgePoints {
    [key: string]: number;

}

/**
 * 
 * @export
 * @interface PostTokensBody
 */
export interface PostTokensBody {
    /**
     * 
     * @type {string}
     * @memberof PostTokensBody
     */
    competitionName: string;
    /**
     * 
     * @type {string}
     * @memberof PostTokensBody
     */
    adminSecret: string;
    /**
     * 
     * @type {string}
     * @memberof PostTokensBody
     */
    emailToVerify?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTokensBody
     */
    emailToInvite?: string;
    /**
     * 
     * @type {number}
     * @memberof PostTokensBody
     */
    userIDForPasswordReset?: number;
}

/**
 * 
 * @export
 * @interface PostUserEmailParams
 */
export interface PostUserEmailParams {
    /**
     * 
     * @type {string}
     * @memberof PostUserEmailParams
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostUserEmailParams
     */
    primary?: boolean;
}

/**
 * 
 * @export
 * @interface RGBA
 */
export interface RGBA {
    /**
     * 
     * @type {number}
     * @memberof RGBA
     */
    r: number;
    /**
     * 
     * @type {number}
     * @memberof RGBA
     */
    g: number;
    /**
     * 
     * @type {number}
     * @memberof RGBA
     */
    b: number;
    /**
     * 
     * @type {number}
     * @memberof RGBA
     */
    a?: number;
}

/**
 * 
 * @export
 * @interface Rect
 */
export interface Rect {
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof Rect
     */
    height: number;
}

/**
 * 
 * @export
 * @interface SocialLinks
 */
export interface SocialLinks {
    /**
     * Should be URL, but this is not validated at server level.
     * @type {string}
     * @memberof SocialLinks
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialLinks
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialLinks
     */
    twitter?: string;
    /**
     * Should be URL, but this is not validated at server level.
     * @type {string}
     * @memberof SocialLinks
     */
    facebook?: string;
    /**
     * Should be URL, but this is not validated at server level.
     * @type {string}
     * @memberof SocialLinks
     */
    linkedin?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialLinks
     */
    instagram?: string;
}

/**
 * 
 * @export
 * @interface Stories
 */
export interface Stories extends Array<Story> {
}

/**
 * 
 * @export
 * @interface Story
 */
export interface Story {
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    competitionPledgeID?: number;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    competitionUserPointID?: number;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    competitionID?: number;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    contentMD: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    contentHTML?: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    locationQualifier?: Story.LocationQualifierEnum;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    status?: Story.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    flags?: number;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    likes?: number;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    pointsEarned?: number;
    /**
     * 
     * @type {Array<OpenGraphLink>}
     * @memberof Story
     */
    links?: Array<OpenGraphLink>;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    updatedAt?: string;
}

/**
 * @export
 * @namespace Story
 */
export namespace Story {
    /**
     * @export
     * @enum {string}
     */
    export enum LocationQualifierEnum {
        Home = <any> 'home',
        Work = <any> 'work',
        Both = <any> 'both',
        Empty = <any> ''
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        AlreadyDo = <any> 'already_do',
        New = <any> 'new',
        Empty = <any> ''
    }
}

/**
 * 
 * @export
 * @interface StoryCommentResults
 */
export interface StoryCommentResults {
    /**
     * 
     * @type {Comments}
     * @memberof StoryCommentResults
     */
    storyComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof StoryCommentResults
     */
    storyCommentLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof StoryCommentResults
     */
    storyCommentFlags: Flags;
    /**
     * 
     * @type {UsersByID}
     * @memberof StoryCommentResults
     */
    users: UsersByID;
    /**
     * 
     * @type {CommentPagination}
     * @memberof StoryCommentResults
     */
    storyCommentPagination: CommentPagination;
}

/**
 * 
 * @export
 * @interface StoryResults
 */
export interface StoryResults {
    /**
     * 
     * @type {Array<Story>}
     * @memberof StoryResults
     */
    stories: Array<Story>;
    /**
     * 
     * @type {Likes}
     * @memberof StoryResults
     */
    storyLikes: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof StoryResults
     */
    storyFlags: Flags;
    /**
     * 
     * @type {Comments}
     * @memberof StoryResults
     */
    storyComments: Comments;
    /**
     * 
     * @type {Likes}
     * @memberof StoryResults
     */
    storyCommentLikes?: Likes;
    /**
     * 
     * @type {Flags}
     * @memberof StoryResults
     */
    storyCommentFlags?: Flags;
    /**
     * 
     * @type {StoryTaggedUserIDs}
     * @memberof StoryResults
     */
    storyTaggedUserIDs?: StoryTaggedUserIDs;
    /**
     * 
     * @type {Pagination}
     * @memberof StoryResults
     */
    storyPagination?: Pagination;
    /**
     * 
     * @type {UsersByID}
     * @memberof StoryResults
     */
    users?: UsersByID;
}

/**
 * Keys are storyIDs.
 * @export
 * @interface StoryTaggedUserIDs
 */
export interface StoryTaggedUserIDs {
    [key: string]: TaggedUserIDs;

}

/**
 * A simple array of user IDs.
 * @export
 * @interface TaggedUserIDs
 */
export interface TaggedUserIDs extends Array<number> {
}

/**
 * 
 * @export
 * @interface TopSubentityScores
 */
export interface TopSubentityScores extends Array<CompetitionEntityScore> {
}

/**
 * 
 * @export
 * @interface TopUserScores
 */
export interface TopUserScores extends Array<CompetitionUserScore> {
}

/**
 * 
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParams
     */
    password: string;
    /**
     * 
     * @type {Password}
     * @memberof UpdateUserParams
     */
    newPassword?: Password;
    /**
     * 
     * @type {UserWrite}
     * @memberof UpdateUserParams
     */
    user?: UserWrite;
}

/**
 * 
 * @export
 * @interface UserEmail
 */
export interface UserEmail {
    /**
     * 
     * @type {string}
     * @memberof UserEmail
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof UserEmail
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserEmail
     */
    userID: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserEmail
     */
    verified: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserEmail
     */
    verificationSentAt?: string;
}

/**
 * 
 * @export
 * @interface UserEmails
 */
export interface UserEmails extends Array<UserEmail> {
}

/**
 * Keys are EntityIDs.
 * @export
 * @interface UserMemberships
 */
export interface UserMemberships {
    [key: string]: EntityMembership;

}

/**
 * 
 * @export
 * @interface UserOptions
 */
export interface UserOptions {
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    isHealthFacilityEmployee?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    isLabUser?: boolean;
}

/**
 * 
 * @export
 * @interface UserPledges
 */
export interface UserPledges {
    [key: string]: any;

}

/**
 * 
 * @export
 * @interface UserRead
 */
export interface UserRead {
    /**
     * 
     * @type {number}
     * @memberof UserRead
     */
    id: number;
    /**
     * 
     * @type {UserType}
     * @memberof UserRead
     */
    type?: UserType;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    zipcode?: string;
    /**
     * 
     * @type {Country}
     * @memberof UserRead
     */
    country?: Country;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    descriptionMD?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    descriptionHTML?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRead
     */
    _public?: boolean;
    /**
     * 
     * @type {UserOptions}
     * @memberof UserRead
     */
    options?: UserOptions;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    profilePhoto?: string;
    /**
     * 
     * @type {SocialLinks}
     * @memberof UserRead
     */
    socialLinks?: SocialLinks;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    emailVerified?: string;
    /**
     * Keys are competitionID => entityID.
     * @type {{ [key: string]: { [key: string]: boolean; }; }}
     * @memberof UserRead
     */
    acceptedCompetitionEntityMemberships?: { [key: string]: { [key: string]: boolean; }; };
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    createdAt?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserType {
    Student = <any> 'student',
    Faculty = <any> 'faculty',
    Staff = <any> 'staff',
    Empty = <any> ''
}

/**
 * 
 * @export
 * @interface UserWrite
 */
export interface UserWrite {
    /**
     * 
     * @type {UserType}
     * @memberof UserWrite
     */
    type?: UserType;
    /**
     * 
     * @type {string}
     * @memberof UserWrite
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserWrite
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWrite
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWrite
     */
    profilePhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWrite
     */
    descriptionMD?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWrite
     */
    descriptionHTML?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserWrite
     */
    _public?: boolean;
    /**
     * 
     * @type {UserOptions}
     * @memberof UserWrite
     */
    options?: UserOptions;
    /**
     * 
     * @type {SocialLinks}
     * @memberof UserWrite
     */
    socialLinks?: SocialLinks;
}

/**
 * Keys are userIDs - they are guaranteed unique across the API.
 * @export
 * @interface UsersByID
 */
export interface UsersByID {
    [key: string]: UserRead;

}

/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    code: ValidationError.CodeEnum;
}

/**
 * @export
 * @namespace ValidationError
 */
export namespace ValidationError {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        ErrSwaggerValidationCode = <any> 'errSwaggerValidationCode',
        ErrEmailNonUnique = <any> 'errEmailNonUnique',
        ErrPasswordIncorrect = <any> 'errPasswordIncorrect',
        ErrEmailNotFound = <any> 'errEmailNotFound',
        ErrUserEmailExists = <any> 'errUserEmailExists',
        ErrCannotDeletePrimaryEmail = <any> 'errCannotDeletePrimaryEmail',
        ErrLikeAlreadyExists = <any> 'errLikeAlreadyExists',
        ErrFlagAlreadyExists = <any> 'errFlagAlreadyExists',
        ErrCompetitionPledgeNotReleased = <any> 'errCompetitionPledgeNotReleased',
        ErrCompetitionEntityMembershipExists = <any> 'errCompetitionEntityMembershipExists',
        ErrInvalidImageType = <any> 'errInvalidImageType',
        ErrVerifiedCompetitionUserNotFound = <any> 'errVerifiedCompetitionUserNotFound',
        ErrEntityDoesNotExist = <any> 'errEntityDoesNotExist',
        ErrEntityMembershipExists = <any> 'errEntityMembershipExists',
        ErrIllegalMembershipJoin = <any> 'errIllegalMembershipJoin',
        ErrCompetitionMembershipTypeExists = <any> 'errCompetitionMembershipTypeExists',
        ErrTokenExpired = <any> 'errTokenExpired',
        ErrTokenInvalidJTI = <any> 'errTokenInvalidJTI'
    }
}

/**
 * 
 * @export
 * @interface ValidationErrors
 */
export interface ValidationErrors extends Array<ValidationError> {
}


/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete comment.
         * @param {number} commentID 
         * @param {'blog_entry' | 'story'} commentableType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentID: number, commentableType: 'blog_entry' | 'story', options: any = {}): FetchArgs {
            // verify required parameter 'commentID' is not null or undefined
            if (commentID === null || commentID === undefined) {
                throw new RequiredError('commentID','Required parameter commentID was null or undefined when calling deleteComment.');
            }
            // verify required parameter 'commentableType' is not null or undefined
            if (commentableType === null || commentableType === undefined) {
                throw new RequiredError('commentableType','Required parameter commentableType was null or undefined when calling deleteComment.');
            }
            const localVarPath = `/{commentableType}/comments/{commentID}`
                .replace(`{${"commentID"}}`, encodeURIComponent(String(commentID)))
                .replace(`{${"commentableType"}}`, encodeURIComponent(String(commentableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This will delete the Entity as well as the CompetitionEntity.
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetitionEntity(competitionID: number, entityID: number, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling deleteCompetitionEntity.');
            }
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling deleteCompetitionEntity.');
            }
            const localVarPath = `/competitions/{competitionID}/entities/{entityID}`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)))
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete entity specific information for competition pledge.
         * @param {number} competitionEntityPledgeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetitionEntityPledge(competitionEntityPledgeID: number, options: any = {}): FetchArgs {
            // verify required parameter 'competitionEntityPledgeID' is not null or undefined
            if (competitionEntityPledgeID === null || competitionEntityPledgeID === undefined) {
                throw new RequiredError('competitionEntityPledgeID','Required parameter competitionEntityPledgeID was null or undefined when calling deleteCompetitionEntityPledge.');
            }
            const localVarPath = `/competition_entity_pledges/{competitionEntityPledgeID}`
                .replace(`{${"competitionEntityPledgeID"}}`, encodeURIComponent(String(competitionEntityPledgeID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete blog entry for entity.
         * @param {number} entityID 
         * @param {number} blogEntryID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityBlogEntry(entityID: number, blogEntryID: number, options: any = {}): FetchArgs {
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling deleteEntityBlogEntry.');
            }
            // verify required parameter 'blogEntryID' is not null or undefined
            if (blogEntryID === null || blogEntryID === undefined) {
                throw new RequiredError('blogEntryID','Required parameter blogEntryID was null or undefined when calling deleteEntityBlogEntry.');
            }
            const localVarPath = `/entities/{entityID}/blog_entries/{blogEntryID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"blogEntryID"}}`, encodeURIComponent(String(blogEntryID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityMembership(membershipID: number, options: any = {}): FetchArgs {
            // verify required parameter 'membershipID' is not null or undefined
            if (membershipID === null || membershipID === undefined) {
                throw new RequiredError('membershipID','Required parameter membershipID was null or undefined when calling deleteEntityMembership.');
            }
            const localVarPath = `/memberships/{membershipID}`
                .replace(`{${"membershipID"}}`, encodeURIComponent(String(membershipID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete flag.
         * @param {number} flaggableID 
         * @param {'story' | 'comment'} flaggableType 
         * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlag(flaggableID: number, flaggableType: 'story' | 'comment', commentableType?: 'story' | 'comment', options: any = {}): FetchArgs {
            // verify required parameter 'flaggableID' is not null or undefined
            if (flaggableID === null || flaggableID === undefined) {
                throw new RequiredError('flaggableID','Required parameter flaggableID was null or undefined when calling deleteFlag.');
            }
            // verify required parameter 'flaggableType' is not null or undefined
            if (flaggableType === null || flaggableType === undefined) {
                throw new RequiredError('flaggableType','Required parameter flaggableType was null or undefined when calling deleteFlag.');
            }
            const localVarPath = `/{flaggableType}/{flaggableID}/flags`
                .replace(`{${"flaggableID"}}`, encodeURIComponent(String(flaggableID)))
                .replace(`{${"flaggableType"}}`, encodeURIComponent(String(flaggableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (commentableType !== undefined) {
                localVarQueryParameter['commentableType'] = commentableType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete like.
         * @param {number} likableID 
         * @param {'story' | 'blog_entry' | 'comment'} likableType 
         * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options: any = {}): FetchArgs {
            // verify required parameter 'likableID' is not null or undefined
            if (likableID === null || likableID === undefined) {
                throw new RequiredError('likableID','Required parameter likableID was null or undefined when calling deleteLike.');
            }
            // verify required parameter 'likableType' is not null or undefined
            if (likableType === null || likableType === undefined) {
                throw new RequiredError('likableType','Required parameter likableType was null or undefined when calling deleteLike.');
            }
            const localVarPath = `/{likableType}/{likableID}/likes`
                .replace(`{${"likableID"}}`, encodeURIComponent(String(likableID)))
                .replace(`{${"likableType"}}`, encodeURIComponent(String(likableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (commentableType !== undefined) {
                localVarQueryParameter['commentableType'] = commentableType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign out.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(options: any = {}): FetchArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStory(storyID: number, options: any = {}): FetchArgs {
            // verify required parameter 'storyID' is not null or undefined
            if (storyID === null || storyID === undefined) {
                throw new RequiredError('storyID','Required parameter storyID was null or undefined when calling deleteStory.');
            }
            const localVarPath = `/stories/{storyID}`
                .replace(`{${"storyID"}}`, encodeURIComponent(String(storyID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete email associated with account.
         * @param {number} userID 
         * @param {number} userEmailID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmail(userID: number, userEmailID: number, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling deleteUserEmail.');
            }
            // verify required parameter 'userEmailID' is not null or undefined
            if (userEmailID === null || userEmailID === undefined) {
                throw new RequiredError('userEmailID','Required parameter userEmailID was null or undefined when calling deleteUserEmail.');
            }
            const localVarPath = `/users/{userID}/email/{userEmailID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"userEmailID"}}`, encodeURIComponent(String(userEmailID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request email verification link.
         * @param {number} userEmailID 
         * @param {string} competitionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountVerification(userEmailID: number, competitionName: string, options: any = {}): FetchArgs {
            // verify required parameter 'userEmailID' is not null or undefined
            if (userEmailID === null || userEmailID === undefined) {
                throw new RequiredError('userEmailID','Required parameter userEmailID was null or undefined when calling getAccountVerification.');
            }
            // verify required parameter 'competitionName' is not null or undefined
            if (competitionName === null || competitionName === undefined) {
                throw new RequiredError('competitionName','Required parameter competitionName was null or undefined when calling getAccountVerification.');
            }
            const localVarPath = `/email/{userEmailID}/verification/{competitionName}`
                .replace(`{${"userEmailID"}}`, encodeURIComponent(String(userEmailID)))
                .replace(`{${"competitionName"}}`, encodeURIComponent(String(competitionName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [entityID] 
         * @param {number} [competitionID] 
         * @param {number} [blogEntryID] 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogEntries(entityID?: number, competitionID?: number, blogEntryID?: number, page?: number, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/blog_entries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityID !== undefined) {
                localVarQueryParameter['entityID'] = entityID;
            }

            if (competitionID !== undefined) {
                localVarQueryParameter['competitionID'] = competitionID;
            }

            if (blogEntryID !== undefined) {
                localVarQueryParameter['blogEntryID'] = blogEntryID;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve user's calculator answers from server.
         * @param {number} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculatorAnswers(userID: number, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling getCalculatorAnswers.');
            }
            const localVarPath = `/calculator_answers/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List users in order of footprint results.
         * @param {'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count'} [order] 
         * @param {string} [page] 1-based index for paginated results.
         * @param {string} [pageSize] Range of 20-100 silently enforced.
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [zipcode] 
         * @param {string} [country] 
         * @param {string} [inputIncome] 
         * @param {string} [inputSize] 
         * @param {string} [includeSeedUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculatorResultLeaders(order?: 'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count', page?: string, pageSize?: string, city?: string, state?: string, zipcode?: string, country?: string, inputIncome?: string, inputSize?: string, includeSeedUsers?: string, options: any = {}): FetchArgs {
            const localVarPath = `/calculator_result_leaders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (zipcode !== undefined) {
                localVarQueryParameter['zipcode'] = zipcode;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (inputIncome !== undefined) {
                localVarQueryParameter['inputIncome'] = inputIncome;
            }

            if (inputSize !== undefined) {
                localVarQueryParameter['inputSize'] = inputSize;
            }

            if (includeSeedUsers !== undefined) {
                localVarQueryParameter['includeSeedUsers'] = includeSeedUsers;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve comments for a story or blog entry.
         * @param {number} commentableID 
         * @param {'story' | 'blog_entry'} commentableType 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(commentableID: number, commentableType: 'story' | 'blog_entry', page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'commentableID' is not null or undefined
            if (commentableID === null || commentableID === undefined) {
                throw new RequiredError('commentableID','Required parameter commentableID was null or undefined when calling getComments.');
            }
            // verify required parameter 'commentableType' is not null or undefined
            if (commentableType === null || commentableType === undefined) {
                throw new RequiredError('commentableType','Required parameter commentableType was null or undefined when calling getComments.');
            }
            const localVarPath = `/{commentableType}/{commentableID}/comments`
                .replace(`{${"commentableID"}}`, encodeURIComponent(String(commentableID)))
                .replace(`{${"commentableType"}}`, encodeURIComponent(String(commentableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve competition dashboard data.
         * @param {string} competitionName 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionDashboard(competitionName: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionName' is not null or undefined
            if (competitionName === null || competitionName === undefined) {
                throw new RequiredError('competitionName','Required parameter competitionName was null or undefined when calling getCompetitionDashboard.');
            }
            const localVarPath = `/competitions/{competitionName}/dashboard`
                .replace(`{${"competitionName"}}`, encodeURIComponent(String(competitionName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of available teams or organizations.
         * @param {number} competitionID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionEntities(competitionID: number, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling getCompetitionEntities.');
            }
            const localVarPath = `/competitions/{competitionID}/entities`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionEntity(competitionID: number, entityID: number, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling getCompetitionEntity.');
            }
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling getCompetitionEntity.');
            }
            const localVarPath = `/competitions/{competitionID}/entities/{entityID}`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)))
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve competition pledge..
         * @param {number} competitionPledgeID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionPledge(competitionPledgeID: number, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionPledgeID' is not null or undefined
            if (competitionPledgeID === null || competitionPledgeID === undefined) {
                throw new RequiredError('competitionPledgeID','Required parameter competitionPledgeID was null or undefined when calling getCompetitionPledge.');
            }
            const localVarPath = `/pledges/{competitionPledgeID}`
                .replace(`{${"competitionPledgeID"}}`, encodeURIComponent(String(competitionPledgeID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} [userID] 
         * @param {number} [entityID] 
         * @param {number} [competitionPledgeID] 
         * @param {number} [storyID] 
         * @param {number} [page] 
         * @param {string} [term] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionStories(competitionID: number, userID?: number, entityID?: number, competitionPledgeID?: number, storyID?: number, page?: number, term?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling getCompetitionStories.');
            }
            const localVarPath = `/competitions/{competitionID}/stories`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (entityID !== undefined) {
                localVarQueryParameter['entityID'] = entityID;
            }

            if (competitionPledgeID !== undefined) {
                localVarQueryParameter['competitionPledgeID'] = competitionPledgeID;
            }

            if (storyID !== undefined) {
                localVarQueryParameter['storyID'] = storyID;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve competition user profile.
         * @param {number} competitionID 
         * @param {number} userID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUser(competitionID: number, userID: number, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling getCompetitionUser.');
            }
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling getCompetitionUser.');
            }
            const localVarPath = `/competitions/{competitionID}/users/{userID}`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)))
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} [entityID] 
         * @param {number} [page] 
         * @param {string} [term] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUserScores(competitionID: number, entityID?: number, page?: number, term?: string, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling getCompetitionUserScores.');
            }
            const localVarPath = `/competitions/{competitionID}/user_scores`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (entityID !== undefined) {
                localVarQueryParameter['entityID'] = entityID;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve competition user profile.
         * @param {number} competitionID 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUsers(competitionID: number, email: string, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling getCompetitionUsers.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling getCompetitionUsers.');
            }
            const localVarPath = `/competitions/{competitionID}/users`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate development/QA data.
         * @param {string} [number] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerate(number?: string, options: any = {}): FetchArgs {
            const localVarPath = `/generate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary of API status
         * @param {string} [include] Comma separated string of response objects to include.
         * @param {string} [competitionName] Name of competition to return CompetitionUserDashboard for.
         * @param {string} [userPledgeDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(include?: string, competitionName?: string, userPledgeDomain?: string, options: any = {}): FetchArgs {
            const localVarPath = `/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (competitionName !== undefined) {
                localVarQueryParameter['competitionName'] = competitionName;
            }

            if (userPledgeDomain !== undefined) {
                localVarQueryParameter['userPledgeDomain'] = userPledgeDomain;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary of API status
         * @param {string} [checks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(checks?: string, options: any = {}): FetchArgs {
            const localVarPath = `/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (checks !== undefined) {
                localVarQueryParameter['checks'] = checks;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Swagger spec.
         * @param {string} [pat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagger(pat?: string, options: any = {}): FetchArgs {
            const localVarPath = `/swagger.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pat !== undefined) {
                localVarQueryParameter['pat'] = pat;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve user.
         * @param {number} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userID: number, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling getUser.');
            }
            const localVarPath = `/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve list of use pledges.
         * @param {number} userID 
         * @param {string} [userPledgeDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPledges(userID: number, userPledgeDomain?: string, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling getUserPledges.');
            }
            const localVarPath = `/users/{userID}/pledges`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userPledgeDomain !== undefined) {
                localVarQueryParameter['userPledgeDomain'] = userPledgeDomain;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify email address.
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountVerification(body: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postAccountVerification.');
            }
            const localVarPath = `/verification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve comments for a story or blog entry.
         * @param {number} commentableID 
         * @param {'story' | 'blog_entry'} commentableType 
         * @param {Body} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComments(commentableID: number, commentableType: 'story' | 'blog_entry', body: Body, options: any = {}): FetchArgs {
            // verify required parameter 'commentableID' is not null or undefined
            if (commentableID === null || commentableID === undefined) {
                throw new RequiredError('commentableID','Required parameter commentableID was null or undefined when calling postComments.');
            }
            // verify required parameter 'commentableType' is not null or undefined
            if (commentableType === null || commentableType === undefined) {
                throw new RequiredError('commentableType','Required parameter commentableType was null or undefined when calling postComments.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postComments.');
            }
            const localVarPath = `/{commentableType}/{commentableID}/comments`
                .replace(`{${"commentableID"}}`, encodeURIComponent(String(commentableID)))
                .replace(`{${"commentableType"}}`, encodeURIComponent(String(commentableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {Body2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntities(competitionID: number, body: Body2, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling postCompetitionEntities.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCompetitionEntities.');
            }
            const localVarPath = `/competitions/{competitionID}/entities`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} entityID 
         * @param {number} competitionID 
         * @param {Body4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntityMemberships(entityID: number, competitionID: number, body: Body4, options: any = {}): FetchArgs {
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling postCompetitionEntityMemberships.');
            }
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling postCompetitionEntityMemberships.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCompetitionEntityMemberships.');
            }
            const localVarPath = `/competitions/{competitionID}/entities/{entityID}/memberships`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body4" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create entity specific information for competition pledge.
         * @param {Body12} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntityPledges(body: Body12, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCompetitionEntityPledges.');
            }
            const localVarPath = `/competition_entity_pledges`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body12" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create points for a CompetitionPledge
         * @param {number} competitionPledgeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionPledgePoints(competitionPledgeID: number, options: any = {}): FetchArgs {
            // verify required parameter 'competitionPledgeID' is not null or undefined
            if (competitionPledgeID === null || competitionPledgeID === undefined) {
                throw new RequiredError('competitionPledgeID','Required parameter competitionPledgeID was null or undefined when calling postCompetitionPledgePoints.');
            }
            const localVarPath = `/pledges/{competitionPledgeID}/points`
                .replace(`{${"competitionPledgeID"}}`, encodeURIComponent(String(competitionPledgeID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {Body5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionStories(competitionID: number, body: Body5, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling postCompetitionStories.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCompetitionStories.');
            }
            const localVarPath = `/competitions/{competitionID}/stories`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body5" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} entityID 
         * @param {Body9} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityBlogEntries(entityID: number, body: Body9, options: any = {}): FetchArgs {
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling postEntityBlogEntries.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postEntityBlogEntries.');
            }
            const localVarPath = `/entities/{entityID}/blog_entries`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body9" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {Body14} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityMembershipLevel(membershipID: number, body: Body14, options: any = {}): FetchArgs {
            // verify required parameter 'membershipID' is not null or undefined
            if (membershipID === null || membershipID === undefined) {
                throw new RequiredError('membershipID','Required parameter membershipID was null or undefined when calling postEntityMembershipLevel.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postEntityMembershipLevel.');
            }
            const localVarPath = `/memberships/{membershipID}/level`
                .replace(`{${"membershipID"}}`, encodeURIComponent(String(membershipID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body14" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {Body15} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityMembershipStatus(membershipID: number, body: Body15, options: any = {}): FetchArgs {
            // verify required parameter 'membershipID' is not null or undefined
            if (membershipID === null || membershipID === undefined) {
                throw new RequiredError('membershipID','Required parameter membershipID was null or undefined when calling postEntityMembershipStatus.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postEntityMembershipStatus.');
            }
            const localVarPath = `/memberships/{membershipID}/status`
                .replace(`{${"membershipID"}}`, encodeURIComponent(String(membershipID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body15" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change owner of entity.
         * @param {number} entityID 
         * @param {Body11} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityOwner(entityID: number, body: Body11, options: any = {}): FetchArgs {
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling postEntityOwner.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postEntityOwner.');
            }
            const localVarPath = `/entities/{entityID}/owner`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body11" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create flag.
         * @param {number} flaggableID 
         * @param {'story' | 'comment'} flaggableType 
         * @param {Body6} body 
         * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFlag(flaggableID: number, flaggableType: 'story' | 'comment', body: Body6, commentableType?: 'story' | 'comment', options: any = {}): FetchArgs {
            // verify required parameter 'flaggableID' is not null or undefined
            if (flaggableID === null || flaggableID === undefined) {
                throw new RequiredError('flaggableID','Required parameter flaggableID was null or undefined when calling postFlag.');
            }
            // verify required parameter 'flaggableType' is not null or undefined
            if (flaggableType === null || flaggableType === undefined) {
                throw new RequiredError('flaggableType','Required parameter flaggableType was null or undefined when calling postFlag.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postFlag.');
            }
            const localVarPath = `/{flaggableType}/{flaggableID}/flags`
                .replace(`{${"flaggableID"}}`, encodeURIComponent(String(flaggableID)))
                .replace(`{${"flaggableType"}}`, encodeURIComponent(String(flaggableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (commentableType !== undefined) {
                localVarQueryParameter['commentableType'] = commentableType;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body6" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post image to Google Cloud Storage.
         * @param {any} image Limited to 5 mb. Must be jpg, gif, or png.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImage(image: any, options: any = {}): FetchArgs {
            // verify required parameter 'image' is not null or undefined
            if (image === null || image === undefined) {
                throw new RequiredError('image','Required parameter image was null or undefined when calling postImage.');
            }
            const localVarPath = `/image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (image !== undefined) {
                localVarFormParams.set('image', image as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mutate image server side.
         * @param {any} image Limited to 10 mb. Must be jpg, gif, or png.
         * @param {string} mutation See \&quot;#/definitions/ImageMutation\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageMutation(image: any, mutation: string, options: any = {}): FetchArgs {
            // verify required parameter 'image' is not null or undefined
            if (image === null || image === undefined) {
                throw new RequiredError('image','Required parameter image was null or undefined when calling postImageMutation.');
            }
            // verify required parameter 'mutation' is not null or undefined
            if (mutation === null || mutation === undefined) {
                throw new RequiredError('mutation','Required parameter mutation was null or undefined when calling postImageMutation.');
            }
            const localVarPath = `/image_mutation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (image !== undefined) {
                localVarFormParams.set('image', image as any);
            }

            if (mutation !== undefined) {
                localVarFormParams.set('mutation', mutation as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite user to join competition.
         * @param {string} competitionName 
         * @param {Body7} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvite(competitionName: string, body: Body7, options: any = {}): FetchArgs {
            // verify required parameter 'competitionName' is not null or undefined
            if (competitionName === null || competitionName === undefined) {
                throw new RequiredError('competitionName','Required parameter competitionName was null or undefined when calling postInvite.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postInvite.');
            }
            const localVarPath = `/invite/{competitionName}`
                .replace(`{${"competitionName"}}`, encodeURIComponent(String(competitionName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body7" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join
         * @param {CompetitionJoinParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJoin(body: CompetitionJoinParams, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postJoin.');
            }
            const localVarPath = `/join`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompetitionJoinParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create like
         * @param {number} likableID 
         * @param {'story' | 'blog_entry' | 'comment'} likableType 
         * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options: any = {}): FetchArgs {
            // verify required parameter 'likableID' is not null or undefined
            if (likableID === null || likableID === undefined) {
                throw new RequiredError('likableID','Required parameter likableID was null or undefined when calling postLike.');
            }
            // verify required parameter 'likableType' is not null or undefined
            if (likableType === null || likableType === undefined) {
                throw new RequiredError('likableType','Required parameter likableType was null or undefined when calling postLike.');
            }
            const localVarPath = `/{likableType}/{likableID}/likes`
                .replace(`{${"likableID"}}`, encodeURIComponent(String(likableID)))
                .replace(`{${"likableType"}}`, encodeURIComponent(String(likableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (commentableType !== undefined) {
                localVarQueryParameter['commentableType'] = commentableType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch open graph data for resource
         * @param {Array<string>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenGraph(body: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postOpenGraph.');
            }
            const localVarPath = `/open_graph`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a password reset email
         * @param {string} competitionName 
         * @param {Body16} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordResetRequest(competitionName: string, body: Body16, options: any = {}): FetchArgs {
            // verify required parameter 'competitionName' is not null or undefined
            if (competitionName === null || competitionName === undefined) {
                throw new RequiredError('competitionName','Required parameter competitionName was null or undefined when calling postPasswordResetRequest.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postPasswordResetRequest.');
            }
            const localVarPath = `/password_reset_request/{competitionName}`
                .replace(`{${"competitionName"}}`, encodeURIComponent(String(competitionName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body16" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save qualtrics ID to CompetitionUser.
         * @param {string} body Password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQualtricsID(body: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postQualtricsID.');
            }
            const localVarPath = `/qualtrics_id`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in.
         * @param {CreateSessionParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSession(body: CreateSessionParams, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postSession.');
            }
            const localVarPath = `/session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSessionParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate invite, verification, and password reset tokens for testing.
         * @param {PostTokensBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTokens(body: PostTokensBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postTokens.');
            }
            const localVarPath = `/tokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PostTokensBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post footprint
         * @param {number} competitionId 
         * @param {Body18} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCompetitionFootprint(competitionId: number, body: Body18, options: any = {}): FetchArgs {
            // verify required parameter 'competitionId' is not null or undefined
            if (competitionId === null || competitionId === undefined) {
                throw new RequiredError('competitionId','Required parameter competitionId was null or undefined when calling postUserCompetitionFootprint.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUserCompetitionFootprint.');
            }
            const localVarPath = `/user/{competitionId}/footprint`
                .replace(`{${"competitionId"}}`, encodeURIComponent(String(competitionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body18" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user.
         * @param {number} userID 
         * @param {string} body Password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserDelete(userID: number, body: string, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling postUserDelete.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUserDelete.');
            }
            const localVarPath = `/users/{userID}/delete`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add email to account.
         * @param {number} userID 
         * @param {PostUserEmailParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserEmail(userID: number, body: PostUserEmailParams, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling postUserEmail.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUserEmail.');
            }
            const localVarPath = `/users/{userID}/email`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PostUserEmailParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up.
         * @param {CreateUserParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers(body: CreateUserParams, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUsers.');
            }
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user's calculator answers.
         * @param {number} userID 
         * @param {CalculatorAnswers} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalculatorAnswers(userID: number, body: CalculatorAnswers, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling putCalculatorAnswers.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putCalculatorAnswers.');
            }
            const localVarPath = `/calculator_answers/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CalculatorAnswers" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update comment content.
         * @param {number} commentID 
         * @param {'blog_entry' | 'story'} commentableType 
         * @param {Body1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComment(commentID: number, commentableType: 'blog_entry' | 'story', body: Body1, options: any = {}): FetchArgs {
            // verify required parameter 'commentID' is not null or undefined
            if (commentID === null || commentID === undefined) {
                throw new RequiredError('commentID','Required parameter commentID was null or undefined when calling putComment.');
            }
            // verify required parameter 'commentableType' is not null or undefined
            if (commentableType === null || commentableType === undefined) {
                throw new RequiredError('commentableType','Required parameter commentableType was null or undefined when calling putComment.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putComment.');
            }
            const localVarPath = `/{commentableType}/comments/{commentID}`
                .replace(`{${"commentID"}}`, encodeURIComponent(String(commentID)))
                .replace(`{${"commentableType"}}`, encodeURIComponent(String(commentableType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {Body3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompetitionEntity(competitionID: number, entityID: number, body: Body3, options: any = {}): FetchArgs {
            // verify required parameter 'competitionID' is not null or undefined
            if (competitionID === null || competitionID === undefined) {
                throw new RequiredError('competitionID','Required parameter competitionID was null or undefined when calling putCompetitionEntity.');
            }
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling putCompetitionEntity.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putCompetitionEntity.');
            }
            const localVarPath = `/competitions/{competitionID}/entities/{entityID}`
                .replace(`{${"competitionID"}}`, encodeURIComponent(String(competitionID)))
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update entity specific information for competition pledge.
         * @param {number} competitionEntityPledgeID 
         * @param {Body13} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompetitionEntityPledge(competitionEntityPledgeID: number, body: Body13, options: any = {}): FetchArgs {
            // verify required parameter 'competitionEntityPledgeID' is not null or undefined
            if (competitionEntityPledgeID === null || competitionEntityPledgeID === undefined) {
                throw new RequiredError('competitionEntityPledgeID','Required parameter competitionEntityPledgeID was null or undefined when calling putCompetitionEntityPledge.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putCompetitionEntityPledge.');
            }
            const localVarPath = `/competition_entity_pledges/{competitionEntityPledgeID}`
                .replace(`{${"competitionEntityPledgeID"}}`, encodeURIComponent(String(competitionEntityPledgeID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body13" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update blog entry for entity.
         * @param {number} entityID 
         * @param {number} blogEntryID 
         * @param {Body10} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBlogEntry(entityID: number, blogEntryID: number, body: Body10, options: any = {}): FetchArgs {
            // verify required parameter 'entityID' is not null or undefined
            if (entityID === null || entityID === undefined) {
                throw new RequiredError('entityID','Required parameter entityID was null or undefined when calling putEntityBlogEntry.');
            }
            // verify required parameter 'blogEntryID' is not null or undefined
            if (blogEntryID === null || blogEntryID === undefined) {
                throw new RequiredError('blogEntryID','Required parameter blogEntryID was null or undefined when calling putEntityBlogEntry.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putEntityBlogEntry.');
            }
            const localVarPath = `/entities/{entityID}/blog_entries/{blogEntryID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"blogEntryID"}}`, encodeURIComponent(String(blogEntryID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body10" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update password.
         * @param {Body17} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPasswordResetRequest(body: Body17, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putPasswordResetRequest.');
            }
            const localVarPath = `/password_reset_request`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body17" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storyID 
         * @param {Body8} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStory(storyID: number, body: Body8, options: any = {}): FetchArgs {
            // verify required parameter 'storyID' is not null or undefined
            if (storyID === null || storyID === undefined) {
                throw new RequiredError('storyID','Required parameter storyID was null or undefined when calling putStory.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putStory.');
            }
            const localVarPath = `/stories/{storyID}`
                .replace(`{${"storyID"}}`, encodeURIComponent(String(storyID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body8" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user.
         * @param {number} userID 
         * @param {UpdateUserParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userID: number, body: UpdateUserParams, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling putUser.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putUser.');
            }
            const localVarPath = `/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateUserParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email associated with account.
         * @param {number} userID 
         * @param {number} userEmailID 
         * @param {Body19} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserEmail(userID: number, userEmailID: number, body: Body19, options: any = {}): FetchArgs {
            // verify required parameter 'userID' is not null or undefined
            if (userID === null || userID === undefined) {
                throw new RequiredError('userID','Required parameter userID was null or undefined when calling putUserEmail.');
            }
            // verify required parameter 'userEmailID' is not null or undefined
            if (userEmailID === null || userEmailID === undefined) {
                throw new RequiredError('userEmailID','Required parameter userEmailID was null or undefined when calling putUserEmail.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putUserEmail.');
            }
            const localVarPath = `/users/{userID}/email/{userEmailID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"userEmailID"}}`, encodeURIComponent(String(userEmailID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body19" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Delete comment.
         * @param {number} commentID 
         * @param {'blog_entry' | 'story'} commentableType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentID: number, commentableType: 'blog_entry' | 'story', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteComment(commentID, commentableType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This will delete the Entity as well as the CompetitionEntity.
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetitionEntity(competitionID: number, entityID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteCompetitionEntity(competitionID, entityID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete entity specific information for competition pledge.
         * @param {number} competitionEntityPledgeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetitionEntityPledge(competitionEntityPledgeID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteCompetitionEntityPledge(competitionEntityPledgeID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete blog entry for entity.
         * @param {number} entityID 
         * @param {number} blogEntryID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityBlogEntry(entityID: number, blogEntryID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteEntityBlogEntry(entityID, blogEntryID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityMembership(membershipID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteEntityMembership(membershipID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete flag.
         * @param {number} flaggableID 
         * @param {'story' | 'comment'} flaggableType 
         * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlag(flaggableID: number, flaggableType: 'story' | 'comment', commentableType?: 'story' | 'comment', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteFlag(flaggableID, flaggableType, commentableType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete like.
         * @param {number} likableID 
         * @param {'story' | 'blog_entry' | 'comment'} likableType 
         * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteLike(likableID, likableType, commentableType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign out.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteSession(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} storyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStory(storyID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteStory(storyID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteUser(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete email associated with account.
         * @param {number} userID 
         * @param {number} userEmailID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmail(userID: number, userEmailID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteUserEmail(userID, userEmailID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Request email verification link.
         * @param {number} userEmailID 
         * @param {string} competitionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountVerification(userEmailID: number, competitionName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getAccountVerification(userEmailID, competitionName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [entityID] 
         * @param {number} [competitionID] 
         * @param {number} [blogEntryID] 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogEntries(entityID?: number, competitionID?: number, blogEntryID?: number, page?: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getBlogEntries(entityID, competitionID, blogEntryID, page, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve user's calculator answers from server.
         * @param {number} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculatorAnswers(userID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCalculatorAnswers(userID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary List users in order of footprint results.
         * @param {'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count'} [order] 
         * @param {string} [page] 1-based index for paginated results.
         * @param {string} [pageSize] Range of 20-100 silently enforced.
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [zipcode] 
         * @param {string} [country] 
         * @param {string} [inputIncome] 
         * @param {string} [inputSize] 
         * @param {string} [includeSeedUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculatorResultLeaders(order?: 'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count', page?: string, pageSize?: string, city?: string, state?: string, zipcode?: string, country?: string, inputIncome?: string, inputSize?: string, includeSeedUsers?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCalculatorResultLeaders(order, page, pageSize, city, state, zipcode, country, inputIncome, inputSize, includeSeedUsers, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Retrieve comments for a story or blog entry.
         * @param {number} commentableID 
         * @param {'story' | 'blog_entry'} commentableType 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(commentableID: number, commentableType: 'story' | 'blog_entry', page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getComments(commentableID, commentableType, page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve competition dashboard data.
         * @param {string} competitionName 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionDashboard(competitionName: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionDashboard> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionDashboard(competitionName, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve a list of available teams or organizations.
         * @param {number} competitionID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionEntities(competitionID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionEntities(competitionID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionEntity(competitionID: number, entityID: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionEntityPage> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionEntity(competitionID, entityID, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve competition pledge..
         * @param {number} competitionPledgeID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionPledge(competitionPledgeID: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionPledgePage> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionPledge(competitionPledgeID, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} [userID] 
         * @param {number} [entityID] 
         * @param {number} [competitionPledgeID] 
         * @param {number} [storyID] 
         * @param {number} [page] 
         * @param {string} [term] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionStories(competitionID: number, userID?: number, entityID?: number, competitionPledgeID?: number, storyID?: number, page?: number, term?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StoryResults> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionStories(competitionID, userID, entityID, competitionPledgeID, storyID, page, term, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve competition user profile.
         * @param {number} competitionID 
         * @param {number} userID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUser(competitionID: number, userID: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserProfile> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionUser(competitionID, userID, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} [entityID] 
         * @param {number} [page] 
         * @param {string} [term] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUserScores(competitionID: number, entityID?: number, page?: number, term?: string, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserScoreResults> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionUserScores(competitionID, entityID, page, term, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve competition user profile.
         * @param {number} competitionID 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUsers(competitionID: number, email: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUsers> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCompetitionUsers(competitionID, email, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Generate development/QA data.
         * @param {string} [number] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerate(number?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getGenerate(number, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get summary of API status
         * @param {string} [include] Comma separated string of response objects to include.
         * @param {string} [competitionName] Name of competition to return CompetitionUserDashboard for.
         * @param {string} [userPledgeDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(include?: string, competitionName?: string, userPledgeDomain?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserDashboard> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getMe(include, competitionName, userPledgeDomain, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get summary of API status
         * @param {string} [checks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(checks?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getStatus(checks, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Swagger spec.
         * @param {string} [pat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagger(pat?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getSwagger(pat, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve user.
         * @param {number} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getUser(userID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve list of use pledges.
         * @param {number} userID 
         * @param {string} [userPledgeDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPledges(userID: number, userPledgeDomain?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserPledges> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getUserPledges(userID, userPledgeDomain, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Verify email address.
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountVerification(body: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postAccountVerification(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Retrieve comments for a story or blog entry.
         * @param {number} commentableID 
         * @param {'story' | 'blog_entry'} commentableType 
         * @param {Body} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComments(commentableID: number, commentableType: 'story' | 'blog_entry', body: Body, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Body> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postComments(commentableID, commentableType, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {Body2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntities(competitionID: number, body: Body2, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserDashboard> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postCompetitionEntities(competitionID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} entityID 
         * @param {number} competitionID 
         * @param {Body4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntityMemberships(entityID: number, competitionID: number, body: Body4, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postCompetitionEntityMemberships(entityID, competitionID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create entity specific information for competition pledge.
         * @param {Body12} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntityPledges(body: Body12, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionEntityPledges> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postCompetitionEntityPledges(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create points for a CompetitionPledge
         * @param {number} competitionPledgeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionPledgePoints(competitionPledgeID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2012> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postCompetitionPledgePoints(competitionPledgeID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {Body5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionStories(competitionID: number, body: Body5, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2011> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postCompetitionStories(competitionID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} entityID 
         * @param {Body9} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityBlogEntries(entityID: number, body: Body9, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Body9> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postEntityBlogEntries(entityID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {Body14} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityMembershipLevel(membershipID: number, body: Body14, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postEntityMembershipLevel(membershipID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {Body15} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityMembershipStatus(membershipID: number, body: Body15, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse204> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postEntityMembershipStatus(membershipID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Change owner of entity.
         * @param {number} entityID 
         * @param {Body11} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityOwner(entityID: number, body: Body11, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postEntityOwner(entityID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Create flag.
         * @param {number} flaggableID 
         * @param {'story' | 'comment'} flaggableType 
         * @param {Body6} body 
         * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFlag(flaggableID: number, flaggableType: 'story' | 'comment', body: Body6, commentableType?: 'story' | 'comment', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postFlag(flaggableID, flaggableType, body, commentableType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Post image to Google Cloud Storage.
         * @param {any} image Limited to 5 mb. Must be jpg, gif, or png.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImage(image: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImageResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postImage(image, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Mutate image server side.
         * @param {any} image Limited to 10 mb. Must be jpg, gif, or png.
         * @param {string} mutation See \&quot;#/definitions/ImageMutation\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageMutation(image: any, mutation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImageResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postImageMutation(image, mutation, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Invite user to join competition.
         * @param {string} competitionName 
         * @param {Body7} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvite(competitionName: string, body: Body7, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postInvite(competitionName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Join
         * @param {CompetitionJoinParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJoin(body: CompetitionJoinParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserDashboard> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postJoin(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Create like
         * @param {number} likableID 
         * @param {'story' | 'blog_entry' | 'comment'} likableType 
         * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postLike(likableID, likableType, commentableType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Fetch open graph data for resource
         * @param {Array<string>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenGraph(body: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OpenGraphResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postOpenGraph(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Request a password reset email
         * @param {string} competitionName 
         * @param {Body16} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordResetRequest(competitionName: string, body: Body16, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postPasswordResetRequest(competitionName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Save qualtrics ID to CompetitionUser.
         * @param {string} body Password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQualtricsID(body: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postQualtricsID(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign in.
         * @param {CreateSessionParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSession(body: CreateSessionParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserDashboard> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postSession(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Generate invite, verification, and password reset tokens for testing.
         * @param {PostTokensBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTokens(body: PostTokensBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postTokens(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Post footprint
         * @param {number} competitionId 
         * @param {Body18} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCompetitionFootprint(competitionId: number, body: Body18, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postUserCompetitionFootprint(competitionId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete user.
         * @param {number} userID 
         * @param {string} body Password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserDelete(userID: number, body: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postUserDelete(userID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add email to account.
         * @param {number} userID 
         * @param {PostUserEmailParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserEmail(userID: number, body: PostUserEmailParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postUserEmail(userID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign up.
         * @param {CreateUserParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers(body: CreateUserParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionUserDashboard> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).postUsers(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update user's calculator answers.
         * @param {number} userID 
         * @param {CalculatorAnswers} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalculatorAnswers(userID: number, body: CalculatorAnswers, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putCalculatorAnswers(userID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update comment content.
         * @param {number} commentID 
         * @param {'blog_entry' | 'story'} commentableType 
         * @param {Body1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComment(commentID: number, commentableType: 'blog_entry' | 'story', body: Body1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Body> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putComment(commentID, commentableType, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {Body3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompetitionEntity(competitionID: number, entityID: number, body: Body3, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putCompetitionEntity(competitionID, entityID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update entity specific information for competition pledge.
         * @param {number} competitionEntityPledgeID 
         * @param {Body13} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompetitionEntityPledge(competitionEntityPledgeID: number, body: Body13, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompetitionEntityPledges> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putCompetitionEntityPledge(competitionEntityPledgeID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update blog entry for entity.
         * @param {number} entityID 
         * @param {number} blogEntryID 
         * @param {Body10} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBlogEntry(entityID: number, blogEntryID: number, body: Body10, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Body10> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putEntityBlogEntry(entityID, blogEntryID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update password.
         * @param {Body17} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPasswordResetRequest(body: Body17, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putPasswordResetRequest(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} storyID 
         * @param {Body8} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStory(storyID: number, body: Body8, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putStory(storyID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update user.
         * @param {number} userID 
         * @param {UpdateUserParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userID: number, body: UpdateUserParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putUser(userID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update email associated with account.
         * @param {number} userID 
         * @param {number} userEmailID 
         * @param {Body19} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserEmail(userID: number, userEmailID: number, body: Body19, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).putUserEmail(userID, userEmailID, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Delete comment.
         * @param {number} commentID 
         * @param {'blog_entry' | 'story'} commentableType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentID: number, commentableType: 'blog_entry' | 'story', options?: any) {
            return DefaultApiFp(configuration).deleteComment(commentID, commentableType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This will delete the Entity as well as the CompetitionEntity.
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetitionEntity(competitionID: number, entityID: number, options?: any) {
            return DefaultApiFp(configuration).deleteCompetitionEntity(competitionID, entityID, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete entity specific information for competition pledge.
         * @param {number} competitionEntityPledgeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetitionEntityPledge(competitionEntityPledgeID: number, options?: any) {
            return DefaultApiFp(configuration).deleteCompetitionEntityPledge(competitionEntityPledgeID, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete blog entry for entity.
         * @param {number} entityID 
         * @param {number} blogEntryID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityBlogEntry(entityID: number, blogEntryID: number, options?: any) {
            return DefaultApiFp(configuration).deleteEntityBlogEntry(entityID, blogEntryID, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityMembership(membershipID: number, options?: any) {
            return DefaultApiFp(configuration).deleteEntityMembership(membershipID, options)(fetch, basePath);
        },
        /**
         * Delete flag.
         * @param {number} flaggableID 
         * @param {'story' | 'comment'} flaggableType 
         * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlag(flaggableID: number, flaggableType: 'story' | 'comment', commentableType?: 'story' | 'comment', options?: any) {
            return DefaultApiFp(configuration).deleteFlag(flaggableID, flaggableType, commentableType, options)(fetch, basePath);
        },
        /**
         * Delete like.
         * @param {number} likableID 
         * @param {'story' | 'blog_entry' | 'comment'} likableType 
         * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options?: any) {
            return DefaultApiFp(configuration).deleteLike(likableID, likableType, commentableType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign out.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(options?: any) {
            return DefaultApiFp(configuration).deleteSession(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} storyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStory(storyID: number, options?: any) {
            return DefaultApiFp(configuration).deleteStory(storyID, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any) {
            return DefaultApiFp(configuration).deleteUser(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete email associated with account.
         * @param {number} userID 
         * @param {number} userEmailID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmail(userID: number, userEmailID: number, options?: any) {
            return DefaultApiFp(configuration).deleteUserEmail(userID, userEmailID, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Request email verification link.
         * @param {number} userEmailID 
         * @param {string} competitionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountVerification(userEmailID: number, competitionName: string, options?: any) {
            return DefaultApiFp(configuration).getAccountVerification(userEmailID, competitionName, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [entityID] 
         * @param {number} [competitionID] 
         * @param {number} [blogEntryID] 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogEntries(entityID?: number, competitionID?: number, blogEntryID?: number, page?: number, authorization?: string, options?: any) {
            return DefaultApiFp(configuration).getBlogEntries(entityID, competitionID, blogEntryID, page, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve user's calculator answers from server.
         * @param {number} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculatorAnswers(userID: number, options?: any) {
            return DefaultApiFp(configuration).getCalculatorAnswers(userID, options)(fetch, basePath);
        },
        /**
         * 
         * @summary List users in order of footprint results.
         * @param {'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count'} [order] 
         * @param {string} [page] 1-based index for paginated results.
         * @param {string} [pageSize] Range of 20-100 silently enforced.
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [zipcode] 
         * @param {string} [country] 
         * @param {string} [inputIncome] 
         * @param {string} [inputSize] 
         * @param {string} [includeSeedUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculatorResultLeaders(order?: 'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count', page?: string, pageSize?: string, city?: string, state?: string, zipcode?: string, country?: string, inputIncome?: string, inputSize?: string, includeSeedUsers?: string, options?: any) {
            return DefaultApiFp(configuration).getCalculatorResultLeaders(order, page, pageSize, city, state, zipcode, country, inputIncome, inputSize, includeSeedUsers, options)(fetch, basePath);
        },
        /**
         * Retrieve comments for a story or blog entry.
         * @param {number} commentableID 
         * @param {'story' | 'blog_entry'} commentableType 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(commentableID: number, commentableType: 'story' | 'blog_entry', page?: number, options?: any) {
            return DefaultApiFp(configuration).getComments(commentableID, commentableType, page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve competition dashboard data.
         * @param {string} competitionName 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionDashboard(competitionName: string, authorization?: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionDashboard(competitionName, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve a list of available teams or organizations.
         * @param {number} competitionID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionEntities(competitionID: number, options?: any) {
            return DefaultApiFp(configuration).getCompetitionEntities(competitionID, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionEntity(competitionID: number, entityID: number, authorization?: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionEntity(competitionID, entityID, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve competition pledge..
         * @param {number} competitionPledgeID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionPledge(competitionPledgeID: number, authorization?: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionPledge(competitionPledgeID, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} [userID] 
         * @param {number} [entityID] 
         * @param {number} [competitionPledgeID] 
         * @param {number} [storyID] 
         * @param {number} [page] 
         * @param {string} [term] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionStories(competitionID: number, userID?: number, entityID?: number, competitionPledgeID?: number, storyID?: number, page?: number, term?: string, authorization?: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionStories(competitionID, userID, entityID, competitionPledgeID, storyID, page, term, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve competition user profile.
         * @param {number} competitionID 
         * @param {number} userID 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUser(competitionID: number, userID: number, authorization?: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionUser(competitionID, userID, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} [entityID] 
         * @param {number} [page] 
         * @param {string} [term] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUserScores(competitionID: number, entityID?: number, page?: number, term?: string, sort?: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionUserScores(competitionID, entityID, page, term, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve competition user profile.
         * @param {number} competitionID 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetitionUsers(competitionID: number, email: string, options?: any) {
            return DefaultApiFp(configuration).getCompetitionUsers(competitionID, email, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Generate development/QA data.
         * @param {string} [number] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerate(number?: string, options?: any) {
            return DefaultApiFp(configuration).getGenerate(number, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get summary of API status
         * @param {string} [include] Comma separated string of response objects to include.
         * @param {string} [competitionName] Name of competition to return CompetitionUserDashboard for.
         * @param {string} [userPledgeDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(include?: string, competitionName?: string, userPledgeDomain?: string, options?: any) {
            return DefaultApiFp(configuration).getMe(include, competitionName, userPledgeDomain, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get summary of API status
         * @param {string} [checks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(checks?: string, options?: any) {
            return DefaultApiFp(configuration).getStatus(checks, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Swagger spec.
         * @param {string} [pat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagger(pat?: string, options?: any) {
            return DefaultApiFp(configuration).getSwagger(pat, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve user.
         * @param {number} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userID: number, options?: any) {
            return DefaultApiFp(configuration).getUser(userID, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve list of use pledges.
         * @param {number} userID 
         * @param {string} [userPledgeDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPledges(userID: number, userPledgeDomain?: string, options?: any) {
            return DefaultApiFp(configuration).getUserPledges(userID, userPledgeDomain, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Verify email address.
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountVerification(body: string, options?: any) {
            return DefaultApiFp(configuration).postAccountVerification(body, options)(fetch, basePath);
        },
        /**
         * Retrieve comments for a story or blog entry.
         * @param {number} commentableID 
         * @param {'story' | 'blog_entry'} commentableType 
         * @param {Body} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComments(commentableID: number, commentableType: 'story' | 'blog_entry', body: Body, options?: any) {
            return DefaultApiFp(configuration).postComments(commentableID, commentableType, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {Body2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntities(competitionID: number, body: Body2, options?: any) {
            return DefaultApiFp(configuration).postCompetitionEntities(competitionID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} entityID 
         * @param {number} competitionID 
         * @param {Body4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntityMemberships(entityID: number, competitionID: number, body: Body4, options?: any) {
            return DefaultApiFp(configuration).postCompetitionEntityMemberships(entityID, competitionID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create entity specific information for competition pledge.
         * @param {Body12} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionEntityPledges(body: Body12, options?: any) {
            return DefaultApiFp(configuration).postCompetitionEntityPledges(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create points for a CompetitionPledge
         * @param {number} competitionPledgeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionPledgePoints(competitionPledgeID: number, options?: any) {
            return DefaultApiFp(configuration).postCompetitionPledgePoints(competitionPledgeID, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {Body5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompetitionStories(competitionID: number, body: Body5, options?: any) {
            return DefaultApiFp(configuration).postCompetitionStories(competitionID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} entityID 
         * @param {Body9} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityBlogEntries(entityID: number, body: Body9, options?: any) {
            return DefaultApiFp(configuration).postEntityBlogEntries(entityID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {Body14} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityMembershipLevel(membershipID: number, body: Body14, options?: any) {
            return DefaultApiFp(configuration).postEntityMembershipLevel(membershipID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} membershipID 
         * @param {Body15} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityMembershipStatus(membershipID: number, body: Body15, options?: any) {
            return DefaultApiFp(configuration).postEntityMembershipStatus(membershipID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Change owner of entity.
         * @param {number} entityID 
         * @param {Body11} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEntityOwner(entityID: number, body: Body11, options?: any) {
            return DefaultApiFp(configuration).postEntityOwner(entityID, body, options)(fetch, basePath);
        },
        /**
         * Create flag.
         * @param {number} flaggableID 
         * @param {'story' | 'comment'} flaggableType 
         * @param {Body6} body 
         * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFlag(flaggableID: number, flaggableType: 'story' | 'comment', body: Body6, commentableType?: 'story' | 'comment', options?: any) {
            return DefaultApiFp(configuration).postFlag(flaggableID, flaggableType, body, commentableType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Post image to Google Cloud Storage.
         * @param {any} image Limited to 5 mb. Must be jpg, gif, or png.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImage(image: any, options?: any) {
            return DefaultApiFp(configuration).postImage(image, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Mutate image server side.
         * @param {any} image Limited to 10 mb. Must be jpg, gif, or png.
         * @param {string} mutation See \&quot;#/definitions/ImageMutation\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageMutation(image: any, mutation: string, options?: any) {
            return DefaultApiFp(configuration).postImageMutation(image, mutation, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Invite user to join competition.
         * @param {string} competitionName 
         * @param {Body7} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvite(competitionName: string, body: Body7, options?: any) {
            return DefaultApiFp(configuration).postInvite(competitionName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Join
         * @param {CompetitionJoinParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJoin(body: CompetitionJoinParams, options?: any) {
            return DefaultApiFp(configuration).postJoin(body, options)(fetch, basePath);
        },
        /**
         * Create like
         * @param {number} likableID 
         * @param {'story' | 'blog_entry' | 'comment'} likableType 
         * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options?: any) {
            return DefaultApiFp(configuration).postLike(likableID, likableType, commentableType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Fetch open graph data for resource
         * @param {Array<string>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenGraph(body: Array<string>, options?: any) {
            return DefaultApiFp(configuration).postOpenGraph(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Request a password reset email
         * @param {string} competitionName 
         * @param {Body16} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordResetRequest(competitionName: string, body: Body16, options?: any) {
            return DefaultApiFp(configuration).postPasswordResetRequest(competitionName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Save qualtrics ID to CompetitionUser.
         * @param {string} body Password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQualtricsID(body: string, options?: any) {
            return DefaultApiFp(configuration).postQualtricsID(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign in.
         * @param {CreateSessionParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSession(body: CreateSessionParams, options?: any) {
            return DefaultApiFp(configuration).postSession(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Generate invite, verification, and password reset tokens for testing.
         * @param {PostTokensBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTokens(body: PostTokensBody, options?: any) {
            return DefaultApiFp(configuration).postTokens(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Post footprint
         * @param {number} competitionId 
         * @param {Body18} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCompetitionFootprint(competitionId: number, body: Body18, options?: any) {
            return DefaultApiFp(configuration).postUserCompetitionFootprint(competitionId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete user.
         * @param {number} userID 
         * @param {string} body Password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserDelete(userID: number, body: string, options?: any) {
            return DefaultApiFp(configuration).postUserDelete(userID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add email to account.
         * @param {number} userID 
         * @param {PostUserEmailParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserEmail(userID: number, body: PostUserEmailParams, options?: any) {
            return DefaultApiFp(configuration).postUserEmail(userID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign up.
         * @param {CreateUserParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers(body: CreateUserParams, options?: any) {
            return DefaultApiFp(configuration).postUsers(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update user's calculator answers.
         * @param {number} userID 
         * @param {CalculatorAnswers} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalculatorAnswers(userID: number, body: CalculatorAnswers, options?: any) {
            return DefaultApiFp(configuration).putCalculatorAnswers(userID, body, options)(fetch, basePath);
        },
        /**
         * Update comment content.
         * @param {number} commentID 
         * @param {'blog_entry' | 'story'} commentableType 
         * @param {Body1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComment(commentID: number, commentableType: 'blog_entry' | 'story', body: Body1, options?: any) {
            return DefaultApiFp(configuration).putComment(commentID, commentableType, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} competitionID 
         * @param {number} entityID 
         * @param {Body3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompetitionEntity(competitionID: number, entityID: number, body: Body3, options?: any) {
            return DefaultApiFp(configuration).putCompetitionEntity(competitionID, entityID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update entity specific information for competition pledge.
         * @param {number} competitionEntityPledgeID 
         * @param {Body13} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompetitionEntityPledge(competitionEntityPledgeID: number, body: Body13, options?: any) {
            return DefaultApiFp(configuration).putCompetitionEntityPledge(competitionEntityPledgeID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update blog entry for entity.
         * @param {number} entityID 
         * @param {number} blogEntryID 
         * @param {Body10} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBlogEntry(entityID: number, blogEntryID: number, body: Body10, options?: any) {
            return DefaultApiFp(configuration).putEntityBlogEntry(entityID, blogEntryID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update password.
         * @param {Body17} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPasswordResetRequest(body: Body17, options?: any) {
            return DefaultApiFp(configuration).putPasswordResetRequest(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} storyID 
         * @param {Body8} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStory(storyID: number, body: Body8, options?: any) {
            return DefaultApiFp(configuration).putStory(storyID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update user.
         * @param {number} userID 
         * @param {UpdateUserParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userID: number, body: UpdateUserParams, options?: any) {
            return DefaultApiFp(configuration).putUser(userID, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update email associated with account.
         * @param {number} userID 
         * @param {number} userEmailID 
         * @param {Body19} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserEmail(userID: number, userEmailID: number, body: Body19, options?: any) {
            return DefaultApiFp(configuration).putUserEmail(userID, userEmailID, body, options)(fetch, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Delete comment.
     * @param {number} commentID 
     * @param {'blog_entry' | 'story'} commentableType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteComment(commentID: number, commentableType: 'blog_entry' | 'story', options?: any) {
        return DefaultApiFp(this.configuration).deleteComment(commentID, commentableType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This will delete the Entity as well as the CompetitionEntity.
     * @param {number} competitionID 
     * @param {number} entityID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCompetitionEntity(competitionID: number, entityID: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteCompetitionEntity(competitionID, entityID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete entity specific information for competition pledge.
     * @param {number} competitionEntityPledgeID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCompetitionEntityPledge(competitionEntityPledgeID: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteCompetitionEntityPledge(competitionEntityPledgeID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete blog entry for entity.
     * @param {number} entityID 
     * @param {number} blogEntryID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteEntityBlogEntry(entityID: number, blogEntryID: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteEntityBlogEntry(entityID, blogEntryID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} membershipID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteEntityMembership(membershipID: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteEntityMembership(membershipID, options)(this.fetch, this.basePath);
    }

    /**
     * Delete flag.
     * @param {number} flaggableID 
     * @param {'story' | 'comment'} flaggableType 
     * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFlag(flaggableID: number, flaggableType: 'story' | 'comment', commentableType?: 'story' | 'comment', options?: any) {
        return DefaultApiFp(this.configuration).deleteFlag(flaggableID, flaggableType, commentableType, options)(this.fetch, this.basePath);
    }

    /**
     * Delete like.
     * @param {number} likableID 
     * @param {'story' | 'blog_entry' | 'comment'} likableType 
     * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options?: any) {
        return DefaultApiFp(this.configuration).deleteLike(likableID, likableType, commentableType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign out.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSession(options?: any) {
        return DefaultApiFp(this.configuration).deleteSession(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} storyID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStory(storyID: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteStory(storyID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUser(options?: any) {
        return DefaultApiFp(this.configuration).deleteUser(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete email associated with account.
     * @param {number} userID 
     * @param {number} userEmailID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserEmail(userID: number, userEmailID: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteUserEmail(userID, userEmailID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Request email verification link.
     * @param {number} userEmailID 
     * @param {string} competitionName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccountVerification(userEmailID: number, competitionName: string, options?: any) {
        return DefaultApiFp(this.configuration).getAccountVerification(userEmailID, competitionName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [entityID] 
     * @param {number} [competitionID] 
     * @param {number} [blogEntryID] 
     * @param {number} [page] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBlogEntries(entityID?: number, competitionID?: number, blogEntryID?: number, page?: number, authorization?: string, options?: any) {
        return DefaultApiFp(this.configuration).getBlogEntries(entityID, competitionID, blogEntryID, page, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve user's calculator answers from server.
     * @param {number} userID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCalculatorAnswers(userID: number, options?: any) {
        return DefaultApiFp(this.configuration).getCalculatorAnswers(userID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary List users in order of footprint results.
     * @param {'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count'} [order] 
     * @param {string} [page] 1-based index for paginated results.
     * @param {string} [pageSize] Range of 20-100 silently enforced.
     * @param {string} [city] 
     * @param {string} [state] 
     * @param {string} [zipcode] 
     * @param {string} [country] 
     * @param {string} [inputIncome] 
     * @param {string} [inputSize] 
     * @param {string} [includeSeedUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCalculatorResultLeaders(order?: 'result_transport_total' | '-result_transport_total' | 'result_housing_total' | '-result_housing_total' | 'result_food_total' | '-result_food_total' | 'result_grand_total' | '-result_grand_total' | 'result_goods_total' | '-result_goods_total' | 'result_services_total' | '-result_services_total' | 'pledge_count' | '-pledge_count', page?: string, pageSize?: string, city?: string, state?: string, zipcode?: string, country?: string, inputIncome?: string, inputSize?: string, includeSeedUsers?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCalculatorResultLeaders(order, page, pageSize, city, state, zipcode, country, inputIncome, inputSize, includeSeedUsers, options)(this.fetch, this.basePath);
    }

    /**
     * Retrieve comments for a story or blog entry.
     * @param {number} commentableID 
     * @param {'story' | 'blog_entry'} commentableType 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getComments(commentableID: number, commentableType: 'story' | 'blog_entry', page?: number, options?: any) {
        return DefaultApiFp(this.configuration).getComments(commentableID, commentableType, page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve competition dashboard data.
     * @param {string} competitionName 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionDashboard(competitionName: string, authorization?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionDashboard(competitionName, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a list of available teams or organizations.
     * @param {number} competitionID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionEntities(competitionID: number, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionEntities(competitionID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} competitionID 
     * @param {number} entityID 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionEntity(competitionID: number, entityID: number, authorization?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionEntity(competitionID, entityID, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve competition pledge..
     * @param {number} competitionPledgeID 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionPledge(competitionPledgeID: number, authorization?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionPledge(competitionPledgeID, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} competitionID 
     * @param {number} [userID] 
     * @param {number} [entityID] 
     * @param {number} [competitionPledgeID] 
     * @param {number} [storyID] 
     * @param {number} [page] 
     * @param {string} [term] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionStories(competitionID: number, userID?: number, entityID?: number, competitionPledgeID?: number, storyID?: number, page?: number, term?: string, authorization?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionStories(competitionID, userID, entityID, competitionPledgeID, storyID, page, term, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve competition user profile.
     * @param {number} competitionID 
     * @param {number} userID 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionUser(competitionID: number, userID: number, authorization?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionUser(competitionID, userID, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} competitionID 
     * @param {number} [entityID] 
     * @param {number} [page] 
     * @param {string} [term] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionUserScores(competitionID: number, entityID?: number, page?: number, term?: string, sort?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionUserScores(competitionID, entityID, page, term, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve competition user profile.
     * @param {number} competitionID 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompetitionUsers(competitionID: number, email: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompetitionUsers(competitionID, email, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Generate development/QA data.
     * @param {string} [number] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGenerate(number?: string, options?: any) {
        return DefaultApiFp(this.configuration).getGenerate(number, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get summary of API status
     * @param {string} [include] Comma separated string of response objects to include.
     * @param {string} [competitionName] Name of competition to return CompetitionUserDashboard for.
     * @param {string} [userPledgeDomain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMe(include?: string, competitionName?: string, userPledgeDomain?: string, options?: any) {
        return DefaultApiFp(this.configuration).getMe(include, competitionName, userPledgeDomain, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get summary of API status
     * @param {string} [checks] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStatus(checks?: string, options?: any) {
        return DefaultApiFp(this.configuration).getStatus(checks, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Swagger spec.
     * @param {string} [pat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSwagger(pat?: string, options?: any) {
        return DefaultApiFp(this.configuration).getSwagger(pat, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve user.
     * @param {number} userID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(userID: number, options?: any) {
        return DefaultApiFp(this.configuration).getUser(userID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve list of use pledges.
     * @param {number} userID 
     * @param {string} [userPledgeDomain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserPledges(userID: number, userPledgeDomain?: string, options?: any) {
        return DefaultApiFp(this.configuration).getUserPledges(userID, userPledgeDomain, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Verify email address.
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postAccountVerification(body: string, options?: any) {
        return DefaultApiFp(this.configuration).postAccountVerification(body, options)(this.fetch, this.basePath);
    }

    /**
     * Retrieve comments for a story or blog entry.
     * @param {number} commentableID 
     * @param {'story' | 'blog_entry'} commentableType 
     * @param {Body} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postComments(commentableID: number, commentableType: 'story' | 'blog_entry', body: Body, options?: any) {
        return DefaultApiFp(this.configuration).postComments(commentableID, commentableType, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} competitionID 
     * @param {Body2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompetitionEntities(competitionID: number, body: Body2, options?: any) {
        return DefaultApiFp(this.configuration).postCompetitionEntities(competitionID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} entityID 
     * @param {number} competitionID 
     * @param {Body4} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompetitionEntityMemberships(entityID: number, competitionID: number, body: Body4, options?: any) {
        return DefaultApiFp(this.configuration).postCompetitionEntityMemberships(entityID, competitionID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create entity specific information for competition pledge.
     * @param {Body12} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompetitionEntityPledges(body: Body12, options?: any) {
        return DefaultApiFp(this.configuration).postCompetitionEntityPledges(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create points for a CompetitionPledge
     * @param {number} competitionPledgeID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompetitionPledgePoints(competitionPledgeID: number, options?: any) {
        return DefaultApiFp(this.configuration).postCompetitionPledgePoints(competitionPledgeID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} competitionID 
     * @param {Body5} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompetitionStories(competitionID: number, body: Body5, options?: any) {
        return DefaultApiFp(this.configuration).postCompetitionStories(competitionID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} entityID 
     * @param {Body9} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postEntityBlogEntries(entityID: number, body: Body9, options?: any) {
        return DefaultApiFp(this.configuration).postEntityBlogEntries(entityID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} membershipID 
     * @param {Body14} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postEntityMembershipLevel(membershipID: number, body: Body14, options?: any) {
        return DefaultApiFp(this.configuration).postEntityMembershipLevel(membershipID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} membershipID 
     * @param {Body15} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postEntityMembershipStatus(membershipID: number, body: Body15, options?: any) {
        return DefaultApiFp(this.configuration).postEntityMembershipStatus(membershipID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Change owner of entity.
     * @param {number} entityID 
     * @param {Body11} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postEntityOwner(entityID: number, body: Body11, options?: any) {
        return DefaultApiFp(this.configuration).postEntityOwner(entityID, body, options)(this.fetch, this.basePath);
    }

    /**
     * Create flag.
     * @param {number} flaggableID 
     * @param {'story' | 'comment'} flaggableType 
     * @param {Body6} body 
     * @param {'story' | 'comment'} [commentableType] If flaggable is a comment, pass commentableType here.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postFlag(flaggableID: number, flaggableType: 'story' | 'comment', body: Body6, commentableType?: 'story' | 'comment', options?: any) {
        return DefaultApiFp(this.configuration).postFlag(flaggableID, flaggableType, body, commentableType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Post image to Google Cloud Storage.
     * @param {any} image Limited to 5 mb. Must be jpg, gif, or png.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postImage(image: any, options?: any) {
        return DefaultApiFp(this.configuration).postImage(image, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Mutate image server side.
     * @param {any} image Limited to 10 mb. Must be jpg, gif, or png.
     * @param {string} mutation See \&quot;#/definitions/ImageMutation\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postImageMutation(image: any, mutation: string, options?: any) {
        return DefaultApiFp(this.configuration).postImageMutation(image, mutation, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Invite user to join competition.
     * @param {string} competitionName 
     * @param {Body7} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postInvite(competitionName: string, body: Body7, options?: any) {
        return DefaultApiFp(this.configuration).postInvite(competitionName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Join
     * @param {CompetitionJoinParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postJoin(body: CompetitionJoinParams, options?: any) {
        return DefaultApiFp(this.configuration).postJoin(body, options)(this.fetch, this.basePath);
    }

    /**
     * Create like
     * @param {number} likableID 
     * @param {'story' | 'blog_entry' | 'comment'} likableType 
     * @param {'story' | 'blog_entry'} [commentableType] If likable is a comment, pass commentableType here.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postLike(likableID: number, likableType: 'story' | 'blog_entry' | 'comment', commentableType?: 'story' | 'blog_entry', options?: any) {
        return DefaultApiFp(this.configuration).postLike(likableID, likableType, commentableType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Fetch open graph data for resource
     * @param {Array<string>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postOpenGraph(body: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).postOpenGraph(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Request a password reset email
     * @param {string} competitionName 
     * @param {Body16} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postPasswordResetRequest(competitionName: string, body: Body16, options?: any) {
        return DefaultApiFp(this.configuration).postPasswordResetRequest(competitionName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Save qualtrics ID to CompetitionUser.
     * @param {string} body Password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postQualtricsID(body: string, options?: any) {
        return DefaultApiFp(this.configuration).postQualtricsID(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign in.
     * @param {CreateSessionParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postSession(body: CreateSessionParams, options?: any) {
        return DefaultApiFp(this.configuration).postSession(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Generate invite, verification, and password reset tokens for testing.
     * @param {PostTokensBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postTokens(body: PostTokensBody, options?: any) {
        return DefaultApiFp(this.configuration).postTokens(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Post footprint
     * @param {number} competitionId 
     * @param {Body18} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUserCompetitionFootprint(competitionId: number, body: Body18, options?: any) {
        return DefaultApiFp(this.configuration).postUserCompetitionFootprint(competitionId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete user.
     * @param {number} userID 
     * @param {string} body Password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUserDelete(userID: number, body: string, options?: any) {
        return DefaultApiFp(this.configuration).postUserDelete(userID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add email to account.
     * @param {number} userID 
     * @param {PostUserEmailParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUserEmail(userID: number, body: PostUserEmailParams, options?: any) {
        return DefaultApiFp(this.configuration).postUserEmail(userID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign up.
     * @param {CreateUserParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUsers(body: CreateUserParams, options?: any) {
        return DefaultApiFp(this.configuration).postUsers(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update user's calculator answers.
     * @param {number} userID 
     * @param {CalculatorAnswers} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putCalculatorAnswers(userID: number, body: CalculatorAnswers, options?: any) {
        return DefaultApiFp(this.configuration).putCalculatorAnswers(userID, body, options)(this.fetch, this.basePath);
    }

    /**
     * Update comment content.
     * @param {number} commentID 
     * @param {'blog_entry' | 'story'} commentableType 
     * @param {Body1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putComment(commentID: number, commentableType: 'blog_entry' | 'story', body: Body1, options?: any) {
        return DefaultApiFp(this.configuration).putComment(commentID, commentableType, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} competitionID 
     * @param {number} entityID 
     * @param {Body3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putCompetitionEntity(competitionID: number, entityID: number, body: Body3, options?: any) {
        return DefaultApiFp(this.configuration).putCompetitionEntity(competitionID, entityID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update entity specific information for competition pledge.
     * @param {number} competitionEntityPledgeID 
     * @param {Body13} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putCompetitionEntityPledge(competitionEntityPledgeID: number, body: Body13, options?: any) {
        return DefaultApiFp(this.configuration).putCompetitionEntityPledge(competitionEntityPledgeID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update blog entry for entity.
     * @param {number} entityID 
     * @param {number} blogEntryID 
     * @param {Body10} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putEntityBlogEntry(entityID: number, blogEntryID: number, body: Body10, options?: any) {
        return DefaultApiFp(this.configuration).putEntityBlogEntry(entityID, blogEntryID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update password.
     * @param {Body17} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putPasswordResetRequest(body: Body17, options?: any) {
        return DefaultApiFp(this.configuration).putPasswordResetRequest(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} storyID 
     * @param {Body8} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putStory(storyID: number, body: Body8, options?: any) {
        return DefaultApiFp(this.configuration).putStory(storyID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update user.
     * @param {number} userID 
     * @param {UpdateUserParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putUser(userID: number, body: UpdateUserParams, options?: any) {
        return DefaultApiFp(this.configuration).putUser(userID, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update email associated with account.
     * @param {number} userID 
     * @param {number} userEmailID 
     * @param {Body19} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putUserEmail(userID: number, userEmailID: number, body: Body19, options?: any) {
        return DefaultApiFp(this.configuration).putUserEmail(userID, userEmailID, body, options)(this.fetch, this.basePath);
    }

}

