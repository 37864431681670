import * as React from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { UserRead, Entity, Competition } from '../userApi';
import { Link } from 'react-router-dom';
import { makeEntityPath, makeUserPath } from '../utils/routerHelper';
import { withLocalization, LProps } from '../utils/wrappers';
import ProfilePhoto from './ProfilePhoto';
import { displayFullName, displayUserType } from '../utils/userApiHelper';
import { State } from '../redux/state';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import actions from '../redux/actions';

interface Props {
  user: UserRead;
  competition: Competition;
  university: Entity;
  team: Entity;
  healthFacility?: Entity;
  linkToProfile?: boolean;
}

const UserHeader = ({
  user, university, team, healthFacility, competition, t, i18n, linkToProfile = false
}: LProps<Props>) => (
  <div className="user_header">
    <div className="cc__user__header__photo">
      <ProfilePhoto
        profilePhoto={user.profilePhoto}
        alt={user.firstName}
      />
    </div>
    <div className="user_header__name_entities">
      {!linkToProfile && <h4>{displayFullName(user)}</h4>}
      {linkToProfile && <h4><Link to={makeUserPath(competition.id, user, t)}>{displayFullName(user)}</Link></h4>}
      {displayUserType(user.type, i18n) && <div className="cc__lighttext">{displayUserType(user.type, i18n)}</div>}
      {university &&
        <div><Link to={makeEntityPath(competition.id, university, t)}>{university.name}</Link></div>
      }
      {healthFacility &&
        <div><Link to={makeEntityPath(competition.id, healthFacility, t)}>{healthFacility.name} <i className="fa fa-hospital-o" /></Link></div>
      }
      {team &&
        <div><Link to={makeEntityPath(competition.id, team, t)}>{team.name}</Link></div>
      }
    </div>
  </div>
);

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withLocalization('userHeader'),
  connect(mapStateToProps, mapDispatchToProps)
)(UserHeader) as React.FunctionComponent<Props>;
