import * as React from 'react';
import cx from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { Routes, makeSimpleRoutePath } from '../utils/routerHelper';
import { LProps, withLocalization, withRouterAndHelpers, RouterProps } from '../utils/wrappers';
import { compose } from 'redux';

interface Props {
  activable?: boolean;
  route?: Routes;
  to?: string;
  children: React.ReactNode;
  linkProps?: Partial<LinkProps>;
  onClick?: () => void;
}

const RouteLink = ({
  to, route, t, activable = false, currentRoute, onClick,
  children, linkProps = {}
}: RouterProps<{}> & LProps<Props>) => (
  <Link
    onClick={onClick}
    className={cx({ inactive: activable && route !== currentRoute })}
    to={to || makeSimpleRoutePath(route,t)}
    {...linkProps}
  >
    {children}
  </Link>
);

export default compose(
  withLocalization('routes', ['formatNumber']),
  withRouterAndHelpers
)(RouteLink) as React.SFC<Props>;
