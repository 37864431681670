import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { State } from '../redux/state';
import { withLocalization, LProps } from '../utils/wrappers';
import { CompetitionEntityScore, Entity, CompetitionEntity } from '../userApi';
import { Link } from 'react-router-dom';
import { makeEntityPath } from '../utils/routerHelper';

interface ManualTeamScoreProps {
  score: CompetitionEntityScore;
  rank: number;
}

interface TeamScoreProps extends ManualTeamScoreProps {
  competitionEntity: CompetitionEntity;
  team: Entity;
}

const TeamScore = ({
  rank, score, team, competitionEntity, t, formatNumber
}: LProps<TeamScoreProps>) => (
  <tr>
    <td>#{rank}</td>
    <td>
      <Link to={makeEntityPath(competitionEntity.competitionID, team, t)}>{team.abbreviation || (team.name || '').slice(0, 20)}</Link>
    </td>
    <td className="cc__scoreboard__points">{formatNumber(score.points, 0)}</td>
  </tr>
);

const ConnectedTeamScore = compose(
  connect((state: State, ownProps: ManualTeamScoreProps) => ({
    competitionEntity: state.competitionEntities[ownProps.score.entityID],
    team: state.entities[ownProps.score.entityID]
  })),
  withLocalization('scoreboard')
)(TeamScore) as React.ComponentClass<ManualTeamScoreProps>;

interface ManualProps {
  teamScores: CompetitionEntityScore[];
  limit?: number;
}

const ScoreboardTeams = ({
  t, teamScores, limit
}: LProps<ManualProps>) => (
  <table>
    <thead>
      <tr>
        <th />
        <th />
        <th className="cc__scoreboard__points">{t('Points')}</th>
      </tr>
    </thead>
    <tbody>
      {((limit ?teamScores&& teamScores.slice(0, limit) : teamScores) || []).map((teamScore, i) => (
        <ConnectedTeamScore
          key={teamScore.entityID}
          score={teamScore}
          rank={i + 1}
        />
      ))}
    </tbody>
  </table>
);

export default compose(
  withLocalization('scoreboard')
)(ScoreboardTeams) as React.SFC<ManualProps>;
