import * as moment from 'moment';
import { State } from '../state';

export const competitionName = (state: State) => {   
  return state.initialQuery.competitionName
}

export const hasCompetitionStarted = (state: State) => {
  if (!state.competition) return false;
  return moment(state.competition.startsAt).isBefore(Date.now());
};

export const hasCompetitionEnded = (state: State) => {
  if (!state.competition) return false;
  return moment(state.competition.endsAt).isBefore(Date.now());
};

export const competitionDescription = (state: State) => {
  if (!state.competition) return '';
  return state.competition.description
};
