import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeEntityPath } from '../utils/routerHelper';
import { Entity } from '../userApi';
import { TranslationFunction } from 'i18next';

interface ManualProps {
  entity: Entity;
  competitionID: number;
  t: TranslationFunction;
}

const EntityIdentity = ({
  entity, competitionID, t
}: ManualProps) => (
  <div className="cc__entity_identity">
    <div className="cc__entity_identity__inner">
      <div className="cc__entity_identity__photo__wrapper">
        <div className="cc__entity_identity__photo cc__rounded">
          <div className="cc__entity_identity__img__wrapper">
            <img src={entity.photo} alt={entity.name} />
          </div>
        </div>
      </div>
      <Link to={makeEntityPath(competitionID, entity, t)}>{entity.abbreviation || (entity.name || '').slice(0, 20)}</Link>
    </div>
  </div>
);

export default EntityIdentity;
