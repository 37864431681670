import * as React from 'react';
import { DataURLFromFile } from '../utils/image';

// DataURLs are too large to story in Redux. Keep them here.
export interface ImageStore {
  [imageID: string]: DataURLFromFile;
}
export const imageStore: ImageStore = {};

export interface ImageStoreProps {
  images: ImageStore;
  setImage: (imageID: string, dataURLFromFile: DataURLFromFile) => void;
  delImage: (imageID: string) => void;
  getImage: (imageID: string) => DataURLFromFile;
}

export const setImage = (imageID: string, image: DataURLFromFile) => {
  imageStore[imageID] = image;
};

export const getImage = (imageID: string): DataURLFromFile => imageStore[imageID];

export const delImage = (imageID: string) => {
  delete imageStore[imageID];
};

type SomeComponent<P> = React.StatelessComponent<P> | React.ComponentClass<P>| React.SFC<P>;

export const withImages = <P>(imageIDs: string[]) => (Component: SomeComponent<P & ImageStoreProps>) =>
  (otherProps: P) => {
      const images: ImageStore = imageIDs.reduce((previous, imageID) => ({ ...previous, [imageID]: imageStore[imageID] }), {});
      return React.createElement(
        Component,
        {
          images, setImage, delImage, getImage, ...otherProps
        }
      );
    };
