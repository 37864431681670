import { State } from '../state';
import { DefaultApi, Configuration } from '../../userApi';

const defaultBasePath = () => {
  switch (true) {
    case window.location.hostname.search('nginx') >= 0:
      return 'http://api:8080/v1';
    case window.location.hostname.search('localhost') >= 0:
    case window.location.hostname.search('127.0.0.1') >= 0:
      return 'http://localhost:8080/v1';
      //return 'https://www.coolcampuschallenge.org/v1'
    default:
      return `${window.location.protocol}//${window.location.host}/v1`;
  }
};

export const userApiBasePath = (state: State) =>
  state.initialQuery.userApiBase || defaultBasePath();

export const userApi = (state: State) => {
  const configuration = new Configuration({
    apiKey: state.token,
    basePath: userApiBasePath(state)
  });
  return new DefaultApi(configuration);
};
