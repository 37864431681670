import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import { Dispatch, compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CompetitionPledge } from '../userApi';
import { makePledgePath, Routes, makeSimpleRoutePath } from '../utils/routerHelper';
import { LProps, RouterProps, withRouterAndHelpers, withLocalization } from '../utils/wrappers';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';

interface Props {
  pledges: CompetitionPledge[];
}

interface PledgeCarouselState {
  activeIndex: number;
}

class PledgeCarousel extends React.Component<LProps<Props> & RouterProps<{}>, PledgeCarouselState> {
  public state = { activeIndex: 0 };

  private next() {
    const { activeIndex } = this.state;
    const { pledges } = this.props;
    if (activeIndex === pledges.length - 1) {
      this.setState({ activeIndex: 0 });
    } else {
      this.setState({ activeIndex: activeIndex + 1 });
    }
  }

  private previous() {
    const { activeIndex } = this.state;
    const { pledges } = this.props;
    if (activeIndex === 0) {
      this.setState({ activeIndex: pledges.length - 1 });
    } else {
      this.setState({ activeIndex: activeIndex - 1 });
    }
  }

  public render() {
    const {
      pledges, history,
      t, formatNumber
    } = this.props;
    const { activeIndex } = this.state;
    return (
      <div
      id="pledge_carousel"
      style={{ display: 'block' }}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="cc__lighttext">{t('Suggested actions')}</div>
          <Link to={makeSimpleRoutePath(Routes.Pledges, t)}>{t('View all actions')} &gt;</Link>
        </div>
        <Carousel
          activeIndex={activeIndex}
          next={() => this.next()}
          previous={() => this.previous()}
          interval={60000}
        >
          {pledges.map((pledge, i) => (
            <CarouselItem key={pledge.id}>
              <div className="pledge_slide">
                <div className="pledge_slide__data">
                  <div><Link to={makePledgePath(pledge, t)}>{pledge.shortTitle}</Link></div>
                  <div>
                    <div>
                      <span className="pledge_slide__data__label">{t('Points')}</span>&nbsp;
                      <span className="cc__lighttext">{formatNumber(pledge.points, 0)}</span>
                    </div>
                    <div>
                      <span
                        className="pledge_slide__data__label"
                        dangerouslySetInnerHTML={{
                          __html: t('Pounds CO<sub>2</sub>')
                        }}
                      />&nbsp;
                      <span className="cc__lighttext">{formatNumber(pledge.savingsCO2Lbs, 0)}</span>
                    </div>
                  </div>
                  <div>
                    <Button
                      color={'primary'}
                      onClick={() => history.push(makePledgePath(pledge, t))}
                    >{t('Take action')}</Button>
                  </div>
                </div>
                <div>
                  {/** OPTIMIZATION: don't load photos unless they are viewable. */}
                  {activeIndex === i && <img src={pledge.photo} alt={pledge.shortTitle} />}
                </div>
              </div>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" directionText={t('Previous')} onClickHandler={() => this.previous()}>
            <img src="/assets/prevNextControl.svg" alt={t('previous')} />
          </CarouselControl>
          <CarouselControl direction="next" directionText={t('Next')} onClickHandler={() => this.next()} />
        </Carousel>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: Props): Props => ({
  pledges: selectors.carouselPledges(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withRouterAndHelpers,
  withLocalization('pledges'),
  connect(mapStateToProps, mapDispatchToProps)
)(PledgeCarousel) as React.ComponentClass;
