import * as React from 'react';
import cx from 'classnames';
import { LProps, withLocalization } from '../../utils/wrappers';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { compose } from 'redux';
import { asPage } from '../../utils/wrappers/asPage';
import Footer from '../../shared/Footer';
import { RouterProps, withRouter } from 'react-router';
import { FaqSection, makeFaqPath, getFaqParams } from '../../utils/routerHelper';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import LinkButton from '../../shared/LinkButton';
import { openIntercom } from '../../utils/intercom';
import ExternalLink from '../../shared/ExternalLink';

interface Props {
  section: FaqSection;
}

const Contact = () => (<ExternalLink href={'mailto:contact@coolcampushchallenge.org'}>contact@coolcampushchallenge.org</ExternalLink>);

const Faq = ({ t, history, section }: LProps<Props> & RouterProps) => (
  <div id="faq" className="cc__lighttext">
    <h2>FAQ</h2>
    <Nav tabs={true}>
      <NavItem>
        <NavLink
          className={cx({ active: section === FaqSection.General })}
          onClick={() => history.push(makeFaqPath(FaqSection.General, t))}
        >{t('General')}</NavLink>
      </NavItem>      
    </Nav>
    <TabContent activeTab={section} >
      <TabPane tabId={FaqSection.General}>
        <div>
          <h4 className="cc__bluetext">{t('How do I sign up?')}</h4>
          <p>{t('Joining a campus competition? Enter the url in this format https://www.coolcampuschallenge.org/<competitionName> and click the signup button. ')}</p>
          <p>{t('If you do not receive a confirmation email, please check your spam folder for the verification email.')}</p>
        </div>
        <div>          
          <h4 className="cc__bluetext">{t('How do participants earn points?')}</h4>
          <p>{t('There are several ways for participants to earn points. Simple actions such as signing up for the Challenge will earn 200 points, and additional points can be earned for tasks such as uploading a photo, and inviting friends to join.')}</p>
          <p>{t('Points earned throughout the Challenge will be tracked and viewable in real-time and added to your total.')}</p>          
        </div>
        <div>
          <h4 className="cc__bluetext">{t('How do teams work?')}</h4>
          <p>{t('Anyone can either create their own team or join an existing team at their UC location.')}</p>
          <p>{t('There is no limit to the number of participants on teams…the more the better!')}</p>
        </div>   
        <div>
          <h4 className="cc__bluetext">{t('Who do I contact if I have a question or want to start my own competition?')}</h4>
          <p>{t('Please contact your local campus sustainability office.')}</p>
        </div>       
      </TabPane>      
    </TabContent>
    <Footer />
  </div>
);

const mapStateToProps = (_state: State, props: LProps<RouterProps>) => ({
  section: getFaqParams(props.history.location, props.i18n).section
});

export default asPage({})(compose(
  withLocalization('faw'),
  withRouter,
  connect(mapStateToProps)
)(Faq));
