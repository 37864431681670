import * as React from 'react';
import { isEmpty } from 'lodash';
import { SocialLinks } from '../userApi';
import { InputGroup, InputGroupAddon, Input, FormGroup, FormFeedback } from 'reactstrap';
import { LProps, withLocalization } from '../utils/wrappers';
import SocialLinksBtnGroup from './SocialLinksBtnGroup';
import { compose } from 'redux';
import { SocialLinkErrors, processSocialLinks } from '../utils/forms/socialLinks';

interface ManualProps {
  socialLinks: SocialLinks;
  errors: SocialLinkErrors;
  onChange: (socialLinks: SocialLinks) => void;
}

interface SocialLinksState {
  form: SocialLinks;
}

const initialState: SocialLinksState = {
  form: {}
};

const SocialLinkAddOn = ({ icon }: { icon: string }) =>
<InputGroupAddon addonType="prepend"><span style={{ width: '41px' }} className="input-group-text"><i className={`fa fa-${icon}`} /></span></InputGroupAddon>;

class SocialLinksForm extends React.Component<LProps<ManualProps>, SocialLinksState> {
  public state = initialState;

  public componentWillMount() {
    const { socialLinks } = this.props;
    this.setState({ form: (socialLinks || {}) });
  }

  private onChange() {
    const { onChange } = this.props;
    const { form } = this.state;
    this.setState({ form: processSocialLinks(form) }, () => {
      onChange(this.state.form);
    });
  }

  public render() {
    const { t, errors } = this.props;
    const { form } = this.state;
    return (
      <div className="cc__social_links_form" style={{ textAlign: 'center' }}>
        {!isEmpty(form) && <SocialLinksBtnGroup socialLinks={form} />}
        <FormGroup>
          <InputGroup>
            <SocialLinkAddOn icon="link" />
            <Input
              invalid={Boolean(errors.website)}
              placeholder={t('Website URL')}
              value={form.website || ''}
              onChange={(e) => this.setState({ form: { ...form, website: e.target.value } })}
              onBlur={() => this.onChange()}
            />
          </InputGroup>
          {errors.website && <FormFeedback>{errors.website}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <SocialLinkAddOn icon="medium" />
            <Input
              placeholder={t('Medium username')}
              value={form.medium || ''}
              onChange={(e) => this.setState({ form: { ...form, medium: e.target.value } })}
              onBlur={() => this.onChange()}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <SocialLinkAddOn icon="facebook-f" />
            <Input
              invalid={Boolean(errors.facebook)}
              placeholder={t('Facebook profile URL')}
              value={form.facebook || ''}
              onChange={(e) => this.setState({ form: { ...form, facebook: e.target.value } })}
              onBlur={() => this.onChange()}
            />
          </InputGroup>
          {errors.facebook && <FormFeedback>{errors.facebook}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <SocialLinkAddOn icon="twitter" />
            <Input
              placeholder={t('Twitter username')}
              value={form.twitter || ''}
              onChange={(e) => this.setState({ form: { ...form, twitter: e.target.value } })}
              onBlur={() => this.onChange()}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <SocialLinkAddOn icon="linkedin" />
            <Input
              invalid={Boolean(errors.linkedin)}
              placeholder={t('LinkedIn profile URL')}
              value={form.linkedin || ''}
              onChange={(e) => this.setState({ form: { ...form, linkedin: e.target.value } })}
              onBlur={() => this.onChange()}
            />
          </InputGroup>
          {errors.linkedin && <FormFeedback>{errors.linkedin}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <SocialLinkAddOn icon="instagram" />
            <Input
              placeholder={t('Instagram username')}
              value={form.instagram || ''}
              onChange={(e) => this.setState({ form: { ...form, instagram: e.target.value } })}
              onBlur={() => this.onChange()}
            />
          </InputGroup>
        </FormGroup>
      </div>
    );
  }
}

export default compose(
  withLocalization('socialLinks')
)(SocialLinksForm) as React.SFC<ManualProps>;
