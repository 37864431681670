import * as React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withLocalization, LProps } from '../utils/wrappers';
import { compose } from 'redux';

interface ManualProps {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
}

const ConfirmDeleteModal = ({
  isOpen, t, confirm, cancel
}: LProps<ManualProps>) => (
  <Modal
    isOpen={isOpen}
  >
    <ModalBody>{t('By confirming below you will permanently delete this content.')}</ModalBody>
    <ModalFooter>
      <Button onClick={confirm}>{t('Confirm')}</Button>
      <Button outline={true} color="danger" onClick={cancel}>{t('Cancel')}</Button>
    </ModalFooter>
  </Modal>
);

export default compose(
  withLocalization('confirmDelete')
)(ConfirmDeleteModal) as React.SFC<ManualProps>;
