import { trim } from 'lodash';
import { I18next } from './i18nextShim';

export const orJoin = (strings: string[], i18n: I18next) => {
  const t = i18n.getFixedT(i18n.language, 'common');
  if (strings.length === 0) return '';
  if (strings.length === 1) return strings[0];
  if (strings.length === 2) return strings.join(` ${t('or')} `);
  return `${strings.slice(0, strings.length - 2).join(', ')} ${t('or')} ${strings[strings.length - 1]}`;
};

const urlRegExpS = 'https?:[-a-zA-Z0-9@%_\\+.~#?&//=]{2,256}\\.[a-z]{2,4}\\b(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?(\\?[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?';
const urlRegExpSearch = new RegExp(urlRegExpS, 'gi');
const urlRegExp = new RegExp(`^${urlRegExpS}$`, 'i');

const localURLRegExpS = 'https?:\\/\\/(localhost|127\\.0\\.0\\.1|nginx)(:\\d{2,4})?\\b(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?';
const localURLRegExpSearch = new RegExp(localURLRegExpS, 'gi');
const localURLRegExp = new RegExp(`^${localURLRegExpS}$`, 'gi');

export const hasURL = (s: string) => urlRegExpSearch.test(s) || localURLRegExpSearch.test(s);
export const isURL = (s: string) => urlRegExp.test(s) || localURLRegExp.test(s);

export const getURLs = (text: string) => {
  const externalMatches = text.match(urlRegExpSearch) || [];
  const localMatches = text.match(localURLRegExpSearch) || [];
  const matches = externalMatches.concat(localMatches);
  return matches.map((url) => trim(url));
};
