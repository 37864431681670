import * as React from 'react';
import cx from 'classnames';

interface Props {
  name: string;
  value: string;
  children?: React.ReactNode;
  placeholder?: string;
  checked: boolean;
  onChange: (value: string) => void;
  error?: string;
  containerStyle?: React.CSSProperties;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const Radio = ({
  name,
  value,
  children,
  checked,
  error,
  onChange,
  placeholder = '',
  containerStyle,
  inputProps = {}
}: Props) => (
  <label className="cc__label" style={containerStyle}>
    <input
      type={'radio'}
      name={name}
      value={value}
      aria-checked={checked || false}
      checked={checked || false}
      onChange={(event) => event.target.checked && onChange(event.target.value)}
      className={cx('cc__radio__input')}
      placeholder={placeholder}
      {...inputProps}
    />&nbsp;
    {children}
    {error && <div className="cc__input__error">{error}</div>}
  </label>
);

export default Radio;
