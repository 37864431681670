import * as React from 'react';
import cx from 'classnames';
import NavBar from './NavBar';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { connect } from 'react-redux';
import { LProps, withLocalization } from '../utils/wrappers';
import { compose } from 'redux';
import { Competition, UserRead } from '../userApi';
import { ErrorUnexpected, ErrorAuth, ErrorUniversityMembership, ErrorCompetitionHasntStarted } from './Errors';
import ErrorCompetitionVerification from './ErrorCompetitionVerification';
import ErrorUserJoin from './ErrorUserJoin';
import { TranslationFunction } from 'i18next';
import logger from '../utils/logger';
import { trackEvent } from '../utils/googleAnalytics';
import { Alert } from 'reactstrap';

enum ErrorNotification {
  Unexpected,
  Auth,
  UniversityVerification,
  CompetitionVerification,
  CompetitionHasntStarted,
  UserHasntJoin
}

interface PageErrorProps {
  errorNotification: ErrorNotification;
  user: UserRead;
  t: TranslationFunction;
  competition?: Competition
} 

const PageError = ({ errorNotification, user, t, competition }: PageErrorProps) => {
  switch (errorNotification) {
    case ErrorNotification.Unexpected:
      return <ErrorUnexpected t={t} user={user} />;
    case ErrorNotification.Auth:
      return <ErrorAuth t={t} user={user} />;
    case ErrorNotification.UniversityVerification:
      return <ErrorUniversityMembership t={t} user={user} />;
    case ErrorNotification.UserHasntJoin:
        return <ErrorUserJoin />;
    case ErrorNotification.CompetitionVerification:
      return <ErrorCompetitionVerification />;
    case ErrorNotification.CompetitionHasntStarted:
      return <ErrorCompetitionHasntStarted competition={competition} t={t} />;
    default:
      logger.error(`Received unexpected app error: ${errorNotification}.`);
      return <div />;
  }
};

export const PageContent = ({ className = '', children }: { className?: string, children: React.ReactNode }) => (
  <div className={cx('cc__page', { [className]: true })}>
    <NavBar />
    <div className="cc__page__content" id="cc__page__content">
      {children}
    </div>
  </div>
);

export const PageContentWithBanner = ({
  className = '', children, bannerURL, alt
}: { bannerURL: string, alt: string, className?: string, children: React.ReactNode }) => (
  <div className={cx('cc__page cc__page__with_banner', { [className]: true })}>
    <NavBar />
    <div className="cc__banner">
      <img src={bannerURL} alt={alt} />
    </div>
    <div className="cc__page__content" id="cc__page__content">
      {children}
    </div>
  </div>
);

export interface PageProps {
  requiresUniversity?: boolean;
  requiresCompetitionStart?: boolean;
  requiresCompetitionVerification?: boolean;
  authenticated?: boolean;
  component: React.ComponentClass | React.SFC;
  raw?: boolean;
  isGrey?: boolean;
  hasBanner?: boolean;
}

interface Props extends PageProps, PageErrorProps {
  hasCompetitionEnded: boolean;
  competition: Competition,
  competitionDescription: string;
}

interface PageState {
  hasError: boolean;
}

class Page extends React.Component<LProps<Props>, PageState> {
  public state = { hasError: false };

  public static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    logger.error(error, info);
    trackEvent('app', 'unhandledError', 'pageDidCatch');
  }

  public render() {
    const {
      component, errorNotification, t, user,
      hasCompetitionEnded, isGrey, raw, hasBanner = false,
      competitionDescription
    } = this.props;
    if (errorNotification) {
      return (
        <PageContent className={cx({ 'cc__grey_bg': isGrey, 'cc__page_content--banner': hasBanner })}>
          <PageError t={t} user={user} competition={this.props.competition} errorNotification={errorNotification} />
        </PageContent>
      );
    } else if (this.state.hasError) {
      return (
        <PageContent className={cx({ cc__grey_bg: isGrey })}>
          <ErrorUnexpected t={t} user={user} />
        </PageContent>
      );
    } else if (raw) {
      return React.createElement(component);
    }
    return (
      <PageContent
        className={cx({ cc__grey_bg: isGrey })}
      >
        {hasCompetitionEnded &&
          <Alert color="info">{`${competitionDescription} ` + t('has ended and winners will be announced by May 6th. Thank you for your participation!')}</Alert>
        }
        {React.createElement(component)}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<PageProps>): Partial<Props> => {
  let errorNotification: ErrorNotification;
  if (Boolean(state.error)) {
    errorNotification = ErrorNotification.Unexpected;
  } else if (ownProps.authenticated && !selectors.isLoggedIn(state)) {
    errorNotification = ErrorNotification.Auth;
  } else if (ownProps.requiresCompetitionVerification && !selectors.isCompetitionUser(state)) {
    errorNotification = ErrorNotification.UserHasntJoin;
  } else if (ownProps.requiresCompetitionVerification && !selectors.isVerifiedCompetitionUser(state)) {
    errorNotification = selectors.isLoggedIn(state) ? ErrorNotification.CompetitionVerification : ErrorNotification.Auth;
  } else if (ownProps.requiresCompetitionStart && !selectors.hasCompetitionStarted(state) && !selectors.isUniversitySuperadmin(state)) {
    errorNotification = ErrorNotification.CompetitionHasntStarted;
  } else if (ownProps.requiresUniversity && !selectors.userUniversity(state)) {
    errorNotification = selectors.isLoggedIn(state) ? ErrorNotification.UniversityVerification : ErrorNotification.Auth;
  }
  return {
    competition: state.competition,
    errorNotification,
    user: state.user,
    hasCompetitionEnded: selectors.hasCompetitionEnded(state),
    competitionDescription: selectors.competitionDescription(state)
  };
};

export default compose(
  withLocalization('dashboard'),
  connect(mapStateToProps)
)(Page) as React.SFC<PageProps>;
