import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import up from 'updeep';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { isEmpty, trim } from 'lodash';
import { Table, Button, FormText, Input, FormGroup, FormFeedback } from 'reactstrap';
import { State, Modals } from '../../redux/state';
import { LProps, withLocalization } from '../../utils/wrappers';
import actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { UserEmail, UserEmails, UserRead, ValidationErrors, removeVEForField } from '../../userApi';
import { UserEmailErrors, makeUserEmailErrors, userEmailVEMessages } from '../../utils/forms';
import EntityEmailDomains from '../../shared/EntityEmailDomains';
import { Patch } from '../../redux/actions/all';
import LinkButton from '../../shared/LinkButton';

interface Props {
  userEmails: UserEmails;
  user: UserRead;
  validationErrors: ValidationErrors;
  onlyVerifiedCompetitionUserEmail: UserEmail;
  getAccountVerification: (userEmail: UserEmail) => void;
  deleteUserEmail: (userEmail: UserEmail) => void;
  makePrimaryUserEmail: (userEmail: UserEmail) => void;
  postUserEmail: (email: string) => void;
  openEntityEmailDomainModal: () => void;
  patch: Patch;
}

const canDelete = (user: UserRead, userEmail: UserEmail, onlyVerifiedCompetitionUserEmail: UserEmail) => {
  // Primary email.
  if (userEmail.email === user.email) return false;
  // Multiple email addresses have been verified for competition.
  if (!onlyVerifiedCompetitionUserEmail) return true;
  return onlyVerifiedCompetitionUserEmail.id !== userEmail.id;
};

const canResendVerification = (userEmail: UserEmail) =>
  moment(userEmail.verificationSentAt).add(moment.duration(5, 'minutes')).isBefore(moment());

interface UserEmailState {
  form: { email: string };
  errors: Partial<UserEmailErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: UserEmailState = {
  form: { email: '' },
  errors: {},
  dirty: {},
  submitted: false
};

const newUserEmailSave = (nextProps: Props, props: Props) =>
  nextProps.userEmails.length === props.userEmails.length + 1;

class UserEmailForm extends React.Component<LProps<Props>, UserEmailState> {
  public state = initialState;

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { validationErrors, t } = this.props;
    if (nextProps.validationErrors !== validationErrors) {
      const { dirty, submitted, form } = this.state;
      const errors = makeUserEmailErrors({ dirty, submitted, ...form }, nextProps.validationErrors, t);
      this.setState({ errors });
    }
    if (newUserEmailSave(nextProps, this.props)) {
      this.setState(initialState);
    }
  }

  private update(update: Partial<UserEmailState>, validate: boolean = false) {
    const { validationErrors, t } = this.props;
    const { dirty, submitted, form } = this.state;
    if (validate) {
      const errors = makeUserEmailErrors({ dirty, submitted, ...form }, validationErrors, t);
      update.errors = errors;
    }
    this.setState(update as UserEmailState);
  }

  private markDirty(field: string) {
    const { submitted, form } = this.state;
    const dirty = { ...this.state.dirty, [field]: true };
    const { validationErrors, t, patch } = this.props;
    let ve = validationErrors;
    if (userEmailVEMessages[field]) {
      ve = removeVEForField(field, validationErrors, userEmailVEMessages);
      patch({ userEmailVE: up.constant(ve) });
    }

    const update: Partial<UserEmailState> = { dirty };
    const errors = makeUserEmailErrors({ dirty, submitted, ...form }, ve, t);
    update.errors = errors;
    this.setState({ errors, dirty });
  }

  private submit() {
    const { postUserEmail, validationErrors, t } = this.props;
    const submitted = true;
    const { dirty, form } = this.state;
    const errors = makeUserEmailErrors({ dirty, submitted, ...form }, validationErrors, t);
    if (isEmpty(errors)) {
      postUserEmail(form.email);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors } = this.state;
    const {
      t, getAccountVerification, userEmails,
      deleteUserEmail, makePrimaryUserEmail,
      user, onlyVerifiedCompetitionUserEmail,
      openEntityEmailDomainModal
    } = this.props;
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th>{t('Email')}</th>
              <th>{t('Verified')}</th>
              <th>{t('Primary')}</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {userEmails.map((userEmail) => (
              <tr key={userEmail.id}>
                <td>{userEmail.email}</td>
                <td>
                  {userEmail.verified &&
                    <Button color="success" aria-label={t('Verified')} disabled={true}><i className="fa fa-check-circle" /></Button>
                  }
                  {!userEmail.verified &&
                    <Button
                      title={t('Resend verification')}
                      disabled={!canResendVerification(userEmail)}
                      color="warning"
                      onClick={() => getAccountVerification(userEmail)}
                    ><i className="fa fa-retweet" /></Button>
                  }
                </td>
                <td>
                  {
                    userEmail.email === user.email ?
                      (<Button color="success" disabled={true}><i className="fa fa-check-circle" /></Button>) :
                      (<Button
                        outline={true}
                        color="warning"
                        title={t('Make primary email for communication and login.')}
                        onClick={() => makePrimaryUserEmail(userEmail)}
                      ><i className="fa fa-check-circle-o" /></Button>)
                  }
                </td>
                <td>
                  <Button
                    color="danger"
                    disabled={!canDelete(user, userEmail, onlyVerifiedCompetitionUserEmail)}
                    onClick={() => deleteUserEmail(userEmail)}
                  ><i className="fa fa-trash" /></Button>
                </td>
              </tr>
            ))}
            <tr>
              <td>
              <FormGroup id="user_email_form__email">
                <Input
                  aria-label={t('Add email')}
                  invalid={Boolean(errors.email)}
                  value={form.email}
                  type={'email'}
                  onChange={(e) => this.update({ form: { ...form, email: trim(e.target.value || '').toLowerCase() } })}
                  onBlur={() => this.markDirty('email')}
                />
                {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                <FormText><LinkButton onClick={openEntityEmailDomainModal} text={t('View permitted university domains')} /></FormText>
              </FormGroup>
              </td>
              <td></td>
              <td></td>
              <td>
                <Button
                  color="primary"
                  aria-label={t('Save')}
                  onClick={() => this.submit()}
                ><i className="fa fa-floppy-o" /></Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <EntityEmailDomains />
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: {}): Partial<Props> => ({
  userEmails: state.userEmails,
  onlyVerifiedCompetitionUserEmail: selectors.onlyVerifiedCompetitionUserEmail(state),
  user: state.user,
  validationErrors: state.userEmailVE
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  getAccountVerification: actions.getAccountVerification,
  deleteUserEmail: actions.deleteUserEmail,
  makePrimaryUserEmail: actions.makePrimaryUserEmail,
  postUserEmail: actions.postUserEmail,
  openEntityEmailDomainModal: () => actions.setModal(Modals.EntityEmailDomains),
  patch: actions.patch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('userEmail')
)(UserEmailForm) as React.ComponentClass;
