import * as React from 'react';
import { LProps, withLocalization } from '../utils/wrappers';
import RouteLink from './RouteLink';
import { Routes } from '../utils/routerHelper';
import { isProduction } from '../utils/environment';
import * as moment from 'moment';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { State } from '../redux/state';
import { connect } from 'react-redux';
import { Competition } from '../userApi';

const Separator = () => (<span>&nbsp;&middot;&nbsp;</span>);

interface Props {
  competition: Competition;
 
}

const Footer = ({
  t,competition
}: LProps<Props>) => (
  <div id="cc__footer">
    <div>
      {competition && <><RouteLink route={Routes.About}>{t('About')}</RouteLink><Separator /></>}
      {!competition && <><RouteLink route={Routes.GlobalAbout}>{t('About')}</RouteLink><Separator /></>}
      {competition && <><RouteLink route={Routes.Blog}>{t('Blog')}</RouteLink><Separator /></>}
      {competition && <><RouteLink route={Routes.Calculator}>{t('Calculator')}</RouteLink><Separator /></>}
      {!isProduction &&
        <span>
          <a href="" target="__blank" rel="noopener noreferrer">{t('Data')}</a><Separator />
        </span>
      }
      {/* tslint:disable-next-line react-a11y-anchors */}
      <a href="https://sites.google.com/view/2019ccc/materials-for-download" target="_blank" rel="noopener noreferrer">{t('Spread the word')} </a>
      <Separator/>
      {/* tslint:disable-next-line react-a11y-anchors */}
      <a
        href="/assets/coolcampuschallenge2019_terms_and_conditions.pdf"
        target="__blank"
        rel="noopener noreferrer"
        download={true}
      > {t('Terms of use')}</a>
    </div>
    <div id="cc__footer__copyright">
      &copy; {moment().format('YYYY')} Regents of the University of California
    </div>
  </div>
);

const mapStateToProps = (state: State): Partial<Props> => ({  
  competition: state.competition
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<{}> => bindActionCreators({
  
}, dispatch);

export default compose(
  withLocalization('footer'),  
  connect(mapStateToProps, mapDispatchToProps)
)(Footer);
