import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError, validationErrorExists, CreateSessionParams, ValidationErrorMessages } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';




export interface JoinErrors {
  join: string;  
}
export const joinVEMessages: ValidationErrorMessages = {
  join: {
    [ValidationError.CodeEnum.ErrIllegalMembershipJoin]: 'This user cannot join this competition'
  }
};

export const makeJoinErrors = (validationErrors: ValidationError[], t: TranslationFunction): Partial<JoinErrors> => {
  let errorMessages: Partial<JoinErrors> = {};  

  if (!errorMessages.join && validationErrorExists(ValidationError.CodeEnum.ErrIllegalMembershipJoin, validationErrors)) {
    errorMessages.join = t(joinVEMessages.join[ValidationError.CodeEnum.ErrIllegalMembershipJoin]);
  }  
  return pickBy(errorMessages);
};
