import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError, validationErrorExists, CreateSessionParams, ValidationErrorMessages } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';
import { Form, validationReady } from './form';

export type LoginForm = CreateSessionParams & Form;

export interface LoginErrors {
  email: string;
  password: string;
}

const validation = {
  type: 'object',
  required: ['email', 'password'],
  properties: {
    email: { type: 'string', format: 'email', minLength: 5 },
    password: { type: 'string', minLength: 2 }
  }
};

export const loginVEMessages: ValidationErrorMessages = {
  email: {
    [ValidationError.CodeEnum.ErrEmailNotFound]: 'No user found with that email.'
  },
  password: {
    [ValidationError.CodeEnum.ErrPasswordIncorrect]: 'Password is incorrect.'
  }
};

export const makeLoginErrors = (loginForm: Partial<LoginForm>, validationErrors: ValidationError[], t: TranslationFunction): Partial<LoginErrors> => {
  let errorMessages: Partial<LoginErrors> = {};
  if (!validator.validate(validation, loginForm)) {
    const { errors } = validator;
    errorMessages = {
      email: makeErrorMessage(errors, '.email', t('Must be valid email.'), validationReady(loginForm as Form, 'email')),
      password: makeErrorMessage(errors, '.password', t('Must be at least two characters.'), validationReady(loginForm as Form, 'password'))
    };
  }

  if (!errorMessages.email && validationErrorExists(ValidationError.CodeEnum.ErrEmailNotFound, validationErrors)) {
    errorMessages.email = t(loginVEMessages.email[ValidationError.CodeEnum.ErrEmailNotFound]);
  }
  if (!errorMessages.password && validationErrorExists(ValidationError.CodeEnum.ErrPasswordIncorrect, validationErrors)) {
    errorMessages.password = t(loginVEMessages.password[ValidationError.CodeEnum.ErrPasswordIncorrect]);
  }
  return pickBy(errorMessages);
};
