import { captureException, configureScope, init } from '@sentry/browser';
import * as mapStackTrace from 'sourcemapped-stacktrace';
import { isEmpty } from 'lodash';
import environment, { isProduction, isStaging } from './environment';
import { State } from '../redux/state';

const reportToSentry = !environment.isDevelopment || environment.config.FORCE_REPORT_TO_SENTRY === 'true';
const silenceAlerts = window.location.search.search('silence') >= 0;

const sentryEnvironment = () => {
  switch (true) {
    case isProduction:
      return 'prd';
    case isStaging:
      return 'stg';
    default:
      return 'dev';
  }
};

if (reportToSentry) setupJSRemoteTrackError();
function setupJSRemoteTrackError() {
  init({
    dsn: environment.config.SENTRY_PUBLIC_DSN,
    environment: sentryEnvironment()
  });
}

export const getMappedStack = async (err: Error): Promise<string[]> => {
  return new Promise<string[]>((done) => {
    if (err.stack) {
      mapStackTrace.mapStackTrace(err.stack, done);
    } else {
      done([]);
    }
  });
};

interface Logger {
  log<T>(...args: T[]): void;
  debug<T>(...args: T[]): void;
  warn<T>(...args: T[]): void;
  info<T>(...args: T[]): void;
  error(arg: string | Error, ...data: any[]): void;
  configure(state: State): void;
}

const configure = (state: State) => {
  const { user } = state;
  if (user) {
    try {
      configureScope((scope) => {
        scope.setUser({ id: user.id.toString(), email: user.email });
      });
    } catch (err) {
      // tslint:disable no-console
      console.error(err);
    }
  }
};

async function error(arg: string | Error, ...data: any[]) {
  // tslint:disable no-console
  console.error(arg);

  let err: Error;
  if (arg instanceof String) {
    err = new Error(arg as string);
  } else {
    err = arg as Error;
  }

  let mappedStack = await getMappedStack(err);
  if (!mappedStack) {
    mappedStack = [];
  }

  if (reportToSentry) {
    try {
      if (data) {
        configureScope((scope) => {
          scope.setExtra('data', data);
        });
      }
      captureException(err);
    } catch (_err) {
      // tslint:disable-next-line
      console.error('Failed to notify Sentry.');
    }
  }

  if (environment.isDevelopment && !silenceAlerts) {
    const alertInfo = {
      mappedStack,
      message: err.message || arg,
      data
    };
    if (!isEmpty(alertInfo.message)) {
      alert(JSON.stringify(alertInfo));
    }
  }
}

const logger: Logger = {
  // tslint:disable no-console
  debug: console.debug,
  error,
  info: console.info,
  log: console.log,
  warn: console.warn,
  // tslint:enable no-console
  configure
};

export default logger;
