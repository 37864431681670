import * as showdown from 'showdown';
import * as xss from 'xss';
import 'react-mde/lib/styles/css/react-mde-all.css';
import ReactMde from 'react-mde';

// removes XSS scripts and adds _blank target to all links.
export const filterHTML = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const frag = document.createDocumentFragment();
  frag.appendChild(div);
  frag.querySelectorAll('a').forEach((a) => a.setAttribute('target', '_blank'));
  const filterXSS = new xss.FilterXSS({});
  return filterXSS.process(frag.firstElementChild.innerHTML);
};

const filterHTMLExt: showdown.ShowdownExtension = {
  type: 'output',
  filter: (html: string, _converter: showdown.Converter, _options: showdown.ConverterOptions) => {
    return filterHTML(html);
  }
};
showdown.extension('filterHTML', filterHTMLExt);

export { ReactMde };

export const converter = new showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: ['filterHTML']
});
