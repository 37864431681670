import * as React from 'react';
import cx from 'classnames';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { CompetitionEntityScore, CompetitionUserScore, Entity } from '../../userApi';
import ScoreboardParticipants from '../../shared/ScoreboardParticipants';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import ScoreboardTeams from '../../shared/ScoreboardTeams';
import { makeCompetitionUserScoresForEntityPath } from '../../utils/routerHelper';

interface ManualProps {
  entity: Entity;
}

interface Props extends ManualProps {
  topSubEntityScores: CompetitionEntityScore[];
  topUserScores: CompetitionUserScore[];
  competitionID: number;
}

enum ScoreboardView {
  Participants = 'participants',
  Teams = 'teams'
}

interface ScoreboardState {
  scoreboardView: ScoreboardView;
}

class Scoreboard extends React.Component<LProps<Props>, ScoreboardState> {
  public state = { scoreboardView: ScoreboardView.Participants };

  public render() {
    const { scoreboardView } = this.state;
    const { topSubEntityScores, topUserScores, t, entity, competitionID } = this.props;
    return (
      <div className="cc__scoreboard">
        <div className="cc__scoreboard__header">
          <div className={'cc__darktext'}>{t('Scoreboard')}</div>
          <div className="cc__scoreboard__header_tabs">
            <div
              role="button"
              className={cx('cc__scoreboard__header_tab', { active: scoreboardView === ScoreboardView.Participants })}
              onClick={() => this.setState({ scoreboardView: ScoreboardView.Participants })}
            >{t('Participants')}</div>
            {(topSubEntityScores && entity.type !== Entity.TypeEnum.Team) &&
              <div
                role="button"
                className={cx('cc__scoreboard__header_tab', { active: scoreboardView === ScoreboardView.Teams })}
                onClick={() => this.setState({ scoreboardView: ScoreboardView.Teams })}
              >{t('Teams')}</div>
            }
          </div>
        </div>
        {scoreboardView === ScoreboardView.Teams &&
          <ScoreboardTeams
            teamScores={topSubEntityScores}
          />
        }
        {scoreboardView === ScoreboardView.Participants &&
          <ScoreboardParticipants
            viewAll={makeCompetitionUserScoresForEntityPath(competitionID, entity.id, t)}
            userScores={topUserScores}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  topSubEntityScores: state.entityTopSubentityScores && state.entityTopSubentityScores[ownProps.entity.id],
  topUserScores: state.entityTopUserScores[ownProps.entity.id],
  competitionID: state.competition.id
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('entity')
)(Scoreboard) as React.ComponentClass<ManualProps>;
