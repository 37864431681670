import * as React from 'react';
import { Alert } from 'reactstrap';
import { StyleState } from '../utils/styles';
import { Competition, UserRead } from '../userApi';
import { TranslationFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { makeSimpleRoutePath, Routes } from '../utils/routerHelper';
import ExternalLink from './ExternalLink';
import moment = require('moment');

interface ErrorProps {
  t: TranslationFunction;
  user?: UserRead;
  competition?: Competition;
}

export const ErrorUnexpected = ({ t, user }: ErrorProps) => (
  <Alert
    color={StyleState.Danger}
  >
    <div>
      {t('We experienced an unexpected error. If the error persists, please let our staff know at')}&nbsp;
      <ExternalLink
        href={'mailto:contact@coolcampuschallenge.org'}
      >contact@coolcampuschallenge.org</ExternalLink>.
    </div>
  </Alert>
);

export const ErrorAuth = ({ t }: ErrorProps) => (
  <Alert
    color={StyleState.Danger}
  >
    <div>
      {t('Please')}&nbsp;
      <Link to={makeSimpleRoutePath(Routes.Login, t)}>{t('login')}</Link>&nbsp;
      {t('to view this page.')}
    </div>
  </Alert>
);

export const ErrorUniversityMembership = ({ t }: ErrorProps) => (
  <Alert
    color={StyleState.Warning}
  >
    <div>
      {t('You must have a verified university membership to view this page. Please')}&nbsp;
      <Link to={makeSimpleRoutePath(Routes.Email, t)}>{t('verify your email')}</Link>&nbsp;
      {t('to continue.')}
    </div>
  </Alert>
);

export const ErrorNoTeamsForUniversity = ({ t }: ErrorProps) => (
  <Alert
    color={StyleState.Warning}
  >
    <div>
      {t('No one has created a team for your campus yet.')}&nbsp;
      <Link to={makeSimpleRoutePath(Routes.TeamsNew, t)}>{t('Be the first!')}</Link>&nbsp;
    </div>
  </Alert>
);

export const ErrorCompetitionHasntStarted = ({ t, competition }: ErrorProps) => (
  <Alert
    color={StyleState.Warning}
  >
    
    <div>
      {t('Thank you for joining the ') + competition.description+ "." }
      <br/>
      {t("The competition officially opens soon") + t('. Please return to this site at ') +  moment(competition.startsAt).format('MMMM Do')   +t(' to log your actions and start earning points.')}
    </div>
  </Alert>
);
