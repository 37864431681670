import * as React from 'react';
import { compose } from 'redux';
import { withLocalization, LProps } from '../utils/wrappers';

const AgreeToTermsAndConditions = ({ t }: LProps<{}>) => (
  <span>
    {t('I agree to the ')}
    <a target="_blank" rel="no opener" href="/assets/coolcampuschallenge2019_terms_and_conditions.pdf">{t('terms and conditions')}</a>.
  </span>
);

export default compose(
  withLocalization('common')
)(AgreeToTermsAndConditions);
