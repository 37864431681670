import { pickBy } from 'lodash';
import { TranslationFunction } from 'i18next';
import { Entity, ValidationError } from '../../userApi';
import { Form, validationReady } from './form';
import { validator, makeErrorMessage } from '../validations';
import { SocialLinkErrors, validateSocialLinks } from './socialLinks';

export interface EntityForm extends Entity, Form {}

export interface EntityErrors {
  name: string;
  abbreviation: string;
  descriptionMD: string;
  socialLinks: SocialLinkErrors;
  // This is manually set during the entityForms saveEntity actions.
  photo: string;
}

const validation = {
  type: 'object',
  required: ['name', 'descriptionMD'],
  properties: {
    name: { type: 'string', minLength: 2, maxLength: 100 },
    abbreviation: { type: 'string', minLength: 2, maxLength: 100 },
    descriptionMD: { type: 'string', minLength: 10, maxLength: 5000 }
  }
};

export const makeEntityErrors = (form: Partial<EntityForm>, _validationErrors: ValidationError[], t: TranslationFunction): Partial<EntityErrors> => {
  const errorMessages: Partial<EntityErrors> = {};
  if (!validator.validate(validation, form)) {
    const { errors } = validator;
    errorMessages.name = makeErrorMessage(errors, '.name', t('Must be 2-100 characters.'), validationReady(form as Form, 'name'));
    errorMessages.abbreviation = makeErrorMessage(errors, '.name', t('Must be 2-20 characters.'), validationReady(form as Form, 'name'));
    errorMessages.descriptionMD = makeErrorMessage(errors, '.descriptionMD', t('Must be 10-5000 characters.'), validationReady(form as Form, 'descriptionMD'));
  }
  if (form.socialLinks) {
    errorMessages.socialLinks = validateSocialLinks(form.socialLinks, t);
  }
  return pickBy(errorMessages);
};
