import * as React from 'react';
import up from 'updeep';
import { FormGroup, Input, FormFeedback, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { TranslationFunction } from 'i18next';
import { State } from '../redux/state';
import actions from '../redux/actions';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { withLocalization } from '../utils/wrappers';
import { connect } from 'react-redux';
import MarkdownEditor from '../shared/MarkdownEditor';
import ImageUploader from './ImageUploader/ImageUploader';
import { RouterProps, withRouter } from 'react-router';
import { makeBlogEntryPath } from '../utils/routerHelper';
import { Competition, BlogEntry } from '../userApi';
import { BlogEntryForm, BlogEntryErrors, makeBlogEntryErrors } from '../utils/forms';

interface ManualProps {
  blogEntry: Partial<BlogEntry>;
}

interface Props extends ManualProps {
  t: TranslationFunction;
  competition: Competition;
  postedImage: string;
  save: (form: BlogEntryForm, photoInputID: string) => void;
  clearPostedImage: (inputID: string) => void;
}

const photoInputID = 'blog_entry__photo__input';

interface BlogEntryFormState {
  form: Partial<BlogEntry>;
  errors: Partial<BlogEntryErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: BlogEntryFormState = {
  form: {
    id: 0, photo: '', title: '', contentMD: ''
  },
  errors: {},
  submitted: false,
  dirty: {}
};

class BlogEntryFormComponent extends React.Component<Props & RouterProps, BlogEntryFormState> {
  public state = initialState;

  public componentDidMount() {
    // entityID only required for new blog entries.
    const { blogEntry } = this.props;
    const { form } = this.state;
    this.setState({
      form: up(blogEntry, form)
    });
  }

  public componentWillReceiveProps(nextProps: Props & RouterProps) {
    const { form } = this.state;
    const { clearPostedImage } = nextProps;
    if (nextProps.postedImage && form.photo !== nextProps.postedImage) {
      this.setState({ form: up({ photo: nextProps.postedImage }, form) });
      clearPostedImage(photoInputID);
    }
  }

  private cancel() {
    const { form } = this.state;
    const { blogEntry, history, t, clearPostedImage } = this.props;
    clearPostedImage(photoInputID);
    this.setState({
      ...initialState,
      form: up(blogEntry, form)
    });
    if (blogEntry.id !== 0) {
      history.push(makeBlogEntryPath(form.id, t));
    }
  }

  private update(update: Partial<BlogEntryFormState>, validate: boolean = false) {
    const { t } = this.props;
    if (validate) {
      const { form, dirty, submitted } = this.state;
      const errors = makeBlogEntryErrors({ ...form, dirty, submitted }, [], t);
      update.errors = errors;
    }
    this.setState(update as BlogEntryFormState);
  }

  private markDirty(field: string) {
    const { t } = this.props;
    const { form, submitted } = this.state;
    const dirty = { ...this.state.dirty, [field]: true };
    const update: Partial<BlogEntryFormState> = { dirty };
    const errors = makeBlogEntryErrors({ ...form, submitted, dirty }, [], t);
    update.errors = errors;
    this.setState({ errors });
  }

  private submit() {
    const { save, t } = this.props;
    const submitted = true;
    const { form, dirty } = this.state;
    const errors = makeBlogEntryErrors({ ...form, dirty, submitted }, [], t);
    if (isEmpty(errors)) {
      save({ dirty, submitted, ...form } as BlogEntryForm, photoInputID);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors } = this.state;
    const {
      t
    } = this.props;
    return (
      <div id="blogEntryForm">
        <FormGroup>
          <ImageUploader
            id={photoInputID}
            readyPhotoClassName={'cc__html_page__photo'}
            currentImage={form.photo}
            onDelete={() => this.update({ form: { ...form, photo: '' } })}
            aspectRatio={t('1:1 to 2:1')}
          />
        </FormGroup>
        <FormGroup >
          <Input
            aria-label={t('Title')}
            invalid={Boolean(errors.title)}
            id="new_team__name__input"
            type="text"
            name="title"
            onBlur={() => this.markDirty('title')}
            placeholder={t('Title')}
            onChange={(e) => this.update({ form: { ...form, title: e.target.value } })}
            value={form.title || ''}
          />
          {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <MarkdownEditor
            textAreaProps={{
              'aria-label': 'Markdown content',
              'onBlur': () => this.markDirty('contentMD')
            }}
            value={form.contentMD}
            onChange={(markdown) => this.update({ form: { ...form, contentMD: markdown } })}
          />
          {errors.contentMD && <FormFeedback>{errors.contentMD}</FormFeedback>}
        </FormGroup>
        <div className="cc__space_around cc__buttons">
          <Button
            onClick={() => this.submit()}
          >{t('Save')}</Button>
          <Button
            outline={true}
            color="danger"
            onClick={() => this.cancel()}
          >{t('Cancel')}</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  competition: state.competition,
  postedImage: state.postedImages[photoInputID]
});
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: {}): Partial<Props> => bindActionCreators({
  save: actions.saveBlogEntry,
  clearPostedImage: actions.clearPostedImage
}, dispatch);

export default compose(
  withLocalization('entityForm'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BlogEntryFormComponent) as React.SFC<ManualProps>;
