import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';
import { Form, validationReady } from './form';

export interface PasswordResetForm extends Form {
  password: string;
  confirmPassword: string;
}

export interface PasswordResetErrors {
  password: string;
  confirmPassword: string;
}

const validation = {
  type: 'object',
  required: ['password', 'confirmPassword'],
  properties: {
    password: { type: 'string', minLength: 8, maxLength: 64, password: true },
    confirmPassword: { type: 'string' }
  }
};

export const makePasswordResetErrors = (passwordResetForm: Partial<PasswordResetForm>, _validationErrors: ValidationError[], t: TranslationFunction): Partial<PasswordResetErrors> => {
  let errorMessages: Partial<PasswordResetErrors> = {};
  validator.validate(validation, passwordResetForm);

  const { errors } = validator;
  let passwordError: string;
  if (errors !== null) {
    passwordError = makeErrorMessage(
      errors, '.password',
      t('8-64 characters with at least 1 number, 1 letter, and 1 special character (!@#$%^&*(),.?\'{}|<>).'),
      validationReady(passwordResetForm as Form, 'password')
    );
  }
  let confirmPasswordError = '';
  if (!passwordError && passwordResetForm.password !== passwordResetForm.confirmPassword) {
    confirmPasswordError = t('Must match password.');
  }
  errorMessages = {
    password: passwordError,
    confirmPassword: confirmPasswordError
  };
  return pickBy(errorMessages);
};
