import * as React from 'react';
import {
  ListGroup, ListGroupItem
} from 'reactstrap';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { compose } from 'redux';
import { UserRead } from '../userApi';
import { displayFullName } from '../utils/userApiHelper';
import { TranslationFunction } from 'i18next';
import { connect } from 'react-redux';

interface ManualProps {
  id: string;
  onSelect: (user: UserRead) => void;
  t: TranslationFunction;
  close: () => void;
}

interface Props extends ManualProps {
  results: UserRead[];
}

const SearchResults = ({
  onSelect, results, t, close
}: Props) => (
  <ListGroup className={'cc__search_results'}>
    {results.length === 0 &&
      <ListGroupItem onClick={close}>{t('No results to display.')}</ListGroupItem>
    }
    {results.length > 0 && results.map((result) => (
      <ListGroupItem
        key={result.id}
        style={{ cursor: 'pointer' }}
        onClick={() => onSelect(result)}
      >{displayFullName(result)}</ListGroupItem>
    ))}
  </ListGroup>
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  results: selectors.searchResults(state, ownProps)
});

export default compose(
  connect(mapStateToProps)
)(SearchResults) as React.ComponentClass<ManualProps>;
