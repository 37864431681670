import { pick, last } from 'lodash';
import up from 'updeep';
import { patch } from './patch';
import { CreateUserParams, CreateSessionParams, someMessageForValidationErrors } from '../../userApi';
import { State } from '../state';
import { Dispatch } from 'redux';
import { LoginForm, signupVEMessages, loginVEMessages } from '../../utils/forms';
import { handleUserApiError, handleUnexpectedError } from './handleUnexpectedError';
import logger from '../../utils/logger';
import * as selectors from '../selectors';
import { getValidationErrors, someMatchingEmailDomain } from '../../utils/userApiHelper';
import { ExtraArguments } from '../extraArguments';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { intercom } from '../../utils/intercom';
import { trackEvent } from '../../utils/googleAnalytics';
import { setAnalyticsAttributes } from './initialize';

const updateIntercom = (state: State) => {
  const { user, competitionUser } = state;
  const university = selectors.userUniversity(state);
  intercom('update', {
    user, university, competitionUser
  });
};

export const login = (loginForm: CreateSessionParams) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  dispatch(patch({ spinner: true }));
  try {
    const params = pick(loginForm, ['email', 'password']) as LoginForm;
    const state = getState();    
    params.competitionName = state.initialQuery.competitionName    
    const api = selectors.userApi(state);
    const dashboard = await api.postSession(params);
    dispatch(patch({
      ...dashboard,
      spinner: false
    }));
    trackEvent('session', 'create');
    const loggedInState = getState();
    updateIntercom(loggedInState);
    setAnalyticsAttributes(loggedInState);
    logger.configure(loggedInState);
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors && someMessageForValidationErrors(validationErrors, loginVEMessages)) {
      dispatch(patch({
        loginVE: up.constant(validationErrors),
        spinner: false
      }));
      return;
    }
    handleUserApiError(err)(dispatch, getState);
    trackEvent('session', 'unhandledError', 'create');
  }
};

export const signup = (params: CreateUserParams) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'auth');
  const state = getState();
  const { initialQuery, competition } = state;
  const api = selectors.userApi(state);

  dispatch(patch({ spinner: true }));
  try {
    params.competitionName = state.initialQuery.competitionName;
    if (initialQuery.invitationToken) {
      params.invitationToken = initialQuery.invitationToken;
    }

    if (!someMatchingEmailDomain(params.user.email, competition.permittedDomains)) {
      dispatch(patch({ spinner: false }));
      toast({
        message: t('You must sign up with a permitted email domain.'),
        style: StyleState.Danger
      })(dispatch, getState);
      return;
    }

    const dashboard = await api.postUsers(params);
    dispatch(patch({
      ...dashboard,
      spinner: false
    }));
    const signedupState = getState();
    updateIntercom(signedupState);
    trackEvent('account', 'create');
    logger.configure(signedupState);
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors && someMessageForValidationErrors(validationErrors, signupVEMessages)) {
      dispatch(patch({
        signupVE: up.constant(validationErrors),
        spinner: false
      }));
      return;
    }
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('account', 'unhandledError', 'create');
  }
};

export const logout = () => async (_dispatch: Dispatch, getState: () => State) => {
  const api = selectors.userApi(getState());
  try {
    await api.deleteSession();
    intercom('shutdown', {});
    trackEvent('session', 'delete');
  } catch (err) {
    logger.error(err);
    trackEvent('session', 'unhandledError', 'delete');
  }
  const state = getState();
  localStorage.clear();
  if (window.location.pathname === `/${state.initialQuery.competitionName}/login`) {
    window.location.reload()
  } else {    
    window.location.pathname = `/${state.initialQuery.competitionName}/login`;
  }
};

export const join = () => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const api = selectors.userApi(state);
  const competitionName = state.initialQuery.competitionName    
  try{
    const dashboard = await api.postJoin({competitionName})
    dispatch(patch({
      ...dashboard,
      spinner: false
    }));
    trackEvent('session', 'join');
    const loggedInState = getState();
    updateIntercom(loggedInState);
    setAnalyticsAttributes(loggedInState);
    logger.configure(loggedInState);
  }catch (err){
    const validationErrors = await getValidationErrors(err);
    if (validationErrors && someMessageForValidationErrors(validationErrors, loginVEMessages)) {
      dispatch(patch({
        joinVE: up.constant(validationErrors),
        spinner: false
      }));
      return;
    }
    handleUserApiError(err)(dispatch, getState);
    trackEvent('session', 'unhandledError', 'join');
  }
}