import * as React from 'react';
import AuthButtons from './AuthButtons';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouterProps } from 'react-router';
import ReactPageScroller from 'react-page-scroller';
import { withLocalization, LProps } from '../../utils/wrappers';
import StatIcon, { StatType } from './StatIcon';
import Indicators from '../../shared/Indicator';
import { State } from '../../redux/state';
import a, { bindActionCreators } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { Routes, makePathnames } from '../../utils/routerHelper';
import RouteLink from '../../shared/RouteLink';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
import Overlay from '../../shared/Overlay';
import { TranslationFunction } from 'i18next';
import { CompetitionScore } from '../../userApi';
import { savingsCO2LbsToCars } from '../../utils/userApiHelper';
import Join from './Join';

interface PageScrollerComponentProps {
  backgroundImage: string;
  backgroundSet?: string;
  children?: React.ReactNode;
  className?: string;
  alt: string  
}

const PageScrollerComponent = ({
  backgroundImage,
  backgroundSet,
  className,
  children,
  alt
}: PageScrollerComponentProps) => (
  <div className={'page-scroller-component ' + className}>
    <div className="page-scroller-compnent__content">
      <img
        alt={alt}
        className="cc__home_img"
        src={backgroundImage}
        srcSet={backgroundSet}
      />
      {children}
    </div>
  </div>
);

interface ManualProps {
  children?: React.ReactNode;
  readOnly?: boolean
}

interface Props extends ManualProps {
  landingPagePosition: number;
  setLandingPagePosition: a.SetLandingPagePosition;
  canViewCompetitionContent: boolean;
  competitionScore: CompetitionScore;
  hasJoined: boolean;
  isLoggedIn: boolean;
}

const HomeWeb = ({
  t, landingPagePosition, setLandingPagePosition, history, competitionScore, hasJoined, isLoggedIn, readOnly
}: LProps<Props> & RouterProps) => {
  const paths = makePathnames(t);
  const path = history.location.pathname.split("/")  
  return (
  <div id="cc__home" className={'cc__columns'}>
    <div
      id="cc__home__fixed_sidebar"
      className={'cc_column'}
    >
      <div id="cc__home__fixed_sidebar__content">
        <Link to="/">
        <img src="/assets/logo/white.png" alt={t('Cool Campus Challenge logo')} />
        </Link>
        <AuthButtons readOnly={readOnly}/>        
        <div id="cc__home__links">
          {!readOnly ?
          <RouteLink route={Routes.About}>{t('About')}</RouteLink>
            :
          <RouteLink route={Routes.GlobalAbout}>{t('About')}</RouteLink>
          }
        </div>
      </div>
    </div>
    <div
      className="cc__home__slides_mobile"
    >
      {sliders(t, competitionScore)}
    </div>
    <div
      id="cc__home__carousel"
      className={'cc_column'}
    >
      <div className="cc__home__scroller_indicator">
        <Indicators
          vertical={true}
          length={5}
          active={landingPagePosition}
        />
      </div>
      <ReactPageScroller
      animationTimer={500}
        containerWidth={'100%'}
        containerHeight={'100%'}
        pageOnChange={(oneBasedPosition) => setLandingPagePosition(oneBasedPosition - 1)}
      >
        {sliders(t, competitionScore)}
      </ReactPageScroller>
    </div>
    {path.length >= 2 && path[2] !='' && !readOnly &&
    <div className="cc__home__form">
      <Overlay>
        <Switch>
          <Route path={paths[Routes.Login]} component={Login} exact={true} />
          <Route path={paths[Routes.Signup]} component={Signup} exact={true} />          
          <Route path={paths[Routes.ForgotPassword]} component={ForgotPassword} exact={true} />
          <Route path={paths[Routes.PasswordReset]} component={PasswordReset} exact={true} />
          {!hasJoined && isLoggedIn &&  <Route component={Join} exact={true} />}
        </Switch>
      </Overlay>
    </div>}
  </div>
)};

export const scrollToLoginForm = () => {
  setTimeout(() => {
    const cmpnt0 = document.querySelector('.cc__home__page_scroller_cmpnt0');
    if (cmpnt0) {
      cmpnt0.scrollIntoView({behavior: 'smooth'});
    }
  }, 800);
};

function sliders(t: TranslationFunction, competitionScore: CompetitionScore) {
  return [
    <PageScrollerComponent
      key="0"
      className="cc__page-form cc__home__page_scroller_cmpnt0"
      alt={t('UCSC collage')}
      backgroundImage="/assets/landing/web/ucsc-collage.png"
      backgroundSet="/assets/landing/web/ucsc-collage@2x.png 2x"
    />,
    <PageScrollerComponent
      key="1"
      alt={t('UCSC forest')}
      backgroundImage="/assets/landing/web/ucsc-day-forest.png"
      backgroundSet="/assets/landing/web/ucsc-day-forest@2x.png 2x"

    >
      <div className="cc__stat_icon__container-outer">
        <div className="cc__stat_icon__container-row">
          <StatIcon
            value={competitionScore.savingsCO2Lbs}
            statType={StatType.Carbon}
            statLine={t('lbs CO<sub>2</sub> saved')}
          />
          <StatIcon
            value={savingsCO2LbsToCars(competitionScore.savingsCO2Lbs)}
            statType={StatType.Cars}
            statLine={t('cars removed (equiv)')}
          />
        </div>
        <div className="cc__stat_icon__container-row">
          <StatIcon
            value={competitionScore.participants}
            statType={StatType.Participants}
            statLine={t('participants')}
          />
          <StatIcon
            value={competitionScore.pledgesCompleted}
            statType={StatType.Pledges}
            statLine={t('actions completed')}
          />
        </div>
      </div>
    </PageScrollerComponent>,
    <PageScrollerComponent
      key="3"
      alt={t('UCSC courtyard')}
      backgroundImage="/assets/landing/web/ucsc-day-courtyard.png"
      backgroundSet="/assets/landing/web/ucsc-day-courtyard@2x.png 2x"
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '367px', textAlign: 'center',  zIndex: 2 }}>
        <div
          style={{ fontSize: '22px', marginBottom: '50px' }}
        >{t('What is the Cool Campus Challenge?')}</div>
        <div
          style={{ fontSize: '42px' }}
        >{t('A friendly competition to reduce UC\'s carbon footprint and create a culture of sustainability across the campuses.')}</div>
      </div>
    </PageScrollerComponent>,
    <PageScrollerComponent
      key="4"
      alt={t('UCSC field')}
      backgroundImage="/assets/landing/web/ucsc-day-field.png"
      backgroundSet="/assets/landing/web/ucsc-day-field@2x.png 2x"
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '288px', marginBottom: '15%', marginTop: '15%', zIndex: 2 }}>
        <div
          style={{ fontSize: '22px', marginBottom: '10px', padding: '10px' }}
        >{t('How does it work?')}</div>
        <div className="home-how_it_works">
          <div className="home-how_it_works-number"><span>1</span></div>
          <div className="home-how_it_works-step">{t('Sign up')}</div>
        </div>
        <div className="home-how_it_works">
          <div className="home-how_it_works-number"><span>2</span></div>
          <div className="home-how_it_works-step">{t('Take sustainable actions')}</div>
        </div>
        <div className="home-how_it_works">
          <div className="home-how_it_works-number"><span>3</span></div>
          <div className="home-how_it_works-step">{t('See your impact')}</div>
        </div>
      </div>
    </PageScrollerComponent>,
    <PageScrollerComponent
      key="5"
      alt={t('UCSC ocean')}
      backgroundImage="/assets/landing/web/ucsc-day-ocean.png"
      backgroundSet="/assets/landing/web/ucsc-day-ocean@2x.png 2x"

    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '288px', marginBottom: '25%', marginTop: '25%', zIndex: 2 }}>
        <div style={{ fontSize: '42px', lineHeight: 1.0, marginBottom: '50px' }}>
          <div>{t('Ten campuses.')}</div>
          <div>{t('One goal.')}</div>
          <div>{t('Carbon neutral')}</div>
          <div>{t('by 2025.')}</div>
        </div>
        <div
          style={{ fontSize: '22px' }}
        >{t('Join today and reduce your impact to help create a cleaner, healthier future.')}</div>
      </div>
    </PageScrollerComponent>];
}

const mapStateToProps = (state: State, _props: Props) => ({
  landingPagePosition: state.landingPagePosition,
  canViewCompetitionContent: selectors.canViewCompetitionContent(state),
  competitionScore: state.competitionScore,
  hasJoined: selectors.hasJoined(state),
  isLoggedIn: selectors.isLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch, _props: Props) =>
  bindActionCreators({
    setLandingPagePosition: a.setLandingPagePostion
  }, dispatch);

export default compose(
  withLocalization('home'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HomeWeb) as React.SFC<ManualProps>;
