import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Story, CompetitionPledge, TaggedUserIDs } from '../userApi';
import { LProps } from '../utils/wrappers';
import actions from '../redux/actions';
import { State } from '../redux/state';
import { connect } from 'react-redux';
import StoryForm from './StoryForm';

interface ManualProps {
  story: Story;
}

interface Props extends ManualProps {
  pledge: CompetitionPledge;
  putStory: (story: Story, taggedUserIDs: TaggedUserIDs, fileInputID: string, storyFormID: string) => void;
}

const StoryEditor = ({
  putStory, story, pledge
}: LProps<Props>) => (
  <StoryForm
    pledge={pledge}
    story={story}
    onSave={putStory}
  />
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  pledge: ownProps.story.competitionPledgeID ? state.competitionPledges[ownProps.story.competitionPledgeID] : null
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: ManualProps): Partial<Props> => bindActionCreators({
  putStory: actions.putStory
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(StoryEditor) as React.ComponentClass<ManualProps>;
