import * as React from 'react';
import { withLocalization } from '../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { asPage } from '../utils/wrappers/asPage';
import actions from '../redux/actions';
import { connect } from 'react-redux';
import { State } from '../redux/state';
import { Routes } from '../utils/routerHelper';
import { WaitingPageContent } from '../shared/WaitingPage';

interface Props {
  initializing: boolean;
  qualtricsID: string;
  initializeSurveyPage: () => void;
  createSurveyStory: () => void;
}

const qualtricsSurveyURL = 'https://berkeley.qualtrics.com/jfe/form/SV_3dAPNj1unLElOy9';

class Survey extends React.Component<Props> {
  private iframe: React.RefObject<HTMLIFrameElement>;
  private messageReceive: (event: MessageEvent) => void;

  constructor(props: Props) {
    super(props);
    this.iframe = React.createRef();
  }

  public componentWillMount() {
    const { initializeSurveyPage } = this.props;
    initializeSurveyPage();

    // NOTE: See assets/survey_complete.html for the page that is loaded within the iframe
    // after survey completion.
    this.messageReceive = (event) => {
      if (event.origin.search(window.location.host) < 0) {
        // something from an unknown domain, let's ignore it
        return;
      }
      const qualtricsID = event.data;
      if (event.data === qualtricsID) {
        const { createSurveyStory } = this.props;
        createSurveyStory();
      }
    };
    window.addEventListener('message', this.messageReceive);
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.messageReceive);
  }

  public render() {
    const { initializing, qualtricsID } = this.props;
    if (initializing) return <WaitingPageContent />;
    return (
      <iframe
        ref={this.iframe}
        src={`${qualtricsSurveyURL}?id=${qualtricsID}&host=${window.location.host}`}
        style={{ width: '100%', height: '90vh', border: 'none' }}
      ></iframe>
    );
  }
}

const mapStateToProps = (state: State): Partial<Props> => ({
  initializing: state.pendingRoute === Routes.Survey,
  qualtricsID: state.competitionUser.qualtricsID
});

const mapDipatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  initializeSurveyPage: actions.initializeSurveyPage,
  createSurveyStory: actions.createSurveyStory
}, dispatch);

export default asPage({ authenticated: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(
  compose(
    withLocalization('survey'),
    connect(mapStateToProps, mapDipatchToProps)
  )(Survey)
) as React.SFC;
