import * as React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

interface Props {
  currentValue: string | number;
  values: Array<string | number | string[]>;
  displayValues?: string[];
  onChange: (value: string) => void;
}

interface State {
  isOpen: boolean;
}
const initialState: State = {
  isOpen: false
};

class Dropdown extends React.Component<Props, State> {
  public state = initialState;

  public render() {
    const { values, displayValues, currentValue } = this.props;
    const { isOpen } = this.state;
    const displayDict: any = {};
    values.forEach((element, i: number) => {
      displayDict[element.toString()] = displayValues[i];
    });
    return (
      <ButtonDropdown isOpen={isOpen} toggle={() => this.setState({ isOpen: !isOpen })} className="cc__dropdown">
        <DropdownToggle caret size="sm">
          {displayDict && displayDict[currentValue] ? displayDict[currentValue] : currentValue}
        </DropdownToggle>
        <DropdownMenu>
          {values.map((value: string, i) => (
            <DropdownItem
              key={`${value.toString()}-${i}`}
              onClick={() => this.onChange(value.toString())}
            >
              {displayDict && displayDict[value.toString()] ? displayDict[value.toString()] : value}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
  private onChange(value: string) {
    this.props.onChange(value);
    this.setState({ isOpen: false });
  }
}

export default Dropdown;
