import { Story, UserRead } from '../../userApi';
import { State } from '../state';

interface Storyable {
  story: Story;
}
export const storyCreator = (state: State, props: Storyable): UserRead => {
  if (props.story.createdBy === state.user.id) {
    return state.user;
  }
  return state.users[props.story.createdBy];
};

export const taggedUsers = (state: State, props: Storyable): UserRead[] => {
  if (!props.story) return [];
  if (!state.storyTaggedUserIDs[props.story.id]) return [];
  return state.storyTaggedUserIDs[props.story.id].map((userID) => state.users[userID]);
};
