import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose } from 'redux';
import { State } from '../../redux/state';
import { connect } from 'react-redux';
import { DataURLFromFile, getDataURLFromFileInput } from '../../utils/image';

interface ManualProps {
  id: string;
  disabled?: boolean;
  onChange: (dataURLFromFile: DataURLFromFile) => void;
}

class ImageInput extends React.Component<LProps<ManualProps>> {
  public state = { dataURL: '' };

  private async onChange() {
    const { onChange } = this.props;
    const dataURLFromFile = await getDataURLFromFileInput(this.props.id);
    if (!dataURLFromFile) return;
    onChange(dataURLFromFile);
  }

  public render() {
    const {
      id, disabled = false
    } = this.props;
    return (
      <input
        id={id}
        aria-label={`video/photo upload ${id}`}
        disabled={disabled}
        type="file"
        onChange={() => this.onChange()}
        accept="image/x-png,image/gif,image/jpeg,video/mp4,video/quicktime,video/mpeg,video/avi,video/3gpp,video/x-m4v"
        style={{ position: 'absolute', left: '-999999px' }}
      />
    );
  }
}

const mapStateToProps = (_state: State, _ownProps: Partial<ManualProps>): Partial<ManualProps> => ({});

export default compose(
  withLocalization('imageUploader'),
  connect(mapStateToProps)
)(ImageInput) as React.SFC<ManualProps>;
