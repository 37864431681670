import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import up from 'updeep';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Alert, Input, Button, FormGroup, Label, FormFeedback } from 'reactstrap';
import { Competition, UserEmail, ValidationErrors, removeVEForField } from '../userApi';
import { State, Modals } from '../redux/state';
import * as selectors from '../redux/selectors';
import actions from '../redux/actions';
import { StyleState } from '../utils/styles';
import { LProps, withLocalization } from '../utils/wrappers';
import { UserEmailErrors, makeUserEmailErrors, userEmailVEMessages } from '../utils/forms';
import { Form } from '../utils/forms/form';
import { Patch } from '../redux/actions/all';
import EntityEmailDomains from './EntityEmailDomains';
import LinkButton from './LinkButton';

interface Props {
  competition: Competition;
  userEmailPermittedByCompetition: UserEmail;
  competitionEmailVerificationSent: string;
  validationErrors: ValidationErrors;
  postUserEmail: (email: string, mustVerifyCompetition: boolean) => void;
  patch: Patch;
  getAccountVerification: (userEmail: UserEmail, verifyingCompetition: boolean) => void;
  openEntityEmailDomainModal: () => void;
}

interface UserEmailState {
  form: { email: string };
  errors: Partial<UserEmailErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: UserEmailState = {
  form: { email: '' },
  errors: {},
  dirty: {},
  submitted: false
};

class ErrorUserJoin extends React.Component<LProps<Props>, UserEmailState> {
  public state = initialState;

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { validationErrors, t } = this.props;
    if (nextProps.validationErrors !== validationErrors) {
      const { dirty, submitted, form } = this.state;
      const errors = makeUserEmailErrors({ dirty, submitted, ...form }, nextProps.validationErrors, t);
      this.setState({ errors });
    }
  }

  private update(update: Partial<UserEmailState>, validate: boolean = false) {
    const { validationErrors, t } = this.props;
    const { dirty, submitted, form } = this.state;
    if (validate) {
      const errors = makeUserEmailErrors({ dirty, submitted, ...form }, validationErrors, t);
      update.errors = errors;
    }
    this.setState(update as UserEmailState);
  }

  private markDirty(field: string) {
    const { submitted, form } = this.state;
    const dirty = { ...this.state.dirty, [field]: true };
    const { validationErrors, t, patch } = this.props;
    let ve = validationErrors;
    if (userEmailVEMessages[field]) {
      ve = removeVEForField(field, validationErrors, userEmailVEMessages);
      patch({ userEmailVE: up.constant(ve) });
    }

    const update: Partial<UserEmailState> = { dirty };
    const errors = makeUserEmailErrors({ dirty, submitted, ...form }, ve, t);
    update.errors = errors;
    this.setState({ errors, dirty });
  }

  private submit() {
    const { postUserEmail, t, validationErrors } = this.props;
    const submitted = true;
    const { form, dirty } = this.state;
    const errors = makeUserEmailErrors({ dirty, submitted, ...form }, validationErrors, t);
    if (isEmpty(errors)) {
      postUserEmail(form.email, true);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors } = this.state;
    const {
      openEntityEmailDomainModal, competitionEmailVerificationSent,
      getAccountVerification, userEmailPermittedByCompetition, t
    } = this.props;
    return (
      <Alert id="error_competition_verification_required" level={StyleState.Danger}>
        <div>          
          <p>
            {t("Your haven't join this competition yet")}
          </p>        
        </div>
        <EntityEmailDomains />
      </Alert>
    );
  }

}

const mapStateToProps = (state: State, _ownProps: Props): Partial<Props> => ({
  competition: state.competition,
  validationErrors: state.userEmailVE,
  userEmailPermittedByCompetition: selectors.userEmailPermittedByCompetition(state),
  competitionEmailVerificationSent: state.competitionEmailVerificationSent
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  postUserEmail: actions.postUserEmail,
  getAccountVerification: actions.getAccountVerification,
  openEntityEmailDomainModal: () => actions.setModal(Modals.EntityEmailDomains),
  patch: actions.patch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('competitionNotification')
)(ErrorUserJoin) as React.ComponentClass;
