import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { BlogEntry, Entity, MembershipLevel } from '../../userApi';
import { connect } from 'react-redux';
import { State, Modals } from '../../redux/state';
import { RouterProps, withRouter } from 'react-router';
import actions from '../../redux/actions';
import { Routes, getBlogEntryParams, makeBlogEntryEditPath, makeEntityBlogPath, makeBlogEntryPath, makeSimpleRoutePath } from '../../utils/routerHelper';
import { atLeastAdmin } from '../../utils/userApiHelper';
import SafeHTMLPageContent from '../../shared/SafeHTMLPageContent';
import { asPage } from '../../utils/wrappers/asPage';
import { WaitingPageContent } from '../../shared/WaitingPage';

interface Props {
  blogEntryID: number;
  entity: Entity;
  blogEntry: BlogEntry;
  membershipLevel: MembershipLevel;
  initializingBlogEntryPage: boolean;
  previousURL?: string;
  allURL?: string;
  nextURL?: string;
  modal: Modals;
  initializeBlogEntryPage: () => void;
  deleteBlogEntry: (blogEntry: BlogEntry) => void;
}

const didBlogEntryIDChange = (nextProps: LProps<Props>, props: LProps<Props>) =>
  nextProps.blogEntryID !== props.blogEntryID;

class BlogEntryPage extends React.Component<LProps<Props> & RouterProps> {
  public componentDidMount() {
    const { initializeBlogEntryPage } = this.props;
    initializeBlogEntryPage();
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (didBlogEntryIDChange(nextProps, this.props)) {
      const { initializeBlogEntryPage} = nextProps;
      initializeBlogEntryPage();
    }
  }

  public render() {
    const {
      initializingBlogEntryPage, blogEntry, t, history, membershipLevel,
      previousURL, allURL, nextURL, deleteBlogEntry
    } = this.props;
    if (initializingBlogEntryPage) {
      return (<WaitingPageContent />);
    }
    const onEdit = atLeastAdmin(membershipLevel) ? () => history.push(makeBlogEntryEditPath(blogEntry.id, t)) : undefined;
    const onDelete = atLeastAdmin(membershipLevel) ? () => deleteBlogEntry(blogEntry) : undefined;
    return (
      <SafeHTMLPageContent
        showControls={true}
        previousURL={previousURL}
        allURL={allURL}
        nextURL={nextURL}
        contentHTML={blogEntry.contentHTML}
        title={blogEntry.title}
        date={blogEntry.createdAt}
        photo={blogEntry.photo}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => {
  const { blogEntryID } = getBlogEntryParams(ownProps.history.location, ownProps.i18n);
  const { blogEntries } = state;
  const blogEntryIndex = state.blogEntries.findIndex((be) => be.id === blogEntryID);
  const blogEntry = blogEntries[blogEntryIndex];
  if (!blogEntry) return { blogEntryID, initializingBlogEntryPage: true };

  const entity = state.entities[blogEntry.entityID];
  const previousEntry = blogEntries[blogEntryIndex - 1];
  const nextEntry = blogEntries[blogEntryIndex + 1];
  const membership = state.userMemberships && state.userMemberships[blogEntry.entityID];
  const allURL = state.blogEntryQuery.entityID !== 0 ?
    makeEntityBlogPath(entity.id, ownProps.t) :
    makeSimpleRoutePath(Routes.Blog, ownProps.t);
  return {
    blogEntryID,
    membershipLevel: membership && membership.level,
    entity,
    initializingBlogEntryPage: state.pendingRoute === Routes.BlogEntry,
    blogEntry,
    allURL,
    previousURL: previousEntry && makeBlogEntryPath(previousEntry.id, ownProps.t),
    nextURL: nextEntry && makeBlogEntryPath(nextEntry.id, ownProps.t)
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializeBlogEntryPage: actions.initializeBlogEntryPage,
  deleteBlogEntry: actions.deleteBlogEntry
}, dispatch);

export default asPage({})(compose(
  withRouter,
  withLocalization('blogEntry'),
  connect(mapStateToProps, mapDispatchToProps)
)(BlogEntryPage) as React.ComponentClass);
