import * as React from 'react';
import { uniqueId } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { FormGroup, Input, Popover, PopoverBody, InputGroupAddon, InputGroupText, Spinner, InputGroup } from 'reactstrap';
import { State } from '../redux/state';
import { UserRead } from '../userApi';
import { LProps, withLocalization } from '../utils/wrappers';
import SearchResults from './SearchResults';
import actions from '../redux/actions';

interface ManualProps {
  onSelect: (user: UserRead) => void;
}

interface Props extends ManualProps {
  isSearching: (key: string) => boolean;
  initializeResults: (key: string) => void;
  clearResults: (key: string) => void;
  search: (query: string, key: string) => void;
}

interface SearchState {
  showResults: boolean;
  input: string;
  hasInputDebounce: boolean;
}

const didSearchResultsReturn = (id: string, nextProps: LProps<Props>, props: LProps<Props>) =>
  props.isSearching(id) && !nextProps.isSearching(id);

class Search extends React.Component<LProps<Props>, SearchState> {
  public state = { showResults: false, input: '', hasInputDebounce: false };
  private id = '';
  private inputDebounce: number;

  public componentWillMount() {
    this.id = uniqueId('search_input');
    this.props.initializeResults(this.id);
  }

  public componentWillUnmount() {
    this.props.clearResults(this.id);
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (didSearchResultsReturn(this.id, nextProps, this.props)) {
      this.setState({ showResults: true });
    }
  }

  private select(result: UserRead) {
    const {
      clearResults, onSelect
    } = this.props;
    clearResults(this.id);
    onSelect(result);
    this.setState({ showResults: false, input: '' });
  }

  private updateInput(input: string) {
    if (this.inputDebounce) {
      clearTimeout(this.inputDebounce);
    }
    this.setState({ hasInputDebounce: Boolean(input), input });
    if (input) {
      this.inputDebounce = window.setTimeout(() => {
        this.setState({ hasInputDebounce: false });
        const { search } = this.props;
        if (this.state.input) {
          search(this.state.input, this.id);
        }
      }, 1000);
    }
  }

  public render() {
    const {
      t, isSearching
    } = this.props;
    const { hasInputDebounce } = this.state;
    return (
      <div>
        <FormGroup>
          <InputGroup  className={'cc__input_group_with_spinner'}>
            <InputGroupAddon addonType="prepend">
              {t('Email')}
            </InputGroupAddon>
            <Input
              type="email"
              id={this.id}
              value={this.state.input}
              onChange={(e) => this.updateInput(e.target.value)}
              placeholder={t('Search for user by email')}
            />
            <InputGroupAddon addonType="append">
              {(hasInputDebounce || isSearching(this.id)) && <InputGroupText><Spinner color="success" /></InputGroupText>}
              {!(hasInputDebounce || isSearching(this.id)) && <InputGroupText>&nbsp;&nbsp;&nbsp;</InputGroupText>}
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <Popover
          placement="bottom"
          isOpen={this.state.showResults}
          target={this.id}
        >
          <PopoverBody>
            <SearchResults
              t={t}
              id={this.id}
              onSelect={(result) => this.select(result)}
              close={() => this.setState({ showResults: false })}
            />
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: Props): Partial<Props> => ({
  isSearching: (id: string) => state.isSearching[id]
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  initializeResults: actions.initializeSearchResults,
  clearResults: actions.clearSearchResults,
  search: actions.search
}, dispatch);

export default compose(
  withLocalization('search'),
  connect(mapStateToProps, mapDispatchToProps)
)(Search) as React.SFC<ManualProps>;
