import * as React from 'react';
import EntityForm from '../../shared/EntityForm';
import { getEntityEditParams, Routes } from '../../utils/routerHelper';
import { LProps, withLocalization } from '../../utils/wrappers';
import { State } from '../../redux/state';
import { RouterProps, withRouter } from 'react-router';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { asPage } from '../../utils/wrappers/asPage';
import { Entity } from '../../userApi';
import actions from '../../redux/actions';
import { WaitingPageContent } from '../../shared/WaitingPage';

interface Props {
  entity: Entity;
  initializing: boolean;
  initialize: () => void;
}

class EntityEditPage extends React.Component<Props> {

  public componentWillMount() {
    const { initialize } = this.props;
    initialize();
  }

  public render() {
    const { initializing, entity } = this.props;
    if (initializing) return <WaitingPageContent />;
    return (
      <EntityForm entity={entity} />
    );
  }
}

const mapStateToProps = (state: State, props: LProps<RouterProps>): Partial<Props> => ({
  initializing: state.pendingRoute === Routes.BlogEntryEdit,
  entity: state.entities[getEntityEditParams(props.history.location, props.i18n).entityID]
});
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: LProps<RouterProps>) => bindActionCreators({
  initialize: actions.initializeEntityEditPage
}, dispatch);

export default asPage({ authenticated: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withLocalization('editEntity'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EntityEditPage));
