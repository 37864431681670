import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { range, last } from 'lodash';
import { connect } from 'react-redux';
import {
  Pagination, PaginationItem, PaginationLink, FormGroup,
  InputGroup, InputGroupAddon, Input, Spinner, InputGroupText
} from 'reactstrap';
import { withLocalization, LProps } from '../utils/wrappers';
import { State } from '../redux/state';

const paginationSpan = 5;

const calculatePaginationLinks = (page: number, totalPages: number): number[] => {
  const pad = (paginationSpan - 1) / 2;
  let min = page - pad;
  let max = page + pad;
  if (min < 1) {
    max += (1 - min);
    min = 1;
  }
  max = Math.min(totalPages, max);
  return range(min, max + 1);
};

interface ManualProps {
  page: number;
  totalPages: number;
  showPage: (page: number) => void;
}

interface Props extends ManualProps {
  spannedPages: number[];
}

interface PaginatorState {
  inputPage: string;
  hasInputDebounce: boolean;
}

const initialState: PaginatorState = {
  inputPage: '',
  hasInputDebounce: false
};

class Paginator extends React.Component<LProps<Props>, PaginatorState> {
  public state = initialState;
  private inputDebounce: number;

  private debounceShowPage() {
    if (this.inputDebounce) {
      clearTimeout(this.inputDebounce);
    }
    this.setState({ hasInputDebounce: true });
    this.inputDebounce = window.setTimeout(() => {
      this.setState({ hasInputDebounce: false });
      const { showPage } = this.props;
      const page = parseInt(this.state.inputPage, 10);
      if (!isNaN(page)) {
        showPage(page);
      }
    }, 2500);
  }

  public componentWillUnmount() {
    if (this.inputDebounce) {
      clearTimeout(this.inputDebounce);
    }
  }

  private setInputPage(value: string) {
    const valueI = parseInt(value.replace(/\D/g, ''), 10);
    let inputPage = valueI.toString();
    const { totalPages } = this.props;
    if (isNaN(valueI)) {
      inputPage = '';
    } else if (valueI < 1) {
      inputPage = '1';
    } else if (valueI > totalPages) {
      inputPage = totalPages.toString();
    }
    this.setState({ inputPage });
    if (!isNaN(valueI)) {
      this.debounceShowPage();
    }
  }

  public render() {
    const {
      page, showPage, t, spannedPages, totalPages
    } = this.props;
    const { inputPage, hasInputDebounce } = this.state;
    return (
      <div className="cc__paginator cc__space_around">
        <div className="cc__paginator__inner">
          <Pagination aria-label={t('User score pagination')}>
            {(spannedPages[0] !== 1) &&
              <PaginationItem>
                <PaginationLink
                  first={'true'}
                  onClick={() => showPage(1)}
                >1</PaginationLink>
              </PaginationItem>
            }
            {(spannedPages[0] - 1 > 1) &&
              <PaginationItem>
                <PaginationLink disabled>...</PaginationLink>
              </PaginationItem>
            }
            {spannedPages.map((n) => (
              <PaginationItem key={n} active={n === page}>
                <PaginationLink onClick={() => n !== page && showPage(n)}>{n}</PaginationLink>
              </PaginationItem>
            ))}
            {(last(spannedPages) + 1 < totalPages) &&
              <PaginationItem>
              <PaginationLink disabled>...</PaginationLink>
              </PaginationItem>
            }
            {(last(spannedPages) !== totalPages) &&
              <PaginationItem>
                <PaginationLink
                  last={'true'}
                  onClick={() => showPage(totalPages)}
                >{totalPages}</PaginationLink>
              </PaginationItem>
            }
          </Pagination>

          {(totalPages > paginationSpan) &&
            <FormGroup>
              <InputGroup className={'cc__input_group_with_spinner'}>
                <InputGroupAddon addonType="prepend">
                  {t('Go to page')}
                </InputGroupAddon>
                <Input
                  value={inputPage}
                  onChange={(e) => this.setInputPage(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  {hasInputDebounce && <InputGroupText><Spinner color="success" /></InputGroupText>}
                  {!hasInputDebounce && <InputGroupText>&nbsp;&nbsp;&nbsp;</InputGroupText>}
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: {}): Partial<Props> => ({
  page: state.topCompetitionUserScoreQuery.pagination.page,
  totalPages: state.topCompetitionUserScoreQuery.pagination.totalPages,
  spannedPages: calculatePaginationLinks(
      state.topCompetitionUserScoreQuery.pagination.page,
      state.topCompetitionUserScoreQuery.pagination.totalPages
    )
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withLocalization('paginator'),
  connect(mapStateToProps, mapDispatchToProps)
)(Paginator) as React.SFC<ManualProps>;
