import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouterProps, withRouter } from 'react-router';
import { State } from '../../redux/state';
import { LProps, withLocalization } from '../../utils/wrappers';
import { Entity, Competition, CompetitionEntityScore } from '../../userApi';
import { makeSimpleRoutePath, Routes, makeEntityPath } from '../../utils/routerHelper';
import ScoreboardTeams from '../../shared/ScoreboardTeams';
import { teamScoresForUserUniversity, userTeam, userUniversity } from '../../redux/selectors';
import actions from '../../redux/actions';
import { ErrorNoTeamsForUniversity } from '../../shared/Errors';
import { asPage } from '../../utils/wrappers/asPage';
import { Button } from 'reactstrap';
import Footer from '../../shared/Footer';
import * as selectors from '../../redux/selectors';

interface Props {
  university: Entity;
  team: Entity;
  competition: Competition;
  teamScores: CompetitionEntityScore[];
  hasCompetitionEnded: boolean;
  initializeTeamsPage: () => void;
}

class TeamsPage extends React.Component<LProps<Props> & RouterProps> {

  public componentWillMount() {
    const { initializeTeamsPage } = this.props;
    initializeTeamsPage();
  }

  public render() {
    const {
      team, t, competition, teamScores, history, hasCompetitionEnded
    } = this.props;
    return (
      <div id="cc__teams" className="cc__page__column_content">
        <div className="cc__page__column_content__left">
          {team &&
            <div>
              <h4>{t('Your team')}</h4>
              <Link to={makeEntityPath(competition.id, team, t)}>{team.name}</Link>
            </div>
          }
          {(!team && !hasCompetitionEnded) &&
            <div className="cc__teams__no_team">
              <h2>{t('No team yet?')}</h2>
              <Button
                color="primary"
                onClick={() => history.push(makeSimpleRoutePath(Routes.TeamsNew, t))}
              >{t('Create a team')}</Button>
              <p className="cc__lighttext cc__bigtext">
                {t('Or choose from one of the featured teams or from the entire list to contribute your points.')}
              </p>
            </div>
          }
          <Footer />
        </div>
        <div className="cc__page__column_content__right">
          <div className="cc__white_box">
            {teamScores.length === 0 && <ErrorNoTeamsForUniversity t={t} />}
            {teamScores.length > 0 &&
              <div className="cc__scoreboard">
                <ScoreboardTeams teamScores={teamScores} />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: LProps<RouterProps>): Partial<Props> => ({
  teamScores: teamScoresForUserUniversity(state),
  competition: state.competition,
  team: userTeam(state),
  university: userUniversity(state),
  hasCompetitionEnded: selectors.hasCompetitionEnded(state)
});
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: LProps<RouterProps>): Partial<Props> => bindActionCreators({
  initializeTeamsPage: actions.initializeTeamsPage
}, dispatch);

export default asPage({ requiresUniversity: true, isGrey: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withRouter,
  withLocalization('teams'),
  connect(mapStateToProps, mapDispatchToProps)
)(TeamsPage));
