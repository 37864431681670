import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { History } from 'history';
import { Router } from 'react-router-dom';
import Dashboard from './Dashboard';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { State, Toast } from '../redux/state';
import * as selectors from '../redux/selectors';
import actions from '../redux/actions';
import { connect } from 'react-redux';
import ToastComponent from '../shared/Toast';
import Home from './Home';
import { Routes, makePathnames } from '../utils/routerHelper';
import About from './About';
import Faq from './Faq';
import Blog from './Blog';
import Share from './Share';
import Missing from './Missing';
import { withLocalization, LProps } from '../utils/wrappers';
import Entity from './Entity';
import Pledge from './Pledge';
import User from './User';
import Account from './Account';
import StoryPage from './StoryPage';
import TeamsNewPage from './TeamsNew';
import EntityEdit from './EntityEdit';
import Teams from './Teams';
import EntityBlog from './EntityBlog';
import BlogEntry from './BlogEntry';
import BlogEntryEdit from './BlogEntryEdit';
import BlogEntryNewPage from './BlogEntryNew';
import Pledges from './Pledges';
import PledgeCategory from './PledgeCategory';
import Calculator from './Calculator';
import CompetitionTopUserScores from './CompetitionTopUserScores';
import EntityTopUserScores from './EntityTopUserScores';
import Survey from './Survey';
import MissingCompetition from './MissingCompetition';
import GlobalAbout from './GlobalAbout';

interface ManualProps {
  history: History;
}

interface Props extends ManualProps {
  initialized: boolean;
  spinner: boolean;
  toast: Partial<Toast>;
  isLoggedIn: boolean;
  hasJoined: boolean;
  initializeEntityPage: (h: History) => void;
}

const getHomeComponent= (isLoggedIn:boolean, hasJoined:boolean) =>{
  if(isLoggedIn && hasJoined){
    return Dashboard
  }else if(!hasJoined){
    return Home;
  }
}

const Layout = ({
  initialized,
  spinner,
  toast,
  isLoggedIn,
  hasJoined,
  history,
  t
}: LProps<Props>) => {
  const paths = makePathnames(t);  
  const homePaths = [
    Routes.Login, Routes.Signup, Routes.ForgotPassword,
    Routes.PasswordReset, Routes.Email
  ];//.map((route) => paths[route]).join('|');
  const acctPaths = [
    Routes.Account, Routes.Email
  ];//.map((route) => paths[route]).join('|');        
  
  return (
    <Router history={history}>
      <div id="layout">
        {spinner && <div
          id="layout__overlay"
        >
          <div id="layout__overlay__bg" />
          <div id="layout__overlay__spinner" />
        </div>}
        {initialized &&          
          <Switch>
            <Route path={paths[Routes.GlobalAbout]} component={GlobalAbout} exact={true} />
            {isLoggedIn && <Redirect from={paths[Routes.Login]} to={paths[Routes.Dashboard]} />}            
            {isLoggedIn && <Redirect from={paths[Routes.Signup]} to={paths[Routes.Dashboard]} />}
            {!isLoggedIn && <Redirect from={`(${acctPaths})`} to={paths[Routes.Login]} exact={true} />}
            {/** Content routes */}
            <Route path={"/"} render={(props)=>
              <Home {...props} readOnly/>
            }  exact={true}  />
            <Route path={paths[Routes.Dashboard]} component={getHomeComponent(isLoggedIn, hasJoined)} exact={true} />
            <Route path={paths[Routes.CompetitionUserScores]} component={CompetitionTopUserScores} exact={true} />
            <Route path={paths[Routes.CompetitionUserScoresForEntity]} component={EntityTopUserScores} exact={true} />

            <Route path={paths[Routes.About]} component={About} exact={true} />
            <Route path={paths[Routes.Faq]} component={Faq} exact={true} />
            <Route path={paths[Routes.Calculator]} component={Calculator} exact={true} />

            <Route path={paths[Routes.Pledge]} component={Pledge} exact={true} />
            <Route path={paths[Routes.Pledges]} component={Pledges} exact={true} />
            <Route path={paths[Routes.PledgeCategory]} component={PledgeCategory} exact={true} />
            <Route path={paths[Routes.User]} component={User} exact={true} />

            <Route path={paths[Routes.Entity]} component={Entity} exact={true} />
            <Route path={paths[Routes.EntityEdit]} component={EntityEdit} exact={true} />
            <Route path={paths[Routes.EntityBlog]} component={EntityBlog} exact={true} />

            <Route path={paths[Routes.Teams]} component={Teams} exact={true} />
            <Route path={paths[Routes.TeamsNew]} component={TeamsNewPage} exact={true} />

            <Route path={paths[Routes.Story]} component={StoryPage} exact={true} />
            <Route path={paths[Routes.Survey]} component={Survey} exact={true} />

            <Route path={paths[Routes.Blog]} component={Blog} exact={true} />
            <Route path={paths[Routes.BlogEntry]} component={BlogEntry} exact={true} />
            <Route path={paths[Routes.BlogEntryEdit]} component={BlogEntryEdit} exact={true} />
            <Route path={paths[Routes.BlogEntryNew]} component={BlogEntryNewPage} exact={true} />

            {acctPaths.map((p)=>
              <Route path={paths[p]} component={Account} exact={true} />
            )}

            {/** Authentication routes */}
            {homePaths.map((p)=>
              <Route path={paths[p]} component={Home} exact={true} />
            )}
            

            {/** Static */}
            <Route path={paths[Routes.Share]} component={Share} exact={true} />
            
            <Route path="/" component={MissingCompetition} exact={true} />

            {/** No route matches */}
            <Route component={Missing} />
          </Switch>
        }
        {toast.on && <ToastComponent />}
      </div>
    </Router>
  );
};

const mapStateToProps = (state: State, _ownProps: Props): Partial<Props> => ({
  initialized: state.initialized,
  spinner: state.spinner,
  toast: state.toast,
  isLoggedIn: selectors.isLoggedIn(state),
  hasJoined: selectors.hasJoined(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializeEntityPage: actions.initializeEntityPage
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('routes')
)(Layout) as React.ComponentClass<ManualProps>;
