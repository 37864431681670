import * as React from 'react';
import { FormText, Input, Button, FormGroup, Label, FormFeedback } from 'reactstrap';
import { compose, Dispatch } from 'redux';
import up from 'updeep';
import { isEmpty, trim } from 'lodash';
import { LProps, withLocalization } from '../../utils/wrappers';
import { State, Modals } from '../../redux/state';
import actions, { bindActionCreators } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { connect } from 'react-redux';
import { SignupErrors, makeSignupErrors, signupVEMessages, SignupForm } from '../../utils/forms';
import { UserType, UserWrite, ValidationErrors, CreateUserParams, removeVEForField } from '../../userApi';
import EntityEmailDomains from '../../shared/EntityEmailDomains';
import { Form } from '../../utils/forms/form';
import SignupWizard from './SignupWizard';
import LinkButton from '../../shared/LinkButton';
import { someMatchingEmailDomain } from '../../utils/userApiHelper';

interface Props {
  validationErrors: ValidationErrors;
  signup: (params: CreateUserParams) => void;
  patch: (update: Partial<State>) => void;
  openEntityEmailDomainModal: () => void;
  canSetHealthSystemsEmployee: (email: string) => boolean;
}

export interface SignupState extends Form {
  form: UserWrite;
  errors: Partial<SignupErrors>;
  password: string;
  confirmPassword: string;
  agreedToTermsAndConditions: string;
  isWizardOpen: boolean;
  signupWizardStep: number;
}

const initialState: SignupState = {
  form: { type: UserType.Student, options: {} } as UserWrite,
  password: '',
  confirmPassword: '',
  agreedToTermsAndConditions: '',
  errors: {},
  dirty: {},
  submitted: false,
  isWizardOpen: false,
  signupWizardStep: 0
};

const stateToSignupForm = (s: SignupState): SignupForm => ({
  user: s.form,
  password: s.password,
  confirmPassword: s.confirmPassword,
  agreedToTermsAndConditions: s.agreedToTermsAndConditions,
  dirty: s.dirty,
  submitted: s.submitted
});

class Signup extends React.Component<LProps<Props>, SignupState> {
  public state = initialState;

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { validationErrors, t } = this.props;
    if (nextProps.validationErrors !== validationErrors) {
      const errors = makeSignupErrors(stateToSignupForm(this.state), nextProps.validationErrors, t);
      const update: Partial<SignupState> = { errors };
      if (!isEmpty(errors)) {
        update.isWizardOpen = false;
      }
      this.setState(update as SignupState);
    }
  }

  private update(update: Partial<SignupState>, validate: boolean = false) {
    const { validationErrors, t } = this.props;
    if (validate) {
      const errors = makeSignupErrors(stateToSignupForm({ ...this.state, ...update }), validationErrors, t);
      update.errors = errors;
    }
    this.setState(update as SignupState);
  }

  private markDirty(field: string) {
    const dirty = { ...this.state.dirty, [field]: true };
    if (field === 'password') {
      dirty.confirmPassword = true;
    }
    const { validationErrors, t, patch } = this.props;
    let ve = validationErrors;
    if (signupVEMessages[field]) {
      ve = removeVEForField(field, validationErrors, signupVEMessages);
      patch({ signupVE: up.constant(ve) });
    }

    const update: Partial<SignupState> = { dirty };
    const errors = makeSignupErrors(stateToSignupForm({ ...this.state, dirty }), ve, t);
    update.errors = errors;
    this.setState(update as SignupState);
  }

  private completeRegistration() {
    const { validationErrors, t } = this.props;
    const errors = makeSignupErrors(stateToSignupForm({ ...this.state, submitted: true }), validationErrors, t);
    const update: Partial<SignupState> = { submitted: true, errors };
    if (isEmpty(errors)) {
      update.isWizardOpen = true;
    }
    this.setState(update as SignupState);
  }

  private submit() {
    const { agreedToTermsAndConditions, password, form } = this.state;
    const { signup } = this.props;
    signup({ user: form, password, agreedToTermsAndConditions });
    this.setState({ isWizardOpen: false });
  }

  public render() {
    const { form, errors, password, confirmPassword, dirty } = this.state;
    const {
      openEntityEmailDomainModal, canSetHealthSystemsEmployee,
      t
    } = this.props;
    const isSignupPasswordValid = dirty.password && !Boolean(errors.password);
    return (
      <div id="signup_form">
        <FormGroup id="signup_form__email">
          <Label>{t('Email*')}</Label>
          <Input
            aria-label={t('Email')}
            invalid={Boolean(errors.email)}
            value={form.email || ''}
            type={'email'}
            onChange={(e) => this.update({ form: up({ email: trim(e.target.value || '').toLowerCase() }, form) })}
            onBlur={() => this.markDirty('email')}
          />
          <FormText><LinkButton onClick={openEntityEmailDomainModal} text={t('View permitted university domains')} /></FormText>
          {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </FormGroup>
        <FormGroup id="signup_form__firstName">
          <Label>{t('First name*')}</Label>
          <Input
            aria-label={t('First name')}
            invalid={Boolean(errors.firstName)}
            value={form.firstName || ''}
            onChange={(e) => this.update({ form: up({ firstName: e.target.value }, form) })}
            onBlur={() => this.markDirty('firstName')}
          />
          {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
        </FormGroup>
        <FormGroup id="signup_form__lastName">
          <Label>{t('Last name')}</Label>
          <Input
            aria-label={t('Last name')}
            invalid={Boolean(errors.lastName)}
            value={form.lastName || ''}
            onChange={(e) => this.update({ form: up({ lastName: e.target.value }, form) })}
            onBlur={() => this.markDirty('lastName')}
          />
          {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
        </FormGroup>
        <FormGroup
          id="signup_form__password"
        >
          <Label>{t('Password*')}</Label>
          <Input
            aria-label={t('Password')}
            valid={isSignupPasswordValid}
            invalid={Boolean(errors.password)}
            value={password as string || ''}
            type={'password'}
            onBlur={() => this.markDirty('password')}
            onChange={(e) => this.update({ password: e.target.value })}
          />
          {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
        </FormGroup>
        <FormGroup
          id="signup_form__confirmPassword"
        >
          <Label>{t('Confirm password')}</Label>
          <Input
            aria-label={t('Confirm password')}
            valid={isSignupPasswordValid && password === confirmPassword}
            invalid={Boolean(errors.confirmPassword)}
            value={confirmPassword || ''}
            type={'password'}
            onChange={(e) => this.update({ confirmPassword: e.target.value }, true)}
            onBlur={() => this.markDirty('confirmPassword')}
            autoComplete={'off'}
          />
          {errors.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
        </FormGroup>
        <SignupWizard
          signupState={this.state}
          update={(update) => this.update(update)}
          submit={() => this.submit()}
          canSetHealthSystemsEmployee={canSetHealthSystemsEmployee(form.email || '')}
          t={t}
        />
        <Button
          id="signup_form__submit"
          onClick={() => this.completeRegistration()}
          style={{ width: '250px' }}
        >{t('Signup')}</Button>
        <EntityEmailDomains />
      </div>
    );
  }
}

const mapStateToProps = (state: State, _props: Props) => ({
  validationErrors: state.signupVE,
  canSetHealthSystemsEmployee: (email: string) => selectors.healthFacilityEntities(state).some((entity) => someMatchingEmailDomain(email, entity.permittedDomains))
});

const mapDispatchToProps = (dispatch: Dispatch, _props: Props) =>
  bindActionCreators({
    signup: actions.signup,
    openEntityEmailDomainModal: () => actions.setModal(Modals.EntityEmailDomains),
    patch: actions.patch
  }, dispatch);

export default compose(
  withLocalization('auth'),
  connect(mapStateToProps, mapDispatchToProps)
)(Signup);
