import * as React from 'react';
import { compose } from 'redux';
import { withLocalization, LProps } from '../../utils/wrappers';
import { CompetitionPledge } from '../../userApi';

interface Props {
  pledge: CompetitionPledge;
  completions: number;
  pointsEarned: number;
}

const PledgeStats = ({
  t, formatNumber, pledge, completions, pointsEarned
}: LProps<Props>) => (
  <div className="cc__white_box">
    <div className="cc__stats__item">
      <div className="cc__stats__label">{t('Baseline Points')}</div>
      <div className="cc__lighttext">{formatNumber(pledge.points, 0)}</div>
    </div>
    {Boolean(pledge.savingsCO2Lbs) &&
      <div className="cc__stats__item">
        <div
          className="cc__stats__label"
          dangerouslySetInnerHTML={{
            __html: t('Pounds CO<sub>2</sub> savings')
          }}
        />
        <div className="cc__lighttext">{formatNumber(pledge.savingsCO2Lbs, 0)}</div>
      </div>
    }
    <div className="cc__stats__item">
      <div className="cc__stats__label">{t('Completions')}</div>
      <div className="cc__lighttext">{formatNumber(completions, 0)} / {pledge.repeats}</div>
    </div>
    {Boolean(pointsEarned) &&
      <div className="cc__stats__item">
        <div className="cc__stats__label">{t('Points Earned')}</div>
        <div className="cc__lighttext">{formatNumber(pointsEarned, 0)}</div>
      </div>
    }
  </div>
);

export default compose(
  withLocalization('userStats')
)(PledgeStats) as React.FunctionComponent<Props>;
