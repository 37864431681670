import * as React from 'react';
import cx from 'classnames';

interface Props {
  children?: React.ReactNode;
  placeholder?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  error?: string;
  containerStyle?: React.CSSProperties;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const Checkbox = ({
  children,
  checked,
  error,
  onChange,
  placeholder = '',
  containerStyle,
  inputProps = {}
}: Props) => (
  <label className="cc__label" style={containerStyle}>
    <input
      role="checkbox"
      type={'checkbox'}
      aria-checked={checked || false}
      checked={checked || false}
      onChange={(event) => onChange(event.target.checked)}
      className={cx('cc__checkbox__input')}
      placeholder={placeholder}
      {...inputProps}
    />&nbsp;
    {children}
    {error && <div className="cc__input__error">{error}</div>}
  </label>
);

export default Checkbox;
