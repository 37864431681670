import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError, validationErrorExists, CreateUserParams, ValidationErrorMessages } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';
import { Form, validationReady } from './form';

interface Signup extends CreateUserParams {
  confirmPassword: string;
}

export type SignupForm = Signup & Form;

export interface SignupErrors {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const validation = {
  type: 'object',
  required: ['user', 'password', 'confirmPassword', 'agreedToTermsAndConditions'],
  properties: {
    user: {
      type: 'object',
      required: ['email', 'firstName', 'type'],
      properties: {
        email: { type: 'string', format: 'email', minLength: 5 },
        firstName: { type: 'string', minLength: 2, maxLength: 64 },
        lastName: { type: 'string', minLength: 1, maxLength: 64 }
      }
    },
    password: { type: 'string', minLength: 8, maxLength: 64, password: true },
    confirmPassword: { type: 'string' }
  }
};

export const signupVEMessages: ValidationErrorMessages = {
  email: {
    [ValidationError.CodeEnum.ErrEmailNonUnique]: 'A user has already signed up with that email.'
  }
};

export const makeSignupErrors = (signupForm: Partial<SignupForm>, validationErrors: ValidationError[], t: TranslationFunction): Partial<SignupErrors> => {
  let errorMessages: Partial<SignupErrors> = {};
  if (!validator.validate(validation, signupForm)) {
    const { errors } = validator;
    const passwordError = makeErrorMessage(
      errors, '.password',
      t('8-64 characters with at least 1 number and 1 letter.'),
      validationReady(signupForm as Form, 'password')
    );

    errorMessages = {
      email: makeErrorMessage(errors, '.user.email', t('Must be valid email.'), validationReady(signupForm as Form, 'email')),
      firstName: makeErrorMessage(errors, '.user.firstName', t('Must be 2-64 characters.'), validationReady(signupForm as Form, 'firstName')),
      lastName: makeErrorMessage(errors, '.user.lastName', t('Must be 2-64 characters.'), validationReady(signupForm as Form, 'lastName')),
      password: passwordError
    };
  }

  if (!errorMessages.password && signupForm.password !== signupForm.confirmPassword && validationReady(signupForm as Form, 'confirmPassword')) {
    errorMessages.confirmPassword = t('Must match password.');
  }

  if (!errorMessages.email && validationErrorExists(ValidationError.CodeEnum.ErrEmailNonUnique, validationErrors)) {
    errorMessages.email = t(signupVEMessages.email[ValidationError.CodeEnum.ErrEmailNonUnique]);
  }
  return pickBy(errorMessages);
};
