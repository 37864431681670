import * as React from 'react';
import { template, templateSettings } from 'lodash';
import { TranslationFunction, TranslationOptions } from 'i18next';

export interface I18next {
  getFixedT: (language: string, namespace: string) => TranslationFunction;
  t: (key: string, options?: TranslationOptions) => string;
  language: string;
}

templateSettings.interpolate = /{{([\s\S]+?)}}/g;
const t = (key: string, options: TranslationOptions = {}) => {
  if (Object.keys(options).length > 0) {
    return template(key)(options);
  }
  return key;
};
const shim = {
  getFixedT: (_language: string, _namespace: string) => t,
  t,
  language: 'en-US'
};

export const i18nextContext = React.createContext(shim);

export interface I18nContext {
  i18n: I18next;
}

export default shim;
