import up from 'updeep';
import * as moment from 'moment';
import { patch } from './patch';
import { UserEmail, UserRead, someMessageForValidationErrors } from '../../userApi';
import initialState, { State, Toast } from '../state';
import { Dispatch } from 'redux';
import { userEmailVEMessages } from '../../utils/forms';
import { handleUserApiError, handleUnexpectedError } from './handleUnexpectedError';
import * as selectors from '../selectors';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import logger from '../../utils/logger';
import { ExtraArguments } from '../extraArguments';
import { getValidationErrors } from '../../utils/userApiHelper';
import { trackEvent } from '../../utils/googleAnalytics';

export const getAccountVerification = (userEmail: UserEmail, verifyingCompeition: boolean = false) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const state = getState();
  const api = selectors.userApi(state);
  dispatch(patch({ spinner: true }));
  try {
    await api.getAccountVerification(userEmail.id, state.competition.name);
    const { userEmails } = state;
    const userEmailIndex = userEmails.findIndex((ue) => ue.id === userEmail.id);
    dispatch(patch({
      spinner: false,
      userEmails: up.updateIn(`${userEmailIndex}`, { verificationSentAt: moment().format() }, userEmails),
      competitionEmailVerificationSent: verifyingCompeition ? userEmail.email : ''
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
  }
};

export const postAccountVerification = () => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const state = getState();
  const { initialQuery } = state;
  if (!initialQuery.verificationToken) return;

  const api = selectors.userApi(state);
  const t = i18n.getFixedT(i18n.language, 'verifyEmail');
  try {
    const headers = { 'Content-Type': 'text/plain' };
    await api.postAccountVerification(initialQuery.verificationToken, { headers });
    trackEvent('email', 'verify');
    toast({
      style: StyleState.Success,
      title: t('Success!'),
      message: t('Your email has been verified.')
    })(dispatch, getState);
  } catch (err) {
    logger.error(err);
    toast({
      style: StyleState.Danger,
      title: t('Ooops!'),
      message: t('We experienced an error verifying your email address.')
    })(dispatch, getState);
    trackEvent('email', 'unhandledError', 'verify');
  }
};

export const postUserEmail = (email: string, mustVerifyCompetition: boolean = false) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const t = extra.i18n.getFixedT(extra.i18n.language, 'userEmail');
  const state = getState();
  const { user, competition } = state;

  if (mustVerifyCompetition && !competition.permittedDomains.some((domain) => email.search(domain) >= 0)) {
    toast({
      style: StyleState.Danger,
      message: t('Please use a competition verifiable email.')
    })(dispatch, getState);
    return;
  }
  dispatch(patch({ spinner: true }));
  try {
    const params = { email };
    const api = selectors.userApi(state);
    const res = await api.postUserEmail(user.id, params);
    trackEvent('email', 'create');
    const { userEmails } = getState();
    dispatch(patch({
      user: up.constant(res.user),
      userEmails: up.constant([ ...userEmails, res.userEmail ]),
      userEmailVE: up.constant(initialState.userEmailVE),
      competitionEmailVerificationSent: mustVerifyCompetition ? email : '',
      spinner: false
    }));
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors && someMessageForValidationErrors(validationErrors, userEmailVEMessages)) {
      dispatch(patch({
        userEmailVE: up.constant(validationErrors),
        spinner: false
      }));
      return;
    }
    handleUserApiError(err)(dispatch, getState);
    trackEvent('email', 'unhandledError', 'create');
  }
};

export const makePrimaryUserEmail = (userEmail: UserEmail) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'userEmail');
  dispatch(patch({ spinner: true }));
  const state = getState();
  const api = selectors.userApi(state);
  try {
    await api.putUserEmail(userEmail.userID, userEmail.id, { primary: true });
    trackEvent('email', 'update');
    const { userEmails } = getState();
    const currentPrimaryI = userEmails.findIndex((ue) => ue.email);
    dispatch(patch({
      userEmails: up.updateIn(`${currentPrimaryI}`, { primary: false }, userEmails)
    }));
    const i = userEmails.findIndex((ue) => ue.id === userEmail.id);
    dispatch(patch({
      user: { id: userEmail.userID, email: userEmail.email } as UserRead,
      userEmails: up.updateIn(`${i}`, { primary: true }, userEmails),
      spinner: false
    }));
  } catch (err) {
    const toastData: Toast = {
      on: true,
      style: StyleState.Danger,
      title: t('Oops!'),
      message: t('Failed to change your primary email address. Please contact us if this recurs.')
    };
    handleUnexpectedError(err, toastData)(dispatch, getState);
    trackEvent('email', 'unhandledError', 'update');
  }
};

export const deleteUserEmail = (userEmail: UserEmail) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'userEmail');
  dispatch(patch({ spinner: true }));
  const state = getState();
  const api = selectors.userApi(state);
  try {
    await api.deleteUserEmail(userEmail.userID, userEmail.id);
    trackEvent('email', 'delete');
    dispatch(patch({
      userEmails: up.reject<UserEmail>((ue) => ue.id === userEmail.id),
      spinner: false
    }));
  } catch (err) {
    const toastData: Toast = {
      on: true,
      style: StyleState.Danger,
      title: t('Oops!'),
      message: t('Failed to delete email address. Please contact us if this recurs.')
    };
    handleUnexpectedError(err, toastData)(dispatch, getState);
    trackEvent('email', 'unhandledError', 'delete');
  }
};
