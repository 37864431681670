import * as React from 'react';
import { LProps, withLocalization } from '../utils/wrappers';
import { UserRead, Comment, Story } from '../userApi';
import { Link } from 'react-router-dom';
import { makeUserPath } from '../utils/routerHelper';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { displayFullName } from '../utils/userApiHelper';
import actions from '../redux/actions';
import { Button } from 'reactstrap';
import LinkButton from './LinkButton';

interface ManualProps {
  comment: Comment;
}

interface Props extends ManualProps {
  competitionID: number;
  creator: UserRead;
  canDelete: boolean;
  deleteStoryComment: (comment: Comment) => void;
}

interface CState {
  showMore: boolean;
}

class CommentComponent extends React.Component<LProps<Props>, CState> {
  public state = { showMore: false };

  public render() {
    const {
      t, creator, competitionID, comment, deleteStoryComment, canDelete
    } = this.props;
    return (
      <div className="cc__comment">
        {canDelete &&
          <Button close={true} onClick={() => deleteStoryComment(comment)} />
        }
        <span><Link to={makeUserPath(competitionID, creator, t)}>{displayFullName(creator)}</Link></span>&nbsp;
        {this.state.showMore && <span>{comment.contentMD}</span>}
        {!this.state.showMore &&
          <span>
            <span>{comment.contentMD.slice(0, 400)}</span>
            {comment.contentMD.length > 400 &&
              <span>
                ...&nbsp;
                <LinkButton onClick={() => this.setState({ showMore: true })}>{t('more')}</LinkButton>
              </span>
            }
          </span>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  competitionID: state.competitionUser.competitionID,
  creator: selectors.commentCreator(ownProps.comment, state),
  canDelete: state.user && ownProps.comment.createdBy === state.user.id
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  deleteStoryComment: actions.deleteStoryComment
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('story')
)(CommentComponent) as React.ComponentClass<ManualProps>;
