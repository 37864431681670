import * as React from 'react';
import { round, omit } from 'lodash';
import { i18nextContext, I18next, default as i18next } from '../i18nextShim';

interface LocalizationProps {
  t: i18next.TranslationFunction;
  i18n: I18next;
  formatNumber: (value: number, places?: number, options?: Intl.NumberFormatOptions) => string;
}

export type LProps<P> = LocalizationProps & P;

type TranslatableComponent<P> = React.StatelessComponent<LProps<P>> | React.ComponentClass<LProps<P>> | React.SFC<LProps<P>>;

const fauxT = i18next.t;

export const withLocalization = <P>(namespace = 'common', exclude: string[] = []) => {
  if (typeof namespace !== 'string') {
    throw new Error(`namespace must be string. received ${typeof namespace}.`);
  }
  return (Component: TranslatableComponent<P & Partial<LocalizationProps>>) => (props: P) => React.createElement(
    i18nextContext.Consumer,
    {
      children: (i18n: I18next) =>{
        const localizationComponent = <LocalizationProps>{
          t: fauxT,
          i18n: i18next,
          formatNumber: (value: number, places: number = 0, options: Intl.NumberFormatOptions = {}) =>
          round(value, places).toLocaleString(i18n.language, options)
        }
        //const localizationComponentWithExclude = omit(localizationComponent, exclude);
        
        return (React.createElement(
          Component,
          Object.assign(localizationComponent, props))
        )
      }
    }
  );
};
