import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

const Overlay = ({
  children
}: Props) => (
  <div className="cc__overlay__container">
    <div className="cc__overlay " />
    <div className="cc__overlay__content">
      <div className="cc__overlay__content__inner">{children}</div>
    </div>
  </div>
);

export default Overlay;
