import { Dispatch } from 'redux';
import { History } from 'history';
import { values } from 'lodash';
import { State, StoryQuery } from '../state';
import * as selectors from '../selectors';
import { Routes, getCompetitionNameParams} from '../../utils/routerHelper';
import { patch } from './patch';
import { ActionType } from './types';
//import { competitionName } from '../../utils/userApiHelper';
import { handleUserApiError, handleUnexpectedError } from './handleUnexpectedError';
import { ExtraArguments } from '../extraArguments';
import { setStoryQuery } from './story';
import { postAccountVerification } from './userEmail';
import { CompetitionPledges } from '../../userApi';
import { sleep } from '../../utils/sleep';
import { intercom } from '../../utils/intercom';
import { trackEvent, setAttribute } from '../../utils/googleAnalytics';
import logger from '../../utils/logger';

const indexPledgeIDsByUUID = (pledges: CompetitionPledges) =>
  values(pledges).reduce((previous, pledge) => ({ ...previous, [pledge.uuid]: pledge.id }), {});

export const initialize = () => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const state = getState();
  const api = selectors.userApi(state);
  const {i18n, history} = extra;
  const params = getCompetitionNameParams(history.location, i18n);
  if(params == 'GLOBAL'){
    dispatch(patch({      
      spinner: false,
      initialized: true
    }, ActionType.Initialize));
    return
  }
  let competitionName = params.competitionName as string;  
  localStorage.setItem('competitionName', params.competitionName);
  dispatch(patch({ spinner: true, initialQuery: { competitionName: params.competitionName } }));

  if (state.initialQuery.verificationToken) {
    await postAccountVerification()(dispatch, getState, extra);
    await sleep(1000);
  }
  let mockCompetition = false;
  if(!competitionName){
    //return handleUnexpectedError({}, {message:"Joining a campus competition? Enter the url in this format https://www.coolcampuschallenge.org/&lt;competitionName&gt;/<br/>If you are looking for more info please visit <a href='https://www.coolcampuschallenge.org/about' target='_blank'>https://www.coolcampuschallenge.org/about</a>", persistent:true})(dispatch, getState);
    competitionName = 'coolcampuschallenge2019'
    mockCompetition= true
  }

  try {
    if (selectors.isLoggedIn(state)) {      

      const userDashboard = await api.getMe(undefined, competitionName);
      const newState = {
        ...userDashboard,
        competitionPledgeIDsByUUID: indexPledgeIDsByUUID(userDashboard.competitionPledges),
        spinner: false,
        initialized: true
      }      
      if(mockCompetition){
        newState['competition'] = undefined
        console.log(newState)
      }
      dispatch(patch(newState, ActionType.Initialize));
      trackEvent('app', 'initialize', 'loggedIn');
      const initializedState = getState();
      setAnalyticsAttributes(initializedState);
      logger.configure(initializedState);
    } else {


      const competitionDashboard = await api.getCompetitionDashboard(competitionName);
      trackEvent('app', 'initialize', 'loggedOut');
      const newState = {
        ...competitionDashboard,
        competitionPledgeIDsByUUID: indexPledgeIDsByUUID(competitionDashboard.competitionPledges),
        spinner: false,
        initialized: true
      }
      if(mockCompetition){
        newState['competition'] = undefined
        console.log(newState)
      }
      dispatch(patch(newState, ActionType.Initialize));
    }
    await sleep(3000);
    const loggedInState = getState();
    const university = selectors.userUniversity(loggedInState);
    const { user, competitionUser } = loggedInState;
    intercom('boot', { user, university, competitionUser });
  } catch (err) {
    handleUserApiError(err)(dispatch, getState);
    await sleep(3000);
    intercom('boot');
  }
};

export const reinitializeDashboardIfNecessary = () => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const { competition, storyQuery } = getState();
  if (values(storyQuery).every((value) => value === 0)) {
    const { pendingRoute } = getState();
    dispatch(patch({
      pendingRoute: pendingRoute === Routes.Dashboard ? null : pendingRoute
    }));
    return;
  }

  const competitionStoryQuery: StoryQuery = {
    competitionPledgeID: 0,
    entityID: 0,
    userID: 0,
    storyID: 0
  };

  dispatch(patch({ spinner: true }));
  const t = i18n.getFixedT(i18n.language, 'pledge');
  setStoryQuery(competitionStoryQuery)(dispatch, getState);

  try {
    const state = getState();
    const api = selectors.userApi(state);
    const res = await api.getCompetitionStories(competition.id);
    const { pendingRoute } = getState();
    dispatch(patch({
      ...res,
      spinner: false,
      pendingRoute: pendingRoute === Routes.Dashboard ? null : pendingRoute
    }));
  } catch (err) {
    const { pendingRoute } = getState();
    dispatch(patch({
      storyPagination: { nextPage: -1 },
      pendingRoute: pendingRoute === Routes.Dashboard ? null : pendingRoute
    }));
    handleUnexpectedError(err, {
      title: t('Oops!'),
      message: t('We experienced an error retrieving stories.')
    })(dispatch, getState);
  }
};

export const setAnalyticsAttributes = (state: State) => {
  const { user } = state;
  if (user) {
    setAttribute('userType', user.type.toString());
  }
  const campus = selectors.userUniversity(state);
  if (campus) {
    setAttribute('campus', campus.name);
  }
  const team = selectors.userTeam(state);
  if (team) {
    setAttribute('team', team.name);
  }
};
