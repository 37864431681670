import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError, validationErrorExists, UserWrite, ValidationErrorMessages } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';
import { Form, validationReady } from './form';

interface UserUpdate {
  user: Partial<UserWrite>;
  password: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export type UserUpdateForm = UserUpdate & Form;

export interface UserUpdateErrors {
  firstName: string;
  lastName: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const validation = {
  type: 'object',
  required: ['user'],
  properties: {
    user: {
      type: 'object',
      required: ['firstName', 'type'],
      properties: {
        email: { type: 'string', format: 'email', minLength: 5 },
        firstName: { type: 'string', minLength: 2, maxLength: 64 },
        lastName: { type: 'string', minLength: 1, maxLength: 64 }
      }
    },
    newPassword: { type: 'string', minLength: 8, maxLength: 64, password: true },
    confirmNewPassword: { type: 'string' }
  }
};

export const userUpdateVEMessages: ValidationErrorMessages = {
  password: {
    [ValidationError.CodeEnum.ErrPasswordIncorrect]: 'Password is incorrect.'
  }
};

export const makeUserUpdateErrors = (userUpdateForm: Partial<UserUpdateForm>, validationErrors: ValidationError[], t: TranslationFunction): Partial<UserUpdateErrors> => {
  let errorMessages: Partial<UserUpdateErrors> = {};
  if (!validator.validate(validation, userUpdateForm)) {
    const { errors } = validator;
    const newPasswordError = makeErrorMessage(
      errors, '.newPassword',
      t('8-64 characters with at least 1 number and 1 letter.'),
      validationReady(userUpdateForm as Form, 'newPassword')
    );
    let confirmPasswordError = '';
    if (!newPasswordError && userUpdateForm.newPassword !== userUpdateForm.confirmNewPassword) {
      confirmPasswordError = t('Must match password.');
    }
    errorMessages = {
      firstName: makeErrorMessage(errors, '.user.firstName', t('Must be 2-64 characters.'), validationReady(userUpdateForm as Form, 'firstName')),
      lastName: makeErrorMessage(errors, '.user.lastName', t('Must be 2-64 characters.'), validationReady(userUpdateForm as Form, 'lastName')),
      newPassword: newPasswordError,
      confirmNewPassword: confirmPasswordError
    };
  }

  if (validationErrorExists(ValidationError.CodeEnum.ErrPasswordIncorrect, validationErrors)) {
    errorMessages.password = t(userUpdateVEMessages.password[ValidationError.CodeEnum.ErrPasswordIncorrect]);
  }
  return pickBy(errorMessages);
};
