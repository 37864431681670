import * as React from 'react';
import up from 'updeep';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Alert, FormGroup, Input, Button, Label, FormFeedback } from 'reactstrap';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { State } from '../../redux/state';
import actions from '../../redux/actions';
import { LProps, withLocalization } from '../../utils/wrappers';
import { PasswordResetForm, PasswordResetErrors, makePasswordResetErrors } from '../../utils/forms';
import { StyleState } from '../../utils/styles';
import { Routes } from '../../utils/routerHelper';

interface Props {
  hasPasswordResetToken: boolean;
  errors: Partial<PasswordResetErrors>;
  form: Partial<PasswordResetForm>;
  putPasswordResetRequest: (password: string) => void;
}

interface PasswordResetState {
  form: {
    password: string;
    confirmPassword: string;
  };
  errors: Partial<PasswordResetErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: PasswordResetState = {
  form: { password: '', confirmPassword: '' },
  errors: {},
  dirty: {},
  submitted: false
};

class PasswordReset extends React.Component<LProps<Props>, PasswordResetState> {
  public state = initialState;

  private update(update: Partial<PasswordResetState>, validate: boolean = false) {
    const { t } = this.props;
    const { dirty, form } = this.state;
    if (validate) {
      const errors = makePasswordResetErrors({
        ...form,
        ...update.form,
        dirty
      }, [], t);
      update.errors = errors;
    }
    this.setState(update as PasswordResetState);
  }

  private markDirty(field: string) {
    const { t } = this.props;
    const dirty = { ...this.state.dirty, [field]: true };
    const errors = makePasswordResetErrors({ ...this.state.form, dirty }, [], t);
    this.setState({ errors, dirty });
  }

  private submit() {
    const { putPasswordResetRequest, t } = this.props;
    const submitted = true;
    const { form, dirty } = this.state;
    const errors = makePasswordResetErrors({ ...form, dirty, submitted }, [], t);
    if (isEmpty(errors)) {
      putPasswordResetRequest(form.password);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors, dirty } = this.state;
    const {
      t, hasPasswordResetToken
    } = this.props;
    return (
      <div id="password_reset" className="home__form">
        {!hasPasswordResetToken &&
          <Alert level={StyleState.Danger}>
            <div>
              <p>{t('It appears the link is missing a valid reset token. Click the link below to try again.')}</p>
              <Link className="alert-link" to={`/${t(Routes.ForgotPassword)}`}>{t('Forgot password')}</Link>
            </div>
          </Alert>
        }
        {hasPasswordResetToken &&
          <div>
            <FormGroup
              id="password_reset__password"
            >
              <Label>{t('Password*')}</Label>
              <Input
                invalid={Boolean(errors.password)}
                valid={dirty.password && !Boolean(errors.password)}
                type={'password'}
                value={form.password}
                onBlur={() => this.markDirty('password')}
                onChange={(e) => this.update({ form: up({ password: e.target.value }, form) })}
              />
              {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
            </FormGroup>
            <FormGroup
              id="password_reset__confirm_password"
            >
              <Label>{t('Confirm password*')}</Label>
              <Input
                invalid={dirty.password && Boolean(errors.confirmPassword)}
                valid={dirty.confirmPassword && !Boolean(errors.password) && form.password === form.confirmPassword}
                type={'password'}
                value={form.confirmPassword}
                onBlur={() => this.markDirty('confirmPassword')}
                onChange={(e) => this.update({ form: { ...form, confirmPassword: e.target.value } }, true)}
              />
              {errors.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
            </FormGroup>
            <Button
              block={true}
              onClick={() => this.submit()}
            >{t('Confirm')}</Button>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: Props): Partial<Props> => ({
  hasPasswordResetToken: Boolean(state.initialQuery.passwordResetToken)
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  putPasswordResetRequest: actions.putPasswordResetRequest
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('passwordReset')
)(PasswordReset) as React.ComponentClass;
