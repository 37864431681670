import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { FormText } from 'reactstrap';
import { compose } from 'redux';

interface Props {
  idealRatio: string;
}

const FormTextAspectRatio = ({
  t, idealRatio
}: LProps<Props>) => (
  <FormText>
    {t('An')}&nbsp;
    <a href="https://en.wikipedia.org/wiki/Aspect_ratio_(image)" target="_blank" rel="">{t('aspect ratio')}</a>&nbsp;
    {t('of {{ratio}} will work best.', { ratio: idealRatio })}</FormText>
);

export default compose(
  withLocalization('aspectRatio')
)(FormTextAspectRatio) as React.SFC<Props>;
