import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import { RouterProps, withRouter } from 'react-router';
import actions from '../../redux/actions';
import { asPage } from '../../utils/wrappers/asPage';
import Pledges from '../../shared/PledgesTable';

interface Props {
  initializePledgesPage: () => void;
}

class PledgeCategoryPage extends React.Component<LProps<Props> & RouterProps> {
  public componentWillMount() {
    const { initializePledgesPage } = this.props;
    initializePledgesPage();
  }

  public render() {
    return (
      <div className="cc__page__column_content">
        <Pledges competitionPledgeCategoryID={0} />
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => ({});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializePledgesPage: actions.initializePledgesPage
}, dispatch);

export default asPage({ requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withLocalization('blog'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PledgeCategoryPage) as React.SFC);
