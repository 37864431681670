import { throttle, pick } from 'lodash';
import { Middleware, Store } from 'redux';
import { State } from '../state';

const reduxStateKey = 'redux';

export const parseLocalStorageState = (): Partial<State> => {
  const stateString = window.localStorage.getItem(reduxStateKey);
  if (stateString === null) return {};
  try {
    return JSON.parse(stateString);
  } catch (_err) {
    return {};
  }
};

const saveLocalStorageThrottle = throttle((store: Store<State>) => {
  const savableState = pick(store.getState(), ['token', 'user']);
  window.localStorage.setItem(reduxStateKey, JSON.stringify(savableState));
}, 500);

const saveLocalStorageMiddleware: Middleware = (store: Store<State>) => (next) => (action) => {
  setTimeout(() => {
    // save localStorage in background - do not block!
    saveLocalStorageThrottle(store);
  }, 0);
  return next(action);
};

export default saveLocalStorageMiddleware;
