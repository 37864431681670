import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Input, FormGroup, FormFeedback } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { ValidationError } from '../../userApi';

interface Props {
  show: boolean;
  t: TranslationFunction;
  isPasswordInvalid: boolean;
  removeUserDeleteVE: (code: ValidationError.CodeEnum) => void;
  close: () => void;
  confirm: (password: string) => void;
}

interface ConfirmDeleteModalState {
  password: string;
}

class ConfirmDeleteModal extends React.Component<Props, ConfirmDeleteModalState> {
  public state = { password: '' };

  public render() {
    const { password } = this.state;
    const { show, t, isPasswordInvalid, confirm, close, removeUserDeleteVE } = this.props;
    return (
      <Modal
        id="confirm_delete_user_modal"
        isOpen={show}
      >
        <ModalHeader>{t('Please confirm your password')}</ModalHeader>
        <ModalBody>
          <FormGroup
            id="user_delete_form__password"
          >
            <Input
              invalid={isPasswordInvalid}
              value={password}
              type={'password'}
              onChange={(e) => this.setState({ password: e.target.value })}
              onBlur={() => removeUserDeleteVE(ValidationError.CodeEnum.ErrPasswordIncorrect)}
              autoComplete={'off'}
            />
            {isPasswordInvalid && <FormFeedback>{t('Password is incorrect')}</FormFeedback>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            id="confirm_delete_user_modal__confirm"
            color="primary"
            disabled={password.length <= 2}
            onClick={() => confirm(password)}
          >{t('Confirm')}</Button>
          <Button outline={true} color="danger" onClick={close}>{t('Cancel')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default (ConfirmDeleteModal);
