import { State } from '../state';
import { someMatchingEmailDomain } from '../../utils/userApiHelper';
import { Entity, MembershipLevel } from '../../userApi';
import { hasCompetitionStarted } from './competition';
import logger from '../../utils/logger';

export const userEmailPermittedByCompetition = (state: State) => {
  const { competition, userEmails, user } = state;

  const primaryUserEmail = userEmails.find((userEmail) => userEmail.email === user.email);
  const primaryPermittedCompDomain = someMatchingEmailDomain(primaryUserEmail.email, competition.permittedDomains);
  if (primaryPermittedCompDomain) {
    return primaryUserEmail;
  }

  return userEmails.find((userEmail) => someMatchingEmailDomain(userEmail.email, competition.permittedDomains));
};

export const onlyVerifiedCompetitionUserEmail = (state: State) => {
  const { competition, competitionUser, userEmails } = state;
  if (!competitionUser.verified) return;

  const verifiedCompetitionUserEmails = userEmails.filter((userEmail) =>
    userEmail.verified &&
    someMatchingEmailDomain(userEmail.email, competition.permittedDomains)
  );
  if (verifiedCompetitionUserEmails.length > 1) return;
  return verifiedCompetitionUserEmails[0];
};

export const hasEmailPermittedByCompetition = (state: State) =>
  Boolean(userEmailPermittedByCompetition(state));

export const isVerifiedCompetitionUser = (state: State) =>
  state.competitionUser && state.competitionUser.verified;

export const isCompetitionUser = (state: State) =>
  state.competitionUser.competitionID == state.competition.id;

export const isUniversitySuperadmin = (state: State) => {
  const { userMemberships, entities } = state;
  return Object.keys(userMemberships || {}).some((entityID) =>
    entities[entityID].type === Entity.TypeEnum.University &&
      userMemberships[entityID].level === MembershipLevel.Superadmin
  );
};

export const isHealthSystemsEmployee = (state: State) => {
  const { user } = state;
  return Boolean(user) && Boolean(user.options) && user.options.isHealthFacilityEmployee;
};

export const canViewCompetitionContent = (state: State) => {
  if (!isVerifiedCompetitionUser(state)) return false;
  return hasCompetitionStarted(state) || isUniversitySuperadmin(state);
};

export const profileUser = (state: State, required: boolean = false) => {
  const profileID = state.storyQuery.userID;
  if (!profileID) return;
  return someUser(state, profileID, required);
};

export const someUser = (state: State, userID: number, required: boolean = false) => {
  if (userID === state.user.id) {
    return state.user;
  }
  const user = state.users[userID];
  if (!user && required) {
    logger.error(new Error(`Could not find user ${userID}.`));
  }
  return user;
};
