import { patch } from './patch';
import { State } from '../state';
import { Dispatch } from 'redux';
import { handleUnexpectedError } from './handleUnexpectedError';
import * as selectors from '../selectors';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { Routes, makeSimpleRoutePath } from '../../utils/routerHelper';
import { ExtraArguments } from '../extraArguments';
import { getValidationErrors } from '../../utils/userApiHelper';
import { matchesErrorCode, ValidationError } from '../../userApi';

export const putPasswordResetRequest = (password: string) =>
  async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  const state = getState();
  const api = selectors.userApi(state);
  const { initialQuery } = state;

  dispatch(patch({ spinner: true }));
  try {
    const params = {
      password,
      token: initialQuery.passwordResetToken
    };
    await api.putPasswordResetRequest(params);
    dispatch(patch({ spinner: false }));
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors) {
      if (validationErrors.some(matchesErrorCode(ValidationError.CodeEnum.ErrTokenExpired))) {
        dispatch(patch({ spinner: false }));
        history.push(makeSimpleRoutePath(Routes.ForgotPassword, i18n.t));
        toast({
          message: i18n.t('Your link expired. Please request another.'),
          style: StyleState.Danger
        })(dispatch, getState);
        return;
      } else if (validationErrors.some(matchesErrorCode(ValidationError.CodeEnum.ErrTokenInvalidJTI))) {
        dispatch(patch({ spinner: false }));
        history.push(makeSimpleRoutePath(Routes.ForgotPassword, i18n.t));
        toast({
          message: i18n.t('You can only use your reset link once. Please request another.'),
          style: StyleState.Danger
        })(dispatch, getState);
        return;
      }
    }
    handleUnexpectedError(err)(dispatch, getState);
    return;
  }

  if (selectors.isLoggedIn(state)) {
    history.push(`/${Routes.Dashboard}`);
  } else {
    history.push(`/${Routes.Login}`);
  }
  toast({
    message: i18n.t('Your password has been successfully reset.'),
    style: StyleState.Success
  })(dispatch, getState);
};
