import * as React from 'react';
import EntityIdentity from '../../shared/EntityIdentity';
import { Entity, CompetitionEntityPledge, Competition, CompetitionPledge } from '../../userApi';
import SafeHTML from '../../shared/SafeHtml';
import { LProps, withLocalization } from '../../utils/wrappers';
import { Button } from 'reactstrap';
import { State } from '../../redux/state';
import * as selectors from '../../redux/selectors';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { patch } from '../../redux/actions/all';
import { connect } from 'react-redux';
import CompetitionEntityPledgeForm from './CompetitionEntityPledgeForm';

interface ManualProps {
  entity: Entity;
  pledge: CompetitionPledge;
}

interface Props extends ManualProps {
  isEntityAdmin: boolean;
  competitionEntityPledge: CompetitionEntityPledge;
  competition: Competition;
  editing: boolean;
  edit: () => void;
}

const makeNewCompetitionEntityPledge = (entity: Entity, pledge: CompetitionPledge) => ({
  id: 0,
  entityID: entity.id,
  competitionPledgeID: pledge.id
});

const CompetitionEntityPledgeContent = ({
  entity, isEntityAdmin, competitionEntityPledge, competition, t,
  edit, editing, pledge
}: LProps<Props>) => (
  <div className="cc__white_box cc__lighttext">
    <EntityIdentity entity={entity} competitionID={competition.id} t={t} />
    {(() => {
      if (editing) {
        return (
          <CompetitionEntityPledgeForm
            competitionEntityPledge={competitionEntityPledge || makeNewCompetitionEntityPledge(entity, pledge)}
          />
        );
      } else if (!competitionEntityPledge && isEntityAdmin) {
        return (
          <div className="cc__competition_entity_pledge__prompt_new cc__lighttext">
            {t('As an admin, you can share content with your members here.')}<br />
            <Button
              color="primary"
              aria-label={t('Edit')}
              size="sm"
              onClick={edit}
            >{t('Get started')}&nbsp;<i className="fa fa-pencil" /></Button>
          </div>
        );
      } else {
        return (
          <div>
            <SafeHTML contentHTML={competitionEntityPledge.contentHTML} />
            {isEntityAdmin &&
              <div className="cc__space_around">
                <Button
                  color="primary"
                  size="sm"
                  onClick={edit}
                >{t('Edit')}&nbsp;<i className="fa fa-pencil" /></Button>
              </div>
            }
          </div>
        );
      }
    })()}
  </div>
);

const mapStateToProps = (state: State, ownProps: LProps<ManualProps>): Partial<Props> => ({
  competitionEntityPledge: selectors.getCompetitionEntityPledge(state, ownProps),
  competition: state.competition,
  isEntityAdmin: selectors.isAtLeastAcceptedAdmin(state, ownProps),
  editing: state.editingCompetitionEntityPledgeEntityID === ownProps.entity.id
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: LProps<ManualProps>): Partial<Props> => bindActionCreators({
  edit: () => patch({ editingCompetitionEntityPledgeEntityID: ownProps.entity.id })
}, dispatch);

export default compose(
  withLocalization('pledge'),
  connect(mapStateToProps, mapDispatchToProps)
)(CompetitionEntityPledgeContent) as React.SFC<ManualProps>;
