// @ts-nocheck
import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LProps, withLocalization } from '../utils/wrappers';
import { State } from '../redux/state';
import actions from '../redux/actions';
import TopUserScores from '../shared/TopUserScores';
import { Routes } from '../utils/routerHelper';
import { WaitingPageContent } from '../shared/WaitingPage';
import { asPage } from '../utils/wrappers/asPage';

interface Props {
  initializing: boolean;
  initializeTopUserScoresPage: (entityID: number, term?:string) => void;
}
interface CompetitionTopUserScoresState{
  term:string
}

class CompetitionTopUserScores extends React.Component<LProps<Props>, CompetitionTopUserScoresState> {
  constructor(props:LProps<Props>){
    super(props)
    this.state = {
      term: ""
    }
  }
  public componentWillMount() {
    const { initializeTopUserScoresPage } = this.props;
    initializeTopUserScoresPage(0);

  }

  public render() {
    const { initializing, initializeTopUserScoresPage } = this.props;
    const { term } = this.state
    if (initializing) {
      return <WaitingPageContent />;
    }
    return (
      <>
      <input className="form-input" value={term} onChange={(ev)=> {this.setState({term: ev.target.value});}} />
      <button onClick={()=>initializeTopUserScoresPage(0, term)} >Search</button>
      <TopUserScores
        entityID={0}
      />
      </>
    );
  }
}

const mapStateToProps = (state: State): Partial<Props> => ({
  initializing: state.pendingRoute === Routes.CompetitionUserScores
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  initializeTopUserScoresPage: actions.initializeTopUserScoresPage
}, dispatch);

export default asPage({})(compose(
  withLocalization('topUserScores'),
  connect(mapStateToProps, mapDispatchToProps)
)(CompetitionTopUserScores) as React.SFC);
