import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LProps, withLocalization } from '../../utils/wrappers';
import { CompetitionUserScore, UserRead, Entity } from '../../userApi';
import { State } from '../../redux/state';
import { makeUserPath, makeEntityPath } from '../../utils/routerHelper';
import { displayFullName } from '../../utils/userApiHelper';
import * as selectors from '../../redux/selectors';
import { Link } from 'react-router-dom';

interface ManualProps {
  score: CompetitionUserScore;
  rank: number;
}

interface Props extends ManualProps {
  user: UserRead;
  campus: Entity;
  competitionID: number;
}

const CompetitionUserScoreRow_ = ({
  rank, user, score, campus, formatNumber, competitionID, t
}: LProps<Props>) => (
  <tr>
    <td>{rank}</td>
    <td><Link to={makeUserPath(competitionID, user, t)}>{displayFullName(user)}</Link></td>
    <td>{campus && <Link to={makeEntityPath(competitionID, campus, t)}>{campus.name}</Link>}</td>
    <td>{formatNumber(score.points)}</td>
    <td>{formatNumber(score.savingsCO2Lbs)}</td>
  </tr>
);

const mapStateToProps = (state: State, ownProps: ManualProps) => ({
  user: state.users[ownProps.score.userID],
  campus: selectors.acceptedUniversity(state, ownProps.score.userID),
  campusID: state.competition.id
});

export default compose(
  connect(mapStateToProps),
  withLocalization('topUserScores')
)(CompetitionUserScoreRow_) as React.ComponentClass<ManualProps>;
