import * as React from 'react';
import { compose } from 'redux';
import { LProps, withLocalization } from '../utils/wrappers';
import { BlogEntry, Entity, Competition } from '../userApi';
import { Link } from 'react-router-dom';
import SafeHTML from './SafeHtml';
import { makeBlogEntryPath, makeEntityPath } from '../utils/routerHelper';
import { State } from '../redux/state';
import { connect } from 'react-redux';
import EntityIdentity from './EntityIdentity';

interface ManualProps {
  blogEntry: BlogEntry;
  showEntity: boolean;
}

interface Props extends ManualProps {
  entity: Entity;
  competition: Competition;
}

const BlogEntryMarkdownPreview = ({
  t, blogEntry, entity, competition, showEntity
}: LProps<Props>) => {
  return (
    <div className="cc__blog_entry__preview cc__blog_entry__item">
      {showEntity &&
        <EntityIdentity entity={entity} competitionID={competition.id} t={t} />
      }
      {blogEntry.photo &&
        <div className="cc__blog_entry__photo">
          <img src={blogEntry.photo} alt={t(blogEntry.title)} />
        </div>
      }
      <div className="cc__h3">
        <Link to={makeBlogEntryPath(blogEntry.id, t)}>{blogEntry.title}</Link>
      </div>
      <div className="cc__blog_entry__content cc__lighttext">
        <SafeHTML
          textOnly={true}
          limit={400}
          contentHTML={blogEntry.contentHTML}
        />
      </div>
      <Link to={makeBlogEntryPath(blogEntry.id, t)}>{t('Read more')} &gt;</Link>
    </div>
  );
};

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  competition: state.competition,
  entity: state.entities[ownProps.blogEntry.entityID]
});

export default compose(
  withLocalization('blogEntry'),
  connect(mapStateToProps)
)(BlogEntryMarkdownPreview) as React.SFC<ManualProps>;
