import * as React from 'react';
import cx from 'classnames';

interface Props {
  onClick: () => void;
  text?: string;
  children?: React.ReactNode;
  className?: string;
}

export default ({ onClick, text, children, className = '' }: Props) => (
  <span
    role="button"
    className={cx('cc__link_button', className)}
    onClick={onClick}
  >
    {text || children}
  </span>
);
