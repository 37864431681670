import * as React from 'react';
// @ts-ignore
import ReactCrop  = require('react-image-crop');
import { debounce, isEqual } from 'lodash';
import {
  DataURLFromFile, ImageMutation
} from '../../utils/image';
import Button from 'reactstrap/lib/Button';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import 'react-image-crop/dist/ReactCrop.css';
import actions from '../../redux/actions';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import { UserRead } from '../../userApi';
import { uuid } from '../../utils/uuid';
import { ImageMutation as ServerImageMutation } from '../../userApi';
import { imageMutationToServerIM } from '../../utils/userApiHelper';

interface ManualProps {
  dataURLFromFile: DataURLFromFile;
  onChange: (dataURLFromFile: DataURLFromFile) => void;
}

interface Props extends ManualProps {
  postImageMutation: (dataURLFromFile: DataURLFromFile, mutation: Partial<ServerImageMutation>) => Promise<DataURLFromFile>;
  user: UserRead;
}

interface ImageEditorState extends ImageMutation {
  processedDataURL: DataURLFromFile;
  crop: ReactCrop.Crop;  
}

const initialState: ImageEditorState = {
  rotation: 0,
  backgroundColor: '',
  processedDataURL: null,
  pixelCrop: {
    height: 0,
    width: 0,
    x: 0,
    y: 0
  },
  crop: {
    height: 0,
    width: 0,
    x: 0,
    y: 0
  },
  image: null
};

const getDefaultCrop = (_dataURLFromFile: DataURLFromFile) => ({
  x: 0, y: 0,
  width: 99,
  height:  99
});

const getDefaultPixelCrop = (dataURLFromFile: DataURLFromFile) => {
  if (!dataURLFromFile) {
    return {
      x: 0, y: 0,
      width: 0,
      height: 0
    };
  }
  return {
    x: 0, y: 0,
    width: dataURLFromFile.width * 0.99,
    height: dataURLFromFile.height * 0.99
  };
};

class ImageEditor extends React.Component<LProps<Props>, ImageEditorState> {
  public state = initialState;
  private id = uuid();
  private setMutationD: (update: Partial<ImageEditorState>) => void;
  private cropElement: any

  public async componentDidMount() {
    const { dataURLFromFile } = this.props;
    this.setMutationD = debounce((update: Partial<ImageEditorState>) => this.setMutation(update), 5000);

    this.initializeDataURLFromFile(dataURLFromFile);
  }

  public async componentWillReceiveProps(nextProps: LProps<Props>) {
    const { dataURLFromFile } = this.props;
    if (dataURLFromFile !== nextProps.dataURLFromFile) {
      this.initializeDataURLFromFile(nextProps.dataURLFromFile);
    }
  }

  private async setMutation(update: Partial<ImageMutation>) {
    const { postImageMutation, onChange, dataURLFromFile, t } = this.props;
    const mutation = imageMutationToServerIM(update, 1, ServerImageMutation.ContentTypeEnum.Png);
    const mutatedImage = await postImageMutation(dataURLFromFile, mutation);
    onChange(mutatedImage);
  }

  private async cutCrop() {    
    this.setMutation({ pixelCrop: this.state.pixelCrop, image:this.cropElement.imageRef });
  }

  private async initializeDataURLFromFile(dataURLFromFile: DataURLFromFile) {
    const processedDataURL = dataURLFromFile;
    this.setState({
      rotation: initialState.rotation,
      backgroundColor: initialState.backgroundColor,
      processedDataURL,
      crop: getDefaultCrop(dataURLFromFile),
      pixelCrop: getDefaultPixelCrop(dataURLFromFile)
    });    
  }

  public render() {
    const { processedDataURL, rotation, crop, pixelCrop: pc } = this.state;
    const { t } = this.props;
    return (
      <div id={this.id} className="cc__image_editor">
        {/** The is the image the user actually sees */}
        {(processedDataURL) &&
          <div className="cc__react_crop__container">            
            {/* @ts-ignore*/}
            <ReactCrop.Component
              ref={(ref:React.Ref<ReactCrop>)=>{this.cropElement=ref}}
              onImageLoad={(img:HTMLImageElement)=>{console.log("Image load"); this.setState({image:img})}}
              src={processedDataURL.dataURL}
              //getNewSize={(height:number, width:number)=>console.log(height, width)}
              crop={crop}
              onChange={(newCrop:any) => this.setState({ crop: newCrop, pixelCrop:newCrop })}
            />
          </div>
        }
        <div className="cc__space_around cc__image_editor__controls">
          <Button
            outline={true}
            size="sm"
            aria-label={t('Rotate left')}
            color="primary"
            onClick={() => this.setMutation({ rotation: 360 + ((rotation - 90) % 360) })}
          ><i className="fa fa-rotate-left" /></Button>
          <Button
            outline={true}
            size="sm"
            color="primary"
            aria-label={t('Rotate right')}
            onClick={() => this.setMutation({ rotation: ((rotation + 90) % 360) || 360 })}
          ><i className="fa fa-rotate-right" /></Button>
          <Button
            outline={true}
            size="sm"
            aria-label={t('Cut')}
            color="primary"
            disabled={!processedDataURL || isEqual(crop, getDefaultCrop(processedDataURL))}
            onClick={() => this.cutCrop()}
          ><i className="fa fa-scissors" /></Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  user: state.user
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  postImageMutation: actions.postImageMutation
}, dispatch);

export default compose(
  withLocalization('imageEditor'),
  connect(mapStateToProps, mapDispatchToProps)
)(ImageEditor) as React.SFC<ManualProps>;
