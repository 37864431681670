import { Dispatch } from 'redux';
import { History } from 'history';
import up from 'updeep';
import { State, StoryQuery } from '../state';
import { values } from 'lodash';
import { getEntityParams, Routes } from '../../utils/routerHelper';
import { setStoryQuery } from './story';
import * as selectors from '../selectors';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';
import { ExtraArguments } from '../extraArguments';
import { EntityMembership, MembershipLevel, UserRead, Entity } from '../../userApi';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { sleep } from '../../utils/sleep';
import { trackEvent } from '../../utils/googleAnalytics';

export const initializeEntityPage = (history: History) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  dispatch(patch({ spinner: true }));

  const t = i18n.getFixedT(i18n.language, 'entity');
  const params = getEntityParams(history.location, i18n);
  const entityStoryQuery: StoryQuery = {
    entityID: params.entityID,
    userID: 0,
    competitionPledgeID: 0,
    storyID: 0
  };
  setStoryQuery(entityStoryQuery)(dispatch, getState);
  try {
    const state = getState();
    const { competition, token } = state;
    const api = selectors.userApi(state);
    const res = await api.getCompetitionEntity(competition.id, params.entityID, token);
    const { pendingRoute: pr } = getState();
    dispatch(patch({
      ...res,
      spinner: false,
      pendingRoute: pr === Routes.Entity ? null : pr
    }));
  } catch (err) {
    const { pendingRoute: pr } = getState();
    dispatch(patch({
      storyPagination: { nextPage: -1 },
      pendingRoute: pr === Routes.Entity ? null : pr
    }));
    handleUnexpectedError(err, {
      title: t('Oops!'),
      message: t('We experienced an error retrieving stories.')
    })(dispatch, getState);
  }
};

export const initializeTeamsPage = () => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const { pendingRoute } = getState();
  dispatch(patch({
    pendingRoute: pendingRoute === Routes.Teams ? null : pendingRoute
  }));
};

export const joinTeam = (entity: Entity) => async (dispatch: Dispatch, getState: () => State, { i18n }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'teams');
  const state = getState();
  const api = selectors.userApi(state);
  const { user, competition } = state;
  const entityMembership: EntityMembership = {
    entityID: entity.id,
    userID: user.id,
    level: MembershipLevel.Member,
    status: EntityMembership.StatusEnum.Accepted
  };
  dispatch(patch({ spinner: true }));
  try {
    const res = await api.postCompetitionEntityMemberships(entity.id, competition.id, { entityMembership });
    trackEvent('entityMembership', 'create', entity.name);
    dispatch(patch({
      user: { acceptedCompetitionEntityMemberships: { [competition.id]: { [entity.id]: true } } } as UserRead,
      userMemberships: { [entity.id]: res.entityMembership},
      spinner: false
    }));
    toast({
      style: StyleState.Success,
      message: t(`You have successfully joined ${entity.name}.`)
    });
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('entityMembership', 'unhandledError', 'create');
  }
};

export const deleteTeam = (entity: Entity) => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'entity');
  const state = getState();
  const api = selectors.userApi(state);
  try {
    dispatch(patch({ spinner: true }));
    await api.deleteCompetitionEntity(state.competition.id, entity.id);
    trackEvent('entity', 'delete', entity.name);
    toast({
      message: t('Your team was successfully deleted.'),
      style: StyleState.Info
    })(dispatch, getState);
    await sleep(4000);
    // TODO: We should make this cleaner than reloading the whole page.
    window.location.href = '/';
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('entity', 'unhandledError', 'delete');
  }
};

export const deleteEntityMembership = (entityMembershipID: number) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  dispatch(patch({ spinner: true }));
  const { i18n } = extra;
  const t = i18n.getFixedT(i18n.language, 'entity');
  const state = getState();
  const { competition, userMemberships, entities } = state;
  const api = selectors.userApi(state);
  try {
    await api.deleteEntityMembership(entityMembershipID);
    const membership = values(userMemberships).find((m) => m.id === entityMembershipID);
    trackEvent('entityMembership', 'delete');
    const entity = entities[membership.entityID];
    const userUpdate: Partial<UserRead> = { acceptedCompetitionEntityMemberships: { [competition.id]: up.omitBy((_v, id) => id === membership.entityID) } };
    dispatch(patch({
      user: userUpdate as UserRead,
      userMemberships: up.omitBy((m, _entityID) => m.id === entityMembershipID),
      spinner: false
    }));
    toast({
      style: StyleState.Info,
      message: t('You have left {{teamName}}.', { teamName: entity.name })
    })(dispatch, getState);
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('entityMembership', 'unhandledError', 'delete');
  }
};
