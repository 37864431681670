import { isDevelopment } from './environment';
import logger from './logger';

type GAEventCategory = 'session' | 'account' | 'story' | 'comment' | 'like' | 'flag' | 'blogEntry' | 'view' | 'invite' | 'link' | 'calculator' | 'entityPledge' |
  'entity' | 'entityMembership' | 'image' | 'email' | 'app';
type GAEventAction = 'unhandledError' | 'create' | 'delete' | 'update' | 'sent' | 'click' | 'mutate' | 'verify' | 'initialize' | 'join';

const waitForGoogleAnalytics = () => new Promise((resolve, reject) => {
  const start = Date.now();
  const interval = setInterval(() => {
    if ((window as any).ga) {
      resolve();
      clearInterval(interval);
      return;
    } else if (Date.now() - start > (10 * 1000)) {
      reject(new Error('Failed to resolve google analytics after 10 seconds.'));
      clearInterval(interval);
      return;
    }
  }, 500);
});

// execute this without blocking.
export const trackEvent = async (category: GAEventCategory, action: GAEventAction, label?: string, value?: number) => {
  if (isDevelopment) return;
  try {
    await waitForGoogleAnalytics();
    if (value !== undefined) {
      (window as any).ga(
        'send',
        'event',
        category,
        action,
        label,
        value
      );
    } else if (label !== undefined) {
      (window as any).ga(
        'send',
        'event',
        category,
        action,
        label
      );
    } else {
      (window as any).ga(
        'send',
        'event',
        category,
        action
      );
    }
  } catch (_err) {
    logger.warn('Google Analytics unavailable');
  }
};

type GAAttributeField = 'campus' | 'team' | 'userType';

export const setAttribute = async (field: GAAttributeField, value: string) => {
  if (isDevelopment) return;
  await waitForGoogleAnalytics();
  (window as any).ga('set', field, value);
};
