import { EntityForm } from '../../utils/forms';
import { patch } from './patch';
import { omit, values, pickBy } from 'lodash';
import { Dispatch } from 'redux';
import { State } from '../state';
import * as selectors from '../selectors';
import { ExtraArguments } from '../extraArguments';
import { Entity, ImageResponse } from '../../userApi';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { handleUnexpectedError } from './handleUnexpectedError';
import { makeEntityPath } from '../../utils/routerHelper';
import { postImage } from './image';
import { trackEvent } from '../../utils/googleAnalytics';

export const initializeTeamsNewPage = () => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  dispatch(patch({
    pendingRoute: null,
    spinner: false
  }));
};

export const initializeEntityEditPage = () => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  dispatch(patch({
    pendingRoute: null,
    spinner: false
  }));
};

export const saveEntity = (entityForm: EntityForm, photoInputID: string, bannerInputID: string) =>
async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { i18n, getImage } = extra;
  const t = i18n.getFixedT(i18n.language, 'entity');
  dispatch(patch({ spinner: true }));

  try {
    const entity = omit(entityForm, ['dirty', 'submitted']) as Entity;
    let imageResponse: ImageResponse;
    if (getImage(photoInputID)) {
      imageResponse = await postImage(photoInputID)(dispatch, getState, extra);
      if (!imageResponse) {
        dispatch(patch({ spinner: false }));
        return;
      }
      entity.photo = imageResponse.url;
    }
    if (!entity.photo) {
      delete entity.photo;
    }
    let banner: ImageResponse;
    if (getImage(bannerInputID)) {
      banner = await postImage(bannerInputID)(dispatch, getState, extra);
      if (!banner) {
        dispatch(patch({ spinner: false }));
        return;
      }
      entity.bannerPhoto = banner.url;
    }
    if (!entity.bannerPhoto) delete entity.bannerPhoto;

    if (entityForm.id === 0) {
      await postCompetitionEntities(entity)(dispatch, getState, extra);
      trackEvent('entity', 'create');
    } else {
      await putCompetitionEntity(entity)(dispatch, getState, extra);
      trackEvent('entity', 'update');
    }
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('entity', 'unhandledError');
    return;
  }
};

const postCompetitionEntities = (entity: Entity) => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'teams');
  const state = getState();
  const { competition } = state;
  const university = selectors.userUniversity(state);
  if (!university) {
    toast({
      title: t('Ooops!'),
      message: t('You must belong to a university to create a team.'),
      style: StyleState.Danger
    })(dispatch, getState);
    return;
  }
  entity.parentID = university.id;
  const { converter } = await import('../../utils/markdown');
  entity.descriptionHTML = converter.makeHtml(entity.descriptionMD);
  const api = selectors.userApi(state);
  const res = await api.postCompetitionEntities(competition.id, { entity });
  dispatch(patch({
    ...res,
    spinner: false
  }));
  const newEntity = values(res.entities).find((e) => e.parentID === university.id && e.name === entity.name);
  history.push(makeEntityPath(competition.id, newEntity, i18n.t));
  toast({
    style: StyleState.Success,
    message: t('Your team was saved successfully.')
  })(dispatch, getState);
};

const putCompetitionEntity = (entity: Entity) => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'teams');
  const state = getState();
  const { competition } = state;
  const { converter } = await import('../../utils/markdown');
  entity.descriptionHTML = converter.makeHtml(entity.descriptionMD);
  const api = selectors.userApi(state);
  // FIXME: Not certain if or why removing nulls is necessary.
  const entityParams = pickBy(entity, (value) => value !== null) as Entity;
  const res = await api.putCompetitionEntity(competition.id, entity.id, { entity: entityParams });
  dispatch(patch({
    ...res,
    spinner: false
  }));
  history.push(makeEntityPath(competition.id, entity, i18n.t));
  toast({
    style: StyleState.Success,
    message: t('Your update was saved successfully.')
  })(dispatch, getState);
};
