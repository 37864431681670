import * as React from 'react';
import { Badge } from 'reactstrap';
import { UserRead } from '../userApi';
import { displayFullName } from '../utils/userApiHelper';
import { State } from '../redux/state';
import { compose } from 'redux';
import { connect } from 'react-redux';

interface ManualProps {
  taggedUserIDs: number[];
  removeUserTag?: (user: UserRead) => void;
}

interface Props extends ManualProps {
  taggedUsers: UserRead[];
}

const StoryTaggedUsers = ({
  taggedUsers, removeUserTag
}: Props) => (
  <div className="cc__story_tagged_users">
    {taggedUsers.map((user) => (
      <Badge key={user.id}>
        {displayFullName(user)}
        {removeUserTag &&
          <span
            role="button"
            style={{ cursor: 'pointer '}}
            onClick={() => removeUserTag(user)}
          >&nbsp;<i className="fa fa-times" /></span>
        }
      </Badge>
    ))}
  </div>
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  taggedUsers: ownProps.taggedUserIDs.map((userID) => state.users[userID])
});

export default compose(
  connect(mapStateToProps)
)(StoryTaggedUsers);
