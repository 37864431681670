import { patch } from './patch';
import { ActionType } from './types';
import { Dispatch } from 'redux';

export type SetLandingPagePosition = (position: number) => void;

export const setLandingPagePostion = (position: number) => (dispatch: Dispatch) => {
  setTimeout(() => {
    dispatch(patch({
      landingPagePosition: position
    }, ActionType.SetLandingPagePosition));
  }, 500);
};
