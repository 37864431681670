import { TranslationFunction } from 'i18next';

export const joinAnd = (items: string[], t: TranslationFunction) => {
  if (items.length < 3) {
    return items.join(t(' and '));
  }
  return [
    items.slice(0, items.length - 1).join(', '),
    `, ${t('and')} `,
    items[items.length - 1]
  ].join('');
};
