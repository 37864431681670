import * as React from 'react';
import EntityForm from '../../shared/EntityForm';
import { asPage } from '../../utils/wrappers/asPage';
import { Entity } from '../../userApi';
import { WaitingPageContent } from '../../shared/WaitingPage';
import { State } from '../../redux/state';
import { Routes } from '../../utils/routerHelper';
import * as selectors from '../../redux/selectors';
import { Dispatch, bindActionCreators, compose } from 'redux';
import actions from '../../redux/actions';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { LProps, withLocalization } from '../../utils/wrappers';

interface Props {
  university: Entity;
  initializing: boolean;
  hasCompetitionEnded: boolean;
  initialize: () => void;
}

const newTeam: Partial<Entity> = { id: 0, type: Entity.TypeEnum.Team };

class TeamsNewPage extends React.Component<LProps<Props>> {

  public componentWillMount() {
    this.props.initialize();
  }

  public render() {
    const { initializing, university, hasCompetitionEnded, t } = this.props;
    if (initializing) {
      return <WaitingPageContent />;
    } else if (hasCompetitionEnded) {
      return <Alert color="warning">{t('You cannot create a new team after the competition has ended.')}</Alert>;
    }
    return (
      <EntityForm entity={{ ...newTeam, parentID: university.id }} />
    );
  }
}

const mapStateToProps = (state: State, _ownProps: {}): Partial<Props> => ({
  initializing: state.pendingRoute === Routes.TeamsNew,
  university: selectors.userUniversity(state),
  hasCompetitionEnded: selectors.hasCompetitionEnded(state)
});
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: {}) => bindActionCreators({
  initialize: actions.initializeTeamsNewPage
}, dispatch);

export default asPage({ requiresUniversity: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLocalization('teams')
  )(TeamsNewPage)
);
