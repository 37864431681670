import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose } from 'redux';
import { Entity, CompetitionEntity, CompetitionEntityScore } from '../../userApi';
import { State } from '../../redux/state';
import { connect } from 'react-redux';
import * as selectors from '../../redux/selectors';
import logger from '../../utils/logger';

interface ManualProps {
  entity: Entity;
}

interface Props extends ManualProps {
  competitionEntity: CompetitionEntity;
  competitionEntityScore: CompetitionEntityScore;
  rank: number;
}

const getRankLabel = (entity: Entity) => {
  switch (entity.type) {
    case Entity.TypeEnum.Team:
      return 'Team Rank';
    case Entity.TypeEnum.University:
      return 'University Rank';
    case Entity.TypeEnum.HealthFacility:
      return 'Health Systems Rank';
    default:
      logger.error(new Error(`Received unexpected Entity type '${entity.type}'.`));
      return 'Rank';
  }
};

const EntityStats = ({ entity, competitionEntity, competitionEntityScore, t, formatNumber, rank }: LProps<Props>) => (
  <div className="cc__white_box">
    <div className="cc__stats__item">
      <div className="cc__stats__label">{t('Points')}</div>
      <div>{formatNumber(competitionEntityScore.points, 0)}</div>
    </div>
    {/*{(rank || '') && <div className="cc__stats__item">
      <div className="cc__stats__label">{t(getRankLabel(entity))}</div>
      <div>{formatNumber(rank, 0)}</div>
</div>} Gets rid of the rank on entity page.*/}
    <div className="cc__stats__item">
      <div className="cc__stats__label">{t('Actions')}</div>
      <div>{formatNumber(competitionEntity.pledgeCount, 0)}</div>
    </div>
    <div className="cc__stats__item">
      <div
        className="cc__stats__label"
        dangerouslySetInnerHTML={{
          __html: t('Pounds CO<sub>2</sub> reduced')
        }}
      />
      <div>{formatNumber(competitionEntityScore.savingsCO2Lbs, 0)}</div>
    </div>
  </div>
);

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => ({
  competitionEntity: state.competitionEntities[ownProps.entity.id],
  competitionEntityScore: selectors.entityScore(state, ownProps),
  rank: selectors.entityRank(state, ownProps)
});

export default compose(
  withLocalization('entity'),
  connect(mapStateToProps)
)(EntityStats) as React.SFC<ManualProps>;
