import * as React from 'react';

interface Props {
  labels: string[];
  percent: number;
  style: React.CSSProperties;
}

const ProgressBar = ({
  percent, labels, style
}: Props) => (
  <div
    className="cc__progress_bar"
  >
    <div className="cc__grey_bg" style={{ flex: '1', justifyContent: 'flex-start', flexDirection: 'row' }}>
      <div
        style={{ ...style, width: `${Math.round(percent * 100)}%` }}
      >&nbsp;</div>
    </div>
    <div className="cc__row-space_between cc__lighttext">
      <div className="cc__progress_bar__label">{labels[0]}</div>
      <div className="cc__progress_bar__label">{labels[1]}</div>
    </div>
  </div>
);
export default ProgressBar;
