import { Dispatch } from 'redux';
import { values } from 'lodash';
import { State } from '../state';
import { ExtraArguments } from '../extraArguments';
import { CompetitionPledges } from '../../utils/userApiHelper';
import logger from '../../utils/logger';
import {  makePledgePathLiteral, getPledgeParams } from '../../utils/routerHelper';
import { Story, CompetitionUser, CompetitionPledge } from '../../userApi';
import { postCompetitionStories } from './story';
import * as selectors from '../selectors';
import { uuid } from '../../utils/uuid';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';

export const initializeSurveyPage = () =>
  async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
    const { userPledgeCompletions, competitionPledges } = getState();
    const surveyPledge = values(competitionPledges).find((pledge) => pledge.uuid === CompetitionPledges.TakeSurvey);
    if (!surveyPledge) {
      handleUnexpectedError(new Error(`Could not find survey pledge by uuid ${CompetitionPledges.TakeSurvey}.`))(dispatch, getState);
      return;
    } else if (userPledgeCompletions[surveyPledge.id]) {
      const { history, i18n } = extra;
      dispatch(patch({
        pendingRoute: null
      }));
      history.push(makePledgePathLiteral(surveyPledge, i18n.t));
      return;
    }
    const state = getState();
    const { competitionUser } = state;
    if (competitionUser.qualtricsID) {
      dispatch(patch({ pendingRoute: null }));
      return;
    }

    const api = selectors.userApi(state);
    const qualtricsID = uuid();
    try {
      await api.postQualtricsID(qualtricsID, { headers: { 'Content-Type': 'text/plain'  }});
      dispatch(patch({
        competitionUser: ({ qualtricsID } as CompetitionUser),
        pendingRoute: null
      }));
    } catch (err) {
      handleUnexpectedError(err)(dispatch, getState);
    }
  };

export const createSurveyStory = () =>
  async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
    const { user, competitionPledges } = getState();
    const surveyPledge = values(competitionPledges).find((pledge) => pledge.uuid === CompetitionPledges.TakeSurvey);
    if (!surveyPledge) {
      logger.error(`Could not find survey pledge by uuid ${CompetitionPledges.TakeSurvey}.`);
      return;
    }
    const { i18n, history } = extra;
    history.push(makePledgePathLiteral(surveyPledge, i18n.t));
    await waitUntilPledgePageInitialized(surveyPledge, getState, extra);
    const t = i18n.getFixedT(i18n.language, 'survey');
    const story: Story = {
      contentMD: t('{{name}} completed the research survey.', { name: user.firstName || user.lastName }),
      competitionPledgeID: surveyPledge.id
    };
    return postCompetitionStories(story, [], '', '')(dispatch, getState, extra);
  };

const maxWaitTimeForPledgePage = 10 * 1000;
const waitUntilPledgePageInitialized = async (surveyPledge: CompetitionPledge, getState: () => State, extra: ExtraArguments) => {
  const { history, i18n } = extra;
  return new Promise((resolve, reject) => {
    const start = Date.now();
    const interval = setInterval(() => {
      try {
        const params = getPledgeParams(history.location, i18n);
        if (params.competitionPledgeID === surveyPledge.id) {
          const { pendingRoute } = getState();
          if (pendingRoute === null) {
            clearInterval(interval);
            resolve();
            return;
          }
        }
      } catch (err) {
        logger.warn(err);
      }
      if (Date.now() - start > maxWaitTimeForPledgePage) {
        clearInterval(interval);
        reject(new Error('Survey pledge page failed to initialize.'));
        return;
      }
    }, 1000);
  });
};
