import * as React from 'react';
import { asPage } from '../utils/wrappers/asPage';
import { Spinner } from 'reactstrap';

export const WaitingWhiteBox = () => (
  <div className="cc__white_box">
    <Spinner color="success" />
  </div>
);

export const WaitingPageContent = () => (
  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
    <Spinner color="success" />
  </div>
);

export default asPage({})(WaitingPageContent);
