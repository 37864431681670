import * as React from 'react';
import * as InfiniteScroll from 'react-infinite-scroller';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { LProps, withLocalization } from '../utils/wrappers';
import StoryComponent from './StoryComponent';
import { Stories, Pagination, CompetitionPledge, Story } from '../userApi';
import { State, Modals } from '../redux/state';
import actions from '../redux/actions';
import { connect } from 'react-redux';
import * as selectors from '../redux/selectors';
import StoryCreator from './StoryCreator';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import UserIneligibleForPledge from './UserIneligibleForPledge';
import StoryPoints from './StoryPoints';
import { Alert } from 'reactstrap';
import { StyleState } from '../utils/styles';

interface ManualProps {
  showPrompt?: boolean;
}

interface Props extends ManualProps {
  stories: Stories;
  pagination: Pagination;
  pledge: CompetitionPledge;
  eligible: boolean;
  modal: Modals;
  canUseMarkdown: boolean;
  deleteStory: () => void;
  pageStories: (page: number) => void;
  closeConfirmStoryDelete: () => void;
}
interface StoriesState {
  term: string
  searched: string
}
class StoriesComponent extends React.Component<LProps<Props>,StoriesState> {
  constructor(props:LProps<Props>){
    super(props)
    this.state = {
      term: "",
      searched: ""
    }
  }
  render(){
    const {term, searched} = this.state
    const {
      t, stories, pagination, pageStories, pledge, canUseMarkdown,
      closeConfirmStoryDelete, eligible, modal, deleteStory,  showPrompt = false} = this.props;
  return (
    <div className="cc__stories">
      {/*}
      <div style={{textAlign:"right"}}>
        <input value={term} onChange={(ev)=> this.setState({term:ev.target.value})}/>
        <button onClick={()=> {pageStories(0,term);this.setState({searched:term});}}>Search</button>
      </div>*/}
      <StoryPoints />
      {(showPrompt && canUseMarkdown && eligible) &&
        <StoryCreator competitionPledgeID={pledge.id} />
      }
      {(showPrompt && !eligible && pledge) &&
        <UserIneligibleForPledge pledge={pledge} />
      }
      {!stories || stories.length == 0 ?
      
          <Alert  level={StyleState.Info}>
          <div>          
            <p>
              {t("This user hasn't publish any stories yet")}
            </p>        
          </div>          
        </Alert>  
      :null}
      <InfiniteScroll
        key={searched}
        pageStart={1}        
        className="cc__story_container"
        loadMore={pageStories}
        hasMore={pagination.nextPage !== -1}
        loader={<div className="loader" key={0}>{t('Loading')} ...</div>}
        threshold={250}
      >
        {(showPrompt && !canUseMarkdown && eligible) &&
          <StoryCreator key={-1} competitionPledgeID={pledge ? pledge.id : 0} />
        }
        {stories.map((story:Story) =>
          <StoryComponent
            key={story.id}
            story={story}
          />
        )}
      </InfiniteScroll>
      <ConfirmDeleteModal
        isOpen={modal === Modals.ConfirmStoryDelete}
        confirm={deleteStory}
        cancel={closeConfirmStoryDelete}
      />
    </div>)
  }
}

const mapStateToProps = (state: State): Partial<Props> => {
  const pledge = state.competitionPledges[state.storyQuery.competitionPledgeID];
  const canUseMarkdown = pledge && pledge.canUseMarkdown;
  return {
    stories: state.stories || [],
    pledge,
    eligible: selectors.isUserEligibleForStoryQueryPledge(state),
    canUseMarkdown,
    modal: state.modal,
    pagination: state.storyPagination
  };
};

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  pageStories: actions.pageStoriesThrottle,
  deleteStory: actions.deleteStory,
  closeConfirmStoryDelete: actions.closeConfirmStoryDelete
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('story')
)(StoriesComponent) as React.ComponentClass<ManualProps>;
