import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError, validationErrorExists, ValidationErrorMessages } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';
import { Form, validationReady } from './form';

export interface ForgotPasswordForm extends Form {
  email: string;
}

export interface ForgotPasswordErrors {
  email: string;
}

const validation = {
  type: 'object',
  required: ['email'],
  properties: {
    email: { type: 'string', format: 'email', minLength: 5 }
  }
};

export const forgotPasswordVEMessages: ValidationErrorMessages = {
  email: {
    [ValidationError.CodeEnum.ErrEmailNotFound]: 'We could not find an account associated with this email address.'
  }
};

export const makeForgotPasswordErrors = (forgotPasswordForm: Partial<ForgotPasswordForm>, validationErrors: ValidationError[], t: TranslationFunction): Partial<ForgotPasswordErrors> => {
  let errorMessages: Partial<ForgotPasswordErrors> = {};
  if (!validator.validate(validation, forgotPasswordForm)) {
    const { errors } = validator;
    errorMessages = {
      email: makeErrorMessage(errors, '.email', t('Must be valid email.'), validationReady(forgotPasswordForm as Form, 'email'))
    };
  }

  if (!errorMessages.email && validationErrorExists(ValidationError.CodeEnum.ErrEmailNotFound, validationErrors)) {
    errorMessages.email = t(forgotPasswordVEMessages.email[ValidationError.CodeEnum.ErrEmailNotFound]);
  }

  return pickBy(errorMessages);
};
