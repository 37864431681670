import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Entity, Competition, MembershipLevel } from '../../userApi';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import { withRouter, RouterProps } from 'react-router';
import actions from '../../redux/actions';
import { makeEntityPath, getEntityBlogParams, makeBlogEntryNewPath } from '../../utils/routerHelper';
import { atLeastSuperadmin } from '../../utils/userApiHelper';
import { Link } from 'react-router-dom';
import BlogEntries from '../../shared/BlogEntries';
import { Button } from 'reactstrap';
import { asPage } from '../../utils/wrappers/asPage';
import WaitingPage, { WaitingPageContent } from '../../shared/WaitingPage';
import { PageContentWithBanner, PageContent } from '../../shared/Page';
import CompetitionAboutText from '../About/CompetitionAboutText';

interface Props {
  entity: Entity;
  membershipLevel: MembershipLevel;
  competition: Competition;
  initializeEntityBlogPage: () => void;
}

class EntityBlogPage extends React.Component<LProps<Props> & RouterProps> {
  public componentWillMount() {
    const { initializeEntityBlogPage } = this.props;
    initializeEntityBlogPage();
  }

  public render() {
    const {
      t, entity, competition, membershipLevel, history
    } = this.props;
    if (!entity) {
      return (<WaitingPage />);
    }
    const Container = entity.bannerPhoto ? PageContentWithBanner : PageContent;
    const containerProps = { bannerURL: entity.bannerPhoto, alt: entity.name };
    return (
      <Container {...containerProps}>
        <div className="cc__entity cc__page__column_content">
          <div className="cc__page__column_content__left">
            <div className="cc__entity__header">
              {entity.photo &&
                <div className="cc__entity__photo">
                  <img src={entity.photo} alt={entity.name} />
                </div>
              }
              <div>
                <div className="cc__entity__name">{entity.name}</div>
                <Link to={makeEntityPath(competition.id, entity, t)}>{t('Home')}</Link><br />
                {atLeastSuperadmin(membershipLevel) &&
                  <Button
                    aria-label={t('Add')}
                    onClick={() => history.push(makeBlogEntryNewPath(entity.id, t))}
                  >{t('New')}&nbsp;<i className="fa fa-plus" /></Button>
                }
                <CompetitionAboutText />
              </div>
            </div>
          </div>
          <div className="cc__page__column_content__right">
            <BlogEntries showEntity={false} />
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => {
  const params = getEntityBlogParams(ownProps.history.location, ownProps.i18n);
  if (!params.entityID) return {};
  const entity = state.entities[params.entityID];
  const membership = state.userMemberships[entity.id];
  return {
    entity,
    membershipLevel: membership && membership.level,
    competition: state.competition
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializeEntityBlogPage: actions.initializeEntityBlogPage
}, dispatch);

export default asPage({ raw: true })(compose(
  withRouter,
  withLocalization('blogEntry'),
  connect(mapStateToProps, mapDispatchToProps)
)(EntityBlogPage));
