import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRead, Competition, Entity, CompetitionUserScore, CompetitionScoreLevel } from '../../userApi';
import { State } from '../../redux/state';
import * as selectors from '../../redux/selectors';
import actions from '../../redux/actions';
import { LProps, RouterProps, withLocalization } from '../../utils/wrappers';
import UserHeader from '../../shared/UserHeader';
import UserStats from '../../shared/UserStats';
import UserScoreLevel from '../../shared/UserScoreLevel';
import Scoreboard from '../../shared/Scoreboard';
import Stories from '../../shared/Stories';
import Footer from '../../shared/Footer';
import { Routes, getUserParams } from '../../utils/routerHelper';
import { withRouter } from 'react-router';
import { asPage } from '../../utils/wrappers/asPage';
import { Button } from 'reactstrap';

interface Props {
  userID: number;
  user: UserRead;
  competition: Competition;
  university: Entity;
  team: Entity;
  healthFacility: Entity;
  score: CompetitionUserScore;
  universityRank: number;
  pledgeCompletions: number;
  scoreLevel: CompetitionScoreLevel;
  nextScoreLevel: CompetitionScoreLevel;
  initializingUserProfile: boolean;
  highlightable: boolean;
  highlightAHero: (userID: number) => void;
  initializeUserPage: () => void;
}

interface UserProfileState {
  mobileSection: number;
}

enum MobileSection {
  ACCOUNT = 1,
  STORIES = 2,
  STATS = 4
}

const initialState: UserProfileState = {
  mobileSection: MobileSection.ACCOUNT
};

const didUserIDUpdate = (nextProps: LProps<Props>, props: LProps<Props>) => nextProps.userID !== props.userID && !isNaN(nextProps.userID);

class UserProfile extends React.Component<LProps<Props>, UserProfileState> {
  public state = initialState;

  public componentWillMount() {
    const { initializeUserPage } = this.props;
    initializeUserPage();
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (didUserIDUpdate(nextProps, this.props)) {
      this.props.initializeUserPage();
    }
  }

  public render() {
    const {
      user, competition, university, healthFacility, team, score,
      universityRank, pledgeCompletions, scoreLevel, nextScoreLevel,
      initializingUserProfile, t, highlightAHero, highlightable
    } = this.props;
    return (
      <div
        id="cc__profile"
        className="cc__page__column_content"
      >
        <div className={'cc__page__column_content__left ' + this.getActiveClass(MobileSection.STATS | MobileSection.ACCOUNT)}>
          <div className={this.getActiveClass(MobileSection.ACCOUNT)}>
            {user &&
              <div>
                <UserHeader
                  user={user}
                  competition={competition}
                  university={university}
                  healthFacility={healthFacility}
                  team={team}
                />
                {highlightable &&
                  <div>
                    <Button
                      block
                      size={'sm'}
                      onClick={() => highlightAHero(user.id)}
                    >{t('Highlight as Hero')}</Button>
                  </div>
                }
              </div>

            }
            {(!initializingUserProfile && score) &&
              <UserStats
                footprint={score.takenCO2Lbs}
                score={score}
                userUniversityRank={universityRank}
                pledgeCompletions={pledgeCompletions}
              />
            }
            {score &&
              <UserScoreLevel
                score={score}
                scoreLevel={scoreLevel}
                nextScoreLevel={nextScoreLevel}
              />
            }
          </div>
          <div className={'cc__white_box ' + this.getActiveClass(MobileSection.STATS)}>
            <Scoreboard />
          </div>
          <Footer />
        </div>
        <div className={'cc__page__column_content__right ' + this.getActiveClass(MobileSection.STORIES)}>
          <Stories />
        </div>
        <div className="cc__user__bottom_menu">
          <div role="button" className="cc__user__bottom_menu__account" onClick={() => this.selectSection(MobileSection.STORIES)}>
            <img
              alt={t('activity')}
              src={this.getSrc(MobileSection.STORIES, 'activity')}
              srcSet={this.getSrcSet(MobileSection.STORIES, 'activity')} />
          </div>
          <div role="button" className="cc__user__bottom_menu__stories" onClick={() => this.selectSection(MobileSection.ACCOUNT)}>
            <img
              alt={t('profile')}
              src={this.getSrc(MobileSection.ACCOUNT, 'profile')}
              srcSet={this.getSrcSet(MobileSection.ACCOUNT, 'profile')} />
          </div>
          <div role="button" className="cc__user__bottom_menu__statistics" onClick={() => this.selectSection(MobileSection.STATS)}>
            <img
              alt={t('scoreboard')}
              src={this.getSrc(MobileSection.STATS, 'scoreboard')}
              srcSet={this.getSrcSet(MobileSection.STATS, 'scoreboard')} />
          </div>
        </div>
      </div>
    );
  }
  private selectSection(index: number) {
    this.setState({
      mobileSection: index
    });
  }

  private getActiveClass(index: number) {
    return (index & this.state.mobileSection) === this.state.mobileSection ? 'cc__mobile_show' : 'cc__mobile_hide';
  }

  private getSrc(index: number, image: string) {
    const selected = index === this.state.mobileSection ? '-selected' : '';
    return `/assets/dashboard/mobile/${image}${selected}.png`;
  }

  private getSrcSet(index: number, image: string) {
    const selected = index === this.state.mobileSection ? '-selected' : '';
    return `/assets/dashboard/mobile/${image}${selected}@2x.png 2x,
            /assets/dashboard/mobile/${image}${selected}@3x.png 3x`;
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps<{}>>): Partial<Props> => {  
  const initializingUserProfile = state.pendingRoute === Routes.User;
  const { userID } = getUserParams(ownProps.history.location, ownProps.i18n);
  let user: UserRead;
  if (!initializingUserProfile && userID) {
    user = selectors.profileUser(state);
  }
  const university = selectors.acceptedUniversity(state, userID);
  const healthFacility = selectors.acceptedHealthFacility(state, userID);
  return {
    userID,
    university,
    team: selectors.acceptedTeam(state, userID),
    healthFacility,
    user,
    competition: state.competition,
    score: state.competitionUserScores[userID],
    scoreLevel: selectors.getScoreLevel(state, userID),
    nextScoreLevel: selectors.getNextScoreLevel(state, userID),
    universityRank: selectors.universityRank(state),// !initializingUserProfile && university && state.profileEntityRanks[university.id],
    pledgeCompletions: !initializingUserProfile && selectors.profileTotalPledges(state),
    initializingUserProfile,
    highlightable: Boolean(state.user) && state.user.id !== userID
  };
};
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  initializeUserPage: actions.initializeUserPage,
  highlightAHero: actions.highlightHero
}, dispatch);

export default asPage({ isGrey: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  withRouter,
  withLocalization('userProfile'),
  connect(mapStateToProps, mapDispatchToProps)
)(UserProfile));
