
interface Config {
  SENTRY_PUBLIC_DSN: string;
  SENTRY_RELEASE: string;
  FORCE_REPORT_TO_SENTRY: string;
  SUPPORT_EMAIL: string;
}

const config: Config = {
  FORCE_REPORT_TO_SENTRY: process.env.FORCE_REPORT_TO_SENTRY,
  SENTRY_PUBLIC_DSN: process.env.SENTRY_PUBLIC_DSN,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
  SUPPORT_EMAIL: process.env.SUPPORT_EMAIL
};

export const isDevelopment = window.location.hostname.search('localhost') === 0 ||
  window.location.hostname.search('127.0.0.1') === 0 ||
  window.location.hostname.search('nginx') === 0 ||
  window.location.hostname.search('ngrok') === 0;

export const isProduction = window.location.hostname === 'www.coolcampuschallenge.org' ||
  window.location.hostname === 'prd.coolcampuschallenge.org';
export const isStaging = window.location.hostname === 'stg.coolcampuschallenge.org';

export const isIOS = Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform);

export default {
  config,
  isDevelopment
};
