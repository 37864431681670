import { values, keys, flatMap, has } from 'lodash';
import up from 'updeep';
import { ValidationError, ValidationErrors } from './api';

export const matchesErrorCode = (code: ValidationError.CodeEnum) => {
  return (err: ValidationError) => err.code === code;
};

export const validationErrorExists = (code: ValidationError.CodeEnum, validationErrors: ValidationError[], field?: string) => {
  if (!field) return validationErrors.some(matchesErrorCode(code));
  return validationErrors.some(matchesErrorCode(code)) && validationErrors.some((e) => e.field === field);
};

export interface ValidationErrorMessages {
  [key: string]: {
    [key: string]: string;
  };
}

export const matchesErrorCodeString = (code: string) => {
  return (err: ValidationError) => err.code.toString() === code;
};

export const getValidationErrorMessage = (field: string, errors: ValidationErrors, messages: ValidationErrorMessages): string => {
  const relevantCodes = keys(messages[field] || []);
  if (relevantCodes.length === 0) return '';
  const foundCode = relevantCodes.find((code) => errors.some(matchesErrorCodeString(code)));
  if (!foundCode) return '';
  return messages[field][foundCode];
};

export const someMessageForValidationErrors = (errors: ValidationErrors, messages: ValidationErrorMessages) => {
  const errorCodes = flatMap(values(messages), (fieldCodeMessages) => keys(fieldCodeMessages));
  return errorCodes.some((code: string) => errors.some(matchesErrorCodeString(code)));
};

export const removeVEForField = (field: string, errors: ValidationErrors, messages: ValidationErrorMessages): ValidationErrors => {
  const relevantCodes = messages[field] || {};
  if (keys(relevantCodes).length === 0) return errors;
  return up(up.reject<ValidationError>((ve) => has(relevantCodes, ve.code.toString())), errors);
};
