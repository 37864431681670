import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose } from 'redux';
import { asPage } from '../../utils/wrappers/asPage';
import Footer from '../../shared/Footer';
import { Link } from 'react-router-dom';
import { makeSimpleRoutePath, Routes } from '../../utils/routerHelper';
import CompetitionAboutText from './CompetitionAboutText';
const About = ({ t }: LProps<{}>) => (
  <div id="about" className="cc__page__column_content cc__lighttext">
    <div className="cc__page__column_content__left">
      <CompetitionAboutText />
      <p>{t('Cool Campus Challenge Team')}</p>
      <p>
        <span>UCOP  (Ryan Bell, Emma Finn &amp; the Marketing and Communications Team)</span><br />
        <span>CoolClimate Network (Chris Jones, Eric Hulburd &amp; Daniel Kammen)</span><br />
        <span>UCSB (Jewel Persad)</span><br />
        <span>Center for Sustainable Energy (Katherine Beaulieu, Jack Ulrich &amp; Ally Senturk)</span><br />
      </p>
      <Footer />
    </div>
    <div className="cc__page__column_content__right">
      <div>
        <h2 className="cc__bluetext">{t('What is the Cool Campus Challenge?')}</h2>
        <p>{t('The Cool Campus Challenge is a friendly challenge designed to educate and motivate the University of California community to take simple energy-saving, waste reducing and sustainability-focused actions to lower their carbon footprint and help the UC system reach carbon neutrality by 2025.')}</p>
        <p>{t('It\'s a big, daring goal, but we can get there if we all work together as a community to make it happen.')}</p>
      </div>
      <div>
        <h2 className="cc__bluetext">{t('How does the Cool Campus Challenge work?')}</h2>
        <p>{t('Once you sign up you will be given a variety of ways to learn about your carbon footprint and take actions that have a direct impact on reducing it, and living a more sustainable lifestyle. These activities could be as simple as turning off the lights when you leave the room, or taking public transportation…things you may already be doing! Show us, your friends, and your colleagues your progress by uploading photos and stories to your personal Challenge page.')}</p>
        <p>{t('For every action you take, you\'ll earn valuable points within your locally hosted competition and track of your personal progress.')}</p>
      </div>      
      <div>
        <h2 className="cc__bluetext">{t('Who runs the Cool Campus Challenge?')}</h2>
        <p>
          {t('The Cool Campus Challenge is a collaboration between the University of California and the CoolClimate Network at U.C. Berkeley, with funding from UC’s Carbon Neutrality Initiative. It was developed by contributions from the University of California, the Center for Sustainable Energy, and your local, friendly Cool Campus Team.')}
        </p>
      </div>
    </div>
  </div>
);

export default asPage({})(compose(
  withLocalization('about')
)(About));
