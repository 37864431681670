import { values } from 'lodash';
import { State } from '../state';
import { History } from 'history';
import {
  CompetitionPledge, CompetitionPledgeCategory, CompetitionUserScore, Story, PledgeCompletions,
  CompetitionEntityPledge, Entities, UserMemberships, Entity, CompetitionScoreLevel
} from '../../userApi';
import { getPledgeParams } from '../../utils/routerHelper';
import { I18next } from '../../utils/i18nextShim';
import {
  isDateEligibleForPledge, isUserTypeEligibleForPledge,
  CompetitionPledges, isUserScoreEligibleForPledge, estimatePointsAvailable
} from '../../utils/userApiHelper';
import logger from '../../utils/logger';

export const userTotalPledges = (state: State) =>
  values(state.userPledgeCompletions).reduce((previous: number, n: number) => previous + n, 0);

export const profileTotalPledges = (state: State) =>
  values(state.profilePledgeCompletions).reduce((previous: number, n: number) => previous + n, 0);

const sortPledgesByRemainingPoints = (pledges: CompetitionPledge[], userPledgeCompletions: PledgeCompletions) =>
  pledges.sort((pledge1, pledge2) => {
    return estimatePointsAvailable(userPledgeCompletions, pledge1) > estimatePointsAvailable(userPledgeCompletions, pledge2) ? -1 : 1;
  });

const filterPledgesByAvailablePointsLeft = (pledges: CompetitionPledge[], userPledgeCompletions: PledgeCompletions) =>
  pledges.filter((pledge) => {
    if (pledge.uiLocation !== CompetitionPledge.UiLocationEnum.Primary) return false;
    return (userPledgeCompletions[pledge.id] || 0) < pledge.repeats;
  });

export const carouselPledges = (state: State) => {
  const { competitionPledges, userPledgeCompletions } = state;
  const pledges = values(competitionPledges);

  const eligiblePledges = pledges.filter((pledge) => isUserEligibleForPledge(state, pledge));
  const filteredPledges = filterPledgesByAvailablePointsLeft(eligiblePledges, userPledgeCompletions);
  return sortPledgesByRemainingPoints(filteredPledges, userPledgeCompletions);
};

interface PledgeCategorical {
  competitionPledgeCategoryID: number;
}

interface PledgesByScoreLevel {
  scoreLevel?: CompetitionScoreLevel;
  pledges: CompetitionPledge[];
  active: boolean;
}

export const pledgesByCategoryAndLevel = (state: State, props: PledgeCategorical): PledgesByScoreLevel[] => {
  const { competitionPledges, competition, competitionUserScores, user, competitionScoreLevels, userPledgeCompletions } = state;
  const competitionUserScore = competitionUserScores[user.id];
  // NOTE: Here we include pledges that user is NOT score level eligible for.
  const filteredPledges = values(competitionPledges).filter((pledge) => {
    if (!isDateEligibleForPledge(pledge, competition)) return false;
    if (!isUserTypeEligibleForPledge(user, pledge)) return false;
    if (props.competitionPledgeCategoryID === 0) return true;
    return pledge.competitionPledgeCategoryID === props.competitionPledgeCategoryID;
  });
  const sortedPledges = sortPledgesByRemainingPoints(filteredPledges, userPledgeCompletions);
  return competitionScoreLevels
    .map((scoreLevel, i) => {
      if (i === 0) {
        return sortedPledges.filter((pledge) => !pledge.scoreLevelUUID || pledge.scoreLevelUUID === scoreLevel.uuid);
      } else {
        return sortedPledges.filter((pledge) => pledge.scoreLevelUUID === scoreLevel.uuid);
      }
    })
    .map((pledges, i) => ({
      scoreLevel: competitionScoreLevels[i],
      pledges,
      active: competitionUserScore.points >= competitionScoreLevels[i].threshold
    }));
};

export const findPledgeByUUID = (state: State, { uuid }: { uuid: string }) =>
  state.competitionPledges[state.competitionPledgeIDsByUUID[uuid]];

interface Pledgeable {
  history: History;
  i18n: I18next;
}

export const isUserEligibleForStoryQueryPledge = (state: State) => {
  const { storyQuery, competitionPledges } = state;
  const { competitionPledgeID } = storyQuery;
  const pledge = competitionPledges[competitionPledgeID];
  if (!pledge) return false;
  return isUserEligibleForPledge(state, pledge);
};

export const isUserEligibleForPledge = (state: State, pledge: CompetitionPledge) => {
  try {
    const { user, competitionUserScores, competitionScoreLevels, competition } = state;
    if (!pledge || !competitionUserScores) return false;
    const score = competitionUserScores[user.id];
    if (!score) return false;
    return isUserScoreEligibleForPledge(score, competitionScoreLevels, pledge) &&
      isUserTypeEligibleForPledge(user, pledge) &&
      isDateEligibleForPledge(pledge, competition);
  } catch (err) {
    logger.error(err);
    return true;
  }
};

export const currentPledge = (state: State, props: Pledgeable): CompetitionPledge =>
  state.competitionPledges[getPledgeParams(props.history.location, props.i18n).competitionPledgeID];

export const currentPledgeCategory = (state: State, props: Pledgeable): CompetitionPledgeCategory => {
  const pledge = state.competitionPledges[getPledgeParams(props.history.location, props.i18n).competitionPledgeID];
  return state.competitionPledgeCategories[pledge.competitionPledgeCategoryID];
};

export const lastUsersToVerify = (state: State, props: Pledgeable): CompetitionUserScore[] => {
  const pledgeID = getPledgeParams(props.history.location, props.i18n).competitionPledgeID;
  if (!state.latestToPledge || !state.latestToPledge[pledgeID]) return;
  return state.latestToPledge[pledgeID].map((userID) => state.competitionUserScores[userID]);
};

interface Storyable {
  story: Story;
}

export const userCompletionsLTPledgeRepeats = (state: State, props: Storyable): boolean => {
  if (!Boolean(props.story.competitionPledgeID) || !state.competitionPledges[props.story.competitionPledgeID] ) return;
  const completions = state.userPledgeCompletions[props.story.competitionPledgeID] || 0;
  const pledge = state.competitionPledges[props.story.competitionPledgeID];  
  return completions < pledge.repeats;  
};

interface CompetitionEntityPledgeable {
  entity: Entity;
  pledge: CompetitionPledge;
}

export const getCompetitionEntityPledge = (state: State, props: CompetitionEntityPledgeable): CompetitionEntityPledge => {
  const { competitionEntityPledges } = state;
  if (!competitionEntityPledges) return;

  const { entity, pledge } = props;
  return competitionEntityPledges[pledge.id][entity.id];
};

export const getEntitiesWithCompetitionEntityPledges = (state: State, props: Pledgeable): Partial<Entities> => {
  const { competitionEntityPledges, entities } = state;
  if (!competitionEntityPledges) return {};

  const { competitionPledgeID } = getPledgeParams(props.history.location, props.i18n);
  const entityIDs = Object.keys(competitionEntityPledges[competitionPledgeID] || {}) || [];
  return entityIDs.reduce((previous, entityID) => ({ [entityID]: entities[entityID], ...previous }), {});
};

export const getMembershipsCompetitionEntityPledges = (state: State, props: Pledgeable): Partial<UserMemberships> => {
  const { competitionEntityPledges, entities, userMemberships } = state;
  if (!competitionEntityPledges) return {};

  const { competitionPledgeID } = getPledgeParams(props.history.location, props.i18n);
  const entityIDs = Object.keys(competitionEntityPledges[competitionPledgeID] || {}) || [];
  return entityIDs.reduce((previous, entityID) => ({ [entityID]: userMemberships[entityID], ...previous }), {});
};

export const defaultCalculatorPoints = 500;

export const calculatorAvailablePoints = (state: State) => {
  if (!state.userPledgeCompletions) return defaultCalculatorPoints;
  const pledge = values(state.competitionPledges).find((p) => p.uuid === CompetitionPledges.TakeCalculator);
  if (!pledge) return defaultCalculatorPoints;
  return estimatePointsAvailable(state.userPledgeCompletions, pledge);
};
