import * as React from 'react';
import { Dispatch, compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link , withRouter} from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { LProps, RouterProps, withLocalization } from '../utils/wrappers';
import { Story, CompetitionPledge, UserRead, Entity, Comment, TaggedUserIDs } from '../userApi';
import { makePledgePath, makeUserPath, makeEntityPath, makeStoryPath } from '../utils/routerHelper';
import CommentComponent from './Comment';
import CommentPrompt from './CommentPrompt';
import { State, Toast } from '../redux/state';
import * as selectors from '../redux/selectors';
import actions from '../redux/actions';
import ProfilePhoto from './ProfilePhoto';
import { displayFullName, CompetitionPledges, makeCampusHeroTitle } from '../utils/userApiHelper';
import StoryEditor from './StoryEditor';
import * as moment from 'moment';
import LinkButton from './LinkButton';
import SafeHTML from './SafeHtml';
import { TranslationFunction } from 'i18next';
import OpenGraphCarousel from './OpenGraphCarousel';
import displayContent from './displayContent';
import StoryControls from './StoryControl';

const StoryContent = ({ story, pledge, taggedUsers, t }: { story: Story, pledge: CompetitionPledge, taggedUsers: UserRead[], t: TranslationFunction }) => {
  if (pledge && pledge.uuid === CompetitionPledges.HighlightAHero) {
    return (
      <div className="cc__story__content">
        <Link to={makeStoryPath(story.id, t)}>{makeCampusHeroTitle(taggedUsers, t)}</Link>
        <SafeHTML
          textOnly={true}
          limit={1000}
          contentHTML={story.contentHTML}
        />
        {story.contentMD.length > 1000 && <Link to={makeStoryPath(story.id, t)}>{t('Read more')} &gt;</Link>}
        {(story.links && story.links.length > 0) &&
          <OpenGraphCarousel links={story.links} />
        }
      </div>
    );
  }
  return (
    <div className="cc__story__content">
      {displayContent(story.contentMD)}
    </div>
  );
};

const StoryTitle = ({ pledge, t }: { pledge: CompetitionPledge, t: TranslationFunction }) =>
 (<div><Link to={makePledgePath(pledge, t)}>{pledge.shortTitle}</Link></div>);

interface ManualProps {
  story: Story;
}

interface Props extends ManualProps {
  pledge: CompetitionPledge;
  creator: UserRead;
  university: Entity;
  userCompletionsLTPledgeRepeats: boolean;
  isUserEligibleForPledge: boolean;
  liked: boolean;
  flagged: boolean;
  comments: Comment[];
  isVerifiedCompetitionUser: boolean;
  isCreator: boolean;
  taggedUsers: UserRead[];
  toggleLike: (story: Story) => void;
  toggleFlag: (story: Story) => void;
  editStory: () => void;
  openConfirmStoryDelete: () => void;
  toast: (toastData: Partial<Toast>, duration?: number) => void;
  editing: boolean;
}

const isVideo =(img:string) =>{
  const imgParts = img.split('.')
  const ext = imgParts[imgParts.length - 1]  
  if(ext=='mp4'){
    return true;
  }
}

class StoryComponent extends React.Component<LProps<Props> & RouterProps<{}>> {
  render(){    
    const {
    t, formatNumber, story, pledge, creator,
    toggleLike, toggleFlag, liked, flagged, comments,
    university, userCompletionsLTPledgeRepeats, history, isVerifiedCompetitionUser,
    isCreator, editStory, openConfirmStoryDelete, editing, taggedUsers, isUserEligibleForPledge
      } = this.props;
      
    if (editing) return (<StoryEditor story={story} />);
    return (
      <div className="cc__story cc__white_box">
        {pledge ? <StoryTitle pledge={pledge} t={t} />: <div>&nbsp;</div>}
        <hr />
        <div>
          <div className="cc__story__creator cc__story__creator">
            <div className="cc__story__creator__identity">
              <div className="cc__story__creator__identity__photo">
                <ProfilePhoto
                  profilePhoto={creator.profilePhoto}
                  alt={creator.firstName}
                />
              </div>
              <div className="cc__story__creator__identity cc__story__creator__name">
                <Link to={makeUserPath(story.competitionID, creator, t)}>{displayFullName(creator)}</Link>
                <div className="cc__story__creator__timestamp cc__lighttext">
                  {moment(story.createdAt).fromNow()}
                </div>
              </div>
            </div>
            {university && <Link to={makeEntityPath(story.competitionID, university, t)}>{university.name}</Link>}
          </div>
        </div>
        {story.photo &&
          <div className="cc__story__photo">
            {isVideo(story.photo) ?
              <video src={story.photo} controls title={t('story video')} />
            :
              <img src={story.photo} alt={t('story image')} />
            }            
          </div>
        }
        {story.contentMD && <StoryContent story={story} pledge={pledge} taggedUsers={taggedUsers} t={t} />}
        {(story.links && story.links.length > 0) &&
          <OpenGraphCarousel links={story.links} />
        }
        {story.competitionUserPointID !== 0 && pledge &&
          <div className="cc__story__pledge_stats">
            <div>
              <div>
                <span>{t('Points')}</span>&nbsp;
                <span className="cc__lighttext">{formatNumber(story.pointsEarned, 0)}</span>
              </div>
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('Pounds CO<sub>2</sub>')
                  }}
                />&nbsp;
                {Boolean(pledge.savingsCO2Lbs) && <span className="cc__lighttext">{formatNumber(pledge.savingsCO2Lbs, 0)}</span>}
              </div>
            </div>
            {isVerifiedCompetitionUser &&
              <div>
                {!userCompletionsLTPledgeRepeats &&
                  <img style={{ width: '36px' }} src="/assets/story/checkmark@2x.png" alt={t('action taken')} />
                }
                {(isUserEligibleForPledge && userCompletionsLTPledgeRepeats) &&
                  <Button
                    color="primary"
                    onClick={() => history.push(makePledgePath(pledge, t))}
                  >{t('Take action')}</Button>
                }
              </div>
            }
          </div>
        }
        <hr />
        {isVerifiedCompetitionUser &&
          <div>
            <div>
              <LinkButton
                onClick={() => toggleLike(story)}
              ><img src={`/assets/story/${liked ? 'filled-' : ''}heart.png`} alt={t('like')} /></LinkButton>
              <LinkButton
                onClick={() => toggleFlag(story)}
              ><img src={`/assets/story/${flagged ? 'filled-' : ''}flag.png`} alt={t('like')} /></LinkButton>
            </div>
            <StoryControls
              t={t}
              editStory={editStory}
              openConfirmStoryDelete={openConfirmStoryDelete}
              storyID={story.id}
              isCreator={isCreator}
            />
          </div>
        }
        <div className="cc__bold">{story.likes} {story.likes === 1 ? t('like') : t('likes')}</div>
        <div>
          {comments.map((comment) =>
            <CommentComponent
              key={comment.id}
              comment={comment}
            />)}
        </div>
        {isVerifiedCompetitionUser &&
          <div>
            <hr />
            <CommentPrompt
              comment={{ commentableID: story.id, commentableType: Comment.CommentableTypeEnum.Story }}
            />
          </div>
        }
      </div>)
  }
}

const mapStateToProps = (state: State, ownProps: ManualProps) => {
  const pledge = ownProps.story.competitionPledgeID !== 0 ? state.competitionPledges[ownProps.story.competitionPledgeID] : null;
  return {
    pledge,
    taggedUsers: selectors.taggedUsers(state, ownProps),
    creator: selectors.storyCreator(state, ownProps),
    isCreator: state.user && state.user.id === ownProps.story.createdBy,
    university: selectors.acceptedUniversity(state, ownProps.story.createdBy),
    userCompletionsLTPledgeRepeats: selectors.userCompletionsLTPledgeRepeats(state, ownProps),
    isUserEligibleForPledge: Boolean(pledge) && selectors.isUserEligibleForPledge(state, pledge),
    liked: state.storyLikes[ownProps.story.id],
    flagged: state.storyFlags[ownProps.story.id],
    comments: state.storyComments[ownProps.story.id] || [],
    isVerifiedCompetitionUser: selectors.isVerifiedCompetitionUser(state),
    editing: state.editingStoryID === ownProps.story.id
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ManualProps) => bindActionCreators({
  toggleLike: actions.toggleStoryLike,
  toggleFlag: actions.toggleStoryFlag,
  editStory: () => actions.editStory(ownProps.story.id),
  openConfirmStoryDelete: () => actions.openConfirmStoryDelete(ownProps.story),
  toast: actions.toast
}, dispatch);

export default compose(
  withLocalization('story'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(StoryComponent) as React.SFC<ManualProps>;;
