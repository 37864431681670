import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { State } from '../redux/state';
import { withLocalization, LProps } from '../utils/wrappers';
import { CompetitionUserScore, UserRead } from '../userApi';
import { Link } from 'react-router-dom';
import * as selectors from '../redux/selectors';
import { makeUserPath } from '../utils/routerHelper';
import { displayFullName } from '../utils/userApiHelper';

interface ManualUserScoreProps {
  score: CompetitionUserScore;
  rank: number;
  showRank: boolean;
}

interface UserScoreProps extends ManualUserScoreProps {
  user: UserRead;
}

const UserScore = ({
  rank, score, user, formatNumber, showRank, i18n
}: LProps<UserScoreProps>) => user ? (
  <tr>
    {showRank && <td>#{rank}</td>}
    <td>
      <Link to={makeUserPath(score.competitionID, user, i18n.getFixedT(i18n.language, 'scoreboard'))}>{displayFullName(user)}</Link>
    </td>
    <td className="cc__scoreboard__points">{formatNumber(score.points, 0)}</td>
  </tr>
):null;

const ConnectedUserScore = compose(
  withLocalization('scoreboard'),
  connect((state: State, ownProps: ManualUserScoreProps) => ({
    user: selectors.someUser(state, ownProps.score.userID)
  }))
)(UserScore) as React.SFC<ManualUserScoreProps>;

interface ManualProps {
  userScores: CompetitionUserScore[];
  showRank?: boolean;
  viewAll?: string;
}

const ScoreboardParticipants = ({
  t, userScores, showRank = true, viewAll = ''
}: LProps<ManualProps>) => (
  <div>
    {viewAll && <Link to={viewAll}>{t('View all >')}</Link>}
    <table>
      <thead>
        <tr>
          {showRank && <th />}
          <th />
          <th className="cc__scoreboard__points">{t('Points')}</th>
        </tr>
      </thead>
      <tbody>
        {userScores?.map((userScore, i) => (
          <ConnectedUserScore
            key={userScore.userID}
            score={userScore}
            rank={i + 1}
            showRank={showRank}
          />
        ))}
      </tbody>
    </table>
  </div>
);

export default compose(
  withLocalization('scoreboard')
)(ScoreboardParticipants) as React.SFC<ManualProps>;
