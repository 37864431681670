import * as React from 'react';
import up from 'updeep';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { isEmpty, trim } from 'lodash';
import { FormGroup, Input, Button, FormFeedback, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { State } from '../../redux/state';
import actions from '../../redux/actions';
import { LProps, withLocalization } from '../../utils/wrappers';
import { ForgotPasswordErrors, makeForgotPasswordErrors, forgotPasswordVEMessages } from '../../utils/forms';
import { ValidationErrors, removeVEForField } from '../../userApi';

interface Props {
  validationErrors: ValidationErrors;
  postPasswordResetRequest: (form: { email: string }) => void;
  patch: (update: Partial<State>) => void;
}

interface ForgotPasswordState {
  errors: Partial<ForgotPasswordErrors>;
  form: { email: string };
  dirty: { [key: string]: boolean };
  submitted: boolean;
}

const initialState: ForgotPasswordState = {
  errors: {},
  form: { email: '' },
  dirty: {},
  submitted: false
};

class ForgotPassword extends React.Component<LProps<Props>, ForgotPasswordState> {
  public state = initialState;

  private update(update: Partial<ForgotPasswordState>, validate: boolean = false) {
    const { dirty, submitted, form } = this.state;
    const { validationErrors, t } = this.props;
    if (validate) {
      const errors = makeForgotPasswordErrors({ dirty, submitted, ...form }, validationErrors, t);
      if (!isEmpty(errors)) {
        update.errors = errors;
      }
    }
    this.setState(update as ForgotPasswordState);
  }

  private markDirty(field: string) {
    const { submitted, form } = this.state;
    const dirty = { ...this.state.dirty, [field]: true };
    const { validationErrors, t, patch } = this.props;
    let ve = validationErrors;
    if (forgotPasswordVEMessages[field]) {
      ve = removeVEForField(field, validationErrors, forgotPasswordVEMessages);
      patch({ forgotPasswordVE: ve });
    }

    const update: Partial<ForgotPasswordState> = { dirty: up({ [field]: true }, this.state.dirty) };
    const errors = makeForgotPasswordErrors({ dirty, submitted, ...form }, ve, t);
    update.errors = errors;
    this.setState({ errors, dirty });
  }

  private submit() {
    const { postPasswordResetRequest, validationErrors, t } = this.props;
    const submitted = true;
    const { form, dirty } = this.state;

    const errors = makeForgotPasswordErrors({ dirty, submitted, ...form }, validationErrors, t);
    if (isEmpty(errors)) {
      postPasswordResetRequest(form);
    }
    this.setState({ submitted, errors });
  }

  public render() {
    const { form, errors } = this.state;
    const {
      t
    } = this.props;
    return (
      <div id="forgot_password" className="home__form">
        <FormGroup
          id="forgot_password__email"
        >
          <Label>{t('Email*')}</Label>
          <Input
            invalid={Boolean(errors.email)}
            value={form.email}
            onBlur={() => this.markDirty('email')}
            onChange={(e) => this.update({ form: { ...form, email: trim(e.target.value || '').toLowerCase() } })}
          />
          {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </FormGroup>
        <Button
          block={true}
          onClick={() => this.submit()}
        >{t('Send link')}</Button>
      </div>
    );
  }
}

const mapStateToProps = (state: State, _ownProps: Props): Partial<Props> => ({
  validationErrors: state.forgotPasswordVE
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  postPasswordResetRequest: actions.postPasswordResetRequest,
  patch: actions.patch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('forgotPassword')
)(ForgotPassword) as React.ComponentClass;
