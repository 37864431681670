// @ts-nocheck
import * as React from 'react';
import { Dispatch, bindActionCreators, compose } from 'redux';
import actions from '../../redux/actions';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { StyleState } from '../../utils/styles';
import logger from '../../utils/logger';
import { LProps, withLocalization } from '../../utils/wrappers';
import { WaitingPageContent } from '../../shared/WaitingPage';
import { asPage } from '../../utils/wrappers/asPage';
import { State } from '../../redux/state';
import { isProduction } from '../../utils/environment';
import { calculatorAvailablePoints } from '../../redux/selectors';
import StoryPoints from '../../shared/StoryPoints';

interface Props {
  token: string;
  availablePoints: number;
  createCalculatorPledgePoints: () => void;
  updateFootprint: (footprint:number) => void;
}

const containerID = 'cc__calculator';

const waitForCalculator = (maxWait: number) => new Promise((resolve, fail) => {
  if (typeof (window as any).Widget !== 'undefined') {
    resolve();
    return;
  }
  const start = Date.now();
  const wait = setInterval(() => {
    if (typeof (window as any).Widget !== 'undefined') {
      resolve();
      clearInterval(wait);
      return;
    } else if (Date.now() - start > maxWait) {
      fail(new Error('Calculator failed to initialize.'));
      clearInterval(wait);
      return;
    }
  }, 500);
});

interface CalculatorPageState {
  isCalculatorReady: boolean;
  error: boolean;
  availablePoints: number;
}

const initialState: CalculatorPageState = { isCalculatorReady: false, error: false, availablePoints: 500 };

class CalculatorPage extends React.Component<LProps<Props>> {
  private widget: calculatorWidget.Widget = null;
  public state = initialState;

  public async componentDidMount() {
    try {
      await waitForCalculator(4000);
      this.setState({ isCalculatorReady: true });
    } catch (err) {
      logger.error(err);
      this.setState({ error: true });
      return;
    }

    const { createCalculatorPledgePoints, availablePoints,updateFootprint } = this.props;
    const callbacks: calculatorWidget.CallBacks = {
      'page:visited': (update) => {
        if (update.allPagesVisited && availablePoints !== 0) createCalculatorPledgePoints();
      },
      onChange:function(ev:any){
        //localStorage.setItem("footprint",ev.value)
        updateFootprint(ev.value)
        //console.log("Widget change", ev)
      },
    };
    const options: calculatorWidget.Options = {
      container_id: containerID,
      source: 'coolcampuschallenge2019',
      campaign: 'carboncalculator',
      hideUserPlatform: true,
      url: 'https://coolclimate-calculator-ui.firebaseapp.com',
      callbacks
    };
    if (this.props.token && isProduction) {
      // currently, widget only points to production.
      options.token = this.props.token;
    }

    this.widget = new (window as any).Widget(options) as calculatorWidget.Widget;
  }

  public componentWillUnmount() {
    // we should kill event call backs here, but since this is a page,
    // we should be OK.
  }

  public render() {
    const { error, isCalculatorReady } = this.state;
    const { t, availablePoints, formatNumber } = this.props;
    if (error) {
      return (
        <Alert
          color={StyleState.Danger}
        >{t('It appears we had an issue loading the calculator. Please try again later.')}</Alert>
      );
    } else if (!isCalculatorReady) {
      return <WaitingPageContent />;
    }
    return (
      <div id="cc__calculator_page">
        <StoryPoints />
        {availablePoints !== 0 && <h4>{formatNumber(availablePoints, 0)}&nbsp;{t('points')}</h4>}
        {availablePoints === 0 &&
        <h4 className="cc__lighttext">
          <img style={{ width: '36px' }} src="/assets/story/checkmark@2x.png" alt={t('action taken')} />&nbsp;
          {t('Complete')}
        </h4>}
        <div id={containerID} />
      </div>);
  }
}

const mapStateToProps = (state: State): Partial<Props> => ({
  token: state.token,
  availablePoints: calculatorAvailablePoints(state)
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  createCalculatorPledgePoints: actions.createCalculatorPledgePoints,
  updateFootprint: actions.updateFootprint
}, dispatch);

export default asPage({})(compose(
  withLocalization('calculator'),
  connect(mapStateToProps, mapDispatchToProps)
)(CalculatorPage) as React.SFC);
