import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from '../redux/state';
import { withLocalization, LProps } from '../utils/wrappers';
import { CompetitionEntityScore, Entity, CompetitionEntity } from '../userApi';
import { Link } from 'react-router-dom';
import { makeEntityPath } from '../utils/routerHelper';

interface ManualCampusScoreProps {
  score: CompetitionEntityScore;
  rank: number;
}

interface CampusScoreProps extends ManualCampusScoreProps {
  competitionEntity: CompetitionEntity;
  university: Entity;
}

const CampusScore = ({
  rank, score, university, competitionEntity, t, formatNumber
}: LProps<CampusScoreProps>) => (
  <tr>
    <td>#{rank}</td>
    <td>
      <Link to={makeEntityPath(competitionEntity.competitionID, university, t)}>{university.abbreviation}</Link>
    </td>
    <td className="cc__scoreboard__percentage">{competitionEntity.memberCount}</td>
    <td className="cc__scoreboard__points">{formatNumber(score.points, 0)}</td>
  </tr>
);

const ConnectedCampusScore = compose(
  connect((state: State, ownProps: ManualCampusScoreProps) => ({
    competitionEntity: state.competitionEntities[ownProps.score.entityID],
    university: state.entities[ownProps.score.entityID]
  })),
  withLocalization('scoreboard')
)(CampusScore) as React.ComponentClass<ManualCampusScoreProps>;

interface Props {
  campusScores: CompetitionEntityScore[];
}

const ScoreboardCampuses = ({
  t, campusScores
}: LProps<Props>) => (
  <table>
    <thead>
      <tr>
        <th />
        <th />
        <th className="cc__scoreboard__percentage">{t('Participants')}</th>
        <th className="cc__scoreboard__points">{t('Points')}</th>
      </tr>
    </thead>
    <tbody>
      {campusScores.map((entityScore, i) => (
        <ConnectedCampusScore
          key={entityScore.id}
          score={entityScore}
          rank={i + 1}
        />
      ))}
    </tbody>
  </table>
);

const mapStateToProps = (state: State, _ownProps: Props): Props => ({
  campusScores: state.competitionEntityScores?.university || []
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('scoreboard')
)(ScoreboardCampuses) as React.ComponentClass;
