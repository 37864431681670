import { Dispatch } from 'redux';
import { pick } from 'lodash';
import up from 'updeep';
import { UserUpdateForm } from '../../utils/forms';
import { State, Modals } from '../state';
import { ExtraArguments } from '../extraArguments';
import { patch } from './patch';
import * as selectors from '../selectors';
import { ValidationError, matchesErrorCode, UpdateUserParams, ImageResponse, UserRead } from '../../userApi';
import { handleUnexpectedError } from './handleUnexpectedError';
import { postImage } from './image';
import { getValidationErrors } from '../../utils/userApiHelper';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { trackEvent } from '../../utils/googleAnalytics';

export const removeUserUpdateVE = (code: ValidationError.CodeEnum) => async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  const { userUpdateVE } = getState();
  dispatch(patch({
    userUpdateVE: up(up.reject<ValidationError>(matchesErrorCode(code)), userUpdateVE)
  }));
};

export const putUser = (userUpdateForm: UserUpdateForm, fileInputID: string, userUpdateID: string) =>
async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { i18n, getImage } = extra;
  const t = i18n.getFixedT(i18n.language, 'userUpdate');
  dispatch(patch({ spinner: true }));

  let imageResponse: ImageResponse;
  if (getImage(fileInputID)) {
    imageResponse = await postImage(fileInputID)(dispatch, getState, extra);
    if (!imageResponse) return;
    dispatch(patch({ user: ({ profilePhoto: imageResponse.url } as UserRead) }));
    userUpdateForm.user = { ...userUpdateForm.user, profilePhoto: imageResponse.url };
  }

  try {
    const state = getState();
    const api = selectors.userApi(state);
    const { user } = state;
    const params = pick(userUpdateForm, ['user', 'password', 'newPassword']) as UpdateUserParams;
    if (!params.newPassword) delete params.newPassword;
    if (!params.user.profilePhoto) delete params.user.profilePhoto;
    const res = await api.putUser(user.id, params);
    trackEvent('account', 'update');
    const { modal } = getState();
    dispatch(patch({
      ...res,
      spinner: false,
      savedUserUpdateID: userUpdateID,
      modal: modal === Modals.UserUpdatePassword ? null : modal
    }));
    toast({
      style: StyleState.Success,
      message: t('Your update was saved successfully')
    })(dispatch, getState);
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (!validationErrors) {
      dispatch(patch({ modal: null }));
      handleUnexpectedError(err)(dispatch, getState);
      trackEvent('account', 'unhandledError', 'update');
      return;
    }
    const update: Partial<State> = {
      userUpdateVE: validationErrors,
      spinner: false,
      modal: null
    };
    if (validationErrors.length === 1 && validationErrors.some((e) => e.field === 'password')) {
      // leave modal open if password is incorrect.
      delete update.modal;
    }
    dispatch(patch(update));
  }
};
