import up from 'updeep';
import { patch } from './patch';
import initialState, { State } from '../state';
import { Dispatch } from 'redux';
import { handleUserApiError } from './handleUnexpectedError';
import * as selectors from '../selectors';
import { ExtraArguments } from '../extraArguments';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { getValidationErrors } from '../../utils/userApiHelper';
import { makeSimpleRoutePath, Routes } from '../../utils/routerHelper';
import { trackEvent } from '../../utils/googleAnalytics';

export const postPasswordResetRequest = (params: { email: string }) => async (dispatch: Dispatch, getState: () => State, { i18n, history }: ExtraArguments) => {
  const t = i18n.getFixedT(i18n.language, 'forgotPassword');
  const state = getState();
  const api = selectors.userApi(state);
  dispatch(patch({ spinner: true }));
  try {
    await api.postPasswordResetRequest(state.competition.name, params);
    dispatch(patch({
      spinner: false,
      forgotPasswordVE: up.constant(initialState.forgotPasswordVE)
    }));
    history.push(makeSimpleRoutePath(Routes.Login, i18n.t));
    toast({
      style: StyleState.Success,
      message: t('Link sent! Make sure to use it within an hour.')
    })(dispatch, getState);
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors) {
      dispatch(patch({
        forgotPasswordVE: validationErrors,
        spinner: false
      }));
      return;
    }
    handleUserApiError(err)(dispatch, getState);
  }
};
