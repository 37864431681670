import * as React from 'react';
import * as moment from 'moment';
import SafeHTML from './SafeHtml';
import { ButtonGroup, Button } from 'reactstrap';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { withLocalization, LProps } from '../utils/wrappers';
import displayContent from './displayContent';
import { UserRead, Competition } from '../userApi';
import { makeUserPath } from '../utils/routerHelper';
import { displayFullName } from '../utils/userApiHelper';
import { State } from '../redux/state';
import { connect } from 'react-redux';

interface ManualProps {
  contentHTML: string;
  title: string;
  date: string;
  photo: string;
  creator?: UserRead;
  showControls?: boolean;
  previousURL?: string;
  allURL?: string;
  nextURL?: string;
  children?: React.ReactNode;
  onEdit?: () => void;
  onDelete?: () => void;
}

interface Props extends ManualProps {
  competition: Competition;
}

interface SafeHTMLPageContentState {
  isConfirmingDelete: boolean;
}

class SafeHTMLPageContent extends React.Component<LProps<Props>, SafeHTMLPageContentState> {
  public state = { isConfirmingDelete: false };

  public render() {
    const {
      photo, title, contentHTML, date, onEdit, onDelete, showControls, competition,
      previousURL, allURL, nextURL, t, children, creator
    } = this.props;
    return (
      <div className="cc__html_page_content cc_blog_page">
        {photo && <div className="cc__html_page__photo"><img src={photo} alt={title} /></div>}
        <div className="cc__page__column_content">
          <div className="cc__page__column_content__left">
            <div className="cc__h3 cc__bluetext">{title}</div>
            {creator && <Link to={makeUserPath(competition.id, creator, t)}>{displayFullName(creator)}</Link>}
            <div className="cc__lighttext">{moment(date).format('MMMM D, YYYY')}</div>
            {(onEdit || onDelete) &&
              <ButtonGroup>
                {onEdit && <Button aria-label={t('Edit')} onClick={onEdit}><i className="fa fa-pencil" /></Button>}
                {onDelete && <Button aria-label={t('Delete')} outline={true} color="danger" onClick={() => this.setState({ isConfirmingDelete: true })}><i className="fa fa-times" /></Button>}
              </ButtonGroup>
            }
          </div>
          <div className="cc__page__column_content__right cc__lighttext">
            <SafeHTML contentHTML={contentHTML} />
          </div>
        </div>
        {onDelete &&
          <ConfirmDeleteModal
            isOpen={this.state.isConfirmingDelete}
            confirm={onDelete}
            cancel={() => this.setState({ isConfirmingDelete: false })}
          />
        }
        {showControls &&
          <div className="cc__html_page__controls cc__space_around cc__lighttext">
            <div>
              {previousURL && <Link to={previousURL}>{t('Previous')}</Link>}
              {!previousURL && <span>{t('Previous')}</span>}
            </div>
            <div>
              {allURL && <Link to={allURL}>{t('All')}</Link>}
              {!allURL && <span>&nbsp;</span>}
            </div>
            <div>
              {nextURL && <Link to={nextURL}>{t('Next')}</Link>}
              {!nextURL && <span>{t('Next')}</span>}
            </div>
          </div>
        }
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  competition: state.competition
});

export default compose(
  withLocalization('safeHTMLPage'),
  connect(mapStateToProps)
)(SafeHTMLPageContent) as React.SFC<ManualProps>;
