import * as React from 'react';
import Page, { PageProps } from '../../shared/Page';

export const asPage = (props: Partial<PageProps>) => {
  return (component: React.ComponentClass | React.SFC) => () => React.createElement(
    Page,
    {
      component,
      ...props
    }
  );
};
