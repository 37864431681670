import { TranslationFunction } from 'i18next';
import { pickBy } from 'lodash';
import { ValidationError, PostUserEmailParams, ValidationErrorMessages, getValidationErrorMessage } from '../../userApi';
import { validator, makeErrorMessage } from '../validations';
import { Form, validationReady } from './form';

const validation = {
  type: 'object',
  required: ['email'],
  properties: {
    email: { type: 'string', format: 'email', minLength: 5 }
  }
};

export const userEmailVEMessages: ValidationErrorMessages = {
  email: {
    [ValidationError.CodeEnum.ErrUserEmailExists]: 'A user has already registered that email.',
    [ValidationError.CodeEnum.ErrEmailNonUnique]: 'A user has already registered that email.'
  }
};

export type UserEmailForm = PostUserEmailParams & Form;

export interface UserEmailErrors {
  email: string;
}

export const makeUserEmailErrors = (form: Partial<UserEmailForm>, validationErrors: ValidationError[], t: TranslationFunction): Partial<UserEmailErrors> => {
  let errorMessages: Partial<UserEmailErrors> = {};
  if (!validator.validate(validation, form)) {
    const { errors } = validator;
    errorMessages = {
      email: makeErrorMessage(errors, '.email', t('Must be valid email.'), validationReady(form as Form, 'email'))
    };
  }
  const emailVEMsg = getValidationErrorMessage('email', validationErrors, userEmailVEMessages);
  if (!errorMessages.email && emailVEMsg) {
    errorMessages.email = t(emailVEMsg);
  }
  return pickBy(errorMessages);
};
