import * as React from 'react';
import BlogEntryForm from '../../shared/BlogEntryForm';
import { RouterProps, withRouter } from 'react-router';
import { getBlogEntryNewParams, Routes } from '../../utils/routerHelper';
import { LProps, withLocalization } from '../../utils/wrappers';
import { State } from '../../redux/state';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { asPage } from '../../utils/wrappers/asPage';
import actions from '../../redux/actions';
import { WaitingPageContent } from '../../shared/WaitingPage';

interface Props {
  entityID: number;
  initializing: boolean;
  initializeBlogEntryForm: (blogEntryID: number) => void;
}

class BlogEntryNewPage extends React.Component<Props> {

  public componentWillMount() {
    const { initializeBlogEntryForm } = this.props;
    initializeBlogEntryForm(0);
  }

  public render() {
    const { entityID, initializing } = this.props;
    if (initializing) return (<WaitingPageContent />);
    return (<BlogEntryForm blogEntry={{ id: 0, entityID }} />);
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => {
  const { pendingRoute } = state;
  if (pendingRoute === Routes.BlogEntryNew) return { initializing: true };
  const { entityID } = getBlogEntryNewParams(ownProps.history.location, ownProps.i18n);
  return ({ entityID, initializing: false });
};
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: LProps<RouterProps>) => bindActionCreators({
  initializeBlogEntryForm: actions.initializeBlogEntryForm
}, dispatch);

export default asPage({})(compose(
  withRouter,
  withLocalization('routes'),
  connect(mapStateToProps, mapDispatchToProps)
)(BlogEntryNewPage) as React.ComponentClass);
