import * as React from 'react';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose } from 'redux';
import { TranslationFunction } from 'i18next';
import { asPage } from '../../utils/wrappers/asPage';

interface ShareItemProps {
  content: string;
  title: string;
  img: string;
  download: string;
  t: TranslationFunction;
}

const ShareItem = ({
  title, content, img, t
}: ShareItemProps) => (
  <div className="share__share-item">
    <img src={img} alt={title} />
    <h4>{title}</h4>
    <p className="cc__lighttext">{content}</p>
    <a href={img} download={true}>{t('Download')} &gt;</a>
  </div>
);

const Share = ({ t }: LProps<{}>) => (
  <div id="cc__share" className="cc__page__column_content">
    <div className="cc__page__column_content__left cc__lighttext">
      <p>
          {t('Want to help us spread the word about the Challenge? You\'ll find all you need to know about promotion on and offline in our spiffy new toolkit.')}
      </p>
      <p>{t('Here are links to some additional resources that might come in handy:')}</p>
    </div>
    <div className="cc__page__column_content__right">
      <ShareItem
        t={t}
        title={t('Social Media Backgrounds')}
        content={t('Campus-specific backgrounds for Facebook and Twitter.')}
        img="/assets/social/social_media_backgrounds.png"
        download="/assets/downloads/social_media_backgrounds.zip"
      />
      <ShareItem
        t={t}
        title={t('CCC Challenge Stamp')}
        content={t('Circular stamps in various colors for use on print and digital pieces.')}
        img="/assets/social/challenge_stamps.png"
        download="/assets/downloads/challenge_stamps.zip"
      />
      <ShareItem
        t={t}
        title={t('Posters')}
        content={t('8.5x11" posters.')}
        img="/assets/social/posters.png"
        download="/assets/downloads/posters.zip"
      />
      <ShareItem
        t={t}
        title={t('Social Media Icons')}
        content={t('A variety of fun, lighthearted meme graphics formatted for use on Twitter, Facebook and Instagram.')}
        img="/assets/social/social_media_icons.png"
        download="/assets/downloads/social_media_icons.zip"
      />
      <ShareItem
        t={t}
        title={t('Carbon Neutrality Logos')}
        content={t('Digital and vector versions of the Carbon Neutrality Initiative wordmark lock-up.')}
        img="/assets/social/carbon_neutrality_logos.png"
        download="/assets/downloads/carbon_neutrality_logos.zip"
      />
    </div>
  </div>
);

export default asPage({})(compose(
  withLocalization('share')
)(Share));
