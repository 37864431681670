import * as React from 'react';
import { times } from 'lodash';

interface Props {
  length: number;
  active?: number;
  vertical?: boolean;
  height?: string;
  width?: string;
  fill?: string;
  containerStyle?: React.CSSProperties;
}

const Indicators = ({
  length, active, vertical,
  containerStyle = {},
  height = '16px', width = '16px', fill = 'white'
}: Props) => (
  <div
    style={{
      display: 'flex', justifyContent: 'space-around', alignItems: 'center',
      flexDirection: vertical ? 'column' : 'row', ...containerStyle
    }}
  >
    {times(length).map((n) => (
      <div key={n} style={{
        border: `1px solid ${fill}`,
        borderRadius: '50%',
        height, width,
        backgroundColor: active === n ? fill : 'transparent',
        margin: '6px'
      }} />
    ))}
    </div>
);

export default Indicators;
