import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRead, CompetitionUser, Competition, Entity, CompetitionUserScore, CompetitionScoreLevel } from '../../userApi';
import { State } from '../../redux/state';
import * as selectors from '../../redux/selectors';
import actions from '../../redux/actions';
import { LProps } from '../../utils/wrappers';
import UserHeader from '../../shared/UserHeader';
import UserStats from '../../shared/UserStats';
import UserScoreLevel from '../../shared/UserScoreLevel';
import Scoreboard from '../../shared/Scoreboard';
import Stories from '../../shared/Stories';
import PledgeCarousel from '../../shared/PledgeCarousel';
import Footer from '../../shared/Footer';
import EarnMorePoints from './EarnMorePoints';
import { asPage } from '../../utils/wrappers/asPage';
{/*import CampusRanks from './CampusRanks'; Get rid of Campus Ranking on dashboard.*/}

interface Props {
  user: UserRead;
  competitionUser: CompetitionUser;
  competition: Competition;
  hasEmailPermittedByCompetition: boolean;
  university: Entity;
  healthFacility: Entity;
  team: Entity;
  score: CompetitionUserScore;
  userUniversityRank: number;
  pledgeCompletions: number;
  scoreLevel: CompetitionScoreLevel;
  nextScoreLevel: CompetitionScoreLevel;
  reinitializeDashboardIfNecessary: () => void;
}

interface DashboardState {
  mobileSection: number;
}
enum MobileSection {
  ACCOUNT = 1,
  STORIES = 2,
  STATS = 4
}

const initialState: DashboardState = {
  mobileSection: MobileSection.STORIES
};

class Dashboard extends React.Component<LProps<Props>, DashboardState> {
  public state = initialState;

  public componentWillMount() {
    const { reinitializeDashboardIfNecessary } = this.props;
    reinitializeDashboardIfNecessary();
  }

  public render() {
    const {
      user, competitionUser, university, healthFacility, team, score,
      userUniversityRank, pledgeCompletions, scoreLevel, nextScoreLevel, competition
    } = this.props;
    return (
      <div id="dashboard">
        <div className="cc__page__column_content">
          <div
            className={'cc__page__column_content__left ' + this.getActiveClass(MobileSection.STATS | MobileSection.ACCOUNT)}
          >
            <div className={this.getActiveClass(MobileSection.ACCOUNT)}>
              <UserHeader
                user={user}
                competition={competition}
                university={university}
                healthFacility={healthFacility}
                team={team}
              />
              {score &&
                <UserStats
                  score={score}
                  footprint={competitionUser.footprint}
                  //userUniversityRank={userUniversityRank}
                  pledgeCompletions={pledgeCompletions}
                />
              }
              <UserScoreLevel
                score={score}
                scoreLevel={scoreLevel}
                nextScoreLevel={nextScoreLevel}
              />
            </div>
            <div className={'cc__white_box ' + this.getActiveClass(MobileSection.STATS)}>
              <Scoreboard />
            </div>
            {/*{university && <CampusRanks />  This gets rid of the 'Campus Ranking' on dashboard.*/}
            <div className={this.getActiveClass(MobileSection.ACCOUNT)}>
              {competitionUser.verified && <EarnMorePoints />}
            </div>
            <Footer />
          </div>
          <div role="main" className={'cc__page__column_content__right ' + this.getActiveClass(MobileSection.STORIES)}>
            <PledgeCarousel />
            <Stories />
          </div>
        </div>
        <div className="cc__dashboard__bottom_menu">
          <div role="button" className="cc__dashboard__bottom_menu__account" onClick={() => this.selectSection(MobileSection.STORIES)}>
            <img
              alt={'activity'}
              src={this.getSrc(MobileSection.STORIES, 'activity')}
              srcSet={this.getSrcSet(MobileSection.STORIES, 'activity')} />
          </div>
          <div role="button" className="cc__dashboard__bottom_menu__stories" onClick={() => this.selectSection(MobileSection.ACCOUNT)}>
            <img
              alt={'profile'}
              src={this.getSrc(MobileSection.ACCOUNT, 'profile')}
              srcSet={this.getSrcSet(MobileSection.ACCOUNT, 'profile')} />
          </div>
          <div role="button" className="cc__dashboard__bottom_menu__statistics" onClick={() => this.selectSection(MobileSection.STATS)}>
            <img
              alt={'scoreboard'}
              src={this.getSrc(MobileSection.STATS, 'scoreboard')}
              srcSet={this.getSrcSet(MobileSection.STATS, 'scoreboard')} />
          </div>
        </div>
      </div>
    );
  }

  private selectSection(index: number) {
    this.setState({
      mobileSection: index
    });
  }

  private getActiveClass(index: number) {
    return (index & this.state.mobileSection) === this.state.mobileSection ? 'cc__mobile_show' : 'cc__mobile_hide';
  }

  private getSrc(index: number, image: string) {
    const selected = index === this.state.mobileSection ? '-selected' : '';
    return `/assets/dashboard/mobile/${image}${selected}.png`;
  }

  private getSrcSet(index: number, image: string) {
    const selected = index === this.state.mobileSection ? '-selected' : '';
    return `/assets/dashboard/mobile/${image}${selected}@2x.png 2x,
            /assets/dashboard/mobile/${image}${selected}@3x.png 3x`;
  }
}

const mapStateToProps = (state: State, _ownProps: Props): Partial<Props> => ({
  university: selectors.userUniversity(state),
  healthFacility: selectors.userHealthFacility(state),
  team: selectors.userTeam(state),
  user: state.user,
  competitionUser: state.competitionUser,
  competition: state.competition,
  score: state.competitionUserScores[state.user.id],
  scoreLevel: selectors.userScoreLevel(state),
  nextScoreLevel: selectors.nextUserScoreLevel(state),
  userUniversityRank: selectors.userUniversityRank(state),
  pledgeCompletions: selectors.userTotalPledges(state),
  hasEmailPermittedByCompetition: selectors.hasEmailPermittedByCompetition(state)
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  reinitializeDashboardIfNecessary: actions.reinitializeDashboardIfNecessary
}, dispatch);

export default asPage({ isGrey: true, requiresCompetitionStart: true, requiresCompetitionVerification: true })(compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard));
