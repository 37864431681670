import * as React from 'react';
import up from 'updeep';
import { FormGroup, Input, FormFeedback, Button, Collapse, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import { TranslationFunction } from 'i18next';
import { EntityForm, EntityErrors, makeEntityErrors } from '../utils/forms';
import { State } from '../redux/state';
import actions from '../redux/actions';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { withLocalization } from '../utils/wrappers';
import { connect } from 'react-redux';
import MarkdownEditor from '../shared/MarkdownEditor';
import ImageUploader from './ImageUploader/ImageUploader';
import { RouterProps, withRouter } from 'react-router';
import { makeEntityPath } from '../utils/routerHelper';
import { Competition, Entity } from '../userApi';
import SocialLinksForm from './SocialLinksForm';

interface ManualProps {
  entity: Partial<Entity>;
}

interface Props extends ManualProps {
  t: TranslationFunction;
  competition: Competition;
  bannerInputID: string;
  photoInputID: string;
  postedPhoto: string;
  postedBanner: string;
  clearPostedImage: (inputID: string) => void;
  save: (entity: EntityForm, photoInputID: string, bannerInputID: string) => void;
}

const bannerInputIDBase = 'entity__banner__input';
const photoInputIDBase = 'entity__photo__input';

interface EntityFormState {
  form: Partial<Entity>;
  errors: Partial<EntityErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
  editingSocial: boolean;
}

const initialState: EntityFormState = {
  form: {
    id: 0,
    name: '',
    // We can only create teams through UI.
    type: Entity.TypeEnum.Team,
    descriptionMD: '',
    photo: '',
    bannerPhoto: '',
    permittedDomains: [],
    publicJoin: true
  },
  errors: {},
  dirty: {},
  submitted: false,
  editingSocial: false
};

const wasPhotoUploaded = (nextProps: Props, form: Entity) => nextProps.postedPhoto && form.photo !== nextProps.postedPhoto;
const wasBannerUploaded = (nextProps: Props, form: Entity) => nextProps.postedBanner && form.bannerPhoto !== nextProps.postedBanner;

class EntityFormComponent extends React.Component<Props & RouterProps, EntityFormState> {
  public state = initialState;

  public componentWillMount() {
    const { entity } = this.props;
    this.setState({
      form: up(entity, this.state.form)
    });
  }

  public componentWillReceiveProps(nextProps: Props & RouterProps) {
    const { form } = this.state;
    const { clearPostedImage, photoInputID, bannerInputID } = nextProps;
    if (wasPhotoUploaded(nextProps, form as Entity)) {
      this.setState({ form: { ...form, photo: nextProps.postedPhoto } });
      clearPostedImage(photoInputID);
    } else if (wasBannerUploaded(nextProps, form as Entity)) {
      this.setState({ form: { ...form, bannerPhoto: nextProps.postedBanner } });
      clearPostedImage(bannerInputID);
    }
  }

  private cancel() {
    const { form } = this.state;
    const { entity, history, t, competition } = this.props;
    // TODO delete from ImageStore.
    this.setState({
      ...initialState,
      form: entity
    });
    if (entity.id !== 0) {
      history.push(makeEntityPath(competition.id, form as Entity, t));
    }
  }

  private update(update: Partial<EntityFormState>, validate: boolean = false) {
    const { t } = this.props;
    this.setState(update as EntityFormState, () => {
      const { form, submitted, dirty } = this.state;
      if (validate) {
        const errors = makeEntityErrors({ ...form, ...update, dirty, submitted }, [], t);
        const validationUpdate: Partial<EntityFormState> = { errors };
        if (errors.socialLinks) {
          validationUpdate.editingSocial = true;
        }
        this.setState(validationUpdate as EntityFormState);
        this.setState({ errors });
      }
    });
  }

  private markDirty(field: string) {
    const { t } = this.props;
    const { form, submitted } = this.state;
    const dirty = { ...this.state.dirty, [field]: true };
    const errors = makeEntityErrors({ ...form, dirty, submitted }, [], t);
    const update: Partial<EntityFormState> = { errors, dirty };
    if (errors.socialLinks) {
      update.editingSocial = true;
    }
    this.setState(update as EntityFormState);
  }

  private submit() {
    const { save, photoInputID, bannerInputID, t } = this.props;
    const submitted = true;
    const { form, dirty } = this.state;
    const errors = makeEntityErrors({ ...form, dirty, submitted }, [], t);
    if (isEmpty(errors)) {
      save({ submitted, dirty, ...form } as EntityForm, photoInputID, bannerInputID);
    }
    const update: Partial<EntityFormState> = { errors, submitted };
    if (errors.socialLinks) {
      update.editingSocial = true;
    }
    this.setState(update as EntityFormState);
  }

  public render() {
    const { form, errors, editingSocial } = this.state;
    const {
      t, entity, bannerInputID, photoInputID
    } = this.props;
    if (!entity) return '';
    return (
      <div id="entity_form">
        <FormGroup id="entity_form__banner">
          <div style={{ display: 'flex' }}>
            <ImageUploader
              id={bannerInputID}
              banner={true}
              currentImage={form.bannerPhoto}
              readyPhotoClassName={'cc__entity_banner'}
              aspectRatio={t('6:1')}
              prompt={t('Upload your banner photo')}
              onDelete={() => this.update({ form: up({ bannerPhoto: '' }, form) })}
            />
          </div>
        </FormGroup>
        <FormGroup id="entity_form__photo">
          <ImageUploader
            id={photoInputID}
            currentImage={form.photo}
            photoClassName={'cc__entity_photo cc__rounded'}
            aspectRatio={t('1:1')}
            prompt={t('Upload logo')}
            onDelete={() => this.update({ form: up({ photo: '' }, form) })}
          />
          {errors.photo && <FormFeedback>{errors.photo}</FormFeedback>}
        </FormGroup>
        <FormGroup >
          <Label>{t('Name')}</Label>
          <Input
            aria-label={t('Name')}
            invalid={Boolean(errors.name)}
            id="new_team__name__input"
            type="text"
            name="name"
            onBlur={() => this.markDirty('name')}
            placeholder={t('Name')}
            onChange={(e) => this.update({ form: { ...form, name: e.target.value } })}
            value={form.name || ''}
          />
          {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
        </FormGroup>
        <FormGroup >
          <Label>{t('Abbreviation')}</Label>
          <Input
            aria-label={t('Abbreviation')}
            invalid={Boolean(errors.abbreviation)}
            id="new_team__name__input"
            type="text"
            name="name"
            onBlur={() => this.markDirty('abbreviation')}
            placeholder={t('Name')}
            onChange={(e) => this.update({ form: { ...form, abbreviation: e.target.value } })}
            value={form.abbreviation || ''}
          />
          {errors.abbreviation && <FormFeedback>{errors.abbreviation}</FormFeedback>}
        </FormGroup>
        <div>
          <div
            role="button"
            className="cc__bluetext"
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ editingSocial: !editingSocial })}
          >{editingSocial ? t('Close') : t('Edit social media links')}</div>
          <Collapse isOpen={editingSocial}>
            <div style={{ padding: '7px', marginBottom: '10px', width: '100%', textAlign: 'center' }}>
              <SocialLinksForm
                errors={errors.socialLinks || {}}
                socialLinks={entity.socialLinks}
                onChange={(socialLinks) => this.update({ form: { ...form, socialLinks } }, true)}
              />
            </div>
          </Collapse>
        </div>

        <FormGroup>
          <MarkdownEditor
            textAreaProps={{
              'aria-label': 'Markdown description',
              'onBlur': () => this.markDirty('descriptionMD')
            }}
            value={form.descriptionMD}
            onChange={(markdown) => this.update({ form: { ...form, descriptionMD: markdown } })}
          />
          {errors.descriptionMD && <FormFeedback>{errors.descriptionMD}</FormFeedback>}
        </FormGroup>
        <div className="cc__space_around cc__buttons">
          <Button
            onClick={() => this.submit()}
          >{t('Save')}</Button>
          <Button
            outline={true}
            color="danger"
            onClick={() => this.cancel()}
          >{t('Cancel')}</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: ManualProps): Partial<Props> => {
  if (!ownProps.entity) return {};
  const photoInputID = `${photoInputIDBase}${ownProps.entity.id}`;
  const bannerInputID = `${bannerInputIDBase}${ownProps.entity.id}`;
  return {
    competition: state.competition,
    photoInputID,
    bannerInputID,
    postedPhoto: state.postedImages[photoInputID],
    postedBanner: state.postedImages[bannerInputID]
  };
};
const mapDispatchToProps = (dispatch: Dispatch, _ownProps: {}): Partial<Props> => bindActionCreators({
  save: actions.saveEntity,
  clearPostedImage: actions.clearPostedImage
}, dispatch);

export default compose(
  withLocalization('entityForm'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EntityFormComponent) as React.SFC<ManualProps>;
