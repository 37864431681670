import * as React from 'react';
import { compose } from 'redux';
import { withLocalization, LProps } from '../../utils/wrappers';

export enum StatType {
  Participants = 'participants',
  Carbon = 'savings_carbon',
  Cars = 'cars',
  Pledges = 'actions_completed'
}

interface Props {
  statType: StatType;
  value: number;
  statLine: string;
  over?: boolean;
  perParticipant?: boolean;
}

const StatIcon = ({ statType, value, statLine, formatNumber, over = false, perParticipant = false }: LProps<Props>) => (
  <div className="stat-icon">
    <div className="stat-icon__icon-container">
      <img
        style={{ width: '55px' }}
        alt={statLine}
        src={`/assets/landing/${statType}.png`}
        srcSet={`/assets/landing/${statType}@2x.png 2x, /assets/landing/${statType}@3x.png 3x`}
      />
    </div>
    <div className="cc__stat__icon__number"
    >{over ? '> ' : ''}{formatNumber(Math.max(0.01, value))}</div>
    <div className="cc__stat__icon__text"
      dangerouslySetInnerHTML={{ __html: statLine}}
    />
    {perParticipant && <div style={{ textTransform: 'uppercase', fontSize: '16px', margin: 'auto', lineHeight: '16px' }}>per participant</div>}
  </div>
);

export default compose(
  withLocalization('common')
)(StatIcon) as React.SFC<Props>;
