import * as React from 'react';
import ReactMde from 'react-mde';

interface Props {
  value: string;
  textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  onChange: (markdown: string) => void;
}

interface MarkdownEditorState {
  converter: showdown.Converter;
  html: string;
  preview: boolean;
}

class MarkdownEditor extends React.Component<Props, MarkdownEditorState> {
  public state: MarkdownEditorState = { converter: null, html: '', preview: false };

  public async componentWillMount() {
    const { converter } = await import('../utils/markdown');
    this.setState({ converter });
  }

  public render() {
    const { converter } = this.state;
    if (converter === null) return '';

    const { onChange, value, textAreaProps = {} } = this.props;
    return (
      <ReactMde
        className={'cc__mde'}
        onChange={onChange}
        textAreaProps={textAreaProps}
        value={this.state.preview ? converter.makeHtml(value) : value}
        selectedTab={this.state.preview ? 'preview' : 'write'}
        onTabChange={() => this.setState({ preview: !this.state.preview  })}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
    );
  }
}

export default MarkdownEditor;
