import * as React from 'react';
import { getURLs, isURL } from '../utils/strings';
import ExternalLink from './ExternalLink';
import logger from '../utils/logger';

function* stringsToJSX(strings: string[]) {
  for (let i = 0; i < strings.length; i++) {
    const s = strings[i];
    if (isURL(s)) {
      yield (<ExternalLink href={s}>{s}</ExternalLink>);
    } else {
      const split = s.split(/[\n\r]+/g);
      for (let j = 0; j < split.length; j++) {
        yield split[j];
        if (j !== split.length - 1) {
          yield <br />;
        }
      }
    }
  }
}

const displayContent = (content: string) => {
  try {
    const urls = getURLs(content);
    const init: [string[], string] = [[], content];
    const reduction: [string[], string] = urls.reduce(([parts, remainder], url, i) => {
      const index = remainder.indexOf(url);
      const update: [string[], string] = [
        parts.concat([
          remainder.slice(0, index),
          remainder.slice(index, index + url.length)
        ]),
        remainder.slice(index + url.length)
      ];
      return update;
    }, init);
    reduction[0].push(reduction[1]);
    const a: Array<string | JSX.Element> = [];
    for (const elem of stringsToJSX(reduction[0])) {
      a.push(elem);
    }
    return a;
  } catch (err) {
    logger.error(new Error(`displayContent: ${err.message}`));
    return [content];
  }
};

export default displayContent;
