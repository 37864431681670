import * as React from 'react';
import { trackEvent } from '../utils/googleAnalytics';

interface Props {
  href: string;
  children: React.ReactNode;
}

const ExternalLink = ({ href, children }: Props) => (
  <a
    href={href}
    target={'_blank'}
    rel={'noopener noreferrer'}
    onClick={() => trackEvent('link', 'click', href)}
  >{children}</a>
);

export default ExternalLink;
