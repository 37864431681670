import * as React from 'react';
import { Switch, Route } from 'react-router';
import { Routes, makePathnames } from '../../utils/routerHelper';
import UserUpdate from './UserUpdate';
import UserEmailForm from './UserEmailForm';
import NavBar from '../../shared/NavBar';
import { asPage } from '../../utils/wrappers/asPage';
import { compose } from 'redux';
import { withLocalization, LProps } from '../../utils/wrappers';
import RouteLink from '../../shared/RouteLink';

const UpdateUser = ({
  t
}: LProps<{}>) => {
  const paths = makePathnames(t);
  return (
    <div className={'cc__page'}>
      <NavBar />
      <div className="cc__well">
        <h2>{t('Account')}</h2>
        <div>
          <RouteLink activable={true} route={Routes.Account}>{t('Profile')}</RouteLink>
          <RouteLink activable={true} route={Routes.Email}>{t('Email')}</RouteLink>
        </div>
      </div>
      <div className="cc__page__content" id="cc__page__content">
        <Switch>
          <Route path={paths[Routes.Email]} component={UserEmailForm} exact={true} />
          <Route component={UserUpdate} />
        </Switch>
      </div>
    </div>
  );
};

// const mapStateToProps = (state: State, _props: {}) => ({});
// const mapDispatchToProps = (dispatch: Dispatch, _props: Props): Partial<Props> => bindActionCreators({}, dispatch);

export default asPage({ raw: true })(compose(
  withLocalization('account')
  // connect(mapStateToProps, mapDispatchToProps)
)(UpdateUser));
