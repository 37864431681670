import * as React from 'react';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Button } from 'reactstrap';
import { withRouterAndHelpers, LProps, RouterProps, withLocalization } from '../../utils/wrappers';
import { connect } from 'react-redux';
import { Routes } from '../../utils/routerHelper';
import { scrollToLoginForm } from '.';

interface AuthButtonsProps {
  readOnly?: boolean
}

const AuthButtons = ({ pushRoute, t, readOnly }: LProps<RouterProps<{}> & AuthButtonsProps>) => (
  <div id="home__auth_buttons">
    {!readOnly ?
    <>
    <div style={{ marginBottom: '40px', fontSize: '22px', textAlign: 'center' }}>
      {t('Sign up today & start reducing emissions.')}
    </div>
    <Button
      block={true}
      color={'primary'}
      style={{ marginBottom: '25px' }}
      onClick={() => {pushRoute(Routes.Signup); scrollToLoginForm(); }}
      id="home__signup_button"
    >
        {t('Get started')}
    </Button>
    <Button
      block={true} outline={true}
      onClick={() => {pushRoute(Routes.Login); scrollToLoginForm(); }}
      id="home__login_button"
    >
        {t('Login')}
    </Button>
    </>
    :
    <>
     <div style={{ marginBottom: '40px', fontSize: '22px', textAlign: 'center',display: "flex",
          flexDirection: "column",
          alignItems: "center" }}>
        Joining a campus competition? Enter the url in this format <br/>
        <span style={{fontSize:14}}>https://www.coolcampuschallenge.org/&lt;competitionName&gt;</span>
      </div>
    </>
    }
  </div>
);

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withLocalization('yada'),
  withRouterAndHelpers,  
  connect(null, mapDispatchToProps)
)(AuthButtons) as React.SFC<AuthButtonsProps>;
