import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { LProps, withLocalization } from '../utils/wrappers';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const PledgesTableHelp = ({
  t, isOpen, toggle
}: LProps<Props>) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
  >
    <ModalHeader>
      {t('How is my score calculated?')}
    </ModalHeader>
    <ModalBody>
      <p>{t('Each action is worth a number of baseline points and can be completed a limited number of times for points, as indicated in the table on this page. Each completion after the first is worth 50 points.')}</p>
      <p>{t('You will earn an additional 50 points for including a photo when you complete an action.')}</p>
    </ModalBody>
    <ModalFooter>
      <Button onClick={toggle} color="primary">{t('Got it!')}</Button>
    </ModalFooter>
  </Modal>
);

export default withLocalization('pledges')(PledgesTableHelp) as React.SFC<Props>;
