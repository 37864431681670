import { pickBy } from 'lodash';
import { TranslationFunction } from 'i18next';
import { BlogEntry, ValidationError } from '../../userApi';
import { Form, validationReady } from './form';
import { validator, makeErrorMessage } from '../validations';

export interface BlogEntryForm extends BlogEntry, Form {}

export interface BlogEntryErrors {
  title: string;
  contentMD: string;
}

const validation = {
  type: 'object',
  required: ['title', 'contentMD'],
  properties: {
    title: { type: 'string', minLength: 2, maxLength: 20 },
    contentMD: { type: 'string', minLength: 10, maxLength: 5000 }
  }
};

export const makeBlogEntryErrors = (form: Partial<BlogEntryForm>, _validationErrors: ValidationError[], t: TranslationFunction): Partial<BlogEntryErrors> => {
  const errorMessages: Partial<BlogEntryErrors> = {};
  if (!validator.validate(validation, form)) {
    const { errors } = validator;
    errorMessages.title = makeErrorMessage(errors, '.title', t('Must be 2-20 characters.'), validationReady(form as Form, 'title'));
    errorMessages.contentMD = makeErrorMessage(errors, '.contentMD', t('Must be 10-5000 characters.'), validationReady(form as Form, 'contentMD'));
  }
  return pickBy(errorMessages);
};
