import * as selectors from '../selectors';
import up from 'updeep';
import { Dispatch } from 'redux';
import { State } from '../state';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';
import { ExtraArguments } from '../extraArguments';
import { CompetitionUserScoreQuery } from '../../userApi';
import { Routes } from '../../utils/routerHelper';

export const pageTopUserScores = (page: number, sort:string) => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const { topCompetitionUserScoreQuery, competition } = state;
  const api = selectors.userApi(state);
  try {
    const res = await api.getCompetitionUserScores(competition.id, topCompetitionUserScoreQuery.entityID, page,"", sort || "");
    const update = {
      users: res.users,
      topCompetitionUserScoreQuery: {
        pagination: res.topUserScoresQuery.pagination
      } as CompetitionUserScoreQuery,
      topCompetitionUserScores: up.constant(res.topUserScores)
    };
    dispatch(patch(update));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
  }
};

export const initializeTopUserScoresPage = (entityID: number, term?:string) => async (dispatch: Dispatch, getState: () => State, { history, i18n }: ExtraArguments) => {
  dispatch(patch({ spinner: true }));
  const state = getState();
  const api = selectors.userApi(state);
  try {
    const { competition } = state;
    const res = await api.getCompetitionUserScores(competition.id, entityID, 1, term||"", "-points");
    dispatch(patch({
      pendingRoute: null as Routes,
      users: res.users,
      topCompetitionUserScoreQuery: res.topUserScoresQuery,
      topCompetitionUserScores: up.constant(res.topUserScores),
      spinner: false
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
  }
};
