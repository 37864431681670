import { Dispatch } from 'redux';
import up from 'updeep';
import { throttle, values } from 'lodash';
import * as selectors from '../selectors';
import { State, StoryQuery, defaultState, Modals } from '../state';
import { Story, Stories, ImageResponse, TaggedUserIDs, CompetitionPledge } from '../../userApi';
import { patch } from './patch';
import { handleUnexpectedError } from './handleUnexpectedError';
import { ActionType } from './types';
import { postImage } from './image';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { ExtraArguments } from '../extraArguments';
import logger from '../../utils/logger';
import { getStoryParams, makePledgePath } from '../../utils/routerHelper';
import { addUserPoints } from './score';
import { trackEvent } from '../../utils/googleAnalytics';
import { CompetitionPledges } from '../../utils/userApiHelper';

export const openConfirmStoryDelete = (story: Story) => async (dispatch: Dispatch, getState: () => State) => {
  dispatch(patch({
    modal: Modals.ConfirmStoryDelete,
    storyToDelete: story
  }));
};

export const closeConfirmStoryDelete = () => async (dispatch: Dispatch, getState: () => State) => {
  dispatch(patch({
    modal: Modals.None,
    storyToDelete: null
  }));
};

// WARN: We are updating the whole array of stories - is there a performance concern here?
export const toggleStoryLike = (story: Story) => async (dispatch: Dispatch, getState: () => State) => {
  const { storyLikes, stories } = getState();
  let likes = story.likes;
  if (storyLikes[story.id]) {
    likes--;
  } else {
    likes++;
  }

  const storyIndex = stories.findIndex((s) => s.id === story.id);
  const storiesUpdate = { [storyIndex]: { ...story, likes } } as Stories;
  dispatch(patch({
    storyLikes: { [story.id]: !storyLikes[story.id] },
    stories: storiesUpdate
  }));

  const api = selectors.userApi(getState());
  try {
    if (!storyLikes[story.id]) {
      await api.postLike(story.id, 'story');
      trackEvent('like', 'create', story.id.toString());
    } else {
      await api.deleteLike(story.id, 'story');
      trackEvent('like', 'delete', story.id.toString());
    }
  } catch (err) {
    dispatch(patch({
      storyLikes: { [story.id]: storyLikes[story.id] },
      stories: up.updateIn(`${storyIndex}`, story, stories)
    }));
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('like', 'unhandledError');
  }
  return;
};

export const toggleStoryFlag = (story: Story) => async (dispatch: Dispatch, getState: () => State) => {
  const { storyFlags, stories } = getState();
  let flags = story.flags;
  if (storyFlags[story.id]) {
    flags--;
  } else {
    flags++;
  }

  const storyIndex = stories.findIndex((s) => s.id === story.id);
  const storiesUpdate = { [storyIndex]: { ...story, flags } } as Stories;
  dispatch(patch({
    storyFlags: { [story.id]: !storyFlags[story.id] },
    stories: storiesUpdate
  }));

  const api = selectors.userApi(getState());
  try {
    if (!storyFlags[story.id]) {
      await api.postFlag(story.id, 'story', { flag: {} });
      trackEvent('flag', 'create', story.id.toString());
    } else {
      await api.deleteFlag(story.id, 'story');
      trackEvent('flag', 'delete', story.id.toString());
    }
  } catch (err) {
    storiesUpdate[storyIndex] = story;
    dispatch(patch({
      storyFlags: { [story.id]: storyFlags[story.id] },
      stories: storiesUpdate
    }));
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('flag', 'unhandledError');
  }
  return;
};

const pageStories = (page: number, term?:string) => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const { storyPagination, stories, storyQuery, competition, token,storyTerm } = state;
  const api = selectors.userApi(state);
  try {
    const { userID, entityID, competitionPledgeID, storyID } = storyQuery;
    const res = await api.getCompetitionStories(competition.id, userID, entityID, competitionPledgeID, storyID, page == 0?0:storyPagination.nextPage, term|| storyTerm, token);
    const storyIDs: { [storyID: number]: boolean } = stories.reduce((previous, story) => ({ ...previous, [story.id]: true }), {});
    const newStories = res.stories.filter((story) => !storyIDs[story.id]);
    const update = {
      ...res,
      storyTerm: term,
      stories: [
        ...stories,
        ...newStories
      ]
    };
    dispatch(patch(update, ActionType.GetCompetitionStories));
  } catch (err) {
    dispatch(patch({
      storyPagination: { nextPage: -1 }
    }, ActionType.GetCompetitionStories));
    handleUnexpectedError(err)(dispatch, getState);
  }
};

export const pageStoriesThrottle = throttle(pageStories, 500);

export const setStoryQuery = (storyQuery: StoryQuery) => async (dispatch: Dispatch, _getState: () => State) => dispatch(patch({
  storyQuery,
  stories: up.constant(defaultState.stories),
  storyPagination: up.constant({ nextPage: -1 }),
  storyComments: up.constant({}),
  storyCommentPagination: up.constant({}),
  storyCommentsFlags: up.constant({}),
  storyCommentsLikes: up.constant({}),
  storyLikes: up.constant({}),
  storyFlags: up.constant({})
}, ActionType.SetStoryQuery));

export const postCompetitionStories = (newStory: Story, taggedUserIDs: TaggedUserIDs, fileInputID: string, savedStoryFormID: string) =>
  async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const { competitionPledges } = getState();
  const { i18n, getImage } = extra;
  const t = i18n.getFixedT(i18n.language, 'story');
  dispatch(patch({ spinner: true }));

  let imageResponse: ImageResponse;
  if (getImage(fileInputID)) {
    imageResponse = await postImage(fileInputID)(dispatch, getState, extra);
    if (!imageResponse) return;
  }

  try {
    const story = {
      ...newStory
    } as Story;
    delete story.id;
    if (imageResponse) {
      story.photo = imageResponse.url;
    } else if (!story.photo) {
      delete story.photo;
    }
    let pledge: CompetitionPledge;
    if (story.competitionPledgeID !== 0) {
      pledge = competitionPledges[story.competitionPledgeID];
      if (pledge.canUseMarkdown) {
        const { converter } = await import('../../utils/markdown');
        story.contentHTML = converter.makeHtml(story.contentMD);
      }
    }
    const state = getState();
    const api = selectors.userApi(state);
    const res = await api.postCompetitionStories(state.competition.id, { story, taggedUserIDs });
    trackEvent('story', 'create', pledge && pledge.shortTitle);
    const update: Partial<State> = {
      spinner: false,
      savedStoryFormID,
      stories: [
        res.story,
        ...state.stories
      ],
      storyTaggedUserIDs: {
        0: [],
        ...res.storyTaggedUserIDs
      },
      users: res.users
    };
    if (story.competitionPledgeID !== 0) {
      let latestToPledge = state.latestToPledge[story.competitionPledgeID] || [];
      if (!latestToPledge.some((userID) => userID === state.user.id)) {
        latestToPledge = [
          state.user.id,
          ...latestToPledge
        ];
        update.latestToPledge = { [story.competitionPledgeID]: up.constant(latestToPledge) };
      }
    }
    dispatch(patch(update));
    const { points } = res;
    if (points && points.points > 0) {
      try {
        addUserPoints(points, story.competitionPledgeID)(dispatch, getState, extra);
      } catch (err) {
        logger.error(err);
      }
    } else {
      toast({
        message: extra.i18n.t('Your story was saved successfully!', { ns: 'story' }),
        style: StyleState.Success
      })(dispatch, getState);
    }
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('story', 'unhandledError', 'create');
  }
};

export const editStory = (storyID: number) => patch({ editingStoryID: storyID });
export const cancelStoryEdit = () => patch({
  editingStoryID: null
});

export const highlightHero = (userID: number) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  dispatch(patch({ storyTaggedUserIDs: { [0]: [userID] } }));
  const { history, i18n } = extra;
  const { competitionPledges } = getState();
  const highlightAHero = values(competitionPledges).find((pledge) => pledge.uuid === CompetitionPledges.HighlightAHero);
  if (!highlightAHero) {
    handleUnexpectedError(new Error('Could not find HighlightAHero pledge.'))(dispatch, getState);
    return;
  }
  history.push(makePledgePath(highlightAHero, i18n.t));
};

export const clearNewStoryTaggedUserIDs = () => patch({ storyTaggedUserIDs: { [0]: up.constant([]) }});

export const putStory = (storyForm: Story, taggedUserIDs: TaggedUserIDs, fileInputID: string, savedStoryFormID: string) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const t = extra.i18n.getFixedT(extra.i18n.language, 'story');
  dispatch(patch({ spinner: true }));

  let imageResponse: ImageResponse;
  try {
    imageResponse = await postImage(fileInputID)(dispatch, getState, extra);
  } catch (err) {
    logger.error(err);
  }

  const story = {
    ...storyForm
  } as Story;
  if (imageResponse) {
    story.photo = imageResponse.url;
  } else if (!story.photo) {
    delete story.photo;
  }
  story.links = story.links || [];
  if (!story.locationQualifier) delete story.locationQualifier;

  const { stories } = getState();
  const api = selectors.userApi(getState());
  try {
    const res = await api.putStory(story.id, { story, taggedUserIDs });
    trackEvent('story', 'update');
    const storyIndex = stories.findIndex((s) => s.id === story.id);
    dispatch(patch({
      spinner: false,
      savedStoryFormID,
      stories: up.updateIn(`${storyIndex}`, res.story, stories),
      editingStoryID: null,
      storyTaggedUserIDs: { [story.id] : up.constant(res.storyTaggedUserIDs[story.id]) },
      users: res.users
    }));

  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('story', 'unhandledError', 'update');
  }
};

export const deleteStory = () => async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  const { stories, storyToDelete } = getState();
  dispatch(patch({
    stories: up.reject((s) => s.id === storyToDelete.id)
  }));
  const api = selectors.userApi(getState());
  try {
    dispatch(patch({ modal: null }));
    const { competitionUserScores } = await api.deleteStory(storyToDelete.id);
    trackEvent('story', 'delete');
    dispatch(patch({ competitionUserScores }));
  } catch (err) {
    dispatch(patch({ stories }));
    handleUnexpectedError(err)(dispatch, getState);
    trackEvent('story', 'unhandledError', 'delete');
  }
};

export const initializeStoryPage = (term?:string) => async (dispatch: Dispatch, getState: () => State, { history, i18n }: ExtraArguments) => {
  const { storyID } = getStoryParams(history.location, i18n);
  const state = getState();
  if (state.stories.some((s) => s.id === storyID)) return;

  dispatch(patch({ spinner: true }));
  const api = selectors.userApi(state);
  try {
    const { token } = state;
    const res = await api.getCompetitionStories(state.competition.id, 0, 0, 0, storyID, 1, term,token);
    dispatch(patch({
      ...res,
      spinner: false
    }));
  } catch (err) {
    handleUnexpectedError(err)(dispatch, getState);
  }
};
