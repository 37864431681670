import { State } from '../state';
import { CompetitionScoreLevel, CompetitionUserScore } from '../../userApi';

export const getScoreLevel = (state: State, userID: number) => {
  const score = state.competitionUserScores[userID];
  if (!score) return state.competitionScoreLevels[0];
  return  state.competitionScoreLevels
  .find((_scoreLevel, i) => {
    const nextLevel = state.competitionScoreLevels[i + 1];
    return !nextLevel || score.points < nextLevel.threshold;
  });
};

export const getNextScoreLevel = (state: State, userID: number) => {
  const score = state.competitionUserScores[userID];
  if (!score) return state.competitionScoreLevels[1];
  const nextLevel = state.competitionScoreLevels
    .slice(1)
    .find((scoreLevel) => score.points < scoreLevel.threshold);
  return nextLevel || state.competitionScoreLevels[state.competitionScoreLevels.length - 1];
};

export const userScoreLevel = (state: State) => {
  if (!state.competitionUser.verified) return state.competitionScoreLevels[0];
  return getScoreLevel(state, state.user.id);
};

export const nextUserScoreLevel = (state: State) => {
  if (!state.competitionUser.verified) return state.competitionScoreLevels[1];
  return getNextScoreLevel(state, state.user.id);
};

interface Scorable {
  scoreLevel: CompetitionScoreLevel;
  nextScoreLevel: CompetitionScoreLevel;
  score: CompetitionUserScore;
}

export const percentToNextLevel = (_state: State, props: Scorable): number => {
  const { score, scoreLevel, nextScoreLevel } = props;
  if (scoreLevel === nextScoreLevel) {
    return 1.0;
  }
  const pointsToNext = nextScoreLevel.threshold - scoreLevel.threshold;
  return ((score.points - scoreLevel.threshold) / (pointsToNext));
};
