import * as ReactCrop from 'react-image-crop';
import { last } from 'lodash';
import { maxImageSizeBytes } from './userApiHelper';
import logger from './logger';

export interface ImageMutation {
  rotation: number;
  backgroundColor: string;
  pixelCrop: ReactCrop.Crop;
  image: HTMLImageElement
}

export interface DataURLFromFile {
  dataURL: string;
  mimeType?: string;
  width: number;
  height: number;
  filename?: string;
}

export const clearFileInput = (inputID: string) => {
  const input = document.getElementById(inputID) as HTMLInputElement;
  if (!input) {
    logger.error(`clearFileInput: Failed to find file input element ${inputID}.`);
    return;
  }
  input.value = '';
};

// https://stackoverflow.com/a/17412387/1149459
export const applyRotation = async (canvas: HTMLCanvasElement, dataURLFromFile: DataURLFromFile, degrees: number, invertDimensions: boolean = false): Promise<DataURLFromFile> => {
  clearCanvas(canvas);
  const context = canvas.getContext('2d');

  // save the unrotated context of the canvas so we can restore it later
  // the alternative is to untranslate & unrotate after drawing
  if (invertDimensions) {
    const { width, height } = canvas;
    canvas.width = height;
    canvas.height = width;
  }
  context.save();

  // move to the center of the canvas
  context.translate(canvas.width / 2, canvas.height / 2);

  // rotate the canvas to the specified degrees
  context.rotate(degrees * Math.PI / 180);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      // draw the image
      // since the context is rotated, the image will be rotated also
      context.drawImage(image, -image.width / 2, -image.height / 2);

      // we’re done with the rotating so restore the unrotated context
      context.restore();
      const updatedDataURLFromFile: DataURLFromFile = {
        filename: dataURLFromFile.filename,
        mimeType: dataURLFromFile.mimeType,
        width: canvas.width,
        height: canvas.height,
        dataURL: canvas.toDataURL(dataURLFromFile.mimeType)
      };
      resolve(updatedDataURLFromFile);
    };
    image.onerror = reject;
    image.src = dataURLFromFile.dataURL;
  });
};

export const applyBackgroundColor = async (canvas: HTMLCanvasElement, dataURLFromFile: DataURLFromFile, color: string): Promise<DataURLFromFile> => {
  clearCanvas(canvas);
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(image, 0, 0);
      const updatedDataURLFromFile: DataURLFromFile = {
        filename: dataURLFromFile.filename,
        mimeType: dataURLFromFile.mimeType,
        width: image.width,
        height: image.height,
        dataURL: canvas.toDataURL(dataURLFromFile.mimeType)
      };
      resolve(updatedDataURLFromFile);
    };
    image.onerror = reject;
    image.src = dataURLFromFile.dataURL;
  });
};

export const applyCrop = async (canvas: HTMLCanvasElement, dataURLFromFile: DataURLFromFile, pixelCrop: ReactCrop.Crop): Promise<DataURLFromFile> => {
  clearCanvas(canvas);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
      const updatedDataURLFromFile: DataURLFromFile = {
        filename: dataURLFromFile.filename,
        mimeType: dataURLFromFile.mimeType,
        width: pixelCrop.width,
        height: pixelCrop.height,
        dataURL: canvas.toDataURL(dataURLFromFile.mimeType)
      };
      resolve(updatedDataURLFromFile);
    };
    image.onerror = reject;
    image.src = dataURLFromFile.dataURL;
  });
};

export const clearCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');
  // Store the current transformation matrix
  ctx.save();

  // Use the identity matrix while clearing the canvas
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Restore the transform
  ctx.restore();
};

export const getDataURLFromFileInput = async (inputID: string): Promise<DataURLFromFile> => {
  const input = document.getElementById(inputID) as HTMLInputElement;
  if (!input) {
    throw new Error(`Failed to find file input element ${inputID}.`);
  }
  const file = input.files && input.files[0];
  if (file) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const result = reader.result;
        let dataURL: string;
        if (result instanceof ArrayBuffer) {
          dataURL = String.fromCharCode.apply(null, new Uint16Array(result));
        } else {
          dataURL = result;
        }
        if(file.type.indexOf('video') ==-1){
          const img = new Image();
          img.onload = async () => {
            const dataURLFromFile = { dataURL, mimeType: file.type, width: img.width, height: img.height, filename: file.name };
            resolve(dataURLFromFile);
          };
          img.onerror = reject;
          img.src = dataURL;
        }else{
          const dataURLFromFile = { dataURL, mimeType: file.type, width: 0, height: 0, filename: file.name };
          resolve(dataURLFromFile);
        }
      };
      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }
  return;
};

/*export const drawDataURLToCanvas = (canvas: HTMLCanvasElement, dataURLFromFile: DataURLFromFile) => {
  clearCanvas(canvas);
  const ctx = canvas.getContext('2d');
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      canvas.width = dataURLFromFile.width;
      canvas.height = dataURLFromFile.height;
      ctx.drawImage(
        image, 0, 0, dataURLFromFile.width, dataURLFromFile.height
      );
      resolve(canvas.toDataURL(dataURLFromFile.mimeType));
    };
    image.onerror = reject;
    image.src = dataURLFromFile.dataURL;
  });
};*/

const minimumAcceptableWidth = 100;

export const ErrCouldNotCompressFile = new Error('Could not compress file to acceptable size while maintaining minimum width.');

/*export const lossyCompressUntilAcceptable = async (canvas: HTMLCanvasElement, dataURLFromFile: DataURLFromFile) => {
  let fileSize = dataURLBytes(dataURLFromFile);
  let compressedDataURL = dataURLFromFile;
  while (fileSize > maxImageSizeBytes) {
    if (compressedDataURL.width < minimumAcceptableWidth) {
      throw ErrCouldNotCompressFile;
    }
    const compressionRatio = 1 / (fileSize / maxImageSizeBytes);
    compressedDataURL = await lossyCompressDataURL(canvas, compressedDataURL, compressionRatio);
    fileSize = await dataURLBytes(compressedDataURL);
  }
  return compressedDataURL;
};*/

// This essentially shrinks the dimensions of the photos in order to reduce the file size.
/*export const lossyCompressDataURL = async (canvas: HTMLCanvasElement, dataURLFromFile: DataURLFromFile, areaRatio: number): Promise<DataURLFromFile> => {
  const oneDRatio = Math.sqrt(areaRatio);
  const targetWidth = dataURLFromFile.width * oneDRatio;
  const targetHeight = dataURLFromFile.height * oneDRatio;
  const ctx = canvas.getContext('2d');
  const img = new Image();
  return new Promise((resolve, reject) => {
    img.onload = () => {
      // set size proportional to image
      canvas.height = targetHeight;
      canvas.width = targetWidth;

      // step 1 - resize to 50%
      const oc = document.createElement('canvas');
      const octx = oc.getContext('2d');

      oc.width = targetWidth;
      oc.height = targetHeight;
      octx.drawImage(img, 0, 0, oc.width, oc.height);
      // step 2
      octx.drawImage(oc, 0, 0, targetWidth, targetHeight);

      // step 3, resize to final size
      ctx.drawImage(oc, 0, 0, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight);

      const updatedDataURLFromFile: DataURLFromFile = {
        ...dataURLFromFile,
        width: targetWidth,
        height: targetHeight,
        dataURL: canvas.toDataURL(dataURLFromFile.mimeType)
      };
      resolve(updatedDataURLFromFile);
    };
    img.onerror = reject;
    img.src = dataURLFromFile.dataURL;
  });
};*/

// See https://stackoverflow.com/a/7261048/1149459
export const dataURLToBlob = (dataURLFromFile: DataURLFromFile): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURLFromFile.dataURL.split(',')[1]);

  // separate out the mime component

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], {type: dataURLFromFile.mimeType});
};

export const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.toString());
    };
    reader.onerror = reject;
    reader.onabort = reject;
    reader.readAsDataURL(blob);
  });
};

export const dataURLBytes = (dataURLFromFile: DataURLFromFile): number => dataURLToBlob(dataURLFromFile).size;

export const isJPG = (mimeType: string) => mimeType.search(/(jpg|jpeg)/i) >= 0;

/*const getMimeTypeFromFromSrc = (src: string) => {
  switch (true) {
    case src.search(/\.(jpg|jpeg)$/) >= 0:
      return 'image/jpeg';
    case src.search(/\.png$/) >= 0:
      return 'image/png';
    case src.search(/\.png$/) >= 0:
      return 'image/gif';
    default:
      return 'text/plain';
  }
};*/
export const getExtensionForMimeType = (mimeType: string) => {
  switch (mimeType) {
    case 'image/jpeg':
      return '.jpg';
    case 'image/png':
      return '.png';
    case 'image/gif':
      return '.fig';
    default:
      logger.error(new Error(`Unexpected image mimetype ${mimeType}.`));
      return 'text/plain';
  }
};

/*export const imageSrcToDataURL = (canvas: HTMLCanvasElement, src: string): Promise<DataURLFromFile> => {
  return new Promise((resolve, fail) => {
    const image = new Image();

    image.onload = () => {
      canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

      canvas.getContext('2d').drawImage(image, 0, 0);
      const mimeType = getMimeTypeFromFromSrc(src);

      resolve({
        dataURL: canvas.toDataURL(mimeType),
        width: image.width,
        height: image.height,
        filename: last(src.split('/')),
        mimeType
      });
    };
    image.src = src;
    image.onabort = fail;
    image.onerror = fail;
  });
};*/

export const setDataURLDimensions = (dataURLFromFile: DataURLFromFile): Promise<DataURLFromFile> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      dataURLFromFile.width = image.width;
      dataURLFromFile.height = image.height;
      resolve(dataURLFromFile);
    };
    image.onerror = reject;
      image.src = dataURLFromFile.dataURL;      
  });
};
