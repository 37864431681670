import * as React from 'react';
import { SocialLinks } from '../userApi';
import { isValidURL } from '../utils/validations';

interface Props {
  socialLinks: SocialLinks;
}

const socialLink = (href: string, icon: string) =>
  <a className="btn" href={href} target="_blank" rel="noreferrer noopener">
    {/** This is for aria */}
    <span style={{ position: 'absolute', left: '-9999px' }}>{icon}</span>
    <i className={`fa fa-${icon}`} />
  </a>;

const displayWebsiteHost = (website: string) => {
  try {
    const url = new URL(website);
    return url.hostname;
  } catch (err) {
    return website;
  }
};

export default ({ socialLinks }: Props) => (
  <div style={{ textAlign: 'center', maxWidth: '150px', margin: 'auto' }}>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {socialLinks.medium && socialLink(`https://medium.com/@${socialLinks.medium}`, 'medium')}
      {isValidURL(socialLinks.facebook) && socialLink(socialLinks.facebook, 'facebook-f')}
      {socialLinks.twitter && socialLink(`https://twitter.com/${socialLinks.twitter}`, 'twitter')}
      {isValidURL(socialLinks.linkedin) && socialLink(socialLinks.linkedin, 'linkedin')}
      {socialLinks.instagram && socialLink(`https://instagram.com/${socialLinks.instagram}`, 'instagram')}
    </div>
    {isValidURL(socialLinks.website) && <a target="_blank" rel="noopener noreferrer" href={socialLinks.website}>{(displayWebsiteHost(socialLinks.website))}</a>}
  </div>
);
