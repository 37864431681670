import { matchPath } from 'react-router';
import { I18next } from './i18nextShim';
import { Location, History } from 'history';
import { TranslationFunction } from 'i18next';
import { Entity, UserRead, CompetitionPledge } from '../userApi';
import { CompetitionPledges } from './userApiHelper';
import { Route } from 'react-router-dom';

export enum Routes {
  GlobalAbout = 'globalAbout',
  About = 'about',
  Faq = 'faq',
  Blog = 'blog',
  BlogEntry = 'blog_entry',
  BlogEntryEdit = 'blog_entry_edit',
  BlogEntryNew = 'blog_entry_new',
  Share = 'share',
  CompetitionUserScores = 'competition_scores',
  CompetitionUserScoresForEntity = 'competition_scores_entity',
  Calculator = 'calculator',
  Dashboard = '',
  Entity = 'entity',
  EntityBlog = 'entity_blog',
  EntityEdit = 'entity_edit',
  Join = 'join',
  Pledge = 'pledge',
  Pledges = 'pledges',
  PledgeCategory = 'pledge_category',
  Story = 'story',
  Login = 'login',
  User = 'user',
  Account = 'account',
  Teams = 'teams',
  TeamsNew = 'new_team',
  Signup = 'signup',
  Survey = 'survey',
  ForgotPassword = 'forgot_password',
  PasswordReset = 'password_reset',
  Email = 'email',
  NotFound = 'not_found'  
}

interface RoutePaths {
  [key: string]: string;
}

export const makePathnames = (translate: TranslationFunction): RoutePaths => {
  return {
    [Routes.Dashboard]: '/:competitionName',
    [Routes.About]: `/:competitionName/${translate(Routes.About)}`,
    [Routes.Faq]: `/${translate(Routes.Faq)}/:section`,
    [Routes.Blog]: `/:competitionName/${translate(Routes.Blog)}`,
    [Routes.BlogEntry]: `/:competitionName/${translate(Routes.BlogEntry)}/:blogEntryID`,
    [Routes.BlogEntryEdit]: `/:competitionName/${translate(Routes.BlogEntry)}/:blogEntryID/${translate('edit')}`,
    [Routes.BlogEntryNew]: `/:competitionName/${translate(Routes.Entity)}/:entityID/${Routes.BlogEntry}/${translate('new')}`,
    [Routes.Share]: `/:competitionName/${translate(Routes.Share)}`,
    [Routes.CompetitionUserScores]: `/:competitionName/${translate('competition')}/:competitionID/${translate('user_scores')}`,
    [Routes.CompetitionUserScoresForEntity]: `/:competitionName/${translate('competition')}/:competitionID/${translate(Routes.Entity)}/:entityID/${translate('user_scores')}`,
    [Routes.Calculator]: `/:competitionName/${translate(Routes.Calculator)}`,
    [Routes.Entity]: `/:competitionName/${translate('competition')}/:competitionID/${translate(Routes.Entity)}/:entityID`,
    [Routes.EntityEdit]: `/:competitionName/${translate(Routes.Entity)}/:entityID/${translate('edit')}`,
    [Routes.EntityBlog]: `/:competitionName/${translate(Routes.Entity)}/:entityID/${translate('blog')}`,
    [Routes.Join]: `/:competitionName/${translate(Routes.Join)}`,
    [Routes.Pledge]: `/:competitionName/${translate(Routes.Pledge)}/:competitionPledgeID`,
    [Routes.Pledges]: `/:competitionName/${translate(Routes.Pledges)}`,
    [Routes.PledgeCategory]: `/:competitionName/${translate(Routes.Pledges)}/:competitionPledgeCategoryID`,
    [Routes.Story]: `/:competitionName/${translate(Routes.Story)}/:storyID`,
    [Routes.User]: `/:competitionName/${translate('competition')}/:competitionID/${translate(Routes.User)}/:userID`,
    [Routes.Teams]: `/:competitionName/${translate(Routes.Teams)}`,
    [Routes.TeamsNew]: `/:competitionName/${translate(Routes.TeamsNew)}`,
    [Routes.Login]: `/:competitionName/${translate(Routes.Login)}`,
    [Routes.Account]: `/:competitionName/${translate(Routes.Account)}`,
    [Routes.Signup]: `/:competitionName/${translate(Routes.Signup)}`,
    [Routes.Survey]: `/:competitionName/${translate(Routes.Survey)}`,
    [Routes.ForgotPassword]: `/:competitionName/${translate(Routes.ForgotPassword)}`,
    [Routes.PasswordReset]: `/:competitionName/${translate(Routes.PasswordReset)}`,
    [Routes.Email]: `/:compeftitionName/${translate(Routes.Email)}`,
    [Routes.GlobalAbout]: `/about`
  }
};

export const makeSimpleRoutePath = (route: Routes, translate: TranslationFunction) => {
  if(route == Routes.GlobalAbout){
    return '/about'; 
  }
  return `/${localStorage.competitionName}/${translate(route, { ns: 'routes' })}`;
}

export const makeEntityPath = (competitionID: number, entity: Entity, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate('competition', { ns: 'routes' })}/${competitionID}/${translate(Routes.Entity, { ns: 'routes' })}/${entity.id}`;

export const makeEntityEditPath = (entity: Entity, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.Entity, { ns: 'routes' })}/${entity.id}/${translate('edit')}`;

export const makeEntityBlogPath = (entityID: number, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.Entity, { ns: 'routes' })}/${entityID}/${translate('blog')}`;

export const makeBlogEntryPath = (blogEntryID: number, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.BlogEntry, { ns: 'routes' })}/${blogEntryID}`;

export const makeBlogEntryEditPath = (blogEntryID: number, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.BlogEntry, { ns: 'routes' })}/${blogEntryID}/${translate('edit')}`;

export const makeBlogEntryNewPath = (entityID: number, translate: TranslationFunction) =>
  `/${translate(Routes.Entity, { ns: 'routes' })}/${entityID}/${translate(Routes.BlogEntry, { ns: 'routes' })}/${translate('new')}`;

export const makePledgePath = (pledge: CompetitionPledge, translate: TranslationFunction) => {
  if (pledge.uuid === CompetitionPledges.TakeCalculator) return makeSimpleRoutePath(Routes.Calculator, translate);
  if (pledge.uuid === CompetitionPledges.TakeSurvey) return makeSimpleRoutePath(Routes.Survey, translate);
  if (pledge.uuid === CompetitionPledges.InviteAFriend) return `/${localStorage.competitionName}/`;
  return makePledgePathLiteral(pledge, translate);
};

export const makePledgePathLiteral = (pledge: CompetitionPledge, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.Pledge, { ns: 'routes' })}/${pledge.id}`;

export const makeUserPath = (competitionID: number, user: UserRead, translationFunction: TranslationFunction) =>
  `/${localStorage.competitionName}/${translationFunction('competition', { ns: 'routes' })}/${competitionID}/${translationFunction(Routes.User, { ns: 'routes' })}/${user.id}`;

export const makeStoryPath = (storyID: number, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.Story, { ns: 'routes' })}/${storyID}`;

export const makePledgeCategoryPath = (competitionPledgeCategoryID: number, translate: TranslationFunction) =>
  `/${localStorage.competitionName}/${translate(Routes.Pledges, { ns: 'routes' })}/${competitionPledgeCategoryID}`;

export const makeCompetitionUserScoresPath = (competitionID: number, translate: TranslationFunction) =>
`/${localStorage.competitionName}/${translate('competition')}/${competitionID}/${translate('user_scores')}`;

export const makeCompetitionUserScoresForEntityPath = (competitionID: number, entityID: number, translate: TranslationFunction) =>
`/${localStorage.competitionName}/${translate('competition')}/${competitionID}/${translate(Routes.Entity)}/${entityID}/${translate('user_scores')}`;

export enum FaqSection {
  General = 'general',
  SpreadTheWord = 'spread_the_word',
  Help = 'help'
}

export const makeFaqPath = (section: FaqSection, translate: TranslationFunction) =>
  `/${translate(Routes.Faq, { ns: 'routes' })}/${section}`;

const getParams = (location: Location, i18n: I18next, route: Routes) => {
  const t = i18n.getFixedT(i18n.language, 'routes');
  const matchingPath = makePathnames(t)[route];
  const m = matchPath<{ [key: string]: string }>(location.pathname, {
    path: matchingPath,
    exact: true,
    strict: false
  });
  return m ? m.params : {};
};

export const getEntityParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.Entity);
  return {
    competitionID: Number(params.competitionID),
    entityID: Number(params.entityID)
  };
};

export const getEntityEditParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.EntityEdit);
  return {
    entityID: Number(params.entityID)
  };
};

export const getEntityBlogParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.EntityBlog);
  return {
    entityID: Number(params.entityID)
  };
};

export const getBlogEntryParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.BlogEntry);
  return {
    blogEntryID: Number(params.blogEntryID)
  };
};

export const getBlogEntryEditParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.BlogEntryEdit);
  return {
    blogEntryID: Number(params.blogEntryID)
  };
};

export const getBlogEntryNewParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.BlogEntryNew);
  return {
    entityID: Number(params.entityID)
  };
};

export const getPledgeParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.Pledge);
  return {
    competitionPledgeID: Number(params.competitionPledgeID)
  };
};

export const getPledgeCategoryParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.PledgeCategory);
  return {
    competitionPledgeCategoryID: Number(params.competitionPledgeCategoryID)
  };
};

export const getUserParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.User);
  return {
    competitionID: Number(params.competitionID),
    userID: Number(params.userID)
  };
};

export const getStoryParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.Story);
  return {
    storyID: Number(params.storyID)
  };
};


export const getCompetitionNameParams = (location: Location, i18n: I18next) => {
  const t = i18n.getFixedT(i18n.language, 'routes');
  console.log(location.pathname)
  if(location.pathname =='/about' || 
    location.pathname.indexOf('/faq')==0 ){
    return 'GLOBAL'
  }
  const matchingPath = makePathnames(t)[Routes.Dashboard];
  const m = matchPath<{ [key: string]: string }>(location.pathname, {
    path: matchingPath,
    exact: false,
    strict: false
  });
  const params =  m ? m.params : {};
  return {    
    competitionName: params.competitionName
  };
};


export const getFaqParams = (location: Location, i18n: I18next): { section: FaqSection } => {
  const params = getParams(location, i18n, Routes.Faq);
  const section = params.section as FaqSection  
  return {
    section
  };  
};

export const getCompetitionUserScoresForEntityParams = (location: Location, i18n: I18next) => {
  const params = getParams(location, i18n, Routes.CompetitionUserScoresForEntity);
  return {
    competitionID: parseInt(params.competitionID, 10),
    entityID: parseInt(params.entityID, 10)
  };
};

export const getCurrentRoute = (location: Location, i18n: I18next) => {  
  const t = i18n.getFixedT(i18n.language, 'routes');
  const pathnames = makePathnames(t);
  const routeName = Object.values(Routes).find((route) => {     
    return Boolean(matchPath(location.pathname, {
      path: pathnames[route],
      exact: true,
      strict: false
    }));
  });
  return routeName || Routes.NotFound;
};

export const goToRouteByName = (route: Routes, h: History, i18n: I18next) => {
  const translate = i18n.getFixedT(null, 'routes');
  h.push(`/${translate(route)}`);
};
