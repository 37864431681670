import * as React from 'react';
import { Table, Modal, ModalBody } from 'reactstrap';
import { Entity } from '../userApi';
import { LProps, withLocalization } from '../utils/wrappers';
import { State, Modals } from '../redux/state';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { patch } from '../redux/actions/all';
import { connect } from 'react-redux';

interface Props {
  entities: Entity[];
  isOpen: boolean;
  toggle: (isOpen: boolean) => void;
}

const EntityEmailDomains = ({
  isOpen, toggle, entities, t
}: LProps<Props>) => (
  <Modal
    isOpen={isOpen}
    toggle={() => toggle(isOpen)}
  >
    <ModalBody>
      <Table>
        <thead>
          <tr>
            <th>{t('University')}</th>
            <th>{t('Permitted domains')}</th>
          </tr>
        </thead>
        <tbody>
          {entities.map((entity) => (
            <tr key={entity.id}>
              <td>{entity.name}</td>
              <td>{entity.permittedDomains.join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ModalBody>
  </Modal>
);

const mapStateToProps = (state: State): Partial<Props> => ({
  isOpen: state.modal === Modals.EntityEmailDomains,
  entities: state.competitionEntityScores.university ? state.competitionEntityScores.university.map((score) => state.entities[score.entityID]) : []
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => bindActionCreators({
  toggle: (isOpen) => patch({ modal: isOpen ? null : Modals.EntityEmailDomains })
}, dispatch);

export default compose(
  withLocalization('email'),
  connect(mapStateToProps, mapDispatchToProps)
)(EntityEmailDomains) as React.SFC;
