import * as React from 'react';
import { compose } from 'redux';
import cx from 'classnames';
import { withLocalization, LProps } from '../utils/wrappers';

interface Props {
  profilePhoto?: string;
  alt?: string;
  outerStyle?: React.CSSProperties;
  rounded?: boolean;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const ProfilePhoto = ({
  profilePhoto, t, alt, rounded = true, outerStyle = {}, imgProps = {}
}: LProps<Props>) => (
  <div className={cx('cc__profile_photo', { cc__rounded: rounded })} style={outerStyle}>
    <div className="cc__profile_photo__img__wrapper">
    {Boolean(profilePhoto) ?
      <img src={profilePhoto} alt={alt || t('account profile')} {...imgProps} /> :
      <img
        src={'/assets/account.png'} alt={t('account profile')}
        {...imgProps}
      />}
    </div>
  </div>
);

export default compose(
  withLocalization('profilePhoto')
)(ProfilePhoto) as React.SFC<Props>;
