import * as React from 'react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { Modal, ModalBody, ButtonGroup } from 'reactstrap';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { Entity, MembershipLevel, EntityMembership } from '../../userApi';
import SocialLinksBtnGroup from '../../shared/SocialLinksBtnGroup';
import EntityStats from './EntityStats';
import Stories from '../../shared/Stories';
import { connect } from 'react-redux';
import Scoreboard from './Scoreboard';
import { State } from '../../redux/state';
import * as selectors from '../../redux/selectors';
import { withRouter, RouterProps } from 'react-router';
import { History } from 'history';
import actions from '../../redux/actions';
import { Routes, makeEntityEditPath, makeEntityBlogPath, getEntityParams } from '../../utils/routerHelper';
import Footer from '../../shared/Footer';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { asPage } from '../../utils/wrappers/asPage';
import { WaitingPageContent } from '../../shared/WaitingPage';
import SafeHTML from '../../shared/SafeHtml';
import { entityCanBlog } from '../../utils/userApiHelper';

interface Props {
  entity: Entity;
  membership: EntityMembership;
  entityID: number;
  membershipLevel: MembershipLevel;
  canJoin: boolean;
  initializingEntityPage: boolean;
  hasCompetitionEnded: boolean;
  initializeEntityPage: (history: History) => void;
  deleteTeam: (entity: Entity) => void;
  joinTeam: (entity: Entity) => void;
  deleteEntityMembership: (membershipID: number) => void;
}

enum MobileSection {
  INFO = 1,
  SCOREBOARD = 2,
  ACTIVITY = 4
}

interface EntityPageState {
  confirmDelete: boolean;
  mobileSection: number;
}

const didEntityIDUpdate = (nextProps: LProps<Props>, props: LProps<Props>) => {
  if (!props.entityID) return false;
  return props.entityID !== nextProps.entityID && Boolean(nextProps.entityID);
};

class EntityPage extends React.Component<LProps<Props> & RouterProps, EntityPageState> {
  public state = { confirmDelete: false, mobileSection: MobileSection.INFO };

  public componentWillMount() {
    const { initializeEntityPage, history } = this.props;
    initializeEntityPage(history);
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    if (didEntityIDUpdate(nextProps, this.props)) {
      const { initializeEntityPage, history } = this.props;
      initializeEntityPage(history);
    }
  }

  private confirmDelete() {
    this.setState({ confirmDelete: true });
  }

  private delete() {
    const { entity, deleteTeam } = this.props;
    this.setState({ confirmDelete: false });
    deleteTeam(entity);
  }

  public render() {
    const {
      t, entity, initializingEntityPage, canJoin, joinTeam, hasCompetitionEnded,
      membershipLevel, history, deleteEntityMembership, membership
    } = this.props;
    if (!entity || initializingEntityPage) return <WaitingPageContent />;
    return (
      <div className="cc__no_top_margin">
        {entity.bannerPhoto &&
          <div className="cc__banner">
            <img src={entity.bannerPhoto} alt={entity.name} />
          </div>
        }
        <div
          className={cx('cc__entity cc__page__column_content', {
            cc__entity__with_banner: Boolean(entity.bannerPhoto),
            cc__entity__no_banner: !Boolean(entity.bannerPhoto)
          })}>
          <div className="cc__page__column_content__left show">
            <div className="cc__entity__header">
              {entity.photo &&
                <div className="cc__entity__photo">
                  <img src={entity.photo} alt={entity.name} />
                </div>
              }
            </div>
            <div>
              <div className="cc__entity__name">
                {entity.photo &&
                  <div className="cc__entity__photo__mobile">
                    <img src={entity.photo} alt={entity.name} />
                  </div>
                }
                {entity.name}&nbsp;
                {entity.type === Entity.TypeEnum.HealthFacility && <i className="fa fa-hospital-o" />}
              </div>
              {!isEmpty(entity.socialLinks) && <SocialLinksBtnGroup socialLinks={entity.socialLinks} />}
              {(canJoin && !hasCompetitionEnded) &&
                <ButtonGroup>
                  <Button
                    color="primary"
                    onClick={() => joinTeam(entity)}
                  >{t('Join now')}</Button>
                </ButtonGroup>
              }
              {(membershipLevel === MembershipLevel.Member && entity.type === Entity.TypeEnum.Team) && !hasCompetitionEnded &&
                <ButtonGroup>
                  <Button
                    outline
                    color={'primary'}
                    onClick={() => deleteEntityMembership(membership.id)}
                  >{t('Leave')}</Button>
                </ButtonGroup>
              }
              {(membershipLevel === MembershipLevel.Admin) &&
                <ButtonGroup>
                  <Button
                    onClick={() => history.push(makeEntityEditPath(entity, t))}
                  ><i className="fa fa-pencil" /></Button>
                  {entity.type === Entity.TypeEnum.Team && !hasCompetitionEnded &&
                    <Button
                      outline
                      onClick={() => deleteEntityMembership(membership.id)}
                    >{t('Leave')}</Button>
                  }
                </ButtonGroup>
              }
              {(membershipLevel === MembershipLevel.Superadmin) &&
                <ButtonGroup>
                  <Button
                    onClick={() => history.push(makeEntityEditPath(entity, t))}
                  ><i className="fa fa-pencil" /></Button>
                  {entity.type !== Entity.TypeEnum.University && !hasCompetitionEnded &&
                    <Button
                      outline={true}
                      color="danger"
                      onClick={() => this.confirmDelete()}
                    ><i className="fa fa-times" /></Button>
                  }
                </ButtonGroup>
              }
              {entityCanBlog(entity) && <div><Link to={makeEntityBlogPath(entity.id, t)}>{t('View blog')}</Link></div>}
            </div>
            <div className={this.getActiveClass(MobileSection.INFO)}>
              <EntityStats entity={entity} />
              <div className="cc__white_box">
                <h6>{t('About')}</h6>
                <SafeHTML contentHTML={entity.descriptionHTML} />
              </div>
              <Footer />
            </div>
          </div>
          <div className={'cc__page__column_content__right ' + this.getActiveClass(MobileSection.SCOREBOARD | MobileSection.ACTIVITY)}>
            {!initializingEntityPage &&
              <div className={'cc__white_box ' + this.getActiveClass(MobileSection.SCOREBOARD)}>
                <Scoreboard entity={entity} />
              </div>
            }

            {!initializingEntityPage &&
              <div className={this.getActiveClass(MobileSection.ACTIVITY)}>
                <Stories />
              </div>
            }
          </div>
          <div className="cc__entity__bottom_menu">
            <div role="button" className={'cc__entity__bottom_menu__button ' + this.getActiveTextClass(MobileSection.INFO)} onClick={() => this.selectSection(MobileSection.INFO)}>
              <span>Info</span>
            </div>
            <div role="button" className={'cc__entity__bottom_menu__button ' + this.getActiveTextClass(MobileSection.SCOREBOARD)} onClick={() => this.selectSection(MobileSection.SCOREBOARD)}>
              <span>Scoreboard</span>
            </div>
            <div role="button" className={'cc__entity__bottom_menu__button ' + this.getActiveTextClass(MobileSection.ACTIVITY)} onClick={() => this.selectSection(MobileSection.ACTIVITY)}>
              <span>Activity</span>
            </div>
          </div>
          {membershipLevel === MembershipLevel.Superadmin &&
            <Modal
              isOpen={this.state.confirmDelete}
              toggle={() => this.setState({ confirmDelete: !this.state.confirmDelete })}
            >
              <ModalBody>{t('By clicking below you acknowledge that this team will no longer earn points and its memberships will be lost.')}</ModalBody>
              <ModalBody>
                <Button outline={true} color="danger" onClick={() => this.delete()}>{t('Confirm')}</Button>
                <Button color="primary" onClick={() => this.setState({ confirmDelete: false })}>{t('Cancel')}</Button>
              </ModalBody>
            </Modal>
          }
        </div>
      </div>
    );
  }
  private getActiveClass(index: number) {
    return (index & this.state.mobileSection) === this.state.mobileSection ? 'cc__mobile_show' : 'cc__mobile_hide';
  }
  private getActiveTextClass(index: number) {
    return (index & this.state.mobileSection) === this.state.mobileSection ? 'active' : '';
  }
  private selectSection(index: number) {
    this.setState({
      mobileSection: index
    });
  }
}

const mapStateToProps = (state: State, ownProps: LProps<RouterProps>): Partial<Props> => {
  const entity = selectors.currentEntity(state, ownProps);
  const initializingEntityPage = state.pendingRoute === Routes.Entity;
  const { entityID } = getEntityParams(ownProps.history.location, ownProps.i18n);
  if (!entity || initializingEntityPage) return { initializingEntityPage: true, entityID };
  const membership = state.userMemberships[entity.id];
  return {
    entityID,
    entity,
    membership,
    membershipLevel: membership && membership.level,
    initializingEntityPage,
    hasCompetitionEnded: selectors.hasCompetitionEnded(state),
    canJoin: selectors.canJoinCurrentEntity(state, ownProps)
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initializeEntityPage: actions.initializeEntityPage,
  joinTeam: actions.joinTeam,
  deleteTeam: actions.deleteTeam,
  deleteEntityMembership: actions.deleteEntityMembership
}, dispatch);

export default asPage({ requiresCompetitionStart: true, requiresCompetitionVerification: true, isGrey: true })(compose(
  withRouter,
  withLocalization('entity'),
  connect(mapStateToProps, mapDispatchToProps)
)(EntityPage));
