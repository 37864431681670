import * as React from 'react';
import { Alert } from 'reactstrap';
import { LProps, withLocalization } from '../utils/wrappers';
import { compose } from 'redux';
import { StyleState } from '../utils/styles';
import { asPage } from '../utils/wrappers/asPage';

const Missing = ({ t }: LProps<{}>) => (
  <Alert
    color={StyleState.Danger}
  >
    <p>{t("You haven't specify a competition")}</p>
  </Alert>
);

export default asPage({})(compose(
  withLocalization('common')
)(Missing));
