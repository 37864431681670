import { ExtraArguments } from '../extraArguments';
import { State, Modals } from '../state';
import up from 'updeep';
import { Dispatch } from 'redux';
import { patch } from './patch';
import { Entity, PledgeCompletions, PledgePoints, CompetitionUserPoint } from '../../userApi';
import logger from '../../utils/logger';

interface Pointable {
  points?: number;
  savingsCO2Lbs?: number;
  savingsWaterOz?: number;
  savingsGasGallons?: number;
  savingsElectricityKwh?: number;
}

const addPointsToScore = (points: Pointable, score: Pointable) => ({
  points: score.points + points.points,
  savingsCO2Lbs: score.savingsCO2Lbs + points.savingsCO2Lbs,
  savingsWaterOz: score.savingsWaterOz + points.savingsWaterOz,
  savingsGasGallons: score.savingsGasGallons + points.savingsGasGallons,
  savingsElectricityKwh: score.savingsElectricityKwh + points.savingsElectricityKwh
});

export const dismissStoryPointsModal = () => patch({
  modal: Modals.None,
  lastPointsEarned: null
});

export const addUserPoints = (points: CompetitionUserPoint, pledgeID?: number, carbon?: number) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const {
    user, userPledgeCompletions, userPledgePoints, entities, competition,
    competitionEntityScores, competitionUserScores, competitionScore, competitionPledges
  } = getState();
  const competitionUserScore = competitionUserScores[user.id];
  const completionUpdates: PledgeCompletions = {};
  const pledgePointsUpdates: PledgePoints = {};
  if (pledgeID) {
    completionUpdates[pledgeID] = (userPledgeCompletions[pledgeID] || 0) + 1;
    pledgePointsUpdates[pledgeID] = (userPledgePoints[pledgeID] || 0) + points.points;
  }

  dispatch(patch({
    competitionScore: {
      ...competitionScore,
      ...addPointsToScore(points, competitionScore)
    },
    competitionUserScores: {
      [user.id]: {
        ...competitionUserScore,
        ...addPointsToScore(points, competitionUserScore)
      }
    },
    userPledgeCompletions: completionUpdates,
    userPledgePoints: pledgePointsUpdates,
    lastPointsEarned: points,
    lastPointsCanEarnMore: completionUpdates[pledgeID] < competitionPledges[pledgeID].repeats,
    modal: Modals.StoryPoints
  }));

  // Update user's entity scores
  const entityIDs = Object.keys(user.acceptedCompetitionEntityMemberships[competition.id]);
  entityIDs.forEach((entityID) => {
    const entity = entities[entityID as any];
    if (!entity) return;
    const entityType = entity.type;
    if (entityType === Entity.TypeEnum.Team) {
      const index = competitionEntityScores.team.findIndex((ces) => ces.entityID === Number(entityID));
      if (index === -1) {
        logger.error(`addUserPoints: Could not find team CompetitionEntityScore for entityID: ${entityID}.`);
        return;
      }
      dispatch(patch({
        competitionEntityScores: { team: up.updateIn(`${index}`, addPointsToScore(points, competitionEntityScores.team[index]), competitionEntityScores.team) }
      }));
    } else if (entityType === Entity.TypeEnum.University) {
      const index = competitionEntityScores.university.findIndex((ces) => ces.entityID === Number(entityID));
      if (index === -1) {
        logger.error(`addUserPoints: Could not find university CompetitionEntityScore for entityID: ${entityID}.`);
        return;
      }
      dispatch(patch({
        competitionEntityScores: { university: up.updateIn(`${index}`, addPointsToScore(points, competitionEntityScores.university[index]), competitionEntityScores.university) }
      }));
    } else if (entityType === Entity.TypeEnum.HealthFacility) {
      const index = competitionEntityScores.healthFacility.findIndex((ces) => ces.entityID === Number(entityID));
      if (index === -1) {
        logger.error(`addUserPoints: Could not find healthFacility CompetitionEntityScore for entityID: ${entityID}.`);
        return;
      }
      dispatch(patch({
        competitionEntityScores: { healthFacility: up.updateIn(`${index}`, addPointsToScore(points, competitionEntityScores.healthFacility[index]), competitionEntityScores.healthFacility) }
      }));
    } else {
      logger.error(`Unexpected entity type when adding user points: ${entityType}`);
    }
  });
};
