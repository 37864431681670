import * as React from 'react';
import cx from 'classnames';
import { Table } from 'reactstrap';
import { CompetitionPledge, CompetitionPledgeCategories, PledgeCompletions, CompetitionScoreLevel } from '../userApi';
import { LProps, withLocalization } from '../utils/wrappers';
import { Link } from 'react-router-dom';
import { makePledgePath, makePledgeCategoryPath } from '../utils/routerHelper';
import { State } from '../redux/state';
import * as selectors from '../redux/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getScoreLevelPhoto, CompetitionPledges } from '../utils/userApiHelper';
import LinkButton from './LinkButton';
import PledgesTableHelp from './PledgesTableHelp';

interface ManualProps {
  competitionPledgeCategoryID: number;
}

interface PledgesByScoreLevel {
  scoreLevel?: CompetitionScoreLevel;
  pledges: CompetitionPledge[];
  active: boolean;
}

interface Props extends ManualProps {
  pledgesByLevel: PledgesByScoreLevel[];
  categories: CompetitionPledgeCategories;
  completions: PledgeCompletions;
  userIsSomeUniversityAdmin: boolean;
}

interface PledgesTableState {
  helpIsOpen: boolean;
}

const initialState: PledgesTableState = { helpIsOpen: false };

class PledgesTable extends React.Component<LProps<Props>, PledgesTableState> {
  public state = initialState;

  public render() {
    const {
      competitionPledgeCategoryID, pledgesByLevel, t, formatNumber, categories, completions,
      userIsSomeUniversityAdmin
    } = this.props;
    return (
      <div>
        <LinkButton
          onClick={() => this.setState({ helpIsOpen: true })}
        >{t('How is my score calculated?')}</LinkButton>
        <Table id="pledges_table" size="sm" >
          <thead>
            <tr className="table-primary">
              <th className="cc__title__column">{t('Title')}</th>
              {competitionPledgeCategoryID === 0 && <th className="cc__category__column">{t('Category')}</th>}
              <th>{t('Points')}</th>
              <th>
                <div className="cc__desktop__label">
                  {t('Pounds CO')}
                    <sub>2</sub>
                  {t('savings')}
                </div>
                <div className="cc__mobile__label">
                  {t('CO')}<sub>2</sub>
                </div>
              </th>
              <th>{t('Completions')}</th>
            </tr>
          </thead>
            {pledgesByLevel.map(({ pledges, scoreLevel, active }, i) => (
              <tbody key={scoreLevel.id}>
                {(pledges.length > 0) &&
                  <tr>
                    <th className={cx('cc__pledges_table__score_level', { 'table-active': !active })} colSpan={active ? 5 : 1}>
                      {getScoreLevelPhoto(scoreLevel) ? (
                        <img src={getScoreLevelPhoto(scoreLevel)} alt={scoreLevel.name} />
                      ):<span className="placeholder"/>}
                      &nbsp;{scoreLevel.name}
                      {}
                    </th>
                    {!active &&
                      <th className={cx('cc__pledges_table__score_level', { 'table-active': !active })} colSpan={4}>
                        {t('Earn {{points}} points to unlock these actions', { points: formatNumber(scoreLevel.threshold) })}
                      </th>
                    }
                  </tr>
                }
                {pledges.map((pledge) =>
                  <tr
                    key={pledge.id}
                    className={cx({
                      'table-success': completions[pledge.id] >= pledge.repeats,
                      'table-warning': completions[pledge.id] >= 1 && completions[pledge.id] < pledge.repeats,
                      'cc__pledge_inactive': i !== 0
                    })}
                  >
                    <td className="cc__title__column">
                    {(active || userIsSomeUniversityAdmin) && <Link to={makePledgePath(pledge, t)}>{pledge.shortTitle}</Link>}
                    {(!active && !userIsSomeUniversityAdmin) && pledge.shortTitle}
                    </td>
                    {competitionPledgeCategoryID === 0 &&
                      <td className="cc__category__column">
                        <Link
                          to={makePledgeCategoryPath(pledge.competitionPledgeCategoryID, t)}
                        >{categories[pledge.competitionPledgeCategoryID] ? categories[pledge.competitionPledgeCategoryID].name : ''}</Link>
                      </td>
                    }
                    <td>{pledge.points}</td>
                    <td>{formatNumber(pledge.savingsCO2Lbs, 0)}</td>
                    <td style={{ textAlign: 'center' }}>
                      {completions[pledge.id] === pledge.repeats && <i className={'fa fa-check'} />}
                      {completions[pledge.id] !== pledge.repeats && <span>{completions[pledge.id] || 0} / {pledge.repeats}</span>}
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
        </Table>
        <PledgesTableHelp
          isOpen={this.state.helpIsOpen}
          toggle={() => this.setState({ helpIsOpen: !this.state.helpIsOpen })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: ManualProps) => ({
  pledgesByLevel: selectors.pledgesByCategoryAndLevel(state, ownProps),
  competitions: state.userPledgeCompletions,
  categories: state.competitionPledgeCategories,
  completions: state.userPledgeCompletions,
  userIsSomeUniversityAdmin: selectors.userIsSomeUniversityAdmin(state)
});

export default compose(
  withLocalization('pledges'),
  connect(mapStateToProps)
)(PledgesTable) as React.SFC<ManualProps>;
