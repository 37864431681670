import { Dispatch } from 'redux';
import { State } from '../state';
import { findPledgeByUUID, userApi } from '../selectors';
import { CompetitionPledges } from '../../utils/userApiHelper';
import { addUserPoints } from './score';
import { ExtraArguments } from '../extraArguments';
import logger from '../../utils/logger';
import { trackEvent } from '../../utils/googleAnalytics';

export const createCalculatorPledgePoints = () => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const state = getState();
  const { user } = state;
  if (!user) return;
  const pledge = findPledgeByUUID(state, { uuid: CompetitionPledges.TakeCalculator });
  const api = userApi(state);
  try {
    const res = await api.postCompetitionPledgePoints(pledge.id);
    const { points } = res;
    if (points && points.points > 0) {
      addUserPoints(points, pledge.id)(dispatch, getState, extra);
    }
    trackEvent('calculator', 'create');
  } catch (err) {
    // Fail silently from user's perspective here.
    logger.error(err, `Failed to add user calculator points. userID: ${user.id}`);
    trackEvent('calculator', 'unhandledError', 'create');
  }
};
export const updateFootprint = (footprint:number) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const state = getState();
  const { user, competition } = state;
  const api = userApi(state);  
  try{
    const res = await api.postUserCompetitionFootprint(competition.id, {footprint})  
  } catch (err) {
    // Fail silently from user's perspective here.
    logger.error(err, `Failed to update footprint: ${user.id}`);
    trackEvent('calculator', 'unhandledError', 'updateFootprint');
  }
};