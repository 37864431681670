import * as React from 'react';
import { Popover, ListGroup, ListGroupItem, PopoverBody, Button } from 'reactstrap';
import { Routes, makeSimpleRoutePath, makeEntityPath, FaqSection, makeFaqPath, makeUserPath } from '../utils/routerHelper';
import ProfilePhoto from './ProfilePhoto';
import { UserRead, Entity, Competition } from '../userApi';
import { TranslationFunction } from 'i18next';
import { History } from 'history';
import LinkButton from './LinkButton';
import RouteLink from '../shared/RouteLink';
import { Link } from 'react-router-dom';
import { scrollToLoginForm } from '../routes/Home';

interface Props {
  t: TranslationFunction;
  history: History;
  logout: () => void;
  user: UserRead;
  university: Entity;
  competition: Competition;
  loggedIn: boolean;
  isProduction: boolean;
}

interface NavBarDropdownState {
  isOpen: boolean;
  isMobileOpen: boolean;
}

export default class NavBarDropdown extends React.Component<Props, NavBarDropdownState> {
  public state = { isOpen: false, isMobileOpen: false };

  public render() {
    const { t, history, logout, user, university, competition, loggedIn, isProduction} = this.props;
    return (
      <div>
        <div  id="cc__nav_bar__mobile__overlay" className={this.state.isMobileOpen ? 'show' : '' }>
          <div  id="cc__nav_bar__mobile__close">
            <img alt={t('close user menu')} src="/assets/navbar/mobile/times.png" onClick={() => this.setState({isMobileOpen: false})}
              srcSet="/assets/navbar/mobile/times@2x.png 2x,
                      /assets/navbar/mobile/times@3x.png 3x" />
          </div>
          <div  id="cc__nav_bar__mobile__container">
            {loggedIn && (
              <div  id="cc__nav_bar__mobile__links">
                  <RouteLink activable={true} route={Routes.Dashboard} onClick={() => this.setState({isMobileOpen: false})}>{t('HOME')}</RouteLink>
                  <RouteLink activable={true} route={Routes.Pledges}  onClick={() => this.setState({isMobileOpen: false})}>{t('ACTIONS')}</RouteLink>
                  <RouteLink activable={true} to={makeUserPath(competition.id, user, t)}  onClick={() => this.setState({isMobileOpen: false})}>{t('PROFILE')}</RouteLink>
                  <RouteLink activable={true} route={Routes.Account}  onClick={() => this.setState({isMobileOpen: false})}>{t('ACCOUNT')}</RouteLink>
                  <RouteLink activable={true} route={Routes.Teams}  onClick={() => this.setState({isMobileOpen: false})}>{t('TEAMS')}</RouteLink>
                  {university &&
                    <Link
                      onClick={() => this.setState({isMobileOpen: false})}
                      to={makeEntityPath(competition.id, university, t)}
                    >{t('CAMPUS')}</Link>
                  }
              </div>
            )}
            {!loggedIn && (
                <div  id="cc__nav_bar__mobile__links">
                  <RouteLink activable={true} route={Routes.About}>{t('ABOUT')}</RouteLink>
                  {!isProduction && <RouteLink activable={true} route={Routes.Blog}>{t('BLOG')}</RouteLink>}
                  <Link to={makeFaqPath(FaqSection.General, t)}>{t('FAQ')}</Link>
                  {university &&
                    <Link
                      to={makeEntityPath(competition.id, university, t)}
                    >{t('Campus')}</Link>
                  }
                </div>
            )}
          </div>
          {loggedIn && (
            <div  id="cc__nav_bar__mobile__buttons">
              <Button
                color="primary"
                onClick={logout}
                style={{ width: '250px', margin: '0 auto'}}
              >{t('Sign out')}</Button>
            </div>
          )}
            {!loggedIn && (
              <div  id="cc__nav_bar__mobile__buttons">
                <Button
                  color="primary"
                  onClick={() => {history.push(makeSimpleRoutePath(Routes.Signup, t)); scrollToLoginForm(); }}
                  style={{ width: '250px', margin: '0 auto', marginBottom: 20}}
                >{t('Get started')}</Button>
                <Button
                  outline={true}
                  color="light"
                  onClick={() => {history.push(makeSimpleRoutePath(Routes.Login, t)); scrollToLoginForm(); }}
                  style={{ width: '250px', margin: '0 auto'}}
                >{t('Login')}</Button>
              </div>
            )}

        </div>
        <div  id="cc__nav_bar__dropdown_toggle">
          {loggedIn && (
            <LinkButton className="cc_nav_bar__desktop_menu" onClick={() => this.openDropdown()}>
              <ProfilePhoto
                rounded={true}
                outerStyle={{width: '60px', height: '60px'}}
                imgProps={{ style: {  cursor: 'pointer' } }}
                profilePhoto={user.profilePhoto}
                alt={user.firstName}
              />
            </LinkButton>
          )}
          <LinkButton className="cc_nav_bar__mobile_menu" onClick={() => this.openMobileDropdown()}>
            <img alt={t('toggle user menu')} src="/assets/navbar/mobile/hamburger.png"
                srcSet="/assets/navbar/mobile/hamburger@2x.png 2x,
                        /assets/navbar/mobile/hamburger@3x.png 3x" />
          </LinkButton>
        </div>
        <Popover trigger="click" placement="bottom" target="cc__nav_bar__dropdown_toggle" isOpen={this.state.isOpen}>
          <PopoverBody style={{ padding: '0px' }}>
            <ListGroup>
              <ListGroupItem
                id="cc__nav_bar__dropdown__profile"
                style={{ border: 'none', cursor: 'pointer' }}
                onClick={() => history.push(makeUserPath(competition.id, user, t))}
              >{t('Profile')}</ListGroupItem>
              <ListGroupItem
                id="cc__nav_bar__dropdown__account"
                style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none', cursor: 'pointer' }}
                onClick={() => history.push(makeSimpleRoutePath(Routes.Account, t))}
              >{t('Account')}</ListGroupItem>
              <ListGroupItem
                id="cc__nav_bar__dropdown__logout"
                style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none', cursor: 'pointer' }}
                onClick={logout}
              >{t('Sign out')}</ListGroupItem>
            </ListGroup>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
  private openDropdown() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  private openMobileDropdown() {
    this.setState({isMobileOpen: true});
  }
}
