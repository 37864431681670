import { patch } from './patch';
import { pick } from 'lodash';
import up from 'updeep';
import { InviteAFriendForm } from '../../utils/forms/inviteAFriend';
import { Dispatch } from 'redux';
import * as selectors from '../selectors';
import initialState, { State } from '../state';
import { ExtraArguments } from '../extraArguments';
import { getValidationErrors, someMatchingEmailDomain } from '../../utils/userApiHelper';
import { handleUserApiError } from './handleUnexpectedError';
import { toast } from './toast';
import { StyleState } from '../../utils/styles';
import { ValidationError, matchesErrorCode } from '../../userApi';
import { trackEvent } from '../../utils/googleAnalytics';

export const removeInviteAFriendVE = (code: ValidationError.CodeEnum) => async (dispatch: Dispatch, getState: () => State, _extra: ExtraArguments) => {
  const { inviteAFriendVE } = getState();
  dispatch(patch({
    inviteAFriendVE: up(up.reject<ValidationError>(matchesErrorCode(code)), inviteAFriendVE)
  }));
};

export const postInvite = (inviteAFriendForm: InviteAFriendForm, formID: string) => async (dispatch: Dispatch, getState: () => State, extra: ExtraArguments) => {
  const t = extra.i18n.getFixedT(extra.i18n.language, 'inviteAFriend');
  const state = getState();
  const { competition } = state;
  dispatch(patch({ spinner: true }));
  try {
    const params = pick(inviteAFriendForm, ['name', 'email']);
    if (!someMatchingEmailDomain(params.email, competition.permittedDomains)) {
      toast({
        style: StyleState.Warning,
        message: t('You can only invite users with a valid competition email domain.')
      })(dispatch, getState);
      dispatch(patch({ spinner: false }));
      return;
    }

    const api = selectors.userApi(state);
    await api.postInvite(state.competition.name,{ name: params.name, email: params.email });
    trackEvent('invite', 'create');
    dispatch(patch({
      inviteAFriendVE: up.constant(initialState.inviteAFriendVE),
      savedInviteAFriendFormID: formID,
      spinner: false
    }));
    toast({
      style: StyleState.Success,
      message: t('Your invite has been sent.')
    })(dispatch, getState);
  } catch (err) {
    const validationErrors = await getValidationErrors(err);
    if (validationErrors) {
      dispatch(patch({
        inviteAFriendVE: validationErrors,
        spinner: false
      }));
      return;
    }
    handleUserApiError(err)(dispatch, getState);
    trackEvent('invite', 'unhandledError', 'create');
  }
};
