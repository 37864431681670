import * as React from 'react';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { LProps, withLocalization } from '../../utils/wrappers';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { State } from '../../redux/state';
import { connect } from 'react-redux';
import ImageUploadTrigger from './ImageUploadTrigger';
import ImageInput from './ImageInput';
import { DataURLFromFile, clearFileInput } from '../../utils/image';
import ImageEditor from './ImageEditor';
import FormTextAspectRatio from './FormTextAspectRatio';
import { ImageStoreProps, withImages } from '../../redux/imageStore';
import { imageMutationToServerIM } from '../../utils/userApiHelper';
import { ImageMutation } from '../../userApi';
import actions from '../../redux/actions';

type MediaType ='picture'|'video';
interface ManualProps {
  id: string;
  disabled?: boolean;
  currentImage: string;
  prompt?: string;
  photoClassName?: string;
  readyPhotoClassName?: string;
  aspectRatio?: string;
  onDelete?: () => void;
  banner?: boolean;
  
}

interface Props extends ManualProps, ImageStoreProps {
  postedImage: string;
  postImageMutation: (dataURLFromFile: DataURLFromFile, mutation: Partial<ImageMutation>) => Promise<DataURLFromFile>;
}

interface ImageUploaderState {
  dataURLFromFile: DataURLFromFile;
  editing: boolean;
  type: MediaType;
}
const initialState: ImageUploaderState = {
  dataURLFromFile: null,
  editing: false,
  type: 'picture'
};
const wasImagePostedOrCleared = (nextProps: Props, actualProps: Props) =>
  nextProps.postedImage !== actualProps.postedImage;

class ImageUploader extends React.Component<LProps<Props>, ImageUploaderState> {
  public state = initialState;

  private open() {
    if (this.props.disabled) return;
    document.getElementById(this.props.id).click();
  }

  private delete() {
    const { onDelete } = this.props;
    const { dataURLFromFile } = this.state;
    if (dataURLFromFile) {
      this.clearDataURL();
    } else if (onDelete) {
      onDelete();
    }
  }

  // Once the photo has been uploaded, display the uploaded photo,
  // clear the file input, and dataURL preview so it is not present
  // once the form has been saved.
  public async componentWillReceiveProps(nextProps: LProps<Props>) {
    if (wasImagePostedOrCleared(nextProps, this.props)) {
      this.clearDataURL();
    }
  }

  public componentWillUnmount() {
    const { delImage, id } = this.props;
    delImage(id);
  }

  private clearDataURL() {
    const { id, delImage } = this.props;
    this.setState({ dataURLFromFile: null, editing: false });
    delImage(id);
    clearFileInput(id);
  }

  private async setEditedDataURLFromFile(dataURLFromFile: DataURLFromFile, initialize: boolean = false) {
    const { id, setImage, postImageMutation } = this.props;
    let type:MediaType = 'picture'
    let editing = this.state.editing;
    let readyImage = dataURLFromFile;    
    if (initialize && dataURLFromFile.mimeType.indexOf('video')== -1) {
      // By default this will compress the image so that further compression is not necessary
      // on client.
      const mutation = imageMutationToServerIM({ backgroundColor: '' }, 1, ImageMutation.ContentTypeEnum.Png);
      readyImage = await postImageMutation(dataURLFromFile, mutation);
    }else if(initialize){
      readyImage = dataURLFromFile
      type= 'video'
    }
    if (!readyImage) editing = false;
    this.setState({ dataURLFromFile: readyImage, editing, type });
    setImage(id, dataURLFromFile);
    clearFileInput(id);
  }

  public render() {
    const { dataURLFromFile, editing, type } = this.state;
    const {
      id, t, currentImage, prompt, onDelete, aspectRatio,
      readyPhotoClassName = '', photoClassName = '', disabled = false
    } = this.props;
    const anyImage = Boolean(dataURLFromFile || currentImage);
    return (
      <div
        className={'cc__image_uploader'}
      >
        <ImageInput
          id={id}
          onChange={(newFile) => this.setEditedDataURLFromFile(newFile, true)}
        />
        {!anyImage &&
          <ImageUploadTrigger fileInputID={id} prompt={prompt} disabled={disabled} />
        }
        <div className={cx(photoClassName, { [readyPhotoClassName]: !editing && anyImage })}>
          {anyImage && type =='picture' &&
            (() => {
              if (editing) {
                  return (
                    <ImageEditor
                      dataURLFromFile={dataURLFromFile}
                      onChange={(update) => this.setEditedDataURLFromFile(update)}
                    />
                  );
                } else if (dataURLFromFile) {
                  return (<img src={dataURLFromFile.dataURL} alt={dataURLFromFile.filename || t('preview')} />);
                } else if (currentImage) {
                  return (<img src={currentImage} alt={t('image')} />);
                }
              }
            )()
          }
          {anyImage && type =='video' && 
            <video controls src={dataURLFromFile.dataURL} title={dataURLFromFile.filename || t('preview')} />
          }
        </div>
        {anyImage &&
          <div className={'cc__image_loader__controls'}>
            <Button
              size="sm"
              color="primary"
              title={t('Add') + " " + t(type)}
              aria-label={t('Add') + " " + t(type)}
              disabled={disabled}
              onClick={() => this.open()}
            ><i className="fa fa-picture-o" /></Button>
            {dataURLFromFile  && type=='picture' &&
              <Button
                outline={true}
                aria-label={t('Edit picture')}
                size="sm"
                color="primary"
                onClick={() => this.setState({ editing: !this.state.editing })}
              ><i className="fa fa-pencil" /></Button>
            }
            {(dataURLFromFile || onDelete) &&
              <Button
                size="sm"
                title={t('Delete') + " " + t(type)}                
                aria-label={t('Delete') + " " + t(type)}                
                color="danger"
                onClick={() => this.delete()}
              ><i className="fa fa-times" /></Button>}
          </div>
        }
        {aspectRatio && <FormTextAspectRatio idealRatio={aspectRatio} />}
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: Partial<ManualProps>): Partial<Props> => ({
  postedImage: state.postedImages[ownProps.id]
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: Partial<ManualProps>) => bindActionCreators({
  postImageMutation: actions.postImageMutation
}, dispatch);

export default compose(
  withLocalization('imageUploader'),
  connect(mapStateToProps, mapDispatchToProps),
  withImages([])
)(ImageUploader) as React.SFC<ManualProps>;
