import * as React from 'react';
import up from 'updeep';
import { compose, Dispatch } from 'redux';
import { isEmpty } from 'lodash';
import { Input, FormGroup, Button, FormFeedback, Label, Collapse } from 'reactstrap';
import { LProps, withLocalization } from '../../utils/wrappers';
import { State, Modals } from '../../redux/state';
import actions, { bindActionCreators } from '../../redux/actions';
import { connect } from 'react-redux';
import { UserUpdateErrors, makeUserUpdateErrors, UserUpdateForm, userUpdateVEMessages } from '../../utils/forms';
import Radio from '../../shared/Radio';
import { UserType, Competition, UserRead, UserWrite, ValidationErrors, ValidationError, removeVEForField, matchesErrorCode } from '../../userApi';
import ImageUploader from '../../shared/ImageUploader/ImageUploader';
import ConfirmModal from './ConfirmModal';
import { validatePassword } from '../../utils/validations';
import { userReadToWrite } from '../../utils/userApiHelper';
import { Patch } from '../../redux/actions/all';
import * as selectors from '../../redux/selectors';
import { uuid } from '../../utils/uuid';
import Checkbox from '../../shared/Checkbox';
import ConfirmDeleteModal from './ConfirmDeleteModal';

interface Props {
  user: UserRead;
  validationErrors: ValidationErrors;
  competition: Competition;
  modal: Modals;
  savedUserUpdateID: string;
  isUsersDeletePasswordIncorrect: boolean;
  canSetHealthSystemsEmployee: boolean;
  removeUserUpdateVE: (code: ValidationError.CodeEnum) => void;
  removeUserDeleteVE: (code: ValidationError.CodeEnum) => void;
  putUser: (userUpdateForm: UserUpdateForm, inputID: string, userUpdateID: string) => void;
  deleteUser: (password: string) => void;
  setModal: (modal: Modals) => void;
  closeModal: () => void;
  patch: Patch;
}

const fileInputID = 'user_update_form__profile_image__input';

interface UserUpdateState {
  user: Partial<UserWrite>;
  newPassword: string;
  confirmNewPassword: string;
  errors: Partial<UserUpdateErrors>;
  dirty: { [key: string]: boolean };
  submitted: boolean;
  changingPassword: boolean;
}

const initialState: Partial<UserUpdateState> = {
  user: { options: {} },
  errors: {},
  dirty: {},
  submitted: false,
  newPassword: undefined,
  confirmNewPassword: undefined
};

class UserUpdate extends React.Component<LProps<Props>, UserUpdateState> {
  public state = initialState as UserUpdateState;
  private id = uuid();

  public componentWillMount() {
    const { user } = this.props;
    this.setState({
      user: { ...initialState.user, ...userReadToWrite(user) }
    });
  }

  public componentWillReceiveProps(nextProps: LProps<Props>) {
    const { user, t } = this.props;
    if (user.profilePhoto !== nextProps.user.profilePhoto) {
      // In this scenario, the photo was saved and
      // updated user.profilePhoto in state, but the user update failed to save.
      this.setState({
        user: { ...this.state.user, profilePhoto: nextProps.user.profilePhoto }
      });
    }
    const { validationErrors } = this.props;
    if (nextProps.validationErrors !== validationErrors) {
      const errors = makeUserUpdateErrors(this.state, nextProps.validationErrors, t);
      this.setState({ errors });
    }

    if (nextProps.savedUserUpdateID === this.id) {
      this.clear(nextProps);
      this.id = uuid();
    }
  }

  private update(update: Partial<UserUpdateState>, validate: boolean = false) {
    const { validationErrors, t } = this.props;
    if (validate) {
      const errors = makeUserUpdateErrors(this.state, validationErrors, t);
      update.errors = errors;
    }
    this.setState(update as UserUpdateState);
  }

  private markDirty(field: string) {
    const { validationErrors, t, patch } = this.props;
    const dirty = { ...this.state.dirty, [field]: true };
    if (field === 'newPassword') {
      dirty.confirmNewPassword = true;
    }
    let ve = validationErrors;
    if (userUpdateVEMessages[field]) {
      ve = removeVEForField(field, validationErrors, userUpdateVEMessages);
      patch({ loginVE: up.constant(ve) });
    }

    const update: Partial<UserUpdateState> = { dirty };
    const errors = makeUserUpdateErrors({ ...this.state, dirty }, validationErrors, t);
    update.errors = errors;
    this.setState(update as UserUpdateState);
  }

  private submit() {
    const { setModal, validationErrors, t } = this.props;
    const submitted = true;
    const errors = makeUserUpdateErrors({ ...this.state, submitted }, validationErrors, t);

    this.setState({ errors, submitted });
    if (isEmpty(errors)) {
      setModal(Modals.UserUpdatePassword);
    }
  }

  private clear(props: LProps<Props>) {
    const { user } = props;
    this.setState({
      ...initialState,
      user: userReadToWrite(user)
    } as UserUpdateState);
  }

  private toggleChangePassword() {
    const { changingPassword, errors, dirty } = this.state;
    if (changingPassword) {
      delete dirty.newPassword;
      delete errors.newPassword;
      delete dirty.confirmNewPassword;
      delete errors.confirmNewPassword;
      this.setState({
        changingPassword: false, errors, dirty,
        newPassword: undefined, confirmNewPassword: undefined
      });
    } else {
      this.setState({ changingPassword: true });
    }
  }

  public render() {
    const {
      putUser, removeUserUpdateVE, removeUserDeleteVE,
      closeModal, modal, deleteUser, isUsersDeletePasswordIncorrect,
      t, setModal, canSetHealthSystemsEmployee
    } = this.props;
    const { user, errors, newPassword, confirmNewPassword, changingPassword } = this.state;
    const isNewPasswordValid = validatePassword(newPassword) as boolean;
    return (
      <div id="user_update_form">
        {/** The display: 'flex' is to fix an issue on desktop where the .ReactCrop element stretches the width of the container. */}
        <div style={{ display: 'flex' }}>
          <ImageUploader
            id={fileInputID}
            currentImage={user.profilePhoto}
            readyPhotoClassName={'cc__profile_photo cc__rounded cc__profile_photo_center'}
            aspectRatio={t('1:1')}
            onDelete={() => this.update({ user: { ...user, profilePhoto: '' } })}
          />
        </div>
        <FormGroup id="user_update_form__firstName">
          <Label>{t('First name*')}</Label>
          <Input
            aria-label={t('First name')}
            value={user.firstName || ''}
            onChange={(e) => this.update({ user: up({ firstName: e.target.value }, this.state.user) })}
            onBlur={() => this.markDirty('firstName')}
          />
          {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
        </FormGroup>
        <FormGroup id="user_update_form__lastName">
          <Label>{t('Last name')}</Label>
          <Input
            aria-label={t('Last name')}
            value={user.lastName || ''}
            onChange={(e) => this.update({ user: up({ lastName: e.target.value }, this.state.user) })}
            onBlur={() => this.markDirty('lastName')}
          />
          {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
        </FormGroup>
        <div>
          <div
            id="user_update_form__update_password__toggle"
            role="button"
            className="cc__bluetext"
            style={{ cursor: 'pointer' }}
            onClick={() => this.toggleChangePassword()}
          >{changingPassword ? t('Cancel') : t('Update password')}</div>
          <Collapse isOpen={changingPassword}>
            <div style={{ padding: '7px', marginBottom: '10px' }}>
              <FormGroup
                id="user_update_form__newPassword"
              >
                <Label>{t('New password')}</Label>
                <Input
                  aria-label={t('New password')}
                  value={newPassword as string || ''}
                  invalid={Boolean(errors.newPassword)}
                  valid={isNewPasswordValid}
                  type={'password'}
                  onBlur={() => this.markDirty('newPassword')}
                  onChange={(e) => this.update({ newPassword: e.target.value })}
                />
                {errors.newPassword && <FormFeedback>{errors.newPassword}</FormFeedback>}
              </FormGroup>
              <FormGroup
                id="user_update_form__confirmNewPassword"
              >
                <Label>{t('Confirm password')}</Label>
                <Input
                  aria-label={t('Confirm password')}
                  value={confirmNewPassword || ''}
                  invalid={isNewPasswordValid && newPassword !== confirmNewPassword}
                  valid={isNewPasswordValid && newPassword === confirmNewPassword}
                  type={'password'}
                  onChange={(e) => this.update({ confirmNewPassword: e.target.value }, true)}
                  onBlur={() => this.markDirty('confirmNewPassword')}
                  autoComplete={'off'}
                />
                {errors.confirmNewPassword && <FormFeedback>{errors.confirmNewPassword}</FormFeedback>}
              </FormGroup>
            </div>
          </Collapse>
        </div>
        <FormGroup
          id="user_update_form__user_type"
          role={'radiogroup'}
          aria-labelledby={'user_update__uc_affiliation__label'}
        >
          <Label id={'user_update__uc_affiliation__label'}>{t('UC Affiliation')}</Label><br />
          <Radio
            name={'user_type'}
            value={UserType.Student.toString()}
            checked={user.type === UserType.Student}
            onChange={(userType) => this.update({ user: { ...user, type: userType as any } })}
          >{t('Student')}</Radio>&nbsp;&nbsp;
          <Radio
            name={'user_type'}
            value={UserType.Faculty.toString()}
            checked={user.type === UserType.Faculty}
            onChange={(userType) => this.update({ user: { ...user, type: userType as any } })}
          >{t('Faculty')}</Radio>&nbsp;&nbsp;
          <Radio
            name={'user_type'}
            value={UserType.Staff.toString()}
            checked={user.type === UserType.Staff}
            onChange={(userType) => this.update({ user: { ...user, type: userType as any } })}
          >{t('Staff')}</Radio>&nbsp;&nbsp;
        </FormGroup>
        {canSetHealthSystemsEmployee &&
          <FormGroup
            id="user_update__healthFacilityEmployee"
          >
            <Checkbox
              checked={Boolean(user.options.isHealthFacilityEmployee)}
              onChange={(checked) => this.update({ user: { ...user, options: { ...user.options, isHealthFacilityEmployee: checked } } })}
            >
              <span>{t('I am a UC Health System employee')}</span>
            </Checkbox>
          </FormGroup>
        }
        <FormGroup
          id="user_update__labUser"
        >
          <Checkbox
            checked={Boolean(user.options.isLabUser)}
            onChange={(checked) => this.update({ user: { ...user, options: { ...user.options, isLabUser: checked } } })}
          >
            <span>{t('I am a lab user')}</span>
          </Checkbox>
        </FormGroup>
        <div className="cc__space_around cc__buttons">
          <Button
            color="primary"
            id="user_update_form__submit"
            onClick={() => this.submit()}
          >{t('Update')}</Button>
          <Button
            outline={true}
            color="warning"
            id="user_update_form__reset"
            onClick={() => this.clear(this.props)}
          >{t('Cancel')}</Button>
          <Button
            color="danger"
            id="user_update_form__delete"
            onClick={() => setModal(Modals.UserDeletePassword)}
          >{t('Delete Account')}</Button>
        </div>
        {modal === Modals.UserUpdatePassword &&
          <ConfirmModal
            t={t}
            show={true}
            close={closeModal}
            confirm={(password: string) => putUser({ password, ...this.state }, fileInputID, this.id)}
            error={errors.password}
            removeUserUpdateVE={removeUserUpdateVE}
          />
        }
        {modal === Modals.UserDeletePassword &&
          <ConfirmDeleteModal
            t={t}
            show={true}
            isPasswordInvalid={isUsersDeletePasswordIncorrect}
            close={closeModal}
            confirm={deleteUser}
            removeUserDeleteVE={removeUserDeleteVE}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State, _props: {}): Partial<Props> => ({
  competition: state.competition,
  modal: state.modal,
  user: state.user,
  validationErrors: state.userUpdateVE,
  isUsersDeletePasswordIncorrect: state.userDeleteVE && state.userDeleteVE.some(matchesErrorCode(ValidationError.CodeEnum.ErrPasswordIncorrect)),
  savedUserUpdateID: state.savedUserUpdateID,
  canSetHealthSystemsEmployee: selectors.canSetHealthSystemsEmployee(state)
});

const mapDispatchToProps = (dispatch: Dispatch, _props: {}): Partial<Props> =>
  bindActionCreators({
    putUser: actions.putUser,
    deleteUser: actions.deleteUser,
    setModal: actions.setModal,
    closeModal: actions.closeModal,
    removeUserUpdateVE: actions.removeUserUpdateVE,
    removeUserDeleteVE: actions.removeUserDeleteVE,
    patch: actions.patch
  }, dispatch);

export default compose(
  withLocalization('updateUser'),
  connect(mapStateToProps, mapDispatchToProps)
)(UserUpdate);
